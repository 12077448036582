import { useState } from 'react';
import Modal from '../../../../../../common/components/Modal';
import { IconPlus } from '@tabler/icons-react';
import RateSeasonForm from './RateSeasonForm';
import RateSeasonsList from './RateSeasonsList';
import Button from '../../../../../../common/components/form/Button';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useTranslation } from 'react-i18next';

export default function RateSeasonModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate();
  const [showNewSeasonForm, setShowNewSeasonForm] = useState(false);

  return (
    <Modal
      title={t('pms:configuration.accommodationSettings.seasons')}
      isOpen={isOpen}
      onClose={onClose}
      size='sm'
    >
      <div ref={parentRef}>
        <RateSeasonsList />
        {showNewSeasonForm ? (
          <div className='card'>
            <div className='card-body'>
              <RateSeasonForm
                action='create'
                onCancel={() => setShowNewSeasonForm(false)}
                onSuccess={() => {
                  setShowNewSeasonForm(false);
                }}
              />
            </div>
          </div>
        ) : (
          <Button
            type='button'
            onClick={() => {
              setShowNewSeasonForm(true);
            }}
          >
            <IconPlus />
            {t('pms:configuration.accommodationSettings.seasonsForm.addNewSeason')}
          </Button>
        )}
      </div>
    </Modal>
  );
}
