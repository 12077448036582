import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Logo from '../../common/static/images/Logo.svg';
import CompleteRegistration from '../components/CompleteRegistration';
import Email from '../components/Email';
import LoginPassword from '../components/LoginPassword';
import CompletePasswordReset from '../components/password-reset/CompletePasswordReset';
import RequestPasswordReset from '../components/password-reset/RequestPasswordReset';
import { useAuth } from '../hooks/AuthContext';
import LanguageSelector from '../components/common/LanguageSelector';
import Demo from '../components/Demo';
import LoginToken from '../components/LoginToken';

export default function Auth() {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  if (isAuthenticated && pathname !== '/auth/login/token') {
    return <Navigate to='/' />;
  }

  const emailParam = searchParams.get('email');
  const idParam = searchParams.get('id');
  const tokenParam = searchParams.get('token');
  const showSessionExpiredError = searchParams.get('sessionExpired') !== null;

  return (
    <div className='d-flex flex-column page page-center'>
      <div className='container-tight py-4'>
        <div className='text-center mb-4'>
          <a href='https://simpulse.it/' className='navbar-brand navbar-brand-autodark'>
            <img
              src={Logo}
              alt='SimPulse logo'
              width={56}
              height={56}
              style={{ borderRadius: '100%' }}
            />
          </a>
        </div>
        <LanguageSelector />
        <Routes>
          <Route index element={<Email showSessionExpiredError={showSessionExpiredError} />} />
          <Route
            path='/login/token'
            element={
              tokenParam !== null && tokenParam !== '' ? (
                <LoginToken token={tokenParam} />
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/login'
            element={
              emailParam !== null && emailParam !== '' ? (
                <LoginPassword email={emailParam} />
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/complete-registration'
            element={
              idParam !== null && tokenParam !== null ? (
                <CompleteRegistration uuid={idParam} token={tokenParam} />
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/request-password-reset'
            element={<RequestPasswordReset email={emailParam} />}
          />
          <Route
            path='/reset-password'
            element={
              idParam !== null && tokenParam !== null ? (
                <CompletePasswordReset uuid={idParam} token={tokenParam} />
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route path='/demo' element={<Demo />} />
        </Routes>

        <div className='text-center mt-4'>
          <a href='https://my.simpulse.it/'>Accedi alla versione precedente di SimPulse</a>
        </div>
      </div>
    </div>
  );
}
