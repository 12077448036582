import FormInput from '../../../common/components/form/input';
import { FormErrors } from '../../../common/utils/api';

export function UserPasswordField({
  formErrors,
  disabled,
}: {
  formErrors: FormErrors | null;
  disabled: boolean;
}) {
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['user', 'password']}
      type='password'
      name='userPassword'
      title='Password'
      disabled={disabled}
      required
    />
  );
}
