import { useState } from 'react';
import { IconCheck, IconCopy, IconMail, IconPhone } from '@tabler/icons-react';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import Modal from '../../../common/components/Modal';
import Button from '../../../common/components/form/Button';

export function ContactsModalButtons({
  size,
  bookingPhone,
  bookingEmail,
}: {
  size: 'normal' | 'small';
  bookingPhone: string | null;
  bookingEmail: string | null;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);
  const [isEmailCopied, setIsEmailCopied] = useState(false);

  const bookingPhoneablePhone = bookingPhone?.replaceAll(/[^0-9+]/g, '');

  return (
    <>
      {(bookingPhone || bookingEmail) && (
        <Button
          className={size === 'small' ? 'btn btn-sm btn-primary' : 'btn btn-primary'}
          onClick={() => setIsModalOpen(true)}
        >
          {bookingPhone && <IconPhone />}
          {bookingEmail && <IconMail />}
        </Button>
      )}
      <Modal title='Contact' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {bookingPhone && (
          <div className='row mb-3'>
            <div className='input-group w-100'>
              <a
                href={`tel:${bookingPhoneablePhone}`}
                className='btn btn-primary border-primary btn-lg form-control'
              >
                <IconPhone />
                {bookingPhone}
              </a>
              <Button
                onClick={() => {
                  copy(bookingPhone);
                  setIsPhoneCopied(true);
                  setTimeout(() => setIsPhoneCopied(false), 3000);
                  toast.success('Copied to clipboard');
                }}
                disabled={isPhoneCopied}
                className='btn border-primary btn-lg'
              >
                {isPhoneCopied ? <IconCheck /> : <IconCopy />}
              </Button>
            </div>
          </div>
        )}
        {bookingEmail && (
          <div className='row mb-3'>
            <div className='input-group w-100'>
              <a
                href={`mailto:${bookingEmail}`}
                className='btn btn-primary border-primary btn-lg form-control'
              >
                <IconMail />
                {bookingEmail}
              </a>
              <Button
                onClick={() => {
                  copy(bookingEmail);
                  setIsEmailCopied(true);
                  setTimeout(() => setIsEmailCopied(false), 3000);
                  toast.success('Copied to clipboard');
                }}
                disabled={isEmailCopied}
                className='btn border-primary btn-lg'
              >
                {isEmailCopied ? <IconCheck /> : <IconCopy />}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
