import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { createMutation } from '../../../../../../utils/api';
import { euroFormatter } from '../../../../../../utils/string';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import FormInput from '../../../../../common/components/form/input';
import { FormErrors } from '../../../../../common/utils/api';
import { Booking } from '../../../../api/bookings/bookings';
import { getBookingChargesAmounts } from './EditBookingCharges';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../common/components/Modal';
import { isOfflinePaymentMethod } from '../../../../utils/payment-method';
import { useCompanyContext } from '../../../../../common/contexts/CompanyContext';

const PAYMENT_METHOD_WIDTH = '100px';

interface CreateRateSeasonFormProps {
  action: 'create';
  bookingPaymentUuid?: undefined;
  bookingPayment?: undefined;
  bookingUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateRateSeasonFormProps {
  action: 'update';
  bookingPaymentUuid: string;
  bookingPayment: Booking['bookingPayments'][number];
  bookingUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
type RateSeasonFormProps = CreateRateSeasonFormProps | UpdateRateSeasonFormProps;

export function getBookingPaymentsAmounts(booking: Booking) {
  const bookingPayments = booking.bookingPayments;
  const totalAmount = bookingPayments.reduce(
    (acc, bookingPayment) => acc + bookingPayment.totalAmount,
    0,
  );

  return { totalAmount };
}

function BookingPaymentForm({
  action,
  bookingPaymentUuid,
  bookingPayment,
  bookingUuid,
  onSuccess,
  onCancel,
}: RateSeasonFormProps) {
  const { t } = useTranslation(['pms']);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const { paymentMethods } = useCompanyContext();

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.pms.booking, trpcContext.pms.bookingPayment],
    onSuccess: () => {
      toast.success(`Booking payment updated`);
      onSuccess();
    },
    setFormErrors,
  };
  const createBookingPayment = createMutation(
    trpc.pms.bookingPayment.createBookingPayment,
    mutationOptions,
  );
  const updateBookingPayment = createMutation(
    trpc.pms.bookingPayment.updateBookingPayment,
    mutationOptions,
  );
  const deleteBookingPayment = createMutation(
    trpc.pms.bookingPayment.deleteBookingPayment,
    mutationOptions,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    const paymentMethodUuid = form.paymentMethodUuid.value as string;

    const data = {
      name: form.bookingPaymentName.value as string,
      date: DateTime.now().toISODate()!,
      notes: '',
      bookingUuid,
      bookingUnitUuid: null,
      paymentMethodUuid: paymentMethodUuid === '' ? null : paymentMethodUuid,
      totalAmount: parseFloat(form.totalAmount.value),
    };

    if (action === 'create') {
      createBookingPayment.mutate(data);
    } else {
      updateBookingPayment.mutate({ ...data, uuid: bookingPaymentUuid });
    }
  };

  const disabled =
    createBookingPayment.isLoading ||
    updateBookingPayment.isLoading ||
    deleteBookingPayment.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <FormInput
            type='text'
            title={t('pms:booking.form.description')}
            name='bookingPaymentName'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={bookingPayment?.name ?? ''}
          />
        </div>
        <div className='col-md-3'>
          <FormInput
            type='select'
            title={t('pms:booking.form.method')}
            name='paymentMethodUuid'
            errorPath={['paymentMethodUuid']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={bookingPayment?.paymentMethodUuid ?? ''}
          >
            <option value=''></option>
            {paymentMethods
              .filter((pm) => pm.provider === null)
              .map((paymentMethod) => (
                <option key={paymentMethod.uuid} value={paymentMethod.uuid}>
                  {paymentMethod.name}
                </option>
              ))}
          </FormInput>
        </div>
        <div className='col-md-3'>
          <FormInput
            type='number'
            title={t('pms:booking.form.total')}
            className='form-control'
            name='totalAmount'
            step='0.01'
            errorPath={['totalAmount']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={bookingPayment?.totalAmount.toString()}
            autoFocus
          />
        </div>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger w-100'
              isLoading={deleteBookingPayment.isLoading}
              disabled={disabled}
              hideContentWhenLoading
              onClick={() => {
                if (confirm('Are you sure you want to delete this booking payment?')) {
                  deleteBookingPayment.mutate({ uuid: bookingPaymentUuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            disabled={disabled}
            isLoading={createBookingPayment.isLoading || updateBookingPayment.isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}

function BookingPaymentElement({
  bookingUuid,
  bookingPayment,
  action,
}: {
  bookingUuid: string;
  bookingPayment: Booking['bookingPayments'][number];
  action: 'view' | 'edit';
}) {
  const [parentRef] = useAutoAnimate<HTMLTableRowElement>();
  const { paymentMethods } = useCompanyContext();
  const [showEditForm, setShowEditForm] = useState(false);

  const paymentMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.uuid === bookingPayment.paymentMethodUuid,
  );

  return (
    <tr ref={parentRef}>
      {showEditForm ? (
        <td colSpan={5}>
          <BookingPaymentForm
            action='update'
            bookingUuid={bookingUuid}
            bookingPayment={bookingPayment}
            bookingPaymentUuid={bookingPayment.uuid}
            onSuccess={() => setShowEditForm(false)}
            onCancel={() => setShowEditForm(false)}
          />
        </td>
      ) : (
        <>
          <td>{bookingPayment.name}</td>
          <td className='text-center '>
            {paymentMethod && (
              <span
                className='badge bg-blue-lt text-truncate'
                style={{ width: PAYMENT_METHOD_WIDTH }}
              >
                {paymentMethod.name}
              </span>
            )}
          </td>
          <td className='text-end'>{euroFormatter.format(bookingPayment.totalAmount)}</td>
          {action === 'edit' && (
            <td className='text-center'>
              <Button type='button' className='btn btn-icon' onClick={() => setShowEditForm(true)}>
                <IconPencil size='1em' />
              </Button>
            </td>
          )}
        </>
      )}
    </tr>
  );
}

export default function EditBookingPayments({
  booking,
  action,
}: {
  booking: Booking;
  action: 'view' | 'edit';
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate<HTMLTableSectionElement>();
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [showQuickPayTotalModal, setShowQuickPayTotalModal] = useState(false);
  const { paymentMethods } = useCompanyContext();

  const trpcContext = trpc.useUtils();
  const createBookingPayment = createMutation(trpc.pms.bookingPayment.createBookingPayment, {
    invalidate: [trpcContext.pms.booking, trpcContext.pms.bookingPayment],
    onSuccess: () => {
      setShowQuickPayTotalModal(false);
    },
  });

  const { totalAmount: totalCharges } = getBookingChargesAmounts(booking);
  const { totalAmount: totalPayments } = getBookingPaymentsAmounts(booking);

  const totalToPay = totalCharges - totalPayments;

  return (
    <div className='card w-100 table-responsive'>
      <table className='table table-vcenter'>
        <thead>
          <tr>
            <th>{t('pms:booking.form.description')}</th>
            <th className='text-center' style={{ width: PAYMENT_METHOD_WIDTH }}>
              {t('pms:booking.form.method')}
            </th>
            <th className='text-end' style={{ width: '100px' }}>
              {t('pms:booking.form.amount')}
            </th>
            {action === 'edit' && <th style={{ width: '0%' }} />}
          </tr>
        </thead>
        <tbody ref={parentRef}>
          {booking.bookingPayments.map((payment) => (
            <BookingPaymentElement
              key={`booking-payment-${payment.uuid}`}
              bookingUuid={booking.uuid}
              bookingPayment={payment}
              action={action}
            />
          ))}
          <tr className='bg-dark text-white fw-bold'>
            <td colSpan={3}>
              <div className='d-flex justify-content-between'>
                <span>{t('pms:booking.form.total')}</span>
                <span>{euroFormatter.format(totalPayments)}</span>
              </div>
            </td>
            {action === 'edit' && <td />}
          </tr>
          {Math.abs(totalToPay) < 0.01 ? null : totalToPay > 0 ? (
            <tr className='bg-info text-white'>
              <td colSpan={3}>
                <div className='d-flex justify-content-between'>
                  <span>{t('pms:booking.form.totalToPay')}</span>
                  <span>{euroFormatter.format(totalToPay)}</span>
                </div>
              </td>
              {action === 'edit' && (
                <td>
                  <div className='dropdown'>
                    <Button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => setShowQuickPayTotalModal(true)}
                    >
                      Pay
                    </Button>
                  </div>
                </td>
              )}
            </tr>
          ) : (
            <tr className='bg-danger text-white'>
              <td colSpan={3}>
                <div className='d-flex justify-content-between'>
                  <span>Total to refund</span>
                  <span>{euroFormatter.format(-totalToPay)}</span>
                </div>
              </td>
              {action === 'edit' && (
                <td>
                  <Button
                    type='button'
                    className='btn btn-success'
                    onClick={() =>
                      createBookingPayment.mutate({
                        name: 'Refund',
                        bookingUuid: booking.uuid,
                        bookingUnitUuid: null,
                        totalAmount: totalToPay,
                        date: DateTime.now().toISODate()!,
                        notes: '',
                        paymentMethodUuid: null,
                      })
                    }
                    isLoading={createBookingPayment.isLoading}
                    hideContentWhenLoading={true}
                  >
                    Refund
                  </Button>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
      {action === 'edit' && (
        <div className='p-3'>
          {showNewPaymentForm ? (
            <BookingPaymentForm
              action='create'
              bookingUuid={booking.uuid}
              onSuccess={() => setShowNewPaymentForm(false)}
              onCancel={() => setShowNewPaymentForm(false)}
            />
          ) : (
            <div className='text-center'>
              <Button className='btn' onClick={() => setShowNewPaymentForm(true)}>
                <IconPlus size='1em' className='me-2' />
                {t('pms:booking.addNewPayment')}
              </Button>
            </div>
          )}
        </div>
      )}
      <Modal
        isOpen={showQuickPayTotalModal}
        onClose={() => setShowQuickPayTotalModal(false)}
        title='Pay'
      >
        <div className='d-flex flex-column gap-2'>
          <Button
            type='button'
            className='btn w-100'
            disabled={createBookingPayment.isLoading}
            onClick={() => {
              createBookingPayment.mutate({
                name: 'Payment',
                bookingUuid: booking.uuid,
                bookingUnitUuid: null,
                totalAmount: totalToPay,
                date: DateTime.now().toISODate()!,
                notes: '',
                paymentMethodUuid: null,
              });
            }}
          >
            No payment method
          </Button>
          {paymentMethods.filter(isOfflinePaymentMethod).map((paymentMethod) => (
            <Button
              key={`mark-paid-with-payment-method-${paymentMethod.uuid}`}
              type='button'
              className='btn w-100'
              disabled={createBookingPayment.isLoading}
              onClick={() => {
                createBookingPayment.mutate({
                  name: 'Payment',
                  bookingUuid: booking.uuid,
                  bookingUnitUuid: null,
                  totalAmount: totalToPay,
                  date: DateTime.now().toISODate()!,
                  notes: '',
                  paymentMethodUuid: paymentMethod.uuid,
                });
              }}
            >
              {paymentMethod.name}
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
}
