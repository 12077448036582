import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../../../common/components/form/Button';
import UnitTypeForm from './UnitTypeForm';
import UnitTypesList from './UnitTypesList';
import { useTranslation } from 'react-i18next';

interface ViewUnitsProps {
  accommodationUuid: string;
}
export default function ViewUnits({ accommodationUuid }: ViewUnitsProps) {
  const { t } = useTranslation(['pms']);
  const [showNewUnitTypeForm, setShowNewUnitTypeForm] = useState(false);

  return (
    <>
      <div className='alert alert-info'>
        {t('pms:configuration.accommodationSettings.unitTypesInfo')}
      </div>
      {showNewUnitTypeForm ? (
        <div className='card'>
          <div className='card-body'>
            <UnitTypeForm
              action='create'
              accommodationUuid={accommodationUuid}
              onCancel={() => setShowNewUnitTypeForm(false)}
              onSuccess={() => {
                setShowNewUnitTypeForm(false);
              }}
            />
          </div>
        </div>
      ) : (
        <Button
          type='button'
          onClick={() => {
            setShowNewUnitTypeForm(true);
          }}
        >
          <IconPlus />
          {t('pms:configuration.accommodationSettings.addNewUnitTypeToThisAccommodation')}
        </Button>
      )}
      <UnitTypesList accommodationUuid={accommodationUuid} />
    </>
  );
}
