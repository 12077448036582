import { trpcErrorParser } from '../../../utils/api';
import { FormErrors } from '../utils/api';

function getErrorContent(error: string | string[] | FormErrors) {
  const errorContent = error instanceof FormErrors ? error.toString() : error;

  if (typeof errorContent === 'string') {
    return errorContent;
  }

  if (errorContent.length === 1) {
    return errorContent[0];
  }

  return (
    <ul>
      {errorContent.map((err, i) => (
        <li key={`ErrorAlert-${err}-${i}`}>{err}</li>
      ))}
    </ul>
  );
}

export default function ErrorAlert({ errors }: { errors: unknown }) {
  const { genericErrors, formErrors } = trpcErrorParser({ error: errors });
  const finalErrors = genericErrors.concat(formErrors.map((err) => err.path + ': ' + err.message));

  if (finalErrors.length === 0) {
    return null;
  }

  return (
    <div className='alert alert-danger' role='alert'>
      <h4 className='alert-title'>Error</h4>
      <div className='text-secondary'>{getErrorContent(finalErrors)}</div>
    </div>
  );
}
