import { useState } from 'react';
import { createMutation } from '../../../../../utils/api';
import { trpc } from '../../../../../utils/trpc';
import Button from '../../../../common/components/form/Button';
import FormInput from '../../../../common/components/form/input';
import { FormErrors } from '../../../../common/utils/api';
import { Referral } from '../../../api/referrals';
import { IconTrash } from '@tabler/icons-react';

interface CreateReferralFormProps {
  action: 'create';
  referralUuid?: undefined;
  referral?: undefined;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateReferralFormProps {
  action: 'update';
  referralUuid: string;
  referral: Referral;
  onSuccess: () => void;
  onCancel: () => void;
}
type ReferralFormProps = CreateReferralFormProps | UpdateReferralFormProps;

export default function ReferralForm({
  action,
  referralUuid,
  referral,
  onSuccess,
  onCancel,
}: ReferralFormProps) {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const trpcContext = trpc.useUtils();
  const options = { invalidate: [trpcContext.pms.referral], setFormErrors, onSuccess };
  const referralCreate = createMutation(trpc.pms.referral.createReferral, options);
  const referralUpdate = createMutation(trpc.pms.referral.updateReferral, options);
  const referralDelete = createMutation(trpc.pms.referral.deleteReferral, options);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const name = form.referralName.value;

    if (action === 'create') {
      referralCreate.mutate({ name });
    } else {
      referralUpdate.mutate({ uuid: referralUuid, name });
    }
  };

  const disabled = referralCreate.isLoading || referralUpdate.isLoading || referralDelete.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <FormInput
            type='text'
            name='referralName'
            title={null}
            placeholder='Referral name'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={referral?.name}
          />
        </div>
        <div className='row col-md-6'>
          {action === 'update' && (
            <div className='col-2'>
              <Button
                type='button'
                className='btn btn-icon btn-outline-danger ms-1 w-100'
                disabled={disabled}
                isLoading={referralDelete.isLoading}
                hideContentWhenLoading
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    referralDelete.mutate({ uuid: referral.uuid });
                  }
                }}
              >
                <IconTrash size='1em' />
              </Button>
            </div>
          )}
          <div className={action === 'update' ? 'col-4' : 'col-6'}>
            <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <div className='col-6'>
            <Button
              disabled={disabled}
              isLoading={referralCreate.isLoading || referralUpdate.isLoading}
              hideContentWhenLoading
            >
              {action === 'create' ? 'Create' : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
