import { IconBed, IconCurrencyEuro, IconHome, IconPencil, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import {
  getArrayOfStringsFromLinedString,
  getPathsFromPathname,
} from '../../../../../utils/string';
import { trpc } from '../../../../../utils/trpc';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import Page from '../../../../common/components/dashboard/Page';
import Button from '../../../../common/components/form/Button';
import { usePmsContext } from '../../../Context';
import { Booking } from '../../../api/bookings/bookings';
import { UnitType } from '../../../api/units/unit-types';
import NavTabs from '../../../components/NavTabs';
import EditBookingCharges from '../../../components/booking/form/edit/EditBookingCharges';
import EditBookingForm from '../../../components/booking/form/edit/EditBookingForm';
import EditBookingPayments from '../../../components/booking/form/edit/EditBookingPayments';
import EditBookingUnits from '../../../components/booking/form/edit/EditBookingUnits';
import { useTranslation } from 'react-i18next';
import { DownloadBookingChargesPaymentsPdfButton } from '../../../components/booking/ChargesPaymentsDownloadPdf';

const navTabsItems = [
  { id: 'overview', title: 'Overview', label: <IconHome />, link: './' },
  { id: 'units', title: 'Units', label: <IconBed />, link: './units' },
  {
    id: 'payments',
    title: 'Charges and payments',
    label: <IconCurrencyEuro />,
    link: './payments',
  },
];

const Overview = ({ booking, unitTypes }: { booking: Booking; unitTypes: UnitType[] }) => {
  const { t } = useTranslation(['pms']);
  const { accommodations } = usePmsContext();
  const [showBookingEditForm, setShowBookingEditForm] = useState(false);

  const notes = getArrayOfStringsFromLinedString(booking.notes);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 card card-body'>
          <div className='d-flex justify-content-between'>
            <h2>{t('pms:booking.booking')}</h2>
            <Button
              className='btn btn-outline'
              type='button'
              onClick={() => setShowBookingEditForm((current) => !current)}
            >
              {showBookingEditForm ? <IconX size={16} /> : <IconPencil size={16} />}
            </Button>
          </div>
          {showBookingEditForm ? (
            <EditBookingForm booking={booking} onSuccess={() => setShowBookingEditForm(false)} />
          ) : (
            <div className='datagrid'>
              <div className='datagrid-item'>
                <div className='datagrid-title'>{t('pms:booking.form.firstName')}</div>
                <div className='datagrid-content'>{booking.firstName}</div>
              </div>
              <div className='datagrid-item'>
                <div className='datagrid-title'>{t('pms:booking.form.lastName')}</div>
                <div className='datagrid-content'>{booking.lastName}</div>
              </div>
              <div className='datagrid-item'>
                <div className='datagrid-title'>{t('pms:booking.form.email')}</div>
                <div className='datagrid-content'>{booking.email}</div>
              </div>
              <div className='datagrid-item'>
                <div className='datagrid-title'>{t('pms:booking.form.phone')}</div>
                <div className='datagrid-content'>{booking.phone}</div>
              </div>
              <div className='datagrid-item'>
                <div className='datagrid-title'>{t('pms:booking.form.referral')}</div>
                <div className='datagrid-content'>{booking.referral?.name ?? 'None'}</div>
              </div>
              {notes && (
                <div className='datagrid-item'>
                  <div className='datagrid-title'>{t('pms:booking.form.notes')}</div>
                  <div className='datagrid-content'>
                    {notes.map((line, i) => (
                      <span key={`note-${i}`}>
                        {line}
                        {i < notes.length - 1 && <br />}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='col-12 col-md-6 card card-body'>
          <div className='d-flex justify-content-between'>
            <h2>
              <Link to='./units'>{t('pms:booking.units')}</Link>
            </h2>
            <Link to='./units' className='btn btn-outline'>
              <IconPencil size={16} />
            </Link>
          </div>
          <h3>
            {
              accommodations.find(
                (accommodation) => accommodation.uuid === booking.accommodationUuid,
              )?.name
            }
          </h3>
          <EditBookingUnits
            accommodationUuid={booking.accommodationUuid}
            booking={booking}
            unitTypes={unitTypes}
            action='view'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 card card-body'>
          <div className='d-flex justify-content-between'>
            <h2>
              <Link to='./payments'>{t('pms:booking.charges')}</Link>
            </h2>
            <div className='d-flex gap-2'>
              <DownloadBookingChargesPaymentsPdfButton booking={booking} />
              <Link to='./payments' className='btn btn-outline'>
                <IconPencil size={16} />
              </Link>
            </div>
          </div>
          <EditBookingCharges booking={booking} action='view' />
        </div>
        <div className='col-12 col-md-6 card card-body'>
          <div className='d-flex justify-content-between'>
            <h2>
              <Link to='./payments'>{t('pms:booking.payments')}</Link>
            </h2>
            <div className='d-flex gap-2'>
              <DownloadBookingChargesPaymentsPdfButton booking={booking} />
              <Link to='./payments' className='btn btn-outline'>
                <IconPencil size={16} />
              </Link>
            </div>
          </div>
          <EditBookingPayments booking={booking} action='view' />
        </div>
      </div>
      {process.env.REACT_APP_BOOKING_ENGINE_ENDPOINT && booking.token && (
        <div className='row'>
          <div className='col-12 card card-body'>
            <h2>{t('pms:booking.bookingLink')}</h2>
            {t('pms:booking.bookingLinkHint')}
            <br />
            <a
              href={`${process.env.REACT_APP_BOOKING_ENGINE_ENDPOINT}booking/${booking.uuid}?token=${booking.token}`}
              target='_blank'
              rel='noreferrer'
            >
              {`${process.env.REACT_APP_BOOKING_ENGINE_ENDPOINT}booking/${booking.uuid}?token=${booking.token}`}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default function EditBooking() {
  const { t } = useTranslation(['pms']);
  const location = useLocation();
  const { unitTypes } = usePmsContext();

  const { id: bookingUuid } = useParams();

  if (bookingUuid === undefined) {
    return <ErrorAlert errors='Booking UUID is missing' />;
  }

  const { isLoading, data: booking } = trpc.pms.booking.getBookingByUuid.useQuery({
    uuid: bookingUuid,
  });

  if (isLoading) {
    return <LoadingSpinner cardBody center />;
  }

  if (!booking) {
    return <ErrorAlert errors={t('pms:booking.bookingNotFoundError')} />;
  }

  const locationPaths = getPathsFromPathname(location.pathname);
  const locationPath = locationPaths[locationPaths.length - 1];
  const activeId =
    navTabsItems.find((item) => item.link.replace('./', '') === locationPath)?.id ?? 'overview';

  return (
    <>
      <Page title={t('pms:booking.editBooking')}>
        <NavTabs items={navTabsItems} activeId={activeId}></NavTabs>
        <Routes>
          <Route index element={<Overview booking={booking} unitTypes={unitTypes} />} />
          <Route
            path='/units'
            element={
              <EditBookingUnits
                accommodationUuid={booking.accommodationUuid}
                booking={booking}
                unitTypes={unitTypes}
                action='edit'
              />
            }
          />
          <Route
            path='/payments'
            element={
              <div className='row'>
                <div className='col-12 col-md-6 card card-body'>
                  <div className='d-flex justify-content-between'>
                    <h2>{t('pms:booking.charges')}</h2>
                    <DownloadBookingChargesPaymentsPdfButton booking={booking} />
                  </div>
                  <EditBookingCharges booking={booking} action='edit' />
                </div>
                <div className='col-12 col-md-6 card card-body'>
                  <div className='d-flex justify-content-between'>
                    <h2>{t('pms:booking.payments')}</h2>
                    <DownloadBookingChargesPaymentsPdfButton booking={booking} />
                  </div>
                  <EditBookingPayments booking={booking} action='edit' />
                </div>
              </div>
            }
          />
        </Routes>
      </Page>
    </>
  );
}
