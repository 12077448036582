import { IconPlus } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { createMutation } from '../../../../../../utils/api';
import { trpc } from '../../../../../../utils/trpc';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import Modal from '../../../../../common/components/Modal';
import Button from '../../../../../common/components/form/Button';
import { Booking } from '../../../../api/bookings/bookings';
import { UnitType } from '../../../../api/units/unit-types';
import AddBookingUnit from '../AddBookingUnit';
import SelectedUnitsList, {
  type BookingEditSelectedUnit,
  type BookingEditSelectedUnits,
} from '../SelectedBookingUnits';
import { useTranslation } from 'react-i18next';
import { LocalDate, LocalTime } from '@js-joda/core';
import { BookingUnitForm } from './EditBookingUnit';
import { getUnitDataById } from '../../../../utils/booking';

function LoadingOverlay() {
  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100%',
        position: 'absolute',
        width: '100%',
        opacity: 0.8,
        zIndex: 999,
      }}
    >
      <div
        className='sticky-top'
        style={{
          top: '50%',
          transform: 'translate(0%, -50%)',
        }}
      >
        <LoadingSpinner center />
      </div>
    </div>
  );
}

export default function EditBookingUnits({
  accommodationUuid,
  booking,
  unitTypes,
  action,
}: {
  accommodationUuid: string;
  booking: Booking;
  unitTypes: UnitType[];
  action: 'edit' | 'view';
}) {
  const { t } = useTranslation(['pms']);
  const [selectedUnits, setSelectedUnits] = useState<BookingEditSelectedUnits>([]);
  const [showNewUnitModal, setShowNewUnitModal] = useState(false);
  const [updateUnitModal, setUpdateUnitModal] = useState<BookingEditSelectedUnit | null>(null);

  const trpcContext = trpc.useUtils();
  const updateBookingUnits = createMutation(trpc.pms.booking.updateBookingUnits, {
    invalidate: [trpcContext.pms.booking],
    onSuccess: () => setShowNewUnitModal(false),
  });

  useEffect(() => {
    const selectedUnitsToSet: BookingEditSelectedUnits = booking.bookingUnits.map(
      (bookingUnit) => ({
        bookingUnitUuid: bookingUnit.uuid,
        unitUuid: bookingUnit.unitUuid,
        from: LocalDate.parse(bookingUnit.arrivalDate),
        arrivalTime: bookingUnit.arrivalTime ? LocalTime.parse(bookingUnit.arrivalTime) : null,
        to: LocalDate.parse(bookingUnit.departureDate),
        departureTime: bookingUnit.departureTime
          ? LocalTime.parse(bookingUnit.departureTime)
          : null,
        adults: bookingUnit.adultsNumber,
        children: bookingUnit.childrenNumber,
        rateOptionUuid: bookingUnit.rateOptionUuid,
        rateAmount: bookingUnit.rateAmount,
        checkInDateTime: bookingUnit.checkInDate,
        checkOutDateTime: bookingUnit.checkOutDate,
      }),
    );
    setSelectedUnits(selectedUnitsToSet);
  }, [booking]);

  return (
    <>
      <SelectedUnitsList
        unitTypes={unitTypes}
        selectedUnits={selectedUnits}
        action={action}
        isLoading={updateBookingUnits.isLoading}
        onUpdate={setUpdateUnitModal}
        onRemove={(bookingUnit) => {
          if (window.confirm('Are you sure you want to remove this unit?')) {
            updateBookingUnits.mutate({
              uuid: booking.uuid,
              deleteBookingUnits: [bookingUnit.bookingUnitUuid],
            });
          }
        }}
      />
      {action === 'edit' && (
        <>
          <div className='text-center mt-3'>
            <Button className='btn' onClick={() => setShowNewUnitModal(true)}>
              <IconPlus size='1em' className='me-2' />
              {t('pms:booking.addNewUnit')}
            </Button>
          </div>
          <Modal isOpen={showNewUnitModal} onClose={() => setShowNewUnitModal(false)} size='xl'>
            <div className='position-relative'>
              {updateBookingUnits.isLoading && <LoadingOverlay />}
              <AddBookingUnit
                accommodationUuid={accommodationUuid}
                notAvailableUnits={[]}
                onSelect={async (bookingUnit) => {
                  const arrivalDate = bookingUnit.from.toString();
                  const departureDate = bookingUnit.to.toString();

                  if (!arrivalDate || !departureDate) {
                    toast.error('Invalid dates');
                    return;
                  }

                  updateBookingUnits.mutate({
                    uuid: booking.uuid,
                    createBookingUnits: [
                      {
                        arrivalDate,
                        arrivalTime: bookingUnit.arrivalTime
                          ? bookingUnit.arrivalTime.toString()
                          : null,
                        departureDate,
                        departureTime: bookingUnit.departureTime
                          ? bookingUnit.departureTime.toString()
                          : null,
                        adultsNumber: bookingUnit.adults,
                        childrenNumber: bookingUnit.children,
                        unitUuid: bookingUnit.unitUuid,
                        rateOptionUuid: bookingUnit.rateOptionUuid,
                        rateAmount: bookingUnit.totalRateAmount,
                        notes: '',
                      },
                    ],
                  });
                }}
              />
            </div>
          </Modal>
          <Modal
            isOpen={updateUnitModal !== null}
            onClose={() => setUpdateUnitModal(null)}
            title={
              updateUnitModal === null
                ? t('pms:booking.editBooking')
                : getUnitDataById(unitTypes, updateUnitModal.unitUuid).unitName
            }
          >
            <div className='position-relative'>
              {updateUnitModal === null ? (
                <></>
              ) : (
                <BookingUnitForm
                  bookingUnit={updateUnitModal}
                  onSuccess={() => setUpdateUnitModal(null)}
                  onCancel={() => setUpdateUnitModal(null)}
                />
              )}
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
