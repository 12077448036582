import React, { useState } from 'react';
import { AVAILABLE_LANGUAGES } from '../../contexts/LanguageContext';
import { IconPlus, IconX } from '@tabler/icons-react';
import FormInput from './input';
import NavTabs from '../../../pms/components/NavTabs';
import Button from './Button';

type Props = {
  defaultValue?: Record<string, string>;
  onChange?: (value: Record<string, string>) => void;
  errors?: any;
  formErrors?: any;
  errorPath?: string[] | string[][];
  name?: string;
  title?: string | JSX.Element | null;
  hint?: string | null;
  disabled?: boolean;
};

export default function I18NTextarea({
  defaultValue,
  onChange,
  errors,
  formErrors,
  errorPath,
  name,
  title,
  hint,
  disabled,
}: Props) {
  const [value, setValue] = useState<Record<string, string>>(defaultValue || {});
  const [activeLanguage, setActiveLanguage] = useState(AVAILABLE_LANGUAGES[0]);

  const languagesInValue = new Set(Object.keys(value));
  const languagesNotInValue = AVAILABLE_LANGUAGES.filter(
    (lang) => !languagesInValue.has(lang.code),
  );

  const selectedLanguages = AVAILABLE_LANGUAGES.filter((lang) => languagesInValue.has(lang.code));

  return (
    <div className='mb-2'>
      {title && <label className='form-label'>{title}</label>}
      {hint && <div className='form-text mb-2'>{hint}</div>}
      {languagesInValue.size > 0 && (
        <>
          <NavTabs
            items={selectedLanguages.map((lang) => ({
              id: lang.code,
              label: (
                <img
                  src={lang.flagUrl}
                  alt={lang.code}
                  title={lang.code}
                  style={{
                    width: '1.5rem',
                    maxHeight: '1rem',
                  }}
                />
              ),
            }))}
            activeId={activeLanguage.code}
            onActiveIdChange={(code) => {
              setActiveLanguage(
                AVAILABLE_LANGUAGES.find((l) => l.code === code) || AVAILABLE_LANGUAGES[0],
              );
            }}
          />
          <div className='mb-2' key={activeLanguage.code}>
            <div className='d-flex gap-2'>
              <div className='flex-grow-1'>
                <FormInput
                  type='textarea'
                  name={`${name}.${activeLanguage}`}
                  defaultValue={value[activeLanguage.code]}
                  disabled={disabled}
                  onChange={(v) => {
                    setValue((prev) => {
                      const newValue = { ...prev, [activeLanguage.code]: v };

                      // I know, the JSON.stringify check is expensive, but it's needed to prevent:
                      // Warning: Cannot update a component (`SelfWhatsAppNotificationForm`) while rendering a different component (`I18NTextarea`). To locate the bad setState() call inside `I18NTextarea`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
                      if (JSON.stringify(newValue) !== JSON.stringify(prev)) {
                        onChange?.(newValue);
                      }
                      return newValue;
                    });
                  }}
                  errors={errors}
                  formErrors={formErrors}
                  errorPath={errorPath}
                  style={{ height: '200px' }}
                  placeholder={`Example:\n\nHi {{firstName}},\nWe look forward to welcoming you to our hotel.\nBest,\nHotel Team`}
                />
              </div>
              <Button
                type='button'
                className='btn btn-outline-danger flex-shrink-0 mb-2'
                disabled={disabled}
                onClick={() => {
                  setValue((prev) => {
                    const newValue = { ...prev };
                    delete newValue[activeLanguage.code];
                    setActiveLanguage(AVAILABLE_LANGUAGES[0]);
                    onChange?.(newValue);
                    return newValue;
                  });
                }}
              >
                <IconX size='1em' />
              </Button>
            </div>
          </div>
        </>
      )}
      {languagesNotInValue.map((lang) => (
        <React.Fragment key={lang.code}>
          <button
            type='button'
            className='btn btn-outline-primary me-2'
            disabled={disabled}
            onClick={() => {
              setValue((prev) => {
                const newValue = { ...prev, [lang.code]: '' };
                setActiveLanguage(lang);
                onChange?.(newValue);
                return newValue;
              });
            }}
          >
            <IconPlus size='1em' />
            <img
              src={lang.flagUrl}
              alt={lang.code}
              title={lang.code}
              style={{
                width: '1.5rem',
                maxHeight: '1rem',
                marginLeft: '0.5rem',
              }}
            />
          </button>
        </React.Fragment>
      ))}
    </div>
  );
}
