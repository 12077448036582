import { useId, useState } from 'react';
import { trpc } from '../../../../../utils/trpc';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { usePmsContext } from '../../../Context';
import { GetBookingsArgs } from '../../../api/bookings/bookings';
import { Pagination } from '../../../../common/components/Pagination';
import BookingUnitListItem from './BookingUnitListItem';
import { useTranslation } from 'react-i18next';

export default function BookingUnitsList({
  params,
}: {
  params: Omit<GetBookingsArgs, 'accommodationsUuids'>;
}) {
  const { t } = useTranslation(['pms']);
  const { currentAccommodations, unitTypes: allUnitTypes } = usePmsContext();
  const currentAccommodationsUuids = currentAccommodations.map((a) => a.uuid);

  const id = useId();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const queryBookingsParams = {
    accommodationsUuids: currentAccommodationsUuids,
    page,
    limit: pageSize,
    ...params,
  };
  const queryBookings = trpc.pms.booking.queryBookingsUnits.useQuery(queryBookingsParams);

  if (queryBookings.isLoading) {
    return <LoadingSpinner center />;
  }

  const bookingsUnits = queryBookings.data?.bookingsUnits;
  const totalBookings = queryBookings.data?.total;
  if (!bookingsUnits || totalBookings === null || totalBookings === undefined) {
    return <ErrorAlert errors='An error occurred' />;
  }

  if (bookingsUnits.length === 0) {
    return (
      <div className='text-center'>
        <h3>{t('pms:booking.noBookingsFound')}</h3>
      </div>
    );
  }

  const unitTypes = allUnitTypes.filter((u) =>
    currentAccommodationsUuids.includes(u.accommodationUuid),
  );

  return (
    <>
      {page === 1 && bookingsUnits.length >= pageSize && (
        <div className='mb-3'>
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(totalBookings / pageSize)}
            onPageChange={(p) => setPage(p)}
            currentPageLimit={pageSize}
            onPageLimitChange={(p) => setPageSize(p)}
          />
        </div>
      )}
      {bookingsUnits.map((bookingUnit, i) => (
        <BookingUnitListItem
          key={`${id}-booking-list-item-${bookingUnit.uuid}`}
          unitTypes={unitTypes}
          bookingUnit={bookingUnit}
          isFirst={i === 0}
        />
      ))}
    </>
  );
}
