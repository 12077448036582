import { useEffect, useMemo, useState } from 'react';
import { getTimezonesByRegions } from '../../../../utils/timezone';
import FormInput from './input';
import { useTranslation } from 'react-i18next';

export default function TimezoneInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation(['generic']);

  const timezonesByRegions = useMemo(() => getTimezonesByRegions(), []);
  const [currentRegion] = value.split('/');
  const [currentRegionTimezones, setCurrentRegionTimezones] = useState(
    timezonesByRegions[currentRegion],
  );
  const [region, setRegion] = useState(currentRegion);
  const [fullTimezone, setFullTimezone] = useState(value);

  const handleRegionChange = (value: string) => {
    setRegion(value);
    setCurrentRegionTimezones(timezonesByRegions[value]);
  };

  const handleCityChange = (value: string) => {
    setFullTimezone(value);
  };

  useEffect(() => {
    const [currentRegion] = fullTimezone.split('/');
    if (currentRegion === region) {
      return;
    }
    setFullTimezone(currentRegionTimezones[0].tzCode);
  }, [currentRegionTimezones]);

  useEffect(() => {
    onChange(fullTimezone);
  }, [fullTimezone]);

  return (
    <div className='row'>
      <div className='col-12'>
        <b>{t('generic:timezone')}</b>
      </div>
      <div className='col-6'>
        <FormInput type='select' name='region' defaultValue={region} onChange={handleRegionChange}>
          {Object.keys(timezonesByRegions).map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </FormInput>
      </div>
      <div className='col-6'>
        <FormInput
          type='select'
          name='city'
          defaultValue={fullTimezone}
          onChange={handleCityChange}
        >
          {currentRegionTimezones
            .sort((a, b) => a.name.localeCompare(b.label))
            .map((timezone) => (
              <option key={timezone.tzCode} value={timezone.tzCode}>
                {timezone.name}
              </option>
            ))}
        </FormInput>
      </div>
    </div>
  );
}
