import {
  IconChevronRight,
  IconClock,
  IconPencil,
  IconTransferIn,
  IconTransferOut,
  IconTrash,
  IconUsers,
  IconWallet,
} from '@tabler/icons-react';
import { euroFormatter } from '../../../../../utils/string';
import Button from '../../../../common/components/form/Button';
import { UnitType } from '../../../api/units/unit-types';
import { SelectedUnits } from '../../../types';
import CheckInOutButton from '../../CheckInOutButton';
import { DateTime } from 'luxon';
import Card from '../../../../common/components/Card';
import { getUnitDataById } from '../../../utils/booking';

export type BookingEditSelectedUnit = Omit<SelectedUnits[number], 'uuid'> & {
  bookingUnitUuid: string;
  checkInDateTime: string | null;
  checkOutDateTime: string | null;
};

export type BookingEditSelectedUnits = Array<BookingEditSelectedUnit>;

export default function SelectedUnitsList({
  unitTypes,
  selectedUnits: unsortedSelectedUnits,
  action,
  onUpdate,
  onRemove,
  isLoading,
}: {
  unitTypes: UnitType[];
  selectedUnits: BookingEditSelectedUnits;
  action: 'edit' | 'view';
  onUpdate: (unit: BookingEditSelectedUnit) => void;
  onRemove: (unit: BookingEditSelectedUnit) => void;
  isLoading: boolean;
}) {
  const selectedUnits = unsortedSelectedUnits.sort((a, b) =>
    a.bookingUnitUuid.localeCompare(b.bookingUnitUuid),
  );

  return (
    <div className='d-flex flex-column gap-2'>
      {selectedUnits.map((unit) => {
        const rateAmountString = unit.rateAmount ? euroFormatter.format(unit.rateAmount) : 'N/A';
        const { unitName, unitTypeName } = getUnitDataById(unitTypes, unit.unitUuid);
        return (
          <Card
            key={unit.bookingUnitUuid}
            title={
              <>
                {unitTypeName} <IconChevronRight size='1em' /> {unitName}
              </>
            }
          >
            <div className='d-flex justify-content-between'>
              <div>
                <div className='d-flex justify-content-between gap-2'>
                  <span>
                    <IconTransferIn size='1em' />{' '}
                    {DateTime.fromISO(unit.from.toString()).toLocaleString(DateTime.DATE_MED)}
                  </span>
                  {unit.arrivalTime && (
                    <span>
                      <IconClock size='1em' /> {unit.arrivalTime.toString()}
                    </span>
                  )}
                </div>
                <div className='d-flex justify-content-between gap-2'>
                  <span>
                    <IconTransferOut size='1em' />{' '}
                    {DateTime.fromISO(unit.to.toString()).toLocaleString(DateTime.DATE_MED)}
                  </span>
                  {unit.departureTime && (
                    <span>
                      <IconClock size='1em' /> {unit.departureTime.toString()}
                    </span>
                  )}
                </div>
                <div>
                  <IconUsers size='1em' /> {unit.adults} + {unit.children}
                </div>
                <div>
                  <IconWallet size='1em' /> {rateAmountString}
                </div>
                <div className='mt-2'>
                  <CheckInOutButton
                    bookingUnitUuid={unit.bookingUnitUuid}
                    type='checkIn'
                    show
                    datetime={unit.checkInDateTime}
                  />
                  <CheckInOutButton
                    bookingUnitUuid={unit.bookingUnitUuid}
                    type='checkOut'
                    show
                    datetime={unit.checkOutDateTime}
                  />
                </div>
              </div>
              {action === 'edit' && (
                <div className='d-flex flex-column gap-2'>
                  <Button
                    type='button'
                    className='btn btn-icon w-100'
                    onClick={() => onUpdate(unit)}
                    isLoading={isLoading}
                    hideContentWhenLoading
                  >
                    <IconPencil size='1em' />
                  </Button>
                  <Button
                    type='button'
                    className='btn btn-icon btn-outline-danger w-100'
                    onClick={() => onRemove(unit)}
                    isLoading={isLoading}
                    hideContentWhenLoading
                  >
                    <IconTrash size='1em' />
                  </Button>
                </div>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
}
