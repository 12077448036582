import { DateTime } from 'luxon';
import FormInput from '../../common/components/form/input';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { trpc } from '../../../utils/trpc';
import { createMutation } from '../../../utils/api';

export default function CheckInOutButton({
  bookingUnitUuid,
  type,
  show,
  datetime,
}: {
  bookingUnitUuid: string;
  type: 'checkIn' | 'checkOut';
  show: boolean;
  datetime: string | null;
}) {
  const trpcContext = trpc.useUtils();
  const options = { invalidate: [trpcContext.pms.booking] };
  const markCheckedIn = createMutation(trpc.pms.booking.checkInBookingUnit, options);
  const markCheckedOut = createMutation(trpc.pms.booking.checkOutBookingUnit, options);

  const isLoading = markCheckedIn.isLoading || markCheckedOut.isLoading;

  if (!show) {
    return null;
  }

  const isChecked = datetime !== null;

  const label =
    type === 'checkIn' ? (
      isChecked ? (
        <span title={new Date(datetime).toLocaleString()}>Checked-in</span>
      ) : (
        <span>Check-in</span>
      )
    ) : isChecked ? (
      <span title={new Date(datetime).toLocaleString()}>Checked-out</span>
    ) : (
      <span>Check-out</span>
    );

  return (
    <FormInput
      type='switch'
      name='checkInOut'
      title={
        <>
          {label}
          {isLoading ? (
            <LoadingSpinner className='spinner-border spinner-border-sm m-0 icon ms-2' />
          ) : null}
        </>
      }
      disabled={isLoading}
      defaultValue={isChecked}
      onChange={(newValue) => {
        if (isChecked !== newValue) {
          if (type === 'checkIn') {
            markCheckedIn.mutate({
              uuid: bookingUnitUuid,
              checkInDateTime: isChecked ? null : DateTime.utc().toISO(),
            });
          } else {
            markCheckedOut.mutate({
              uuid: bookingUnitUuid,
              checkOutDateTime: isChecked ? null : DateTime.utc().toISO(),
            });
          }
        }
      }}
      checkboxValue='on'
    />
  );
}
