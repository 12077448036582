import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { createMutation } from '../../../../../../../utils/api';
import { insertAtIndex } from '../../../../../../../utils/array';
import { trpc } from '../../../../../../../utils/trpc';
import Button from '../../../../../../common/components/form/Button';
import FormInput from '../../../../../../common/components/form/input';
import { FormErrors } from '../../../../../../common/utils/api';
import { usePmsContext } from '../../../../../Context';
import { RateSeason } from '../../../../../api/rates/rate-seasons';
import { useTranslation } from 'react-i18next';

type DateRange = {
  tmpUuid: string;
  startDate: string;
  endDate: string;
  seasonUuid: '' | string;
};

const AddDateRangeButton = ({
  dateRanges,
  newIndex,
  startDate,
  endDate,
  setDateRanges,
  isDisabled,
}: {
  dateRanges: DateRange[];
  newIndex: number;
  startDate: DateTime;
  endDate: DateTime;
  setDateRanges: (dateRanges: DateRange[]) => void;
  isDisabled: boolean;
}) => {
  let emptyDatesBetweenCurrentEndDateAndNextStartDate = 1;
  if (newIndex !== 0 && newIndex !== dateRanges.length) {
    emptyDatesBetweenCurrentEndDateAndNextStartDate = Interval.fromDateTimes(
      DateTime.fromISO(dateRanges[newIndex - 1].endDate),
      DateTime.fromISO(dateRanges[newIndex].startDate),
    ).length('days');
  }

  const buttonSuccessStyle =
    emptyDatesBetweenCurrentEndDateAndNextStartDate !== 1 ? 'btn-warning' : 'btn-outline-success';

  return (
    <div className='row'>
      <div className='col-md-12 text-center'>
        <Button
          type='button'
          className={`btn btn-sm ${buttonSuccessStyle} mb-2`}
          onClick={() => {
            const newStartDate = startDate.toISODate();
            const newEndDate = endDate.toISODate();
            if (!newStartDate || !newEndDate) {
              return;
            }

            const newDateRanges = insertAtIndex(dateRanges, newIndex, {
              tmpUuid: uuidv4(),
              startDate: newStartDate,
              endDate: newEndDate,
              seasonUuid: '',
            });
            setDateRanges(newDateRanges);
          }}
          disabled={isDisabled}
        >
          <IconPlus />
          {emptyDatesBetweenCurrentEndDateAndNextStartDate !== 1 &&
            !Number.isNaN(emptyDatesBetweenCurrentEndDateAndNextStartDate) &&
            ` (${emptyDatesBetweenCurrentEndDateAndNextStartDate - 1} empty dates)`}
        </Button>
      </div>
    </div>
  );
};

function DateRangeElement({
  rateSeasons,
  dateRanges,
  setDateRanges,
  dateRange,
  index,
  isDisabled,
  formErrors,
}: {
  rateSeasons: RateSeason[];
  dateRanges: DateRange[];
  setDateRanges: (dateRanges: DateRange[]) => void;
  dateRange: DateRange;
  index: number;
  isDisabled: boolean;
  formErrors: FormErrors | null;
}) {
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);
  const [seasonUuid, setSeasonId] = useState(dateRange.seasonUuid);

  useEffect(() => {
    const newDateRanges = [...dateRanges];
    newDateRanges[index] = {
      ...newDateRanges[index],
      startDate,
      endDate,
      seasonUuid: seasonUuid,
    };
    setDateRanges(newDateRanges);
  }, [startDate, endDate, seasonUuid]);

  return (
    <>
      <div className='row'>
        <div className='col-md-4'>
          <FormInput
            type='date'
            name='startDates[]'
            title={null}
            errorPath={[`startDate-${dateRange.tmpUuid}`]}
            formErrors={formErrors}
            defaultValue={startDate}
            onChange={setStartDate}
            disabled={isDisabled}
          />
        </div>
        <div className='col-md-4'>
          <FormInput
            type='date'
            name='endDates[]'
            title={null}
            placeholder='Season name'
            errorPath={[`endDate-${dateRange.tmpUuid}`]}
            formErrors={formErrors}
            defaultValue={endDate}
            onChange={setEndDate}
            disabled={isDisabled}
          />
        </div>
        <div className='col-md-3'>
          <FormInput
            type='select'
            className='form-control'
            name='seasonUuids[]'
            errorPath={[`seasonUuid-${dateRange.tmpUuid}`]}
            formErrors={formErrors}
            onChange={(value) => setSeasonId(value)}
            defaultValue={seasonUuid.toString()}
            disabled={isDisabled}
          >
            <option value={''}>-- no season --</option>
            {rateSeasons.map((rateSeason) => (
              <option key={`rateSeason-${rateSeason.uuid}`} value={rateSeason.uuid}>
                {rateSeason.name}
              </option>
            ))}
          </FormInput>
        </div>
        <div className='col-md-1 text-end'>
          <Button
            type='button'
            className='btn btn-outline-danger me-2'
            onClick={() => {
              setDateRanges(dateRanges.filter((dr) => dr.tmpUuid !== dateRange.tmpUuid));
            }}
            disabled={isDisabled}
          >
            <IconMinus />
          </Button>
        </div>
      </div>
      <AddDateRangeButton
        startDate={DateTime.fromISO(dateRange.endDate).plus({ days: 1 })}
        endDate={DateTime.fromISO(dateRange.endDate).plus({ days: 2 })}
        dateRanges={dateRanges}
        setDateRanges={setDateRanges}
        newIndex={index + 1}
        isDisabled={isDisabled}
      />
    </>
  );
}

type RateSeasonFormProps = {
  accommodationUuid: string;
};

export default function RateSeasonDateRangeForm({ accommodationUuid }: RateSeasonFormProps) {
  const { t } = useTranslation(['pms']);
  const { rateSeasons } = usePmsContext();

  const [parentRef] = useAutoAnimate();
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const originalRateSeasonsIds = rateSeasons
    .filter((rateSeason) => rateSeason.accommodations.some((a) => a.uuid === accommodationUuid))
    .map((rateSeason) => rateSeason.uuid);

  const [selectedRateSeasonsIds, setSelectedRateSeasonsIds] = useState(originalRateSeasonsIds);

  const trpcContext = trpc.useUtils();
  const replaceRateSeasonDateRangesOfAccommodation = createMutation(
    trpc.pms.rate.replaceRateSeasonDateRangesOfAccommodation,
    {
      invalidate: [trpcContext.pms.rate],
      onSuccess: () => toast.success('Rate season date ranges updated'),
      onError: (error) => toast.error(`Error updating rate season date ranges: ${error}`),
      setFormErrors,
    },
  );

  const year = DateTime.now().year.toString();
  const firstDate = `${year}-01-01`;
  const lastDate = `${year}-12-31`;

  const resetDateRanges = (rateSeasons: RateSeason[]) => {
    setDateRanges(
      rateSeasons.flatMap((season) =>
        season.dateRanges.map((dateRange) => ({
          tmpUuid: uuidv4(),
          startDate: dateRange.from,
          endDate: dateRange.to,
          seasonUuid: season.uuid,
        })),
      ),
    );
  };

  const resetOriginalDateRanges = () => {
    setSelectedRateSeasonsIds(originalRateSeasonsIds);
    resetDateRanges(rateSeasons.filter((rs) => originalRateSeasonsIds.includes(rs.uuid)));
  };

  useEffect(() => {
    resetDateRanges(rateSeasons.filter((rs) => selectedRateSeasonsIds.includes(rs.uuid)));
  }, [selectedRateSeasonsIds]);

  useEffect(() => {
    const errors: {
      message: string;
      path: (string | number)[];
    }[] = [];
    dateRanges.forEach((dateRange1, index1) => {
      if (dateRange1.endDate <= dateRange1.startDate) {
        errors.push({
          message: t(
            'pms:configuration.accommodationSettings.seasonsForm.errors.endDateMustBeAfterStartDate',
          ),
          path: [`startDate-${dateRange1.tmpUuid}`],
        });
        errors.push({
          message: t(
            'pms:configuration.accommodationSettings.seasonsForm.errors.startDateMustBeBeforeEndDate',
          ),
          path: [`endDate-${dateRange1.tmpUuid}`],
        });
      }
      if (dateRange1.seasonUuid === '') {
        errors.push({
          message: t('pms:configuration.accommodationSettings.seasonsForm.errors.seasonIsRequired'),
          path: [`seasonUuid-${dateRange1.tmpUuid}`],
        });
      }
      const interval1 = Interval.fromISO(`${dateRange1.startDate}/${dateRange1.endDate}`);
      dateRanges.forEach((dateRange2, index2) => {
        const interval2 = Interval.fromISO(`${dateRange2.startDate}/${dateRange2.endDate}`);
        if (
          index1 !== index2 &&
          (interval1.overlaps(interval2) ||
            dateRange1.startDate === dateRange2.endDate ||
            dateRange2.startDate === dateRange1.endDate)
        ) {
          errors.push({
            path: [`startDate-${dateRange1.tmpUuid}`],
            message: t(
              'pms:configuration.accommodationSettings.seasonsForm.errors.dateRangesOverlap',
            ),
          });
          errors.push({
            path: [`endDate-${dateRange1.tmpUuid}`],
            message: t(
              'pms:configuration.accommodationSettings.seasonsForm.errors.dateRangesOverlap',
            ),
          });
          errors.push({
            path: [`startDate-${dateRange2.tmpUuid}`],
            message: t(
              'pms:configuration.accommodationSettings.seasonsForm.errors.dateRangesOverlap',
            ),
          });
          errors.push({
            path: [`endDate-${dateRange2.tmpUuid}`],
            message: t(
              'pms:configuration.accommodationSettings.seasonsForm.errors.dateRangesOverlap',
            ),
          });
        }
      });
    });
    setFormErrors(new FormErrors(errors));
  }, [dateRanges]);

  const handleAccommodationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const uuid = String(value);

    if (checked) {
      setSelectedRateSeasonsIds([...selectedRateSeasonsIds, uuid]);
    } else {
      setSelectedRateSeasonsIds(
        selectedRateSeasonsIds.filter((rateSeasonsId) => rateSeasonsId !== uuid),
      );
    }
  };

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='form-check'>
          <b>
            {t('pms:configuration.accommodationSettings.seasonsForm.seasonsForThisAccommodation')}
          </b>
          <br />
          {rateSeasons.map((rateSeason) => (
            <label
              key={`BookingEnginePageForm-rateSeason-${rateSeason.uuid}`}
              className='form-check form-check-inline'
            >
              <input
                className='form-check-input'
                type='checkbox'
                name='rateSeasonsIds[]'
                value={rateSeason.uuid}
                checked={selectedRateSeasonsIds.includes(rateSeason.uuid)}
                onChange={handleAccommodationChange}
              />
              <span className='form-check-label'>{rateSeason.name}</span>
            </label>
          ))}
          <br />
          <span className='text-muted'>
            {t(
              'pms:configuration.accommodationSettings.seasonsForm.seasonsForThisAccommodationHint',
            )}
          </span>
        </div>
        {selectedRateSeasonsIds.length === 0 ? (
          <div className='alert alert-info mt-2'>
            Please select at least one rate season to define date ranges.
          </div>
        ) : (
          <div ref={parentRef}>
            {dateRanges.length === 0 ? (
              <Button
                onClick={() =>
                  setDateRanges([
                    {
                      tmpUuid: uuidv4(),
                      startDate: firstDate,
                      endDate: lastDate,
                      seasonUuid: '',
                    },
                  ])
                }
                className='btn btn-primary mb-1 w-100'
              >
                <IconPlus />
                {t('pms:configuration.accommodationSettings.seasonsForm.createNewSeasonRange')}
              </Button>
            ) : (
              <>
                <AddDateRangeButton
                  startDate={DateTime.fromISO(dateRanges[0].startDate).minus({ days: 2 })}
                  endDate={DateTime.fromISO(dateRanges[0].startDate).minus({ days: 1 })}
                  dateRanges={dateRanges}
                  setDateRanges={setDateRanges}
                  newIndex={0}
                  isDisabled={replaceRateSeasonDateRangesOfAccommodation.isLoading}
                />
                {dateRanges
                  .sort((a, b) => a.startDate.localeCompare(b.startDate))
                  .map((dateRange, index) => (
                    <DateRangeElement
                      key={`DateRangeElement-${dateRange.tmpUuid}`}
                      dateRange={dateRange}
                      dateRanges={dateRanges}
                      rateSeasons={rateSeasons.filter((rs) =>
                        selectedRateSeasonsIds.includes(rs.uuid),
                      )}
                      setDateRanges={setDateRanges}
                      index={index}
                      formErrors={formErrors}
                      isDisabled={replaceRateSeasonDateRangesOfAccommodation.isLoading}
                    />
                  ))}
              </>
            )}
            <div className='row'>
              <div className='col-md-4'>
                <Button
                  type='button'
                  className='btn w-100'
                  onClick={resetOriginalDateRanges}
                  disabled={replaceRateSeasonDateRangesOfAccommodation.isLoading}
                >
                  {t('pms:form.reset')}
                </Button>
              </div>
              <div className='col-md-8'>
                <Button
                  onClick={() => {
                    replaceRateSeasonDateRangesOfAccommodation.mutate({
                      accommodationUuid,
                      rateSeasonUuids: selectedRateSeasonsIds,
                      dateRanges: dateRanges.map((dateRange) => ({
                        from: dateRange.startDate,
                        to: dateRange.endDate,
                        rateSeasonUuid: dateRange.seasonUuid,
                      })),
                    });
                  }}
                  isLoading={replaceRateSeasonDateRangesOfAccommodation.isLoading}
                  hideContentWhenLoading
                  // disabled={formErrors !== null && formErrors.errors.length > 0}
                >
                  {t('pms:form.save')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
