import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../../../common/components/form/Button';
import { usePmsContext } from '../../../../../Context';
import { RateSeason } from '../../../../../api/rates/rate-seasons';
import RateSeasonForm from './RateSeasonForm';

function RateSeasonElement({ rateSeason }: { rateSeason: RateSeason }) {
  const [showEditRateSeasonForm, setShowEditRateSeasonForm] = useState(false);

  if (showEditRateSeasonForm) {
    return (
      <div className='mb-2 card'>
        <div className='card-body'>
          <RateSeasonForm
            action='update'
            rateSeasonUuid={rateSeason.uuid}
            rateSeason={rateSeason}
            onSuccess={() => setShowEditRateSeasonForm(false)}
            onCancel={() => setShowEditRateSeasonForm(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='card card-body mb-2'>
      <div className='d-flex justify-content-between'>
        <div>
          <div className='text-truncate mb-2'>{rateSeason.name}</div>
          {rateSeason.accommodations.map((accommodation) => {
            return (
              <span
                key={`RateSeasonElement-${rateSeason.uuid}-accommodation-${accommodation.uuid}`}
                className='badge me-1'
              >
                {accommodation.name}
              </span>
            );
          })}
        </div>
        <div>
          <Button
            type='button'
            className='btn btn-icon ms-1'
            onClick={() => {
              setShowEditRateSeasonForm(true);
            }}
          >
            <IconPencil size='1em' />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function RateSeasonsList() {
  const { rateSeasons } = usePmsContext();

  return (
    <>
      {rateSeasons.map((season) => (
        <RateSeasonElement
          key={`RateSeasonElement-rateSeason-${season.uuid}`}
          rateSeason={season}
        />
      ))}
    </>
  );
}
