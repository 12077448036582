import { useId } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import Button from './form/Button';

export function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  currentPageLimit,
  onPageLimitChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  currentPageLimit: number;
  onPageLimitChange: (pageLimit: number) => void;
}) {
  const id = useId();
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <ul className='pagination mb-0'>
        <li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
          <Button className='page-link' onClick={() => onPageChange(currentPage - 1)}>
            <IconChevronLeft />
          </Button>
        </li>
        {totalPages > 6 ? (
          <div className='form-group' style={{ width: '100px' }}>
            <select
              className='form-select'
              value={currentPage}
              onChange={(e) => onPageChange(parseInt(e.target.value, 10))}
            >
              {pages.map((page) => (
                <option key={`booking-page-${id}-${page}`} value={page}>
                  {page}
                </option>
              ))}
            </select>
          </div>
        ) : (
          pages.map((page) => (
            <li
              key={`booking-page-${id}-${page}`}
              className={`page-item${page === currentPage ? ' active' : ''}`}
            >
              <Button
                disabled={page === currentPage}
                className='page-link'
                onClick={() => onPageChange(page)}
              >
                {page}
              </Button>
            </li>
          ))
        )}
        <li className={`page-item${currentPage === totalPages ? ' disabled' : ''}`}>
          <Button className='page-link' onClick={() => onPageChange(currentPage + 1)}>
            <IconChevronRight />
          </Button>
        </li>
      </ul>
      <div className='form-group' style={{ width: '100px' }}>
        <select
          className='form-select'
          value={currentPageLimit}
          onChange={(e) => {
            onPageLimitChange(parseInt(e.target.value, 10));
            onPageChange(1);
          }}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
}
