import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../common/components/form/Button';
import { PaymentMethod } from '../../pms/api/payment-methods';
import { isOfflinePaymentMethod } from '../../pms/utils/payment-method';
import PaymentMethodForm from './PaymentMethodForm';

export function PaymentMethodElement({ paymentMethod }: { paymentMethod: PaymentMethod }) {
  const [parentRef] = useAutoAnimate();
  const [showEditPaymentMethodForm, setShowEditPaymentMethodForm] = useState(false);

  return (
    <div ref={parentRef}>
      {showEditPaymentMethodForm ? (
        <PaymentMethodForm
          action='update'
          paymentMethodUuid={paymentMethod.uuid}
          paymentMethod={paymentMethod}
          onSuccess={() => setShowEditPaymentMethodForm(false)}
          onCancel={() => setShowEditPaymentMethodForm(false)}
          allowDelete={paymentMethod.provider === null}
        />
      ) : (
        <div className='d-flex justify-content-between'>
          <h3 className='text-truncate'>{paymentMethod.name}</h3>
          <div>
            <Button
              type='button'
              className='btn btn-icon ms-1'
              onClick={() => {
                setShowEditPaymentMethodForm(true);
              }}
            >
              <IconPencil size='1em' />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function PaymentMethodsList({
  paymentMethods,
}: {
  paymentMethods: PaymentMethod[];
}) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  return (
    <div className='row' ref={parentRef}>
      {paymentMethods.filter(isOfflinePaymentMethod).map((paymentMethod) => (
        <div
          key={`PaymentMethodElement-paymentMethod-${paymentMethod.uuid}`}
          className='col-md-6 pt-3'
        >
          <div className='card card-body'>
            <PaymentMethodElement paymentMethod={paymentMethod} />
          </div>
        </div>
      ))}
    </div>
  );
}
