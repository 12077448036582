import { Link, useLocation } from 'react-router-dom';
import { isNotEmpty } from '../../../utils/array';
import { getPathsFromPathname } from '../../../utils/string';

interface BreadcrumbsProps {
  startFromPath: string;
  pathToLabel: (path: string) => string | undefined;
}
export default function Breadcrumbs({ startFromPath, pathToLabel }: BreadcrumbsProps) {
  const location = useLocation();

  const allPaths = getPathsFromPathname(location.pathname);

  const startFromIndex = allPaths.indexOf(startFromPath);
  const paths = allPaths.slice(startFromIndex);

  const getLabelFromPath = (path: string) => pathToLabel(path);

  const breadcrumbs = paths
    .map((path, index) => {
      const isFirst = index === 0;
      const isLast = index === paths.length - 1;
      const link = `/${allPaths.slice(0, startFromIndex + index + 1).join('/')}`;

      const label = getLabelFromPath(path);
      if (!label) {
        return null;
      }

      return (
        <span key={path}>
          {!isFirst && <span> &gt; </span>}
          {isLast ? <span>{label}</span> : <Link to={link}>{label}</Link>}
        </span>
      );
    })
    .filter(isNotEmpty);

  return (
    <div className='row'>
      <div className='col'>{breadcrumbs}</div>
    </div>
  );
}
