import { Route, Routes } from 'react-router-dom';
import EditBooking from './booking/EditBooking';
import NewBooking from './booking/NewBooking';
import ViewBookings from './ViewBookings';
import ViewUnconfirmedBookings from './ViewUnconfirmedBookings';

export default function Bookings() {
  return (
    <Routes>
      <Route index element={<ViewBookings />} />
      <Route path='/new' element={<NewBooking />} />
      <Route path='/edit/:id/*' element={<EditBooking />} />
      <Route path='/unconfirmed' element={<ViewUnconfirmedBookings />} />
    </Routes>
  );
}
