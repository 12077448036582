import { DateTime } from 'luxon';
import { LocalDate } from '@js-joda/core';

export function localDateToLuxon(date: LocalDate) {
  return DateTime.fromISO(date.toString());
}

export function localDateToUTCJSDate(date: LocalDate) {
  return new Date(date.toString() + 'T00:00:00Z');
}

export function localDateToLocaleDateString(date: LocalDate) {
  return localDateToLuxon(date).toJSDate().toLocaleDateString();
}

// Range of dates from start (inclusive) to end (exclusive)
export function* dateRange(start: LocalDate, end: LocalDate) {
  for (let date = start; date.isBefore(end); date = date.plusDays(1)) {
    yield date;
  }
}
