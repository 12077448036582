import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../common/components/form/Button';
import { usePmsContext } from '../../../Context';
import PaymentMethodForm from './BookingEnginePageForm';
import BookingEnginePagesList from './BookingEnginePagesList';
import Modal from '../../../../common/components/Modal';
import { useTranslation } from 'react-i18next';
import { useCompanyContext } from '../../../../common/contexts/CompanyContext';

export default function ViewBookingEnginePages() {
  const { t } = useTranslation(['pms']);
  const { paymentMethods } = useCompanyContext();
  const { unitTypes } = usePmsContext();

  const [showCreateForm, setShowCreateForm] = useState(false);

  return (
    <>
      <Button
        type='button'
        onClick={() => {
          setShowCreateForm(true);
        }}
      >
        <IconPlus size='1em' className='me-2' />
        {t('pms:bookingEngine.createNewBookingEngine')}
      </Button>
      <BookingEnginePagesList unitTypes={unitTypes} paymentMethods={paymentMethods} />
      <Modal
        title={t('pms:bookingEngine.createNewBookingEngine')}
        isOpen={showCreateForm}
        onClose={() => setShowCreateForm(false)}
      >
        <PaymentMethodForm
          action='create'
          onCancel={() => setShowCreateForm(false)}
          onSuccess={() => {
            setShowCreateForm(false);
          }}
          unitTypes={unitTypes}
          paymentMethods={paymentMethods}
        />
      </Modal>
    </>
  );
}
