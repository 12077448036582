import { useState } from 'react';
import RateOptionModal from './RateOptionsModal';
import Button from '../../../../../../common/components/form/Button';
import { useTranslation } from 'react-i18next';

export default function ViewRateOptions() {
  const { t } = useTranslation(['pms']);
  const [showRateOptionModal, setShowRateOptionModal] = useState(false);

  return (
    <>
      <div className='d-flex justify-content-end mb-2'>
        <Button
          type='button'
          className='btn'
          onClick={() => {
            setShowRateOptionModal(true);
          }}
        >
          {t('pms:configuration.accommodationSettings.optionsForm.manageOptions')}
        </Button>
      </div>
      <RateOptionModal isOpen={showRateOptionModal} onClose={() => setShowRateOptionModal(false)} />
    </>
  );
}
