import { TRPCClientError } from '@trpc/client';
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ZodError } from 'zod';

export class FormErrors extends Error {
  constructor(
    public errors: {
      message: string;
      path: (string | number)[];
    }[],
  ) {
    const message = 'Form error';
    super(message);
  }

  toString(): string {
    return this.errors.map(({ message, path }) => `${message} at ${path.join('.')}`).join('\n');
  }

  static fromZodError(error: ZodError): FormErrors {
    return new FormErrors(
      error.issues.map((issue) => ({
        message: issue.message,
        path: issue.path,
      })),
    );
  }
}

type ApiResponse<T> = {
  success: boolean;
  errors: ZodError | string[];
  data: T;
};

export async function apiRequest<T>(
  endpoint: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  body?: any,
  headers?: AxiosRequestHeaders,
) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;
  const options: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    } as any,
  };

  try {
    const response = (await axios(url, {
      ...options,
      data: body,
    })) as AxiosResponse<ApiResponse<T>>;
    const { data } = response.data;
    return { errors: null, data };
  } catch (e) {
    let errors: string[] | FormErrors | null = null;
    if (e instanceof AxiosError) {
      if (e.status && parseInt(String(e.status), 10) >= 500) {
        errors = [e.message];
      } else if (e.response?.data?.error_type === 'form') {
        errors = new FormErrors(e.response?.data?.errors);
      } else if (Array.isArray(e.response?.data?.errors)) {
        errors = e.response?.data?.errors;
      }
    }
    if (errors === null) {
      if (e instanceof Error) {
        errors = [e.message];
      } else {
        errors = ['Unknown error'];
      }
    }
    return { errors, data: null };
  }
}

export async function apiGetRequest<T>(
  endpoint: string,
  token?: string,
  headers?: AxiosRequestHeaders,
) {
  const headersObj: any = headers ?? {};
  if (token) {
    headersObj.Authorization = `Bearer ${token}`;
  }
  return apiRequest<T>(endpoint, 'get', undefined, headersObj);
}

export async function apiPostRequest<T>(
  endpoint: string,
  body: any,
  token?: string,
  headers?: AxiosRequestHeaders,
) {
  const headersObj: any = headers ?? {};
  if (token) {
    headersObj.Authorization = `Bearer ${token}`;
  }
  return apiRequest<T>(endpoint, 'post', body, headersObj);
}

export async function apiPutRequest<T>(
  endpoint: string,
  body: any,
  token?: string,
  headers?: AxiosRequestHeaders,
) {
  const headersObj: any = headers ?? {};
  if (token) {
    headersObj.Authorization = `Bearer ${token}`;
  }
  return apiRequest<T>(endpoint, 'put', body, headersObj);
}

export async function apiPatchRequest<T>(
  endpoint: string,
  body: any,
  token?: string,
  headers?: AxiosRequestHeaders,
) {
  const headersObj: any = headers ?? {};
  if (token) {
    headersObj.Authorization = `Bearer ${token}`;
  }
  return apiRequest<T>(endpoint, 'patch', body, headersObj);
}

export async function apiDeleteRequest<T>(
  endpoint: string,
  token?: string,
  headers?: AxiosRequestHeaders,
) {
  const headersObj: any = headers ?? {};
  if (token) {
    headersObj.Authorization = `Bearer ${token}`;
  }
  return apiRequest<T>(endpoint, 'delete', undefined, headersObj);
}

export const getZodErrorMessagesByPath = (
  zodError: FormErrors | null,
  path: string[] | string[][],
) => {
  if (!zodError) return [];
  const errorMessages: string[] = [];

  zodError.errors.forEach((error) => {
    if (Array.isArray(path[0])) {
      if (path.some((p) => (p as string[]).join('.') === error.path.join('.'))) {
        errorMessages.push(error.message);
      }
    } else {
      if (error.path.join('.') === path.join('.')) {
        errorMessages.push(error.message);
      }
    }
  });

  return errorMessages;
};

export const getNewZodErrorMessagesByPath = (
  errors: any,
  path: (string | number)[] | string[][],
) => {
  if (!errors) return [];
  const errorMessages: string[] = [];

  if (errors instanceof TRPCClientError) {
    try {
      errors = JSON.parse(errors.message);
    } catch (e) {
      return [];
    }
  }

  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if ('message' in error && 'path' in error && error.path.join('.') === path.join('.')) {
        errorMessages.push(error.message);
      }
    });
  }

  return errorMessages;
};

export default {};
