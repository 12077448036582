import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createMutation, trpcErrorParser } from '../../../../utils/api';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Button from '../../../common/components/form/Button';
import { FormErrors } from '../../../common/utils/api';
import { CompanyEmailField } from '../common/CompanyEmailField';
import { UserPasswordField } from '../common/UserPasswordField';

const CompletePasswordResetContent = ({
  hasSucceeded,
  isGetting,
  isSubmitting,
  companyEmail,
  formErrors,
  genericError,
}: {
  hasSucceeded: boolean;
  isGetting: boolean;
  isSubmitting: boolean;
  companyEmail: string;
  formErrors: FormErrors | null;
  genericError: string[] | null;
}) => {
  if (hasSucceeded) {
    return (
      <>
        <div className='alert alert-success'>
          <h4 className='alert-title'>Password reset</h4>
          <div className='text-secondary'>
            Your password has been reset.
            <br />
            You can now login with your new password.
          </div>
        </div>
        <div className='form-footer'>
          <Link to={`../login?email=${companyEmail || ''}`} className='btn btn-icon w-100'>
            Go back to login
          </Link>
        </div>
      </>
    );
  }

  if (isGetting) {
    return <LoadingSpinner center />;
  }

  if (genericError) {
    return (
      <>
        <ErrorAlert errors={genericError} />
        <div className='form-footer'>
          <Link to={`../login`} className='btn btn-icon w-100'>
            Go back to login
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <CompanyEmailField value={companyEmail} />
      <UserPasswordField formErrors={formErrors} disabled={isSubmitting} />
      <div className='form-footer'>
        <Button isLoading={isSubmitting} hideContentWhenLoading>
          Reset password
        </Button>
      </div>
    </>
  );
};

export default function CompletePasswordReset({ uuid, token }: { uuid: string; token: string }) {
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [genericError, setGenericError] = useState<string[] | null>(null);
  const {
    data,
    isLoading: isGetting,
    error: getError,
  } = trpc.auth.getPasswordResetRequest.useQuery({
    uuid,
    token,
  });
  const resetPassword = createMutation(trpc.auth.passwordReset, {
    onSuccess: () => {
      setHasSucceeded(true);
    },
    setFormErrors,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const password = formData.get('userPassword') as string;

    resetPassword.mutate({
      passwordResetRequest: { uuid, token },
      user: { password },
    });
  };

  useEffect(() => {
    if (getError) {
      trpcErrorParser({ error: getError, setGenericError });
    }
  }, [getError]);

  return (
    <form className='card card-md' onSubmit={handleSubmit}>
      <div className='card-body'>
        <h2 className='card-title text-center mb-4'>Reset your password</h2>
        <CompletePasswordResetContent
          hasSucceeded={hasSucceeded}
          isGetting={isGetting}
          isSubmitting={resetPassword.isLoading}
          companyEmail={data?.company.email || ''}
          formErrors={formErrors}
          genericError={genericError}
        />
      </div>
    </form>
  );
}
