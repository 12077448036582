import { useTranslation } from 'react-i18next';
import { createMutation } from '../../../../../utils/api';
import { trpc } from '../../../../../utils/trpc';
import Button from '../../../../common/components/form/Button';

export default function CreateEmailNotificationMethodButton() {
  const { t } = useTranslation(['generic']);
  const trpcContext = trpc.useUtils();
  const createMethod = createMutation(trpc.notification.createEmailNotificationMethod, {
    invalidate: [trpcContext.notification],
  });

  return (
    <Button
      onClick={() => createMethod.mutate()}
      isLoading={createMethod.isLoading}
      hideContentWhenLoading
    >
      {t('generic:notificationMethod.form.createEmail')}
    </Button>
  );
}
