import { useId } from 'react';

export default function Steps({ steps, currentStep }: { steps: number; currentStep: number }) {
  const id = useId();
  return (
    <ul className='steps steps-green steps-counter'>
      {Array.from({ length: steps }, (_, i) => (
        <li
          key={`Steps-${id}-${i}`}
          className={`step-item ${i + 1 === currentStep ? 'active' : ''}`}
        ></li>
      ))}
    </ul>
  );
}
