import { useState } from 'react';
import { SelfWhatsAppBookingGuestNotification } from '../../types';
import SelfWhatsAppNotificationForm from './SelfWhatsAppNotificationForm';
import Button from '../../../../../common/components/form/Button';
import { IconPencil } from '@tabler/icons-react';
import Card from '../../../../../common/components/Card';
import { getLanguageFromCode } from '../../../../utils/i18n';
import { useTranslation } from 'react-i18next';

export default function SelfWhatsAppNotificationCard({
  notification,
}: {
  notification: SelfWhatsAppBookingGuestNotification;
}) {
  const { t } = useTranslation(['pms']);
  const [showForm, setShowForm] = useState(false);

  let whenToSendText = t(
    'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.never',
  );
  if (notification.checkInOffsetDays !== null) {
    if (notification.checkInOffsetDays === 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.checkInDay',
      );
    } else if (notification.checkInOffsetDays > 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.daysAfterCheckIn',
        { count: Math.abs(notification.checkInOffsetDays) },
      );
    } else if (notification.checkInOffsetDays < 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.daysBeforeCheckIn',
        { count: Math.abs(notification.checkInOffsetDays) },
      );
    }
  } else if (notification.checkOutOffsetDays !== null) {
    if (notification.checkOutOffsetDays === 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.checkOutDay',
      );
    } else if (notification.checkOutOffsetDays > 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.daysAfterCheckOut',
        { count: Math.abs(notification.checkOutOffsetDays) },
      );
    } else if (notification.checkOutOffsetDays < 0) {
      whenToSendText = t(
        'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.daysBeforeCheckOut',
        { count: Math.abs(notification.checkOutOffsetDays) },
      );
    }
  }

  if (showForm) {
    return (
      <Card title={notification.name}>
        <SelfWhatsAppNotificationForm
          action='update'
          selfWhatsAppNotification={notification}
          onSuccess={() => setShowForm(false)}
          onCancel={() => setShowForm(false)}
        />
      </Card>
    );
  }

  return (
    <Card
      title={
        <span>
          {notification.isEnabled ? (
            <span className='badge bg-success text-white me-2'>
              {t('pms:configuration.bookingGuestNotificationsSettings.enabled')}
            </span>
          ) : (
            <span className='badge bg-danger text-white me-2'>
              {t('pms:configuration.bookingGuestNotificationsSettings.disabled')}
            </span>
          )}
          {notification.name}
        </span>
      }
    >
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex justify-content-between'>
          <span>
            {t(
              'pms:configuration.bookingGuestNotificationsSettings.bookingGuestNotification.sendMessage',
            )}
            : {whenToSendText}.
          </span>
          <div>
            <Button
              type='button'
              className='btn btn-icon'
              onClick={() => {
                setShowForm(true);
              }}
            >
              <IconPencil size='1em' />
            </Button>
          </div>
        </div>
        <div>
          <p className='fw-bold'>
            {t('pms:configuration.bookingGuestNotificationsSettings.message')}
          </p>
          <div className='d-flex flex-column gap-2'>
            {Object.entries(notification.template).map(([lang, message]) => {
              const language = getLanguageFromCode(lang);

              const languageName = language ? language.name : lang;
              const languageFlag = language ? language.flagUrl : '';

              return (
                <Card
                  key={`SelfWhatsAppNotificationCard-${notification.uuid}-${lang}`}
                  title={
                    <>
                      <img
                        src={languageFlag}
                        className='me-2'
                        alt={lang}
                        title={lang}
                        style={{ width: '1.5rem', maxHeight: '2em' }}
                      />
                      <span>{languageName}</span>
                    </>
                  }
                >
                  <div style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
