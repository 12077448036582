import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { MenuPage } from '../api/menu-page';
import { useLanguageContext } from '../../common/contexts/LanguageContext';
import { useMenuContext } from '../Context';
import { isOnlinePaymentMethod } from '../../pms/utils/payment-method';
import ErrorAlert from '../../common/components/ErrorAlert';
import { useTranslation } from 'react-i18next';

type MenuPageFormProps = {
  onCancel: () => void;
  onSuccess?: () => void;
} & (
  | {
      action: 'create';
    }
  | {
      action: 'update';
      menuPage: MenuPage;
    }
);

export default function MenuPageForm(props: MenuPageFormProps) {
  const { t } = useTranslation(['menu']);
  const { action, onCancel } = props;
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const navigate = useNavigate();
  const { availableLanguages } = useLanguageContext();
  const { paymentMethods } = useMenuContext();
  const [allowOnlineOrdering, setAllowOnlineOrdering] = useState(
    action === 'update' ? props.menuPage.allowOnlineOrdering : false,
  );
  const [requireOnlinePayment, setRequireOnlinePayment] = useState(
    action === 'update' ? props.menuPage.requireOnlinePayment : false,
  );

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
    setFormErrors,
    onSuccess: () => {
      setFormErrors(null);
      props.onSuccess?.();
    },
  };
  const createMenuPage = createMutation(trpc.menu.admin.createMenuPage, mutationOptions);
  const updateMenuPage = createMutation(trpc.menu.admin.updateMenuPage, mutationOptions);
  const deleteMenuPage = createMutation(trpc.menu.admin.deleteMenuPage, {
    ...mutationOptions,
    onSuccess: () => {
      setFormErrors(null);
      props.onSuccess?.();
      navigate('../');
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const menuPageName = formData.get('menuPageName') as string;
    const defaultLanguageCode = formData.get('defaultLanguage') as string;
    const availableLanguageCodes = formData.getAll('availableLanguages[]') as string[];
    const allowOnlineOrdering = formData.get('allowOnlineOrdering') === 'on';
    const requireOnlinePayment = formData.get('requireOnlinePayment') === 'on';
    const paymentMethodsUuids = formData.getAll('paymentMethods[]') as string[];

    const data = {
      name: menuPageName,
      defaultLanguageCode,
      availableLanguageCodes,
      allowOnlineOrdering,
      requireOnlinePayment,
      paymentMethodsUuids,
    };

    if (action === 'create') {
      createMenuPage.mutate(data);
    } else {
      updateMenuPage.mutate({ ...data, uuid: props.menuPage.uuid });
    }
  };

  const isUpserting = createMenuPage.isLoading || updateMenuPage.isLoading;
  const isDeleting = deleteMenuPage.isLoading;
  const isLoading = isUpserting || isDeleting;

  const onlinePaymentMethods = paymentMethods.filter(isOnlinePaymentMethod);

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        type='text'
        title={t('menu:form.menuName')}
        hint={t('menu:form.menuNameHint')}
        name='menuPageName'
        errorPath={['name']}
        formErrors={formErrors}
        disabled={isLoading}
        defaultValue={action === 'update' ? props.menuPage.name : ''}
      />
      <FormInput
        type='select'
        title={t('menu:form.defaultLanguage')}
        name='defaultLanguage'
        errorPath={['defaultLanguage']}
        formErrors={formErrors}
        disabled={isLoading}
        defaultValue={action === 'update' ? props.menuPage.defaultLanguage : ''}
      >
        {availableLanguages.map((language) => (
          <option key={`select-language-${language.code}`} value={language.code}>
            {language.name}
          </option>
        ))}
      </FormInput>
      <div className='row col-12'>
        <b>{t('menu:form.availableLanguages')}</b>
        <small className='form-hint'>{t('menu:form.availableLanguagesHint')}</small>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '1em' }}>
          {availableLanguages.map((language) => (
            <FormInput
              key={`MenuPageForm-language-code-${language.code}`}
              type='checkbox'
              name='availableLanguages[]'
              checkboxValue={language.code}
              title={language.name}
              errorPath={['availableLanguages']}
              formErrors={formErrors}
              disabled={isLoading}
              defaultValue={
                action === 'create'
                  ? true
                  : props.menuPage.availableLanguages.includes(language.code)
              }
              autoFocus
            />
          ))}
        </div>
      </div>
      <div className='row col-12'>
        <FormInput
          type='switch'
          title={t('menu:form.allowOnlineOrdering')}
          hint={t('menu:form.allowOnlineOrderingHint')}
          name='allowOnlineOrdering'
          errorPath={['allowOnlineOrdering']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={allowOnlineOrdering}
          checkboxValue='on'
          onChange={(checked) => setAllowOnlineOrdering(checked)}
        />
      </div>
      {allowOnlineOrdering && (
        <>
          <FormInput
            type='switch'
            title={t('menu:form.requireOnlinePayment')}
            hint={t('menu:form.requireOnlinePaymentHint')}
            name='requireOnlinePayment'
            errorPath={['requireOnlinePayment']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={requireOnlinePayment}
            checkboxValue='on'
            onChange={(checked) => setRequireOnlinePayment(checked)}
          />
          {requireOnlinePayment && onlinePaymentMethods.length === 0 && (
            <ErrorAlert errors={t('menu:form.noOnlinePaymentMethodsError')} />
          )}
          {onlinePaymentMethods.length > 0 && (
            <div className='row col-12'>
              <b>{t('menu:form.acceptedOnlinePaymentMethods')}</b>
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '1em' }}
              >
                {onlinePaymentMethods.map((paymentMethod) => (
                  <FormInput
                    key={`MenuPageForm-payment-method-${paymentMethod.uuid}`}
                    type='checkbox'
                    name='paymentMethods[]'
                    checkboxValue={paymentMethod.uuid}
                    title={paymentMethod.name}
                    errorPath={['paymentMethodsUuids']}
                    formErrors={formErrors}
                    disabled={isLoading}
                    defaultValue={
                      action === 'create'
                        ? true
                        : props.menuPage.paymentMethodsUuids.includes(paymentMethod.uuid)
                    }
                    autoFocus
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-outline-danger btn-icon w-100'
              isLoading={isDeleting}
              disabled={isLoading}
              hideContentWhenLoading
              onClick={() => {
                if (confirm('Are you sure you want to delete this menu?')) {
                  deleteMenuPage.mutate({ uuid: props.menuPage.uuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
            {t('menu:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button disabled={isLoading} isLoading={isUpserting} hideContentWhenLoading>
            {action === 'create' ? t('menu:form.create') : t('menu:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
