import Card from '../Card';

export default function Page({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) {
  return <Card title={title}>{children}</Card>;
}
