import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import { trpc } from '../../../../../utils/trpc';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import Button from '../../../../common/components/form/Button';
import { BookingEnginePage } from '../../../api/booking-engine-pages';
import { PaymentMethod } from '../../../api/payment-methods';
import { UnitType } from '../../../api/units/unit-types';
import BookingEnginePageForm from './BookingEnginePageForm';

function BookingEnginePageElement({
  bookingEnginePage,
  unitTypes,
  paymentMethods,
}: {
  bookingEnginePage: BookingEnginePage;
  unitTypes: UnitType[];
  paymentMethods: PaymentMethod[];
}) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [showEditForm, setShowEditForm] = useState(false);

  const url = `${process.env.REACT_APP_BOOKING_ENGINE_ENDPOINT}book/${bookingEnginePage.slug}`;

  return (
    <div ref={parentRef}>
      {showEditForm ? (
        <BookingEnginePageForm
          action='update'
          bookingEnginePageUuid={bookingEnginePage.uuid}
          bookingEnginePage={bookingEnginePage}
          unitTypes={unitTypes}
          paymentMethods={paymentMethods}
          onSuccess={() => setShowEditForm(false)}
          onCancel={() => setShowEditForm(false)}
        />
      ) : (
        <div className='d-flex justify-content-between'>
          <div>
            <h3 className='text-truncate'>{bookingEnginePage.name}</h3>
            <a href={url} target='_blank' rel='noreferrer'>
              {url}
            </a>
          </div>
          <div>
            <Button
              type='button'
              className='btn btn-icon'
              onClick={() => {
                setShowEditForm(true);
              }}
            >
              <IconPencil size='1em' />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function BookingEnginePagesList({
  unitTypes,
  paymentMethods,
}: {
  unitTypes: UnitType[];
  paymentMethods: PaymentMethod[];
}) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const query = trpc.pms.bookingEngine.listPages.useQuery();

  if (query.isLoading) {
    return <LoadingSpinner cardBody center />;
  }

  if (!query.isSuccess) {
    return <ErrorAlert errors={query.error as any} />;
  }

  return (
    <div className='row' ref={parentRef}>
      {query.data.map((bookingEnginePage) => (
        <div
          key={`BookingPageElement-bookingPage-${bookingEnginePage.name}`}
          className='col-md-6 pt-3'
        >
          <div className='card card-body'>
            <BookingEnginePageElement
              bookingEnginePage={bookingEnginePage}
              unitTypes={unitTypes}
              paymentMethods={paymentMethods}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
