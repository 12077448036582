import { checkUnreachableAndReturn } from '../../../utils/type';
import { NotificationMethod } from '../../pms/api/notification-methods';

export function getNotificationMethodTypeName(notificationMethod: NotificationMethod): string {
  const type = notificationMethod.data.type;
  switch (type) {
    case 'email':
      return `Email: ${notificationMethod.data.email}`;
    case 'telegram':
      return `Telegram: @${notificationMethod.data.username}`;
    default:
      return checkUnreachableAndReturn(type, `${type}`);
  }
}
