import { Navigate, Route, Routes } from 'react-router-dom';
import { MenuContextProvider } from './Context';
import MenuPagesPage from './pages/menus';
import MenuPageEditPage from './pages/menus/edit';
import { LanguageContextProvider } from '../common/contexts/LanguageContext';
import OrdersPage from './pages/orders';
import ConfigurationPage from './pages/configuration';
import PlansPage from './pages/plans';

export default function Router() {
  return (
    <MenuContextProvider>
      <LanguageContextProvider>
        <Routes>
          <Route
            path='*'
            element={
              <Routes>
                <Route index element={<Navigate to='menus' />} />
                <Route path='/menus' element={<MenuPagesPage />} />
                <Route path='/menus/edit/:id/*' element={<MenuPageEditPage />} />
                <Route path='/orders' element={<OrdersPage />} />
                <Route path='/configuration/*' element={<ConfigurationPage />} />
                <Route path='/plans/*' element={<PlansPage />} />
              </Routes>
            }
          />
        </Routes>
      </LanguageContextProvider>
    </MenuContextProvider>
  );
}
