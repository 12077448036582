import ReactModal from 'react-modal';

function Modal({
  children,
  isOpen,
  onClose,
  title,
  size,
  showCloseButton = true,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  showCloseButton?: boolean;
}) {
  if (!isOpen) {
    return null;
  }

  let maxWidth;
  switch (size) {
    case 'xl':
      maxWidth = '1140px';
      break;
    case 'lg':
      maxWidth = '960px';
      break;
    case 'md':
      maxWidth = '720px';
      break;
    case 'sm':
      maxWidth = '480px';
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: 0,
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          width: '80%',
          maxWidth,
          minWidth: '300px',
          maxHeight: '80%',
          minHeight: '300px',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: '9998', // react-toast is 9999
        },
      }}
    >
      <div className='modal-header'>
        <h5 className='modal-title'>{title}</h5>
        {showCloseButton && (
          <button type='button' className='btn-close' aria-label='Close' onClick={onClose} />
        )}
      </div>
      <div className='modal-body' style={{ padding: '1rem' }}>
        {children}
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = {
  title: '',
  size: 'md',
};

export default Modal;
