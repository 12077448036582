import { createContext, useContext, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(cookies.get('token') !== undefined);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    const allCookies = cookies.getAll<Record<string, unknown>>();
    Object.keys(allCookies).forEach((cookieName) => {
      cookies.remove(cookieName, { path: '/' });
    });
    localStorage.clear();
    setIsAuthenticated(false);
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      login,
      logout,
    }),
    [isAuthenticated, user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
