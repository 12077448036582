import { TRPCClientError } from '@trpc/client';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { FormErrors } from '../packages/common/utils/api';

const getGenericAndFormErrors = (error: unknown) => {
  const genericErrors: string[] = [];
  const formErrors: {
    message: string;
    path: (string | number)[];
  }[] = [];

  if (!error) return { genericErrors, formErrors };

  if (error instanceof TRPCClientError) {
    try {
      const messageObject = JSON.parse(error.message);
      if (Array.isArray(messageObject)) {
        for (const err of messageObject) {
          const parseResult1 = z
            .object({
              message: z.string(),
              path: z.array(z.union([z.string(), z.number()])),
            })
            .safeParse(err);
          const parseResult2 = z
            .object({
              error: z.object({
                message: z.string(),
                path: z.array(z.union([z.string(), z.number()])),
              }),
            })
            .safeParse(err);
          if (parseResult1.success) {
            formErrors.push(parseResult1.data);
          } else if (parseResult2.success) {
            formErrors.push(parseResult2.data.error);
          } else {
            genericErrors.push(new String(err).toString());
          }
        }
      } else {
        genericErrors.push(new String(messageObject).toString());
      }
    } catch (e) {
      genericErrors.push(new String(error.message).toString());
    }
  } else if (Array.isArray(error)) {
    for (const err of error) {
      const parseResult = z
        .object({
          error: z.object({
            message: z.string(),
            path: z.array(z.union([z.string(), z.number()])),
          }),
        })
        .safeParse(err);
      if (parseResult.success) {
        formErrors.push(parseResult.data.error);
      } else {
        genericErrors.push(new String(err).toString());
      }
    }
  } else {
    genericErrors.push(new String(error).toString());
  }

  return {
    genericErrors,
    formErrors,
  };
};

export const trpcErrorParser = ({
  error,
  setGenericError,
  setFormErrors,
}: {
  error: unknown;
  setGenericError?: (errors: string[]) => void;
  setFormErrors?: (errors: FormErrors | null) => void;
}) => {
  const { genericErrors, formErrors } = getGenericAndFormErrors(error);

  if (setGenericError) setGenericError(genericErrors);
  if (setFormErrors) setFormErrors(formErrors.length > 0 ? new FormErrors(formErrors) : null);

  return {
    genericErrors,
    formErrors,
  };
};

type SuccessHandler<ReturnDataT> = (data: ReturnDataT) => void;

export function createMutation<
  T extends {
    useMutation: ({
      onSuccess,
      onError,
    }: {
      onSuccess: SuccessHandler<ReturnDataT>;
      onError: (error: unknown) => void;
    }) => unknown;
  },
  ReturnDataT = unknown,
>(
  mutation: T,
  {
    invalidate,
    onSuccess,
    onSuccessBeforeInvalidate,
    onError,
    setFormErrors,
    setGenericError,
  }: {
    invalidate?: Array<{ invalidate: () => Promise<any> }>;
    onSuccess?: (data: ReturnDataT) => void;
    onSuccessBeforeInvalidate?: (data: ReturnDataT) => Promise<void>;
    onError?: (error: unknown) => void;
    setFormErrors?: (formErrors: FormErrors | null) => void;
    setGenericError?: (errors: string[]) => void;
  } = {},
): ReturnType<T['useMutation']> {
  return mutation.useMutation({
    onSuccess: async (data) => {
      if (onSuccessBeforeInvalidate) {
        await onSuccessBeforeInvalidate(data);
      }
      if (invalidate) {
        await Promise.all(invalidate.map((i) => i.invalidate()));
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error) => {
      const { formErrors, genericErrors } = trpcErrorParser({
        error,
        setFormErrors,
        setGenericError,
      });
      if (onError) {
        onError(error);
      }
      toast.error(
        [...genericErrors, ...formErrors.map((e) => e.path.join('.') + ': ' + e.message)].join(
          '\n',
        ),
        {
          duration: 5000,
        },
      );
    },
  }) as ReturnType<T['useMutation']>;
}
