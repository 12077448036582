import { useEffect, useState } from 'react';
import Button from '../form/Button';
import Dropzone from 'react-dropzone';
import Modal from '../Modal';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import ProgressBar from '../ProgressBar';
import PublicMenuImagePreview from '../../../menu/components/PublicMenuImagePreview';
import LoadingSpinner from '../LoadingSpinner';

const MAX_IMAGE_DIMENSION_PX = 200;
const MAX_IMAGE_SIZE_KB = 200;

export default function UploadImageModal({
  title,
  defaultImageUrl,
  isOpen,
  onClose,
  getPresignedUrl,
  callback,
}: {
  title: string;
  defaultImageUrl: string | null;
  isOpen: boolean;
  onClose: () => void;
  getPresignedUrl: ({
    contentType,
    contentLength,
    originalFileName,
  }: {
    contentType: string;
    contentLength: number;
    originalFileName: string;
  }) => Promise<{
    presignedUrl: string;
  }>;
  callback?: () => Promise<void>;
}) {
  const { t } = useTranslation(['storage']);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [uploadStep, setUploadStep] = useState<string | null>(null);
  const [previewFile, setPreviewFile] = useState<File | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setProgress(null);
      setUploadStep(null);
      setPreviewFile(null);
    }
  }, [isOpen]);

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose}>
      <div className='d-flex w-100 gap-2 flex-column flex-md-row align-items-center align-items-md-start'>
        <Dropzone
          disabled={isLoading}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }}
          onDrop={async (files) => {
            setPreviewFile(null);
            if (files.length !== 1) {
              alert(t('storage:uploadImage.uploadErrors.onlyOne'));
              return;
            }

            try {
              setIsLoading(true);

              const originalFile = files[0];
              setPreviewFile(originalFile);

              setProgress(0);
              setUploadStep(t('storage:uploadImage.uploadSteps.compressing'));

              const file = await imageCompression(originalFile, {
                maxWidthOrHeight: MAX_IMAGE_DIMENSION_PX,
                maxSizeMB: MAX_IMAGE_SIZE_KB / 1000,
                onProgress: (percent) => setProgress(percent / 2),
              });
              setPreviewFile(file);
              setProgress(50);
              setUploadStep(t('storage:uploadImage.uploadSteps.obtainingUploadData'));

              const { presignedUrl } = await getPresignedUrl({
                contentType: file.type,
                contentLength: file.size,
                originalFileName: originalFile.name,
              });

              setProgress(60);
              setUploadStep(t('storage:uploadImage.uploadSteps.uploading'));

              await axios.put(presignedUrl, file, {
                headers: { 'Content-Type': file.type },
              });

              setProgress(90);
              setUploadStep(t('storage:uploadImage.uploadSteps.updatingData'));

              if (callback) {
                await callback();
              }

              setProgress(100);

              onClose();
            } catch (error) {
              alert(t('storage:uploadImage.uploadErrors.generic'));
              console.error(error);
            } finally {
              setProgress(null);
              setIsLoading(false);
            }
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={`card ${isDragActive ? 'shadow' : ''}`}
              style={{
                cursor: isLoading ? 'inherit' : 'pointer',
                padding: '1em',
                textAlign: 'center',
                width: '100%',
                height: '150px',
                borderRadius: '1em',
              }}
            >
              <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <div className='mb-4'>{t('storage:uploadImage.dropzoneText')}</div>
                    <Button type='button' className='btn'>
                      {t('storage:uploadImage.uploadButton')}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        <div style={{ minWidth: '150px', width: '150px', minHeight: '150px', height: '150px' }}>
          {previewFile ? (
            <PublicMenuImagePreview
              src={URL.createObjectURL(previewFile)}
              width={150}
              height={150}
            />
          ) : defaultImageUrl ? (
            <PublicMenuImagePreview src={defaultImageUrl} width={150} height={150} />
          ) : (
            <div
              style={{
                width: '150px',
                height: '150px',
                borderRadius: '16px',
                backgroundColor: '#f0f0f0',
              }}
            />
          )}
        </div>
      </div>
      <div className='text-center w-100 mt-2' style={{ height: '5em' }}>
        {progress !== null && (
          <>
            <ProgressBar progress={progress} />
            <br />
            {uploadStep}
          </>
        )}
      </div>
    </Modal>
  );
}
