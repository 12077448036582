import { useTranslation } from 'react-i18next';
import FormInput from '../../../common/components/form/input';

export function CompanyEmailField({
  value,
  disabled = true,
}: {
  value: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      type='email'
      name='companyEmail'
      title={t('auth:form.companyEmail')}
      defaultValue={value}
      disabled={disabled}
    />
  );
}
