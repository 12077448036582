import { useState } from 'react';
import RateSeasonDateRangeForm from './RateSeasonDateRangesForm';
import RateSeasonModal from './RateSeasonsModal';
import Button from '../../../../../../common/components/form/Button';
import { usePmsContext } from '../../../../../Context';
import { t } from 'i18next';

export default function ViewSeasons({ accommodationUuid }: { accommodationUuid: string }) {
  const { rateSeasons } = usePmsContext();

  const [showRateSeasonModal, setShowRateSeasonModal] = useState(false);

  return (
    <>
      <div className='d-flex justify-content-end mb-2'>
        <Button
          type='button'
          className='btn'
          onClick={() => {
            setShowRateSeasonModal(true);
          }}
        >
          {t('pms:configuration.accommodationSettings.seasonsForm.manageSeasons')}
        </Button>
      </div>
      {rateSeasons.length === 0 ? (
        <div className='alert alert-info'>
          {t(
            'pms:configuration.accommodationSettings.seasonsConfiguration.createAtLeastOneSeasonError',
          )}
        </div>
      ) : (
        <RateSeasonDateRangeForm accommodationUuid={accommodationUuid} />
      )}
      <RateSeasonModal isOpen={showRateSeasonModal} onClose={() => setShowRateSeasonModal(false)} />
    </>
  );
}
