import { Link } from 'react-router-dom';

type IdType = string;
type NavTabsProps = {
  items: {
    id: IdType;
    label: React.ReactNode;
    title?: string;
    link?: string;
  }[];
  activeId: IdType;
  onActiveIdChange?: (id: IdType) => void;
};

export default function NavTabs({ items, activeId, onActiveIdChange }: NavTabsProps) {
  return (
    <ul className='nav nav-tabs'>
      {items.map((item) => {
        const isActive = item.id === activeId;
        const navItemClassName = isActive ? 'nav-item active' : 'nav-item';
        const navLinkClassName = isActive ? 'nav-link active' : 'nav-link';
        return (
          <li
            className={navItemClassName}
            key={item.id}
            onClick={() => onActiveIdChange?.(item.id)}
          >
            {item.link === undefined ? (
              <span className={`${navLinkClassName} cursor-pointer`}>{item.label}</span>
            ) : (
              <Link to={item.link} className={navLinkClassName} title={item.title}>
                {item.label}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
