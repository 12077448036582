import { useId, useState } from 'react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { usePmsContext } from '../../Context';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import { Pagination } from '../../../common/components/Pagination';
import BookingListItem from './booking/BookingListItem';
import Page from '../../../common/components/dashboard/Page';

export default function ViewUnconfirmedBookings() {
  const { t } = useTranslation(['pms']);
  return (
    <Page title={t('pms:navbar.unconfirmedBookings')}>
      <div className='alert alert-info mt-3'>{t('pms:unconfirmedBooking.hint')}</div>
      <UnconfirmedBookingList />
    </Page>
  );
}

function UnconfirmedBookingList() {
  const { t } = useTranslation(['pms']);
  const { currentAccommodations, unitTypes: allUnitTypes } = usePmsContext();
  const currentAccommodationsUuids = currentAccommodations.map((a) => a.uuid);

  const id = useId();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const queryBookings = trpc.pms.booking.queryUnconfirmedBookings.useQuery({
    accommodationsUuids: currentAccommodationsUuids,
    page,
    limit: pageSize,
  });

  if (queryBookings.isLoading) {
    return <LoadingSpinner center />;
  }

  if (queryBookings.error) {
    return <ErrorAlert errors='An error occurred' />;
  }

  const bookings = queryBookings.data.bookings;
  const totalBookings = queryBookings.data.total;

  if (bookings.length === 0) {
    return (
      <div className='text-center'>
        <h3>{t('pms:booking.noBookingsFound')}</h3>
      </div>
    );
  }

  const unitTypes = allUnitTypes.filter((u) =>
    currentAccommodationsUuids.includes(u.accommodationUuid),
  );

  return (
    <>
      <div className='mb-3'>
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(totalBookings / pageSize)}
          onPageChange={(p) => setPage(p)}
          currentPageLimit={pageSize}
          onPageLimitChange={(p) => setPageSize(p)}
        />
      </div>
      <div className='card table-responsive'>
        <table className='table'>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>Room</th>
              <th scope='col'>Arrival</th>
              <th scope='col'>Departure</th>
              <th scope='col' className='text-end'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <BookingListItem
                key={`${id}-booking-list-item-${booking.uuid}`}
                unitTypes={unitTypes}
                booking={booking}
                showEdit={false}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
