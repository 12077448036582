import { useState } from 'react';
import toast from 'react-hot-toast';
import { createMutation } from '../../../../../../utils/api';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import { FormErrors } from '../../../../../common/utils/api';
import { useTranslation } from 'react-i18next';
import { ArrivalDepartureTimesSelector, PaxSelector } from '../AddBookingUnit';
import { LocalTime } from '@js-joda/core';
import { DEFAULT_ADULTS, DEFAULT_CHILDREN } from '../../../../utils/booking';
import FormInput from '../../../../../common/components/form/input';
import type { BookingEditSelectedUnit } from '../SelectedBookingUnits';

interface UpdateBookingUnitFormProps {
  bookingUnit: BookingEditSelectedUnit;
  onSuccess: () => void;
  onCancel: () => void;
}
type BookingUnitFormProps = UpdateBookingUnitFormProps;

export function BookingUnitForm({ bookingUnit, onSuccess, onCancel }: BookingUnitFormProps) {
  const { t } = useTranslation(['pms']);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.pms.booking],
    onSuccess: () => {
      toast.success(`Booking unit updated`);
      onSuccess();
    },
    setFormErrors,
  };
  const updateBookingUnit = createMutation(trpc.pms.booking.updateBookingUnit, mutationOptions);

  const [adultsNumber, setAdultsNumber] = useState(bookingUnit.adults ?? DEFAULT_ADULTS);
  const [childrenNumber, setChildrenNumber] = useState(bookingUnit.children ?? DEFAULT_CHILDREN);
  const [arrivalTime, setArrivalTime] = useState<LocalTime | null>(bookingUnit.arrivalTime);
  const [departureTime, setDepartureTime] = useState<LocalTime | null>(bookingUnit.departureTime);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    const rateAmount = form.get('rateAmount') as string;

    const data = {
      arrivalTime: arrivalTime === null ? null : arrivalTime.toString(),
      departureTime: departureTime === null ? null : departureTime.toString(),
      adultsNumber,
      childrenNumber,
      rateAmount: rateAmount !== '' ? parseFloat(rateAmount) : null,
    };

    updateBookingUnit.mutate({ ...data, uuid: bookingUnit.bookingUnitUuid });
  };

  const disabled = updateBookingUnit.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='d-flex flex-wrap gap-2'>
        <div>
          <ArrivalDepartureTimesSelector
            arrivalTime={arrivalTime}
            departureTime={departureTime}
            onChange={(a, d) => {
              setArrivalTime(a);
              setDepartureTime(d);
            }}
          />
        </div>
        <div>
          <PaxSelector
            adultsPax={adultsNumber}
            childrenPax={childrenNumber}
            onChange={(a, c) => {
              setAdultsNumber(a);
              setChildrenNumber(c);
            }}
          />
        </div>
        <div>
          <FormInput
            type='number'
            name='rateAmount'
            title={t('pms:booking.form.rate')}
            startText='€'
            defaultValue={String(bookingUnit.rateAmount) ?? ''}
            formErrors={formErrors}
            errorPath={['rateAmount']}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end gap-2'>
        <Button type='button' className='btn' disabled={disabled} onClick={onCancel}>
          {t('pms:form.cancel')}
        </Button>
        <Button
          className='btn btn-primary'
          disabled={disabled}
          isLoading={updateBookingUnit.isLoading}
          hideContentWhenLoading
        >
          {t('pms:form.update')}
        </Button>
      </div>
    </form>
  );
}
