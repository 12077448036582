import { IconPlus } from '@tabler/icons-react';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Button from '../../../common/components/form/Button';
import { PaymentMethod } from '../../../pms/api/payment-methods';
import { PaymentMethodElement } from '../PaymentMethodsList';
import Modal from '../../../common/components/Modal';
import { useState } from 'react';
import FormInput from '../../../common/components/form/input';
import { createMutation } from '../../../../utils/api';
import Card from '../../../common/components/Card';
import { useTranslation } from 'react-i18next';

enum Status {
  NotStarted,
  Completed,
}

function SatispayShowPaymentMethod({
  paymentMethods,
  paymentMethodUuid,
}: {
  paymentMethodUuid: string | null;
  paymentMethods: PaymentMethod[];
}) {
  return (
    <>
      {paymentMethods
        .filter((paymentMethod) => paymentMethod.provider?.id === paymentMethodUuid)
        .map((paymentMethod) => {
          return (
            <div
              key={`Stripe-paymentmethod-${paymentMethod.uuid}`}
              className='list-group list-group-flush list-group-hoverable'
            >
              <div className='list-group-item'>
                <PaymentMethodElement paymentMethod={paymentMethod} />
              </div>
            </div>
          );
        })}
    </>
  );
}

function SatispayAction({
  status,
  paymentMethodUuid,
  onClick,
  isLoading,
  paymentMethods,
}: {
  status: Status | null;
  paymentMethodUuid: string | null;
  onClick: () => void;
  isLoading: boolean;
  paymentMethods: PaymentMethod[];
}) {
  const { t } = useTranslation(['pms']);

  if (status === null && !isLoading) {
    return null;
  }

  if (status === Status.Completed) {
    return (
      <SatispayShowPaymentMethod
        paymentMethods={paymentMethods}
        paymentMethodUuid={paymentMethodUuid}
      />
    );
  }

  return (
    <Button type='button' isLoading={isLoading} hideContentWhenLoading onClick={onClick}>
      <IconPlus />
      {t('pms:configuration.paymentMethodSettings.satispayForm.connect')}
    </Button>
  );
}

function SetupSatispayModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation(['pms']);
  const [activationToken, setActivationToken] = useState('');

  const trpcContext = trpc.useUtils();
  const activate = createMutation(trpc.pay.providers.satispay.connectSatispayAccountWithToken, {
    onSuccess: () => {
      onClose();
    },
    invalidate: [trpcContext.pay.providers.satispay, trpcContext.menu.admin.getMenuData],
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    activate.mutate({
      token: activationToken,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Satispay'>
      <form onSubmit={onSubmit}>
        <p>{t('pms:configuration.paymentMethodSettings.satispayForm.hint')}</p>
        <FormInput
          type='text'
          title={t('pms:configuration.paymentMethodSettings.satispayForm.activationToken')}
          name='activation-token'
          onChange={(v) => setActivationToken(v)}
          disabled={activate.isLoading}
        />
        <Button type='submit' isLoading={activate.isLoading} hideContentWhenLoading>
          {t('pms:configuration.paymentMethodSettings.satispayForm.connect')}
        </Button>
      </form>
    </Modal>
  );
}

export default function Satispay({ paymentMethods }: { paymentMethods: PaymentMethod[] }) {
  const { t } = useTranslation(['pms']);
  const getAccountStatus = trpc.pay.providers.satispay.getAccountStatus.useQuery();

  const [showSetupModal, setShowSetupModal] = useState(false);

  const isLoading = getAccountStatus.isLoading;
  if (isLoading) {
    return <LoadingSpinner center cardBody />;
  }

  if (!getAccountStatus.data) {
    return <ErrorAlert errors={getAccountStatus.error} />;
  }

  let status;
  if (getAccountStatus.data.processCompleted) {
    status = Status.Completed;
  } else {
    status = Status.NotStarted;
  }

  const paymentMethodUuid = getAccountStatus.data.processCompleted
    ? getAccountStatus.data.paymentMethodUuid
    : null;

  return (
    <Card title='Satispay' style={{ flexGrow: 1 }}>
      <span>{t('pms:configuration.paymentMethodSettings.satispayForm.description')}</span>
      <div className='mt-3'>
        {isLoading && <LoadingSpinner center />}
        <SatispayAction
          status={status}
          onClick={() => setShowSetupModal(true)}
          isLoading={isLoading}
          paymentMethods={paymentMethods}
          paymentMethodUuid={paymentMethodUuid}
        />
        <SetupSatispayModal isOpen={showSetupModal} onClose={() => setShowSetupModal(false)} />
      </div>
    </Card>
  );
}
