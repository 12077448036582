import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMutation } from '../../../../../../utils/api';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import FormInput from '../../../../../common/components/form/input';
import { FormErrors } from '../../../../../common/utils/api';
import { useTranslation } from 'react-i18next';

export default function NewAccommodation() {
  const { t } = useTranslation(['pms']);
  const navigate = useNavigate();
  const trpcContext = trpc.useUtils();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const createAccommodation = createMutation(trpc.pms.accommodation.createAccommodation, {
    invalidate: [trpcContext.pms.accommodation],
    setFormErrors,
    onSuccess: () => {
      navigate('./../');
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    const form = e.currentTarget;

    let website = form.website.value as string;
    if (website !== '' && !website.startsWith('http://') && !website.startsWith('https://')) {
      website = `https://${website}`;
    }

    const data = {
      name: form.accommodationName.value,
      address: form.address.value,
      email: form.email.value,
      phone: form.phone.value,
      website,
    };

    createAccommodation.mutate(data);
  };

  const disabled = createAccommodation.isLoading;

  return (
    <div className='col-md-4'>
      <form onSubmit={handleSubmit}>
        <FormInput
          type='text'
          name='accommodationName'
          title={t('pms:configuration.accommodationSettings.accommodationForm.name')}
          errorPath={[['name'], ['accommodation', 'name']]}
          formErrors={formErrors}
          disabled={disabled}
        />
        <FormInput
          type='text'
          name='address'
          title={t('pms:configuration.accommodationSettings.accommodationForm.address')}
          errorPath={[['address'], ['accommodation', 'address']]}
          formErrors={formErrors}
          disabled={disabled}
        />
        <FormInput
          type='text'
          name='email'
          title={t('pms:configuration.accommodationSettings.accommodationForm.email')}
          errorPath={[['email'], ['accommodation', 'email']]}
          formErrors={formErrors}
          disabled={disabled}
        />
        <FormInput
          type='text'
          name='phone'
          title={t('pms:configuration.accommodationSettings.accommodationForm.phone')}
          errorPath={[['phone'], ['accommodation', 'phone']]}
          formErrors={formErrors}
          disabled={disabled}
        />
        <FormInput
          type='text'
          name='website'
          title={t('pms:configuration.accommodationSettings.accommodationForm.website')}
          errorPath={[['website'], ['accommodation', 'website']]}
          formErrors={formErrors}
          disabled={disabled}
          startText='https://'
        />
        <div className='form-footer'>
          <Button
            disabled={disabled}
            isLoading={createAccommodation.isLoading}
            hideContentWhenLoading
          >
            {t('pms:form.create')}
          </Button>
        </div>
      </form>
    </div>
  );
}
