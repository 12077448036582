export function getPathsFromPathname(pathname: string) {
  let allPaths = pathname.split('/');

  // "foo/bar" and "foo/bar/" are the same
  if (allPaths[allPaths.length - 1] === '') {
    allPaths = allPaths.slice(0, allPaths.length - 1);
  }

  // "foo/bar" and "/foo/bar" are the same
  if (allPaths[0] === '') {
    allPaths = allPaths.slice(1, allPaths.length);
  }

  return allPaths;
}

export const euroFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

let canvas: HTMLCanvasElement | null = null;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  if (!canvas) {
    canvas = document.createElement('canvas');
  }
  const context = canvas.getContext('2d')!;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

export function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function getArrayOfStringsFromLinedString(str: string | null) {
  if (!str) {
    return null;
  }

  return str.split('\n').map((s) => s.trim());
}
