import React, { createContext, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import { Duration } from 'luxon';

const LanguageSchema = z.literal('it-it').or(z.literal('en-gb')).or(z.literal('fr-fr'));
type LanguageCode = z.infer<typeof LanguageSchema>;
type Language = {
  code: LanguageCode;
  name: string;
  flagUrl: string;
};

export const AVAILABLE_LANGUAGES: Language[] = [
  {
    code: 'it-it',
    name: 'Italian',
    flagUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/it.svg',
  },
  {
    code: 'en-gb',
    name: 'English (GB)',
    flagUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/gb.svg',
  },
  {
    code: 'fr-fr',
    name: 'French',
    flagUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/fr.svg',
  },
];
const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0];

const cookies = new Cookies();
const SELECTED_LANGUAGE_COOKIE_NAME = 'currentLanguage';

type LanguageContextValue = {
  availableLanguages: Language[];
  selectedLanguage: Language;
  setSelectedLanguage: (code: string) => void;
  getTranslatedString: (
    translations: { [key: string]: string },
    selectedLanguageCode?: string,
  ) => string | null;
};

const initialState: LanguageContextValue = {
  availableLanguages: AVAILABLE_LANGUAGES,
  selectedLanguage: DEFAULT_LANGUAGE,
  setSelectedLanguage: () => {},
  getTranslatedString: () => '',
};

const LanguageContext = createContext<LanguageContextValue>(initialState);

export function LanguageContextProvider({ children }: { children: React.ReactNode }) {
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);

  useEffect(() => {
    const cookieLanguageCode = retrieveSelectedLanguageCodeFromCookies();
    let language = AVAILABLE_LANGUAGES.find((l) => l.code === cookieLanguageCode);
    if (!language) {
      language = DEFAULT_LANGUAGE;
    }
    setSelectedLanguage(language);
  }, []);

  if (selectedLanguage === null) {
    return <LoadingSpinner />;
  }

  const value = {
    availableLanguages: AVAILABLE_LANGUAGES,
    selectedLanguage,
    setSelectedLanguage: (code: string) => {
      const language = AVAILABLE_LANGUAGES.find((l) => l.code === code);
      if (!language) {
        return;
      }
      cookies.set(SELECTED_LANGUAGE_COOKIE_NAME, code, {
        maxAge: Duration.fromObject({ days: 7 }).as('seconds'),
        path: '/',
      });
      setSelectedLanguage(language);
    },
    getTranslatedString: (
      translations: { [key: string]: string },
      selectedLanguageCode?: string,
    ) => {
      if (selectedLanguageCode === undefined) {
        selectedLanguageCode = selectedLanguage.code;
      }
      if (!(selectedLanguageCode in translations)) {
        return null;
      }
      return translations[selectedLanguageCode];
    },
  };
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

function removeSelectedLanguageCodeFromCookies() {
  cookies.remove(SELECTED_LANGUAGE_COOKIE_NAME);
}

function retrieveSelectedLanguageCodeFromCookies(): LanguageCode {
  const cookieValue = cookies.get(SELECTED_LANGUAGE_COOKIE_NAME);
  const result = LanguageSchema.safeParse(cookieValue);

  if (!result.success) {
    removeSelectedLanguageCodeFromCookies();
    return DEFAULT_LANGUAGE.code;
  }

  return result.data;
}

export const useLanguageContext = () => useContext(LanguageContext);
