import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = [
  {
    code: 'it',
    name: 'Italiano',
  },
  {
    code: 'en',
    name: 'English',
  },
] as const;

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'it',
    supportedLngs: availableLanguages.map((l) => l.code),
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export function setLanguage(lang: string) {
  i18n.changeLanguage(lang);
  document.documentElement.lang = lang;
  localStorage.setItem('i18nextLng', lang);
}

export function getAvailableLanguages() {
  return availableLanguages;
}

export default i18n;
