import { useEffect, useState } from 'react';
import { createMutation } from '../../../../utils/api';
import { trpc } from '../../../../utils/trpc';
import GoToBillingPortalButton from '../../../common/billing/GoToBillingPortalButton';
import ErrorAlert from '../../../common/components/ErrorAlert';
import Page from '../../../common/components/dashboard/Page';
import Button from '../../../common/components/form/Button';
import { IconCheck } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

type Plan = 'free' | 'starter' | 'pro';

export default function PlansPage() {
  const { t } = useTranslation(['menu']);

  return (
    <Page title={t('menu:navbar.plans')}>
      <PlansContent />
    </Page>
  );
}

function PlansContent() {
  return (
    <>
      <div className='mb-2 text-center text-sm-end'>
        <GoToBillingPortalButton />
      </div>
      <div className='table-responsive'>
        <table className='table text-center table-mobile-sm card-table'>
          <tbody>
            <PlansRow />
            <FeatureRow feature='Menu' free='1' starter='illimitati' pro='illimitati' />
            <FeatureRow
              feature='Categorie'
              free='illimitate'
              starter='illimitate'
              pro='illimitate'
            />
            <FeatureRow
              feature='Sotto-categorie'
              free='illimitate'
              starter='illimitate'
              pro='illimitate'
            />
            <FeatureRow
              feature='Prodotti'
              free='illimitati'
              starter='illimitati'
              pro='illimitati'
            />
            <FeatureRow feature='Prezzi' free='illimitati' starter='illimitati' pro='illimitati' />
            <FeatureRow feature='Nessuna pubblicità' free starter pro />
            <FeatureRow feature='Download PDF' free starter pro />
            <FeatureRow feature='Foto' starter pro />
            <FeatureRow feature='Traduzioni' starter pro />
            <FeatureRow feature='Ordine online' pro />
            <FeatureRow feature='Pagamento ordine online' pro />
            <PlansRow />
          </tbody>
        </table>
      </div>
      <div className='alert alert-info mt-4'>
        <h4 className='alert-title'>
          Cosa succede passando da un piano a pagamento a uno gratuito?
        </h4>
        <div className='text-secondary'>
          Tutte le funzionalità del piano a pagamento rimarranno attive fino alla fine del periodo
          di fatturazione. Dopo la scadenza del periodo di fatturazione, i servizi non inclusi nel
          piano gratuito verranno disattivati.
          <br />
          <br />
          In particolare:
          <ul>
            <li>
              <b>Menu</b>: i clienti potranno visualizzare solo il primo menu creato.
              <ul>
                <li>
                  Gli altri menu non verranno eliminati, ma verranno nascosti fino a quando non
                  verrà riattivato il piano a pagamento.
                </li>
              </ul>
            </li>
            <li>
              <b>Foto</b>: i clienti non potranno più visualizzare le foto caricate nel menu.
              <ul>
                <li>
                  Tutte le foto verranno eliminate dopo 30 giorni dalla disattivazione del piano a
                  pagamento (le foto non verranno eliminate se l{`'`}
                  account verrà riattivato prima della scadenza dei 30 giorni).
                </li>
              </ul>
            </li>
            <li>
              <b>Traduzioni</b>: i clienti non potranno più visualizzare le traduzioni del menu. Il
              menu verrà visualizzato nella lingua predefinita.
              <ul>
                <li>
                  Le traduzioni nelle altre lingue non verranno eliminate, ma verranno nascoste fino
                  a quando non verrà riattivato il piano a pagamento.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

function PlansRow() {
  const activePlansQuery = trpc.pay.providers.stripe.simpulseSubscription.getActivePlans.useQuery();

  const trpcContext = trpc.useUtils();
  const changeMenuPlan = createMutation(
    trpc.pay.providers.stripe.simpulseSubscription.changeMenuPlan,
    {
      invalidate: [trpcContext.pay.providers.stripe.simpulseSubscription],
      onSuccess: (data: { redirectUrl?: string | null }) => {
        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        }
      },
    },
  );

  if (activePlansQuery.isLoading) {
    return (
      <tr className='align-items-center'>
        <th></th>
        <td style={{ width: '150px' }}>
          <div className='w-100 d-flex flex-column justify-content-center gap-2'>
            <b>Free</b>
            <span>0€</span>
            <Button isLoading hideContentWhenLoading>
              <></>
            </Button>
          </div>
        </td>
        <td style={{ width: '150px' }}>
          <div className='w-100 d-flex flex-column justify-content-center gap-2'>
            <b>Starter</b>
            <span>3€ / mese</span>
            <Button isLoading hideContentWhenLoading>
              <></>
            </Button>
          </div>
        </td>
        <td style={{ width: '150px' }}>
          <div className='w-100 d-flex flex-column justify-content-center gap-2'>
            <b>Pro</b>
            <span>5€ / mese</span>
            <Button isLoading hideContentWhenLoading>
              <></>
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  if (activePlansQuery.error) {
    return (
      <>
        <tr className='align-items-center'>
          <th></th>
          <td style={{ width: '150px' }}>
            <div className='w-100 d-flex flex-column justify-content-center gap-2'>
              <b>Free</b>
              <span>0€</span>
            </div>
          </td>
          <td style={{ width: '150px' }}>
            <div className='w-100 d-flex flex-column justify-content-center gap-2'>
              <b>Starter</b>
              <span>3€ / mese</span>
            </div>
          </td>
          <td style={{ width: '150px' }}>
            <div className='w-100 d-flex flex-column justify-content-center gap-2'>
              <b>Pro</b>
              <span>5€ / mese</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <ErrorAlert errors={activePlansQuery.error} />
          </td>
        </tr>
      </>
    );
  }

  const activePlans = activePlansQuery.data;

  const activePlan = activePlans.menu;
  const cancelAtPeriodEnd = activePlan.plan === 'free' ? false : activePlan.cancelAtPeriodEnd;

  return (
    <tr className='align-items-center'>
      <th></th>
      <PlanNameTd name='Free' price='0€'>
        <ActivatePlanButton plan='free' activePlan={activePlan} changeMenuPlan={changeMenuPlan} />
      </PlanNameTd>
      <PlanNameTd name='Starter' price='3€ / mese'>
        <>
          <span>
            <ActivatePlanButton
              plan='starter'
              activePlan={activePlan}
              changeMenuPlan={changeMenuPlan}
            />
          </span>
          {cancelAtPeriodEnd && activePlan.plan === 'starter' && (
            <div className='text-muted small'>
              <span className='text-danger'>
                Verrà disattivato il{' '}
                {DateTime.fromISO(activePlan.expiresAt).toLocaleString(DateTime.DATE_MED)}
              </span>
            </div>
          )}
        </>
      </PlanNameTd>
      <PlanNameTd name='Pro' price='5€ / mese'>
        <>
          <span>
            <ActivatePlanButton
              plan='pro'
              activePlan={activePlan}
              changeMenuPlan={changeMenuPlan}
            />
          </span>
          {cancelAtPeriodEnd && activePlan.plan === 'pro' && (
            <div className='text-muted small'>
              <span className='text-danger'>
                Verrà disattivato il{' '}
                {DateTime.fromISO(activePlan.expiresAt).toLocaleString(DateTime.DATE_MED)}
              </span>
            </div>
          )}
        </>
      </PlanNameTd>
    </tr>
  );
}

function PlanNameTd({
  name,
  price,
  children,
}: {
  name: string;
  price: string;
  children: React.ReactNode;
}) {
  return (
    <td style={{ width: '150px' }}>
      <div className='w-100 d-flex flex-column justify-content-center gap-2'>
        <b>{name}</b>
        <span>{price}</span>
        {children}
      </div>
    </td>
  );
}

function FeatureRow({
  feature,
  free,
  starter,
  pro,
}: {
  feature: string;
  free?: boolean | string;
  starter?: boolean | string;
  pro?: boolean | string;
}) {
  return (
    <tr>
      <td className='text-start'>{feature}</td>
      <FeatureTdValue planName='Free' value={free} />
      <FeatureTdValue planName='Starter' value={starter} />
      <FeatureTdValue planName='Pro' value={pro} />
    </tr>
  );
}

function FeatureTdValue({ planName, value }: { planName: string; value?: boolean | string }) {
  const text = value === true ? <IconCheck /> : value === false ? '' : value;

  return (
    <td>
      <div className='d-inline d-sm-none d-flex justify-content-between'>
        <span>{planName}</span>
        <span>{text}</span>
      </div>
      <span className='d-none d-sm-inline'>{text}</span>
    </td>
  );
}

function ActivatePlanButton({
  activePlan,
  plan,
  changeMenuPlan,
}: {
  activePlan:
    | {
        plan: 'free';
      }
    | {
        plan: 'starter' | 'pro';
        cancelAtPeriodEnd: boolean;
      };
  plan: Plan;
  changeMenuPlan: {
    mutate: (data: { plan: Plan }) => void;
    isLoading: boolean;
  };
}) {
  const [clickedThis, setClickedThis] = useState(false);
  const cancelAtPeriodEnd = activePlan.plan === 'free' ? false : activePlan.cancelAtPeriodEnd;

  useEffect(() => {
    if (!changeMenuPlan.isLoading) {
      setClickedThis(false);
    }
  }, [changeMenuPlan.isLoading]);

  let text: string;
  let isActive = false;
  if ((activePlan.plan === plan && !cancelAtPeriodEnd) || (plan === 'free' && cancelAtPeriodEnd)) {
    text = 'Piano attivo';
    isActive = true;
  } else if (cancelAtPeriodEnd && plan === activePlan.plan) {
    text = `Riattiva ${plan}`;
  } else {
    text = `Attiva ${plan}`;
  }

  return (
    <Button
      onClick={() => {
        setClickedThis(true);
        changeMenuPlan.mutate({ plan });
      }}
      isLoading={changeMenuPlan.isLoading && clickedThis}
      hideContentWhenLoading
      disabled={changeMenuPlan.isLoading || isActive}
    >
      {text}
    </Button>
  );
}
