import { Route, Routes } from 'react-router-dom';
import {
  MutationCache,
  QueryCache,
  QueryCacheNotifyEvent,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { trpc } from './utils/trpc';
import { httpBatchLink, TRPCClientError } from '@trpc/client';
import { toast, Toaster } from 'react-hot-toast';
import { AuthProvider } from './packages/auth/hooks/AuthContext';
import Auth from './packages/auth/pages/Auth';
import Dashboard from './packages/common/pages/Dashboard';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Bugfender } from '@bugfender/sdk';
import { z } from 'zod';
import Modal from './packages/common/components/Modal';
import LoadingSpinner from './packages/common/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

import 'react-tooltip/dist/react-tooltip.css';

const cookies = new Cookies();

// TODO: remove any once https://github.com/TanStack/query/pull/6270 is merged and released
const eventHandler = (
  event: QueryCacheNotifyEvent | any,
  setShowSessionExpiredModal: (_: boolean) => void,
) => {
  if (event.type === 'updated' && event.action.type === 'error') {
    const { success: isSessionExpired } = z
      .object({ shape: z.object({ message: z.literal('Session expired') }) })
      .safeParse(event.action.error);
    if (isSessionExpired) {
      setShowSessionExpiredModal(true);
      cookies.remove('token', { path: '/' });
      setTimeout(() => {
        window.location.href = `${window.location.origin}/auth?sessionExpired`;
      }, 2000);
    }
  }
};

const handleError = (error: unknown) => {
  if (error instanceof TRPCClientError) {
    try {
      const messageObject = JSON.parse(error.message);
      if (Array.isArray(messageObject)) {
        const toastMessages: string[] = [];
        for (const err of messageObject) {
          if (err.message && typeof err.message === 'string' && Array.isArray(err.path)) {
            toastMessages.push(`${err.path.join('.')}: ${err.message}`);
          } else {
            toastMessages.push(new String(err).toString());
          }
        }
        toast.error(toastMessages.join('<br />'));
        return;
      }
    } catch (e) {
      //
    }

    toast.error(error.message);
    return;
  }
  toast.error(new String(error).toString());
};

function App() {
  const { t } = useTranslation(['auth']);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 3,
          },
          mutations: {
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: handleError,
        }),
        mutationCache: new MutationCache({
          onError: handleError,
        }),
      }),
  );
  queryClient.getQueryCache().subscribe((e) => eventHandler(e, setShowSessionExpiredModal));
  queryClient.getMutationCache().subscribe((e) => eventHandler(e, setShowSessionExpiredModal));
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: process.env.REACT_APP_TRPC_API_ENDPOINT || '',
          headers() {
            const headers: Record<string, string> = {};

            const token = cookies.get('token');
            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }

            return headers;
          },
        }),
      ],
    }),
  );

  useEffect(() => {
    if (process.env.REACT_APP_VERCEL_ENV !== 'development') {
      Bugfender.log('App Created');
    }
  }, []);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes>
            <Route path='/auth/*' element={<Auth />} />
            <Route path='/*' element={<Dashboard />} />
          </Routes>
          <Toaster position='bottom-left' />
          <Modal
            isOpen={showSessionExpiredModal}
            title='Session expired'
            onClose={() => {}}
            size='sm'
            showCloseButton={false}
          >
            <p>{t('auth:sessionExpiredError')}</p>
            <LoadingSpinner center />
          </Modal>
        </AuthProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
