import { IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { euroFormatter } from '../../../../../utils/string';
import { trpc } from '../../../../../utils/trpc';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import Button from '../../../../common/components/form/Button';
import FormInput from '../../../../common/components/form/input';
import { usePmsContext } from '../../../Context';
import { UnitType } from '../../../api/units/unit-types';
import { Unit } from '../../../api/units/units';
import { SelectedUnits } from '../../../types';
import {
  DEFAULT_ADULTS,
  DEFAULT_CHILDREN,
  isUnitSelectedInDateRange,
} from '../../../utils/booking';
import { LocaleStorageItemKey, useLocalStorage } from '../../../../common/utils/local-storage';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import DateRangeSelector from '../../DateRangeSelector';
import { LocalDate, LocalTime } from '@js-joda/core';
import { localDateToLocaleDateString } from '../../../../../utils/date';

const PAX_ADULTS_MIN = 0;
const PAX_ADULTS_MAX = 10;
const PAX_CHILDREN_MIN = 0;
const PAX_CHILDREN_MAX = 10;

type PriceT = number | null;
type DailyRatesT = { price: PriceT }[];

enum UnitStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
  NoDates = 'NoDates',
  Loading = 'Loading',
}

type OptionsT = {
  includeNotAvailable: boolean;
  includeIncompatiblePax: boolean;
};

const smallUnitStatus = {
  [UnitStatus.Available]: 'OK',
  [UnitStatus.Unavailable]: 'N/A',
  [UnitStatus.NoDates]: 'Dates',
};

function UnitElementButton({ status, onClick }: { status: UnitStatus; onClick: () => void }) {
  if (status === UnitStatus.Available) {
    return (
      <Button type='button' className='btn btn-primary btn-icon w-100' onClick={onClick}>
        <IconPlus />
      </Button>
    );
  }

  return (
    <Button type='button' className='btn btn-icon w-100' disabled>
      &nbsp;
    </Button>
  );
}

function UnitElement({
  unit,
  status,
  price,
  onSelect,
}: {
  unit: Unit;
  status: UnitStatus;
  price: PriceType;
  onSelect: ({ totalRateAmount }: { totalRateAmount: PriceT }) => void;
}) {
  const { t } = useTranslation(['pms']);
  const [totalAmount, setTotalAmount] = useState<PriceT>(null);

  useEffect(() => {
    if (!price.isLoading) {
      setTotalAmount(price.price);
    }
  }, [price.isLoading, price.price]);

  let statusText: React.ReactNode = null;
  switch (status) {
    case UnitStatus.Available:
      statusText = (
        <>
          <span className='badge bg-success text-white d-none d-sm-inline'>{status}</span>
          <span className='badge bg-success text-white d-sm-none'>{smallUnitStatus[status]}</span>
        </>
      );
      break;
    case UnitStatus.Unavailable:
      statusText = (
        <>
          <span className='badge bg-danger text-white d-none d-sm-inline'>{status}</span>
          <span className='badge bg-danger text-white d-sm-none'>{smallUnitStatus[status]}</span>
        </>
      );
      break;
    case UnitStatus.Loading:
      statusText = <LoadingSpinner />;
      break;
    default:
      statusText = (
        <>
          <span className='badge bg-secondary text-white d-none d-sm-inline'>{status}</span>
          <span className='badge bg-secondary text-white d-sm-none'>{smallUnitStatus[status]}</span>
        </>
      );
  }

  return (
    <tr>
      <td className='bg-dark text-white'>{unit.name}</td>
      <td>
        {status === UnitStatus.NoDates
          ? t('pms:booking.form.selectDateRangeToSeeAvailability')
          : statusText}
      </td>
      <td className='text-end'>
        {price.isLoading ? (
          <LoadingSpinner className='spinner-border-sm' />
        ) : (
          <div className='input-group'>
            <span className='input-group-text d-none d-sm-block'>€</span>
            <FormInput
              type='text'
              name='amount'
              defaultValue={price.price ? String(price.price) : ''}
              onChange={(value) => setTotalAmount(Number(value))}
              showHtmlInputOnly
            />
            <span>
              <UnitElementButton
                status={status}
                onClick={() => onSelect({ totalRateAmount: totalAmount })}
              />
            </span>
          </div>
        )}
      </td>
    </tr>
  );
}

type PriceType = {
  isLoading: boolean;
  price: number | null;
};

function PriceText({ price }: { price: PriceType }) {
  if (price.isLoading) {
    return <LoadingSpinner className='spinner-border-sm' />;
  }
  if (price.price === null) {
    return <span>N/A</span>;
  }
  return <span>{euroFormatter.format(price.price)}</span>;
}

function UnitTypeElement({
  unitType,
  onSelect,
  isLoading,
  startDate,
  endDate,
  selectedUnits,
  price,
}: {
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  selectedUnits: SelectedUnits;
  unitType: UnitType;
  onSelect: ({ unitUuid, totalRateAmount }: { unitUuid: string; totalRateAmount: PriceT }) => void;
  isLoading: boolean;
  price: PriceType;
}) {
  const unitTypeTitleText = `${unitType.name} (${unitType.paxMin} - ${unitType.paxMax} pax)`;

  const invalidDates = !startDate || !endDate;

  return (
    <>
      <tr className='bg-dark text-white'>
        <td colSpan={3}>
          <div className='d-flex justify-content-between'>
            <span>{unitTypeTitleText}</span>
            <PriceText price={price} />
          </div>
        </td>
      </tr>
      {unitType.units.map((unit) => {
        let status = null;
        if (invalidDates) {
          status = UnitStatus.NoDates;
        } else if (isUnitSelectedInDateRange(unit, startDate, endDate, selectedUnits)) {
          status = UnitStatus.Unavailable;
        } else if (isLoading) {
          status = UnitStatus.Loading;
        } else {
          status = UnitStatus.Available;
        }
        return (
          <UnitElement
            key={`availability-table-unit-${unit.uuid}`}
            unit={unit}
            status={status}
            price={price}
            onSelect={({ totalRateAmount }) => onSelect({ unitUuid: unit.uuid, totalRateAmount })}
          />
        );
      })}
    </>
  );
}

function UnitsAvailabilityList({
  unitTypes,
  selectedUnits,
  onSelect,
  isLoading,
  options,
  startDate,
  endDate,
  adultsNumber,
  childrenNumber,
  rateOptionUuid,
}: {
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  adultsNumber: number;
  childrenNumber: number;
  rateOptionUuid: string | null;
  options: OptionsT;
  unitTypes: UnitType[];
  selectedUnits: SelectedUnits;
  onSelect: ({ unitUuid, totalRateAmount }: { unitUuid: string; totalRateAmount: PriceT }) => void;
  isLoading: boolean;
}) {
  const fromISO = startDate === null ? null : startDate.toString();
  const toISO = endDate === null ? null : endDate.toString();
  const prices =
    fromISO && toISO
      ? trpc.pms.rate.getQuotes.useQuery(
          unitTypes.map((unitType) => ({
            unitTypeUuid: unitType.uuid,
            from: fromISO,
            to: toISO,
            adults: adultsNumber,
            children: childrenNumber,
          })),
        )
      : null;

  const unitTypesElements = unitTypes
    .filter(
      (unitType) =>
        options.includeIncompatiblePax ||
        (adultsNumber + childrenNumber >= unitType.paxMin &&
          adultsNumber + childrenNumber <= unitType.paxMax),
    )
    .map((unitType) => {
      let price: {
        isLoading: boolean;
        price: number | null;
        dailyRates: DailyRatesT | null;
      };
      const pricesQueryData = prices?.data;
      if (prices && pricesQueryData) {
        const foundOptions = pricesQueryData.find(
          (quote) =>
            quote.request.unitTypeUuid === unitType.uuid &&
            quote.request.from === fromISO &&
            quote.request.to === toISO,
        )?.options;
        const foundOption = foundOptions?.find((option) => option.optionUuid === rateOptionUuid);
        price = {
          isLoading: false,
          price: foundOption?.totalPrice ?? null,
          dailyRates: foundOption?.dailyRates ?? null,
        };
      } else {
        price = {
          isLoading: prices?.isLoading ?? false,
          price: null,
          dailyRates: null,
        };
      }
      return (
        <UnitTypeElement
          key={`availability-table-unit-type-${unitType.uuid}`}
          startDate={startDate}
          endDate={endDate}
          unitType={unitType}
          selectedUnits={selectedUnits}
          price={price}
          onSelect={({ unitUuid, totalRateAmount }) =>
            onSelect({
              unitUuid,
              totalRateAmount,
            })
          }
          isLoading={isLoading}
        />
      );
    });

  return (
    <div className='card w-100 table-responsive'>
      <table className='table table-vcenter'>
        <tbody>{unitTypesElements}</tbody>
      </table>
    </div>
  );
}

function UnitsAvailability({
  unitTypes,
  queryBookings,
  selectedUnits,
  onSelect,
  options,
  startDate,
  endDate,
  adultsNumber,
  childrenNumber,
  rateOptionUuid,
}: {
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  adultsNumber: number;
  childrenNumber: number;
  rateOptionUuid: string | null;
  unitTypes: UnitType[];
  queryBookings: ReturnType<typeof trpc.pms.booking.queryBookings.useQuery>;
  selectedUnits: SelectedUnits;
  onSelect: ({ unitUuid, totalRateAmount }: { unitUuid: string; totalRateAmount: PriceT }) => void;
  options: OptionsT;
}) {
  const { t } = useTranslation(['pms']);
  let infoAlert: React.ReactNode = null;
  const invalidDates = startDate === null || endDate === null;
  if (invalidDates) {
    infoAlert = (
      <div className='alert alert-warning' role='alert'>
        <h4 className='alert-title'>{t('pms:booking.form.selectDateRangeToSeeAvailability')}</h4>
      </div>
    );
  } else {
    const startDateString = localDateToLocaleDateString(startDate);
    const endDateString = localDateToLocaleDateString(endDate);

    infoAlert = (
      <div className='alert alert-info' role='alert'>
        <h4 className='alert-title'>
          {t('pms:booking.form.showingAvailabilityFromTo', {
            from: startDateString,
            to: endDateString,
          })}
        </h4>
      </div>
    );
  }

  return (
    <>
      {infoAlert}
      <UnitsAvailabilityList
        startDate={startDate}
        endDate={endDate}
        adultsNumber={adultsNumber}
        childrenNumber={childrenNumber}
        rateOptionUuid={rateOptionUuid}
        unitTypes={unitTypes}
        selectedUnits={selectedUnits}
        onSelect={onSelect}
        isLoading={queryBookings.isFetching}
        options={options}
      />
    </>
  );
}

export function PaxSelector({
  adultsPax,
  childrenPax,
  onChange,
}: {
  adultsPax: number;
  childrenPax: number;
  onChange: (adults: number, children: number) => void;
}) {
  const { t } = useTranslation(['pms']);
  const handleAdultsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAdults = parseInt(e.target.value, 10);
    onChange(newAdults, childrenPax);
  };

  const handleChildrenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newChildren = parseInt(e.target.value, 10);
    onChange(adultsPax, newChildren);
  };

  return (
    <div className='row'>
      <div className='form-group col-12'>
        <label className='form-label'>{t('pms:booking.form.adults')}</label>
        <div className='input-group mb-2'>
          <button
            className='btn btn-icon'
            type='button'
            disabled={adultsPax === PAX_ADULTS_MIN}
            onClick={() => onChange(adultsPax - 1, childrenPax)}
          >
            <IconMinus size='1em' />
          </button>
          <select
            className='form-control form-select'
            value={adultsPax}
            onChange={handleAdultsChange}
          >
            {Array.from(Array(PAX_ADULTS_MAX - PAX_ADULTS_MIN + 1).keys())
              .map((n) => n + PAX_ADULTS_MIN)
              .map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
          <button
            className='btn btn-icon'
            type='button'
            disabled={adultsPax === PAX_ADULTS_MAX}
            onClick={() => onChange(adultsPax + 1, childrenPax)}
          >
            <IconPlus size='1em' />
          </button>
        </div>
      </div>
      <div className='form-group col-12'>
        <label className='form-label'>{t('pms:booking.form.children')}</label>
        <div className='input-group mb-2'>
          <button
            className='btn btn-icon'
            type='button'
            disabled={childrenPax === PAX_CHILDREN_MIN}
            onClick={() => onChange(adultsPax, childrenPax - 1)}
          >
            <IconMinus size='1em' />
          </button>
          <select className='form-select' value={childrenPax} onChange={handleChildrenChange}>
            {Array.from(Array(PAX_CHILDREN_MAX - PAX_CHILDREN_MIN + 1).keys())
              .map((n) => n + PAX_CHILDREN_MIN)
              .map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
          <button
            className='btn btn-icon'
            type='button'
            disabled={childrenPax === PAX_CHILDREN_MAX}
            onClick={() => onChange(adultsPax, childrenPax + 1)}
          >
            <IconPlus size='1em' />
          </button>
        </div>
      </div>
    </div>
  );
}

export function ArrivalDepartureTimesSelector({
  arrivalTime,
  departureTime,
  onChange,
}: {
  arrivalTime: LocalTime | null;
  departureTime: LocalTime | null;
  onChange: (arrivalTime: LocalTime | null, departureTime: LocalTime | null) => void;
}) {
  const { t } = useTranslation(['pms']);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(!!arrivalTime);
  const [showDepartureTime, setShowDepartureTime] = useState<boolean>(!!departureTime);

  const handleArrivalTimeChange = (newValue: string) => {
    if (newValue === '') {
      onChange(null, departureTime);
      return;
    }

    const newTime = LocalTime.parse(newValue);
    onChange(newTime, departureTime);
  };

  const handleDepartureTimeChange = (newValue: string) => {
    if (newValue === '') {
      onChange(arrivalTime, null);
      return;
    }

    const newTime = LocalTime.parse(newValue);
    onChange(arrivalTime, newTime);
  };

  return (
    <div className='row'>
      <div className='form-group col-12'>
        {showArrivalTime ? (
          <div className='d-flex flex-wrap gap-2 align-items-end'>
            <div>
              <FormInput
                type='time'
                name='arrivalTime'
                title={t('pms:booking.form.expectedArrivalTime')}
                className='form-control'
                defaultValue={arrivalTime?.toString() ?? ''}
                onChange={handleArrivalTimeChange}
              />
            </div>
            <Button
              className='btn btn-icon mb-2'
              onClick={() => {
                setShowArrivalTime(false);
                handleArrivalTimeChange('');
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        ) : (
          <>
            <span className='form-label'>{t('pms:booking.form.expectedArrivalTime')}</span>
            <Button className='btn mb-2' onClick={() => setShowArrivalTime(true)}>
              {t('pms:booking.form.addTime')}
            </Button>
          </>
        )}
      </div>
      <div className='form-group col-12'>
        {showDepartureTime ? (
          <div className='d-flex flex-wrap gap-2 align-items-end'>
            <div>
              <FormInput
                type='time'
                name='departureTime'
                title={t('pms:booking.form.expectedDepartureTime')}
                className='form-control'
                defaultValue={departureTime?.toString() ?? ''}
                onChange={handleDepartureTimeChange}
              />
            </div>
            <Button
              className='btn btn-icon mb-2'
              onClick={() => {
                setShowDepartureTime(false);
                handleDepartureTimeChange('');
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        ) : (
          <>
            <span className='form-label'>{t('pms:booking.form.expectedDepartureTime')}</span>
            <Button className='btn mb-2' onClick={() => setShowDepartureTime(true)}>
              {t('pms:booking.form.addTime')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default function AddBookingUnit({
  accommodationUuid,
  notAvailableUnits,
  onSelect,
}: {
  accommodationUuid: string;
  notAvailableUnits: SelectedUnits;
  onSelect: ({
    rateOptionUuid,
    unitUuid,
    adults,
    children,
    from,
    arrivalTime,
    to,
    departureTime,
    totalRateAmount,
  }: {
    rateOptionUuid: string | null;
    unitUuid: string;
    adults: number;
    children: number;
    from: LocalDate;
    arrivalTime: LocalTime | null;
    to: LocalDate;
    departureTime: LocalTime | null;
    totalRateAmount: PriceT;
  }) => void;
}) {
  const { t } = useTranslation(['pms']);
  const { unitTypes: allUnitTypes, rateOptions } = usePmsContext();

  const TODAY = LocalDate.now();
  const DEFAULT_START_DATE = TODAY;
  const DEFAULT_END_DATE = TODAY.plusDays(1);

  const [startDateForm, setStartDateForm] = useState<LocalDate | null>(DEFAULT_START_DATE);
  const [arrivalTime, setArrivalTime] = useState<LocalTime | null>(null);
  const [endDateForm, setEndDateForm] = useState<LocalDate | null>(DEFAULT_END_DATE);
  const [departureTime, setDepartureTime] = useState<LocalTime | null>(null);
  const [adultsNumber, setAdultsNumber] = useState(DEFAULT_ADULTS);
  const [childrenNumber, setChildrenNumber] = useState(DEFAULT_CHILDREN);
  const [options, setOptions] = useState({
    includeNotAvailable: false,
    includeIncompatiblePax: false,
  });
  const [rateOptionUuid, setRateOptionUuid] = useLocalStorage(
    LocaleStorageItemKey.PMS_DEFAULT_RATE_OPTION_UUID,
    rateOptions.length > 0 ? rateOptions[0].uuid : null,
    z.string().uuid().nullable(),
  );

  const startDate = startDateForm ?? DEFAULT_START_DATE;
  const endDate = endDateForm ?? DEFAULT_END_DATE;

  const queryBookings = trpc.pms.booking.queryBookings.useQuery({
    accommodationsUuids: [accommodationUuid],
    departureDateAfter: startDate.toString(),
    arrivalDateBefore: endDate.toString(),
  });

  const unitTypes = allUnitTypes.filter(
    (unitType) => unitType.accommodationUuid === accommodationUuid,
  );

  const [selectedAndBookedUnits, setSelectedAndBookedUnits] =
    useState<SelectedUnits>(notAvailableUnits);

  useEffect(() => {
    if (queryBookings.data && queryBookings.data.bookings) {
      setSelectedAndBookedUnits([
        ...notAvailableUnits,
        ...queryBookings.data.bookings.flatMap((booking) =>
          booking.bookingUnits.map((unit) => ({
            rateOptionUuid,
            unitUuid: unit.unitUuid,
            adults: unit.adultsNumber,
            children: unit.childrenNumber,
            from: LocalDate.parse(unit.arrivalDate),
            arrivalTime: unit.arrivalTime ? LocalTime.parse(unit.arrivalTime) : null,
            to: LocalDate.parse(unit.departureDate),
            departureTime: unit.departureTime ? LocalTime.parse(unit.departureTime) : null,
            rateAmount: unit.rateAmount,
          })),
        ),
      ]);
    }
  }, [notAvailableUnits, queryBookings.data]);

  return (
    <>
      <div className='row mb-3'>
        <div className='col-sm-6 col-md-9'>
          <DateRangeSelector
            startDate={startDateForm}
            endDate={endDateForm}
            setStartDate={setStartDateForm}
            setEndDate={setEndDateForm}
          />
        </div>
        <div className='col-sm-6 col-md-3'>
          <PaxSelector
            adultsPax={adultsNumber}
            childrenPax={childrenNumber}
            onChange={(a, c) => {
              setAdultsNumber(a);
              setChildrenNumber(c);
            }}
          />
          <ArrivalDepartureTimesSelector
            arrivalTime={arrivalTime}
            departureTime={departureTime}
            onChange={(a, d) => {
              setArrivalTime(a);
              setDepartureTime(d);
            }}
          />
          <FormInput
            type='switch'
            name='includeIncompatiblePax'
            title={t('pms:booking.form.includeIncompatiblePax')}
            defaultValue={false}
            checkboxValue='on'
            onChange={(checked) =>
              setOptions({
                ...options,
                includeIncompatiblePax: checked,
              })
            }
          />
          {rateOptions.length > 1 && (
            <FormInput
              type='select'
              name='rateOptionUuid'
              title={t('pms:booking.form.rateOption')}
              defaultValue={rateOptionUuid}
              onChange={(value) => setRateOptionUuid(value === '' ? null : value)}
            >
              <>
                <option key={'AddBookingUnit-RateOptionUuid-default-null'} value={''}></option>
                {rateOptions.map((option) => (
                  <option key={option.uuid} value={option.uuid}>
                    {option.name}
                  </option>
                ))}
              </>
            </FormInput>
          )}
        </div>
      </div>
      <UnitsAvailability
        startDate={startDateForm}
        endDate={endDateForm}
        adultsNumber={adultsNumber}
        childrenNumber={childrenNumber}
        rateOptionUuid={rateOptionUuid}
        unitTypes={unitTypes}
        queryBookings={queryBookings}
        selectedUnits={selectedAndBookedUnits}
        options={options}
        onSelect={({ unitUuid, totalRateAmount }) => {
          onSelect({
            rateOptionUuid,
            unitUuid: unitUuid,
            adults: adultsNumber,
            children: childrenNumber,
            from: startDate,
            arrivalTime,
            to: endDate,
            departureTime,
            totalRateAmount,
          });
        }}
      />
    </>
  );
}
