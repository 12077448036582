import { Page, Text, Document, StyleSheet, Font, View, usePDF } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconExternalLink, IconPrinter } from '@tabler/icons-react';
import { Booking } from '../../api/bookings/bookings';
import { formatPriceInSmallestUnitToString } from '../../../../utils/currency';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ErrorAlert from '../../../common/components/ErrorAlert';
import FormInput from '../../../common/components/form/input';
import Button from '../../../common/components/form/Button';
import Modal from '../../../common/components/Modal';
import { usePmsContext } from '../../Context';
import { UnitType } from '../../api/units/unit-types';
import { LocaleStorageItemKey, useLocalStorage } from '../../../common/utils/local-storage';
import { z } from 'zod';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 14,
    fontFamily: 'Helvetica',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  sectionName: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginTop: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDD',
    padding: '5px 0',
  },
  itemName: {
    fontFamily: 'Helvetica',
  },
  itemDescription: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: 12,
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
  },
  priceTextView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minWidth: 50,
  },
});

function DocumentPriceItem({
  name,
  priceCents,
}: {
  name: string | null;
  priceCents: number | null;
}) {
  return (
    <View style={styles.item}>
      <View style={{ flex: 1 }}>
        <Text style={styles.itemName}>{name}</Text>
      </View>
      <View style={styles.price}>
        <View style={styles.priceView}>
          <View style={styles.priceTextView}>
            <Text>
              {priceCents !== null
                ? formatPriceInSmallestUnitToString(priceCents * 100, 'EUR')
                : ''}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const PdfDocument = ({
  booking,
  includeCharges,
  includePayments,
  unitTypes,
}: {
  booking: Booking;
  includeCharges: boolean;
  includePayments: boolean;
  unitTypes: UnitType[];
}) => {
  function getUnitTypeNameByUnitUuid(unitUuid: string) {
    for (const unitType of unitTypes) {
      for (const unit of unitType.units) {
        if (unit.uuid === unitUuid) {
          return unitType.name;
        }
      }
    }
    return '';
  }

  const unitRatesTotal = booking.bookingUnits.reduce(
    (total, unit) => total + (unit.rateAmount || 0),
    0,
  );
  const chargesTotal = booking.bookingCharges.reduce(
    (total, charge) => total + charge.totalAmount,
    unitRatesTotal,
  );
  const paymentsTotal = booking.bookingPayments.reduce(
    (total, payment) => total + payment.totalAmount,
    0,
  );

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <Text style={styles.title} fixed>
          {booking.firstName} {booking.lastName}
        </Text>
        {includeCharges && (
          <>
            <Text style={styles.sectionName}>Charges</Text>
            {unitRatesTotal > 0 && (
              <>
                {booking.bookingUnits.map((bookingUnit) => (
                  <DocumentPriceItem
                    key={bookingUnit.uuid}
                    name={getUnitTypeNameByUnitUuid(bookingUnit.unitUuid)}
                    priceCents={bookingUnit.rateAmount}
                  />
                ))}
                <DocumentPriceItem name='' priceCents={null} />
              </>
            )}
            {booking.bookingCharges.map((charge) => (
              <DocumentPriceItem
                key={charge.uuid}
                name={charge.name}
                priceCents={charge.totalAmount}
              />
            ))}
            <DocumentPriceItem name='' priceCents={null} />
            <DocumentPriceItem name='Total' priceCents={chargesTotal} />
          </>
        )}
        {includePayments && (
          <>
            <Text style={styles.sectionName}>Payments</Text>
            {booking.bookingPayments.map((payment) => (
              <DocumentPriceItem
                key={payment.uuid}
                name={payment.name}
                priceCents={payment.totalAmount}
              />
            ))}
            <DocumentPriceItem name='' priceCents={null} />
            <DocumentPriceItem name='Total' priceCents={paymentsTotal} />
          </>
        )}
      </Page>
    </Document>
  );
};

function ChargesPaymentsDownloadPdf({ booking }: { booking: Booking }) {
  const { t } = useTranslation(['pms']);
  const { unitTypes } = usePmsContext();
  const [includeCharges, setIncludeCharges] = useLocalStorage(
    LocaleStorageItemKey.PMS_CHARGES_PAYMENTS_DOWNLOAD_PDF_INCLUDE_CHARGES,
    true,
    z.boolean(),
  );
  const [includePayments, setIncludePayments] = useLocalStorage(
    LocaleStorageItemKey.PMS_CHARGES_PAYMENTS_DOWNLOAD_PDF_INCLUDE_PAYMENTS,
    true,
    z.boolean(),
  );

  const [instance, updateInstance] = usePDF({});

  useEffect(() => {
    updateInstance(
      <PdfDocument
        booking={booking}
        includeCharges={includeCharges}
        includePayments={includePayments}
        unitTypes={unitTypes}
      />,
    );
  }, [booking, includeCharges, includePayments]);

  if (instance.loading) {
    return <LoadingSpinner center />;
  }
  if (instance.error) {
    return <ErrorAlert errors={instance.error} />;
  }
  if (instance.url === null) {
    return <ErrorAlert errors={['Something went wrong']} />;
  }

  const pdfName = `${booking.firstName} ${booking.lastName}`;

  return (
    <div className='d-grid gap-2'>
      <FormInput
        type='switch'
        name='includeCharges'
        title={t('pms:booking.chargesPayments.pdf.includeCharges')}
        checkboxValue='includeCharges'
        defaultValue={includeCharges}
        onChange={setIncludeCharges}
      />
      <FormInput
        type='switch'
        name='includePayments'
        title={t('pms:booking.chargesPayments.pdf.includePayments')}
        checkboxValue='includePayments'
        defaultValue={includePayments}
        onChange={setIncludePayments}
      />
      {includeCharges || includePayments ? (
        <div className='d-flex justify-content-center gap-2'>
          <a href={instance.url} target='_blank' rel='noreferrer' className='btn w-100'>
            {t('pms:booking.chargesPayments.pdf.openPdf')}
            <IconExternalLink size='1em' className='ms-2' />
          </a>
          <a href={instance.url} download={`${pdfName}.pdf`} className='btn btn-primary w-100'>
            {t('pms:booking.chargesPayments.pdf.downloadPdf')}
          </a>
        </div>
      ) : (
        <ErrorAlert errors={['Please select at least one of the options']} />
      )}
    </div>
  );
}

export function DownloadBookingChargesPaymentsPdfButton({ booking }: { booking: Booking }) {
  const { t } = useTranslation(['pms']);
  const [showDownloadPdfModal, setShowDownloadPdfModal] = useState(false);

  return (
    <>
      <Button type='button' onClick={() => setShowDownloadPdfModal(true)} className='btn'>
        <IconPrinter size='1em' />
      </Button>
      <Modal
        title={t('pms:booking.chargesPayments.pdf.downloadPdf')}
        isOpen={showDownloadPdfModal}
        onClose={() => setShowDownloadPdfModal(false)}
        size='sm'
      >
        <ChargesPaymentsDownloadPdf booking={booking} />
      </Modal>
    </>
  );
}
