import { IconX } from '@tabler/icons-react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import { MenuSection } from '../api/menu-page';
import { useState } from 'react';
import Modal from '../../common/components/Modal';

export default function UnlinkOrDeleteElementButton({
  type,
  parentMenuSection,
  elementUuidToRemove,
}: {
  type: 'item' | 'section';
  parentMenuSection: MenuSection;
  elementUuidToRemove: string;
}) {
  const trpcContext = trpc.useUtils();

  const [showModal, setShowModal] = useState(false);

  const mutationOptions = {
    invalidate: [trpcContext.menu],
  };
  const updateSectionElements = createMutation(
    trpc.menu.admin.updateMenuSectionElements,
    mutationOptions,
  );
  const deleteElement = createMutation(
    type === 'item' ? trpc.menu.admin.deleteMenuItem : trpc.menu.admin.deleteMenuSection,
    mutationOptions,
  );

  const isLoading = updateSectionElements.isLoading || deleteElement.isLoading;

  let strings: {
    unlinkLabel: string;
    unlinkButton: string;
    deleteLabel: string;
    deleteButton: string;
  };

  if (type === 'item') {
    strings = {
      unlinkLabel:
        'Unlinks the item from its parent section. This will allow you to add this item again in the future.',
      unlinkButton: 'Remove item from here',
      deleteLabel: 'Permanently deletes the item.',
      deleteButton: 'Delete item',
    };
  } else {
    strings = {
      unlinkLabel:
        'Unlinks the section from its parent section. This will allow you to add this section again in the future.',
      unlinkButton: 'Remove section from here',
      deleteLabel:
        'Permanently deletes the section. All the elements inside this section will not be deleted.',
      deleteButton: 'Delete section',
    };
  }

  return (
    <>
      <Button
        type='button'
        className='btn btn-icon btn-outline-danger'
        onClick={() => setShowModal(true)}
      >
        <IconX size='1em' />
      </Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        {strings.unlinkLabel}
        <Button
          type='button'
          className='btn btn-outline-danger w-100 mt-2'
          onClick={async () => {
            await updateSectionElements.mutateAsync({
              uuid: parentMenuSection.uuid,
              elementsUuids: parentMenuSection.elementsUuids
                .map((e) => e.uuid)
                .filter((e) => e !== elementUuidToRemove),
            });
            setShowModal(false);
          }}
          disabled={isLoading}
          isLoading={updateSectionElements.isLoading}
          hideContentWhenLoading
        >
          {strings.unlinkButton}
        </Button>
        <div className='hr-text'>or</div>
        {strings.deleteLabel}
        <Button
          type='button'
          className='btn btn-outline-danger w-100 mt-2'
          onClick={async () => {
            await deleteElement.mutateAsync({ uuid: elementUuidToRemove });
            setShowModal(false);
          }}
          disabled={isLoading}
          isLoading={deleteElement.isLoading}
          hideContentWhenLoading
        >
          {strings.deleteButton}
        </Button>
      </Modal>
    </>
  );
}
