import { useState } from 'react';
import { createMutation } from '../../../../../../../utils/api';
import { trpc } from '../../../../../../../utils/trpc';
import Button from '../../../../../../common/components/form/Button';
import FormInput from '../../../../../../common/components/form/input';
import { FormErrors } from '../../../../../../common/utils/api';
import { RateSeason } from '../../../../../api/rates/rate-seasons';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface CreateRateSeasonFormProps {
  action: 'create';
  rateSeasonUuid?: undefined;
  rateSeason?: undefined;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateRateSeasonFormProps {
  action: 'update';
  rateSeasonUuid: string;
  rateSeason: RateSeason;
  onSuccess: () => void;
  onCancel: () => void;
}
type RateSeasonFormProps = CreateRateSeasonFormProps | UpdateRateSeasonFormProps;

export default function RateSeasonForm({
  action,
  rateSeasonUuid,
  rateSeason,
  onSuccess,
  onCancel,
}: RateSeasonFormProps) {
  const { t } = useTranslation(['pms']);
  const trpcContext = trpc.useUtils();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const mutationOptions = {
    invalidate: [trpcContext.pms.rate],
    setFormErrors,
    onSuccess,
  };
  const createRateSeason = createMutation(trpc.pms.rate.createRateSeason, mutationOptions);
  const updateRateSeason = createMutation(trpc.pms.rate.updateRateSeason, mutationOptions);
  const deleteRateSeason = createMutation(trpc.pms.rate.deleteRateSeason, mutationOptions);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const rateSeasonName = formData.get('rateSeasonName') as string;

    if (action === 'create') {
      createRateSeason.mutate({
        name: rateSeasonName,
      });
    } else {
      updateRateSeason.mutate({
        uuid: rateSeasonUuid,
        name: rateSeasonName,
      });
    }
  };

  const disabled =
    createRateSeason.isLoading || updateRateSeason.isLoading || deleteRateSeason.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-12'>
          <FormInput
            type='text'
            name='rateSeasonName'
            title={t('pms:configuration.accommodationSettings.seasonsForm.name')}
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={rateSeason?.name}
            autoFocus
          />
        </div>
      </div>
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger ms-1 w-100'
              disabled={disabled}
              isLoading={deleteRateSeason.isLoading}
              hideContentWhenLoading
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  deleteRateSeason.mutate({ uuid: rateSeasonUuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            disabled={disabled}
            isLoading={createRateSeason.isLoading || updateRateSeason.isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
