import { IconTrash } from '@tabler/icons-react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import { NotificationMethod } from '../../pms/api/notification-methods';
import FormInput from '../../common/components/form/input';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { getNotificationMethodTypeName } from '../../common/utils/notification-method';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import ErrorAlert from '../../common/components/ErrorAlert';
import { TimeAgo } from '../../menu/pages/orders';
import Button from '../../common/components/form/Button';
import { useEffect, useState } from 'react';
import Card from '../../common/components/Card';

export default function NotificationMethodItem({
  notificationMethod,
}: {
  notificationMethod: NotificationMethod;
}) {
  const { t } = useTranslation(['generic']);
  const trpcContext = trpc.useUtils();
  const options = {
    invalidate: [trpcContext.notification],
  };
  const updateMethod = createMutation(trpc.notification.updateNotificationMethod, options);
  const deleteMethod = createMutation(trpc.notification.deleteNotificationMethod, options);

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <p className='fw-bold'>{getNotificationMethodTypeName(notificationMethod)}</p>
          <FormInput
            type='switch'
            name='enabled'
            title={
              <>
                {t('generic:notificationMethod.form.enable')}
                {updateMethod.isLoading ? (
                  <LoadingSpinner className='spinner-border spinner-border-sm m-0 icon ms-2' />
                ) : null}
              </>
            }
            checkboxValue='enabled'
            defaultValue={notificationMethod.isEnabled}
            disabled={updateMethod.isLoading}
            onChange={(value) => {
              if (value !== notificationMethod.isEnabled) {
                updateMethod.mutate({
                  notificationMethodUuid: notificationMethod.uuid,
                  isEnabled: value,
                });
              }
            }}
          />
        </div>
        <Button
          className='btn btn-icon btn-outline-danger'
          onClick={() => {
            if (confirm(t('generic:notificationMethod.form.deleteConfirm'))) {
              deleteMethod.mutate({
                notificationMethodUuid: notificationMethod.uuid,
              });
            }
          }}
          isLoading={deleteMethod.isLoading}
          hideContentWhenLoading
        >
          <IconTrash size='1em' />
        </Button>
      </div>
      {notificationMethod.data.type === 'telegram' && !notificationMethod.isVerified && (
        <>
          <div className='mt-2' />
          <TelegramVerification notificationMethod={notificationMethod} />
        </>
      )}
    </>
  );
}

function TelegramVerification({ notificationMethod }: { notificationMethod: NotificationMethod }) {
  const { t } = useTranslation(['generic']);
  const [isTelegramInstalled, setIsTelegramInstalled] = useState<boolean | null>(null);
  const [isExpired, setIsExpired] = useState(false);

  if (notificationMethod.data.type !== 'telegram') {
    return null;
  }

  const verificationTokenExpiresAt = notificationMethod.data.verificationTokenExpiresAt;
  const expiresAt = verificationTokenExpiresAt
    ? DateTime.fromISO(verificationTokenExpiresAt)
    : null;
  const expiresAtIso = expiresAt ? expiresAt.toISO() : null;

  useEffect(() => {
    if (!expiresAt) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsExpired(true);
    }, expiresAt.diffNow().milliseconds);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [expiresAt]);

  if (isExpired) {
    return (
      <ErrorAlert
        errors={[t('generic:notificationMethod.form.telegram.verificationCodeExpired')]}
      />
    );
  }

  return (
    <div className='alert alert-warning'>
      <div className='d-flex justify-content-between'>
        <p className='fw-bold'>
          {t('generic:notificationMethod.form.telegram.verifyTelegramAccount')}
        </p>
        <p>
          {t('generic:notificationMethod.form.telegram.expiration')}:{' '}
          {expiresAtIso && <TimeAgo datetime={expiresAtIso} />}.
        </p>
      </div>
      {t('generic:notificationMethod.form.telegram.isTelegramInstalled')}
      <div className='d-flex gap-2 mt-2'>
        <Button
          className={`btn w-100 ${
            isTelegramInstalled === true ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            setIsTelegramInstalled(true);
          }}
        >
          {t('generic:notificationMethod.form.telegram.yes')}
        </Button>
        <Button
          className={`btn w-100 ${
            isTelegramInstalled === false ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => {
            setIsTelegramInstalled(false);
          }}
        >
          {t('generic:notificationMethod.form.telegram.no')}
        </Button>
      </div>
      {isTelegramInstalled !== null && (
        <Card className='mt-2'>
          {isTelegramInstalled === true && (
            <TelegramInCurrentDeviceSteps notificationMethod={notificationMethod} />
          )}
          {isTelegramInstalled === false && (
            <TelegramNotInCurrentDeviceSteps notificationMethod={notificationMethod} />
          )}
        </Card>
      )}
    </div>
  );
}

function TelegramInCurrentDeviceSteps({
  notificationMethod,
}: {
  notificationMethod: NotificationMethod;
}) {
  const { t } = useTranslation(['generic']);
  const trpcContext = trpc.useUtils();
  const verifyMethod = createMutation(trpc.notification.verifyTelegramNotificationMethod, {
    invalidate: [trpcContext.notification],
  });

  if (notificationMethod.data.type !== 'telegram') {
    return null;
  }

  const verificationToken = notificationMethod.data.verificationToken;

  return (
    <p>
      <p>
        <Button
          onClick={() => {
            window.open(`https://t.me/SimPulse_bot?start=${verificationToken}`, '_blank');
          }}
        >
          {t('generic:notificationMethod.form.telegram.installed.step1')}
        </Button>
      </p>
      <p>
        <Button disabled>{t('generic:notificationMethod.form.telegram.installed.step2')}</Button>
      </p>
      <p>
        <Button
          onClick={() => {
            verifyMethod.mutate({
              notificationMethodUuid: notificationMethod.uuid,
            });
          }}
          isLoading={verifyMethod.isLoading}
          hideContentWhenLoading
        >
          {t('generic:notificationMethod.form.telegram.installed.step3')}
        </Button>
      </p>
    </p>
  );
}

function TelegramNotInCurrentDeviceSteps({
  notificationMethod,
}: {
  notificationMethod: NotificationMethod;
}) {
  const { t } = useTranslation(['generic']);
  const trpcContext = trpc.useUtils();
  const verifyMethod = createMutation(trpc.notification.verifyTelegramNotificationMethod, {
    invalidate: [trpcContext.notification],
  });

  if (notificationMethod.data.type !== 'telegram') {
    return null;
  }

  const verificationToken = notificationMethod.data.verificationToken;

  return (
    <p>
      <p>{t('generic:notificationMethod.form.telegram.notInstalled.step1')}</p>
      <p>{t('generic:notificationMethod.form.telegram.notInstalled.step2')}</p>
      <p>
        {t('generic:notificationMethod.form.telegram.notInstalled.step3')}
        <p className='text-center'>
          <p>
            <code className='fw-bold bg-dark text-light p-2 rounded tracking-wide fs-1'>
              {verificationToken}
            </code>
          </p>
        </p>
      </p>
      <p>
        <Button
          onClick={() => {
            verifyMethod.mutate({
              notificationMethodUuid: notificationMethod.uuid,
            });
          }}
          isLoading={verifyMethod.isLoading}
          hideContentWhenLoading
        >
          {t('generic:notificationMethod.form.telegram.notInstalled.step4')}
        </Button>
      </p>
    </p>
  );
}
