import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import FormInput from '../../../common/components/form/input';
import BookingList from './booking/BookingsList';

export default function ViewBookings() {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);

  const isDebouncing = query !== debouncedQuery;

  return (
    <>
      <FormInput
        placeholder={'Search'}
        name='query'
        type='text'
        onChange={setQuery}
        startIcon={isDebouncing ? <LoadingSpinner /> : <IconSearch />}
      />
      <BookingList
        params={{
          fullTextSearch: debouncedQuery,
        }}
      />
    </>
  );
}
