import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../common/components/form/Button';
import ReferralForm from './ReferralForm';
import ReferralsList from './ReferralsList';
import { usePmsContext } from '../../../Context';

export default function ViewReferrals() {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const { referrals } = usePmsContext();
  const [showNewReferralForm, setShowNewReferralForm] = useState(false);

  return (
    <div ref={parentRef}>
      {showNewReferralForm ? (
        <div className='card border-primary'>
          <div className='card-body'>
            <h2>New referral</h2>
            <ReferralForm
              action='create'
              onCancel={() => setShowNewReferralForm(false)}
              onSuccess={() => {
                setShowNewReferralForm(false);
              }}
            />
          </div>
        </div>
      ) : (
        <Button
          type='button'
          onClick={() => {
            setShowNewReferralForm(true);
          }}
        >
          <IconPlus />
          Add new referral
        </Button>
      )}
      <ReferralsList referrals={referrals} />
    </div>
  );
}
