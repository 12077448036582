import { trpc } from '../../../utils/trpc';
import Button from '../components/form/Button';
import { createMutation } from '../../../utils/api';

export default function GoToBillingPortalButton() {
  const createBillingPortalLink = createMutation(
    trpc.pay.providers.stripe.simpulseSubscription.createBillingPortalLink,
    {
      onSuccess: (data: { redirectUrl: string }) => {
        window.location.href = data.redirectUrl;
      },
    },
  );

  return (
    <Button
      className='btn'
      onClick={() =>
        createBillingPortalLink.mutate({
          returnUrl: window.location.href,
        })
      }
      isLoading={createBillingPortalLink.isLoading}
    >
      Gestione fatturazione e pagamenti
    </Button>
  );
}
