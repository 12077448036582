import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../../../common/components/form/Button';
import { UnitType } from '../../../../../api/units/unit-types';
import UnitForm from './UnitForm';

interface Unit {
  uuid: string;
  name: string;
}

function UnitElement({ unit, unitTypeUuid }: { unit: Unit; unitTypeUuid: string }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [showEditForm, setShowEditForm] = useState(false);

  return (
    <div key={`UnitElement-unit-${unit.uuid}`} className='col-md-6 col-xl-4 pt-3'>
      <div className='card card-body' ref={parentRef}>
        {showEditForm ? (
          <UnitForm
            action='update'
            unit={unit}
            unitTypeUuid={unitTypeUuid}
            onSuccess={async () => {
              setShowEditForm(false);
            }}
            onCancel={() => {
              setShowEditForm(false);
            }}
          />
        ) : (
          <div className='d-flex justify-content-between'>
            <h3 className='text-truncate'>{unit.name}</h3>
            <div>
              <Button
                type='button'
                className='btn btn-icon ms-1'
                onClick={() => setShowEditForm(true)}
              >
                <IconPencil size='1em' />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default function UnitsList({ unitType }: { unitType: UnitType }) {
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  return (
    <div className='row' ref={animationParent}>
      {unitType.units.map((unit) => (
        <UnitElement
          key={`UnitElement-unit-${unit.uuid}`}
          unit={unit}
          unitTypeUuid={unitType.uuid}
        />
      ))}
    </div>
  );
}
