import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil, IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../../../common/components/form/Button';
import { usePmsContext } from '../../../../../Context';
import { UnitType } from '../../../../../api/units/unit-types';
import UnitForm from './UnitForm';
import UnitTypeForm from './UnitTypeForm';
import UnitsList from './UnitsList';
import { useTranslation } from 'react-i18next';

function UnitTypeElement({
  unitType,
  accommodationUuid,
}: {
  unitType: UnitType;
  accommodationUuid: string;
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [showEditUnitTypeForm, setShowEditUnitTypeForm] = useState(false);
  const [showNewUnitForm, setShowNewUnitForm] = useState(false);

  if (showEditUnitTypeForm) {
    return (
      <div className='mt-3 card'>
        <div className='card-body'>
          <h2 className='text-truncate'>{unitType.name}</h2>
          <UnitTypeForm
            action='update'
            unitType={unitType}
            accommodationUuid={accommodationUuid}
            onSuccess={() => setShowEditUnitTypeForm(false)}
            onCancel={() => setShowEditUnitTypeForm(false)}
          />
        </div>
      </div>
    );
  }

  const addNewUnitClassName = unitType.units.length === 0 ? 'btn-warning text-white' : '';

  return (
    <div className='mt-3 card'>
      <div className='card-body'>
        <h2 className='text-truncate'>{unitType.name}</h2>

        <div className='card card-body'>
          <div className='d-flex justify-content-between'>
            <div className='text-truncate'>
              {`${t('pms:configuration.accommodationSettings.unitTypeForm.name')}: ${
                unitType.name
              }.`}
              <br />
              {`Min ${unitType.paxMin}, max ${unitType.paxMax} pax.`}
              <br />
              {`${t('pms:configuration.accommodationSettings.unitTypeForm.publicName')}:`}
              {Object.keys(unitType.publicName.translations).length === 0 ? (
                <>
                  {' N/A'}
                  <br />
                </>
              ) : (
                <ul>
                  {Object.entries(unitType.publicName.translations).map(([key, value]) => (
                    <li
                      key={`UnitTypeElement-${unitType.uuid}-publicName-${key}`}
                    >{`${key}: ${value}`}</li>
                  ))}
                </ul>
              )}
              {`${t('pms:configuration.accommodationSettings.unitTypeForm.publicDescription')}:`}
              {Object.keys(unitType.publicDescription.translations).length === 0 ? (
                <>
                  {' N/A'}
                  <br />
                </>
              ) : (
                <ul>
                  {Object.entries(unitType.publicDescription.translations).map(([key, value]) => (
                    <li
                      key={`UnitTypeElement-${unitType.uuid}-publicDescription-${key}`}
                    >{`${key}: ${value}`}</li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <Button
                type='button'
                className='btn btn-icon ms-1'
                onClick={() => {
                  setShowEditUnitTypeForm(true);
                }}
              >
                <IconPencil size='1em' />
              </Button>
            </div>
          </div>
        </div>

        <UnitsList unitType={unitType} />

        <div ref={parentRef}>
          {showNewUnitForm ? (
            <div className='card card-body mt-3'>
              <UnitForm
                action='create'
                unitTypeUuid={unitType.uuid}
                onCancel={() => setShowNewUnitForm(false)}
                onSuccess={() => {
                  setShowNewUnitForm(false);
                }}
              />
            </div>
          ) : (
            <Button
              type='button'
              className={`btn w-100 mt-3 ${addNewUnitClassName}`}
              onClick={() => {
                setShowNewUnitForm(true);
              }}
            >
              <IconPlus className='me-2' />
              <span className='text-truncate'>{`${t(
                'pms:configuration.accommodationSettings.addNewUnitTo',
              )} ${unitType.name}`}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default function UnitTypesList({ accommodationUuid }: { accommodationUuid: string }) {
  const { unitTypes: allUnitTypes } = usePmsContext();

  const currentAccommodationUnitTypes = allUnitTypes.filter(
    (unitType) => unitType.accommodationUuid === accommodationUuid,
  );

  return (
    <>
      {currentAccommodationUnitTypes.map((unitType) => (
        <UnitTypeElement
          key={`UnitTypeElement-unitType-${unitType.uuid}`}
          unitType={unitType}
          accommodationUuid={accommodationUuid}
        />
      ))}
    </>
  );
}
