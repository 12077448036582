import { useState } from 'react';
import Modal from '../../../../../../common/components/Modal';
import { IconPlus } from '@tabler/icons-react';
import RateOptionForm from './RateOptionForm';
import RateOptionsList from './RateOptionsList';
import Button from '../../../../../../common/components/form/Button';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useTranslation } from 'react-i18next';

export default function RateOptionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate();
  const [showNewOptionForm, setShowNewOptionForm] = useState(false);

  return (
    <Modal
      title={t('pms:configuration.accommodationSettings.options')}
      isOpen={isOpen}
      onClose={onClose}
      size='sm'
    >
      <div className='alert'>{t('pms:configuration.accommodationSettings.optionsForm.hint')}</div>
      <div ref={parentRef}>
        <RateOptionsList />
        {showNewOptionForm ? (
          <div className='card'>
            <div className='card-body'>
              <RateOptionForm
                action='create'
                onCancel={() => setShowNewOptionForm(false)}
                onSuccess={() => {
                  setShowNewOptionForm(false);
                }}
              />
            </div>
          </div>
        ) : (
          <Button
            type='button'
            onClick={() => {
              setShowNewOptionForm(true);
            }}
          >
            <IconPlus />
            {t('pms:configuration.accommodationSettings.optionsForm.addNewOption')}
          </Button>
        )}
      </div>
    </Modal>
  );
}
