import { useState } from 'react';
import Modal from './Modal';
import Button from './form/Button';
import { CURRENT_HOSTNAME, LOCALHOST_HOSTNAME, PRODUCTION_HOSTNAME } from '../../../const';

const DangerIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon mb-2 text-danger icon-lg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 9v2m0 4v.01' />
    <path d='M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75' />
  </svg>
);

export default function NonProductionEnvironmentModalWarning() {
  if ([PRODUCTION_HOSTNAME, LOCALHOST_HOSTNAME].includes(CURRENT_HOSTNAME)) {
    return null;
  }

  return <ShownModal />;
}

function ShownModal() {
  const [show, setShow] = useState(true);

  return (
    <Modal isOpen={show} title='Warning' onClose={() => setShow(false)} size='sm'>
      <div className='modal-status bg-danger' />
      <div className='text-center'>
        <DangerIcon />
        <div className='text-secondary mb-4'>
          This is a non-production environment. All the data may be deleted at any time without
          notice.
          <br />
          Do not use this environment for production purposes.
        </div>
        <div className='d-grid gap-2'>
          <Button
            onClick={() => {
              window.location.href = `https://${PRODUCTION_HOSTNAME}`;
            }}
          >
            Go to the production environment
          </Button>
          <Button
            className='btn btn-outline-danger w-100'
            onClick={() => {
              setShow(false);
            }}
          >
            I understand, continue anyway
          </Button>
        </div>
      </div>
    </Modal>
  );
}
