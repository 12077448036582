import { useState } from 'react';
import Cookies from 'universal-cookie';
import { trpc } from '../../../utils/trpc';
import ErrorAlert from '../../common/components/ErrorAlert';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { CompanyEmailField } from './common/CompanyEmailField';
import { UserPasswordField } from './common/UserPasswordField';
import { createMutation } from '../../../utils/api';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies();

function CompanyNameField({
  formErrors,
  disabled,
}: {
  formErrors: FormErrors | null;
  disabled: boolean;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['company', 'name']}
      type='text'
      name='companyName'
      title={t('auth:form.companyName')}
      disabled={disabled}
      required
    />
  );
}

function CompanyPhoneField({
  formErrors,
  disabled,
}: {
  formErrors: FormErrors | null;
  disabled: boolean;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['company', 'phone']}
      type='text'
      name='companyPhone'
      title={t('auth:form.companyPhone')}
      disabled={disabled}
      required
    />
  );
}

function CompanyWebsiteField({
  formErrors,
  disabled,
}: {
  formErrors: FormErrors | null;
  disabled: boolean;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['company', 'website']}
      type='text'
      name='companyWebsite'
      title={t('auth:form.companyWebsite')}
      disabled={disabled}
      startText='https://'
    />
  );
}

function InterestsField({
  formErrors,
  disabled,
  setInterestedInPms,
  setInterestedInMenu,
}: {
  formErrors: FormErrors | null;
  disabled: boolean;
  setInterestedInPms: (value: boolean) => void;
  setInterestedInMenu: (value: boolean) => void;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <>
      <div className='fw-bold mb-2'>{t('auth:form.interests')}</div>
      <FormInput
        formErrors={formErrors}
        errorPath={['interests', 'pms']}
        type='switch'
        name='interestedInPms'
        title={t('auth:form.interestsOptions.pms')}
        hint={t('auth:form.interestsOptions.pmsHint')}
        disabled={disabled}
        checkboxValue='on'
        onChange={setInterestedInPms}
      />
      <FormInput
        formErrors={formErrors}
        errorPath={['interests', 'menu']}
        type='switch'
        name='interestedInMenu'
        title={t('auth:form.interestsOptions.menu')}
        hint={t('auth:form.interestsOptions.menuHint')}
        disabled={disabled}
        checkboxValue='on'
        onChange={setInterestedInMenu}
      />
    </>
  );
}

function CompleteRegistrationContent({ uuid, token }: { uuid: string; token: string }) {
  const { t, i18n } = useTranslation(['auth']);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [genericError, setGenericError] = useState<string[]>([]);
  const [registrationRequestSent, setRegistrationRequestSent] = useState(false);
  const registrationRequestQuery = trpc.auth.getRegistrationRequest.useQuery({ uuid, token });
  const [interestedInPms, setInterestedInPms] = useState(false);
  const [interestedInMenu, setInterestedInMenu] = useState(false);

  const { isLoading: isCreating, mutate: completeRegistration } = createMutation(
    trpc.auth.register,
    {
      onSuccess: (response: { token: string }) => {
        cookies.set('token', response.token, { path: '/' });

        // Hard refresh to get the new token
        // TODO: for some reason, onSuccess the user is redirected to the login page.
        // By refreshing, the user is logged in. It looks like the cookie is not set immediately.
        window.location.reload();
      },
      setFormErrors,
      setGenericError,
    },
  );
  const registerCompanyRequest = createMutation(trpc.auth.registerRequest, {
    onSuccess: () => {
      setRegistrationRequestSent(true);
    },
    setFormErrors,
    setGenericError,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    if (!interestedInPms && !interestedInMenu) {
      setGenericError([t('auth:form.interestsError')]);
      return;
    }

    const form = e.currentTarget;

    let website = form.companyWebsite.value as string;
    if (website !== '' && !website.startsWith('http://') && !website.startsWith('https://')) {
      website = `https://${website}`;
    }

    completeRegistration({
      registrationRequest: {
        uuid,
        token,
      },
      company: {
        name: form.companyName.value,
        phone: form.companyPhone.value,
        website,
      },
      user: {
        password: form.userPassword.value,
      },
      interests: {
        pms: interestedInPms,
        menu: interestedInMenu,
      },
    });
  };

  if (registrationRequestQuery.isLoading) {
    return <LoadingSpinner center />;
  }

  if (registrationRequestQuery.error) {
    return <ErrorAlert errors={registrationRequestQuery.error} />;
  }

  const data = registrationRequestQuery.data;

  if (!data.valid) {
    if (data.cause === 'EXPIRED') {
      if (registrationRequestSent) {
        return (
          <div className='alert alert-success'>
            {t('auth:registrationRequestSentMessage')}
            <p>
              <b>
                <a href='/'>{t('auth:registrationRequestSentMessageAction')}</a>
              </b>
            </p>
          </div>
        );
      }
      return (
        <>
          <ErrorAlert errors={t('auth:registrationExpired')} />
          <Button
            isLoading={registerCompanyRequest.isLoading}
            hideContentWhenLoading
            onClick={() => {
              registerCompanyRequest.mutate({
                language: i18n.language,
                company: { email: data.email },
              });
            }}
          >
            {t('auth:requestRegistrationLink')}
          </Button>
        </>
      );
    }

    return <ErrorAlert errors={t('auth:invalidRegistrationRequest')} />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ErrorAlert errors={genericError} />
        <CompanyEmailField value={data.email || ''} />
        <UserPasswordField formErrors={formErrors} disabled={isCreating} />
        <CompanyNameField formErrors={formErrors} disabled={isCreating} />
        <CompanyPhoneField formErrors={formErrors} disabled={isCreating} />
        <CompanyWebsiteField formErrors={formErrors} disabled={isCreating} />
        <InterestsField
          formErrors={formErrors}
          disabled={isCreating}
          setInterestedInPms={setInterestedInPms}
          setInterestedInMenu={setInterestedInMenu}
        />
        {!interestedInPms && !interestedInMenu && (
          <div className='alert alert-warning'>{t('auth:form.interestsError')}</div>
        )}
        <div className='form-footer'>
          <Button isLoading={isCreating} hideContentWhenLoading>
            {t('auth:form.register')}
          </Button>
        </div>
      </form>
    </>
  );
}

export default function CompleteRegistration({ uuid, token }: { uuid: string; token: string }) {
  const { t } = useTranslation(['auth']);
  return (
    <div className='card card-md'>
      <div className='card-body'>
        <h2 className='card-title text-center mb-4'>{t('auth:registration')}</h2>
        <CompleteRegistrationContent uuid={uuid} token={token} />
      </div>
    </div>
  );
}
