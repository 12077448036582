import { useState } from 'react';
import Modal from '../../../common/components/Modal';
import FormInput from '../../../common/components/form/input';
import { usePlanningSettingsContext } from '../../contexts/PlanningSettingsContext';
import Button from '../../../common/components/form/Button';
import {
  IconCalendar,
  IconCash,
  IconCashOff,
  IconTransferIn,
  IconTransferOut,
} from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

export default function PlanningSettingsModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ul className='nav nav-tabs card-header-tabs mb-2' data-bs-toggle='tabs' role='tablist'>
        <li className='nav-item' role='presentation'>
          <a href='#dates' className='nav-link active show' data-bs-toggle='tab' role='tab'>
            {t('pms:calendar.settings.dates')}
          </a>
        </li>
        <li className='nav-item' role='presentation'>
          <a href='#labels' className='nav-link' data-bs-toggle='tab' role='tab'>
            {t('pms:calendar.settings.labels')}
          </a>
        </li>
        <li className='nav-item' role='presentation'>
          <a href='#colors' className='nav-link' data-bs-toggle='tab' role='tab'>
            {t('pms:calendar.settings.colors')}
          </a>
        </li>
      </ul>
      <div className='tab-content'>
        <div className='tab-pane active show' id='dates' role='tabpanel'>
          <DatesSetting />
        </div>
        <div className='tab-pane' id='labels' role='tabpanel'>
          <PaymentStatusSetting />
          <hr />
          <CheckInOutStatusSetting />
        </div>
        <div className='tab-pane' id='colors' role='tabpanel'>
          <ColorBookingSetting />
        </div>
      </div>
    </Modal>
  );
}

function DatesSetting() {
  const { t } = useTranslation(['pms']);
  const { startDaysOffset, setStartDaysOffset, showDays, setShowDays } =
    usePlanningSettingsContext();
  const [showStartDaysAsDateInput, setShowStartDaysAsDateInput] = useState(false);

  const currentDate = DateTime.now().startOf('day');
  const [initialStartDate] = useState(currentDate.plus({ days: startDaysOffset }).toISODate()!);

  return (
    <>
      <div className='d-flex gap-2'>
        <div className='flex-grow-1'>
          {showStartDaysAsDateInput ? (
            <FormInput
              name='startDate'
              title={t('pms:calendar.showCalendarStartingFrom')}
              type='date'
              defaultValue={initialStartDate}
              onChange={(newDateString) => {
                setStartDaysOffset(
                  Math.ceil(DateTime.fromISO(newDateString).diff(currentDate).as('days')),
                );
              }}
            />
          ) : (
            <FormInput
              name='startDaysBefore'
              title={t('pms:calendar.showCalendarStartingFrom')}
              type='select'
              defaultValue={startDaysOffset.toString()}
              onChange={(value) => setStartDaysOffset(parseInt(value))}
            >
              <option value='0'>{t('pms:calendar.periods.sameDay')}</option>
              <option value='-1'>{t('pms:calendar.periods.dayBefore', { count: 1 })}</option>
              <option value='-2'>{t('pms:calendar.periods.dayBefore', { count: 2 })}</option>
              <option value='-7'>{t('pms:calendar.periods.weekBefore', { count: 1 })}</option>
              {![0, -1, -2, -7].includes(startDaysOffset) && (
                <option value={startDaysOffset.toString()}>
                  {startDaysOffset < 0
                    ? t('pms:calendar.periods.dayBefore', { count: Math.abs(startDaysOffset) })
                    : t('pms:calendar.periods.dayAfter', { count: startDaysOffset })}
                </option>
              )}
            </FormInput>
          )}
        </div>
        {!showStartDaysAsDateInput && (
          <Button
            className='btn btn-icon mb-2'
            onClick={() => setShowStartDaysAsDateInput(!showStartDaysAsDateInput)}
          >
            <IconCalendar size='1em' />
          </Button>
        )}
      </div>
      <FormInput
        name='showDays'
        title={t('pms:calendar.showCalendarFor')}
        type='select'
        defaultValue={showDays.toString()}
        onChange={(value) => setShowDays(parseInt(value))}
      >
        <option value='7'>{t('pms:calendar.periods.week', { count: 1 })}</option>
        <option value='30'>{t('pms:calendar.periods.month', { count: 1 })}</option>
        <option value='60'>{t('pms:calendar.periods.month', { count: 2 })}</option>
        <option value='90'>{t('pms:calendar.periods.month', { count: 3 })}</option>
        <option value='120'>{t('pms:calendar.periods.month', { count: 4 })}</option>
      </FormInput>
    </>
  );
}

function PaymentStatusSetting() {
  const { t } = useTranslation(['pms']);
  const { showPaymentNeeded, setShowPaymentNeeded } = usePlanningSettingsContext();

  return (
    <>
      <FormInput
        name='showPaymentNeeded'
        title={t('pms:calendar.showPaymentNeeded')}
        hint={t('pms:calendar.showPaymentNeededHint')}
        type='switch'
        checkboxValue='on'
        defaultValue={showPaymentNeeded}
        onChange={setShowPaymentNeeded}
      />
      <IconCash size='1em' className='me-2' /> {t('pms:calendar.showPaymentNeededPaidIcon')}
      <br />
      <IconCashOff size='1em' className='me-2' /> {t('pms:calendar.showPaymentNeededToBePaidIcon')}
    </>
  );
}

function CheckInOutStatusSetting() {
  const { t } = useTranslation(['pms']);
  const { showCheckInOutStatus, setShowCheckInOutStatus } = usePlanningSettingsContext();

  return (
    <>
      <FormInput
        name='showCheckInOutStatus'
        title={t('pms:calendar.showCheckInOutStatus')}
        hint={t('pms:calendar.showCheckInOutStatusHint')}
        type='switch'
        checkboxValue='on'
        defaultValue={showCheckInOutStatus}
        onChange={setShowCheckInOutStatus}
      />
      <IconTransferIn size='1em' className='me-2' />{' '}
      {t('pms:calendar.showCheckInOutStatusCheckInIcon')}
      <br />
      <IconTransferOut size='1em' className='me-2' />{' '}
      {t('pms:calendar.showCheckInOutStatusCheckOutIcon')}
    </>
  );
}

function ColorBookingSetting() {
  const { t } = useTranslation(['pms']);
  const { bookingColors: bookingColorsRaw, setBookingColors } = usePlanningSettingsContext();
  const [bookingColors] = useDebounce(bookingColorsRaw, 100);

  let input: JSX.Element;
  switch (bookingColors.type) {
    case 'singleColor':
      input = (
        <FormInput
          name='bookingColor'
          title={t('pms:calendar.bookingColor')}
          type='color'
          defaultValue={bookingColors.color}
          onChange={(value) => setBookingColors({ type: 'singleColor', color: value })}
        />
      );
      break;
    case 'checkinCheckout':
      input = (
        <>
          <FormInput
            name='bookingColor'
            title={t('pms:calendar.bookingColorOption.defaultColor')}
            type='color'
            defaultValue={bookingColors.colors.default}
            onChange={(value) =>
              setBookingColors({
                ...bookingColors,
                colors: {
                  ...bookingColors.colors,
                  default: value,
                },
              })
            }
          />
          <FormInput
            name='bookingColor'
            title={t('pms:calendar.bookingColorOption.checkinColor')}
            type='color'
            defaultValue={bookingColors.colors.checkin}
            onChange={(value) =>
              setBookingColors({
                ...bookingColors,
                colors: {
                  ...bookingColors.colors,
                  checkin: value,
                },
              })
            }
          />
          <FormInput
            name='bookingColor'
            title={t('pms:calendar.bookingColorOption.checkoutColor')}
            type='color'
            defaultValue={bookingColors.colors.checkout}
            onChange={(value) =>
              setBookingColors({
                ...bookingColors,
                colors: {
                  ...bookingColors.colors,
                  checkout: value,
                },
              })
            }
          />
        </>
      );
      break;
  }

  return (
    <>
      <FormInput
        type='select'
        name='bookingColorType'
        title={t('pms:calendar.bookingColorType')}
        defaultValue={bookingColors.type}
        onChange={(value) => {
          if (value === bookingColors.type) {
            return;
          }

          switch (value) {
            case 'single':
              setBookingColors({ type: 'singleColor', color: '#d63939' });
              break;
            case 'checkinCheckout':
              setBookingColors({
                type: 'checkinCheckout',
                colors: {
                  default: '#d63939',
                  checkin: '#ca6702',
                  checkout: '#9b2226',
                },
              });
              break;
          }
        }}
      >
        <option value='single'>{t('pms:calendar.bookingColorTypeOption.single')}</option>
        <option value='checkinCheckout'>
          {t('pms:calendar.bookingColorTypeOption.checkinCheckout')}
        </option>
      </FormInput>
      {input}
    </>
  );
}
