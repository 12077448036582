import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAuth } from '../hooks/AuthContext';
import LoadingSpinner from '../../common/components/LoadingSpinner';

const cookies = new Cookies();

export default function LoginToken({ token }: { token: string }) {
  const { login: useLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    cookies.set('token', token, {
      path: '/',
    });
    useLogin();
    navigate('/');
  }, [token]);

  return <LoadingSpinner cardBody center />;
}
