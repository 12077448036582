import { Route, Routes } from 'react-router-dom';
import NewAccommodation from './accommodation/NewAccommodation';
import ViewAccommodation from './accommodation/ViewAccommodation';
import ViewAccommodations from './ViewAccommodations';

export default function Accommodations() {
  return (
    <Routes>
      <Route index element={<ViewAccommodations />} />
      <Route path='/new' element={<NewAccommodation />} />
      <Route path='/:uuid/*' element={<ViewAccommodation />} />
    </Routes>
  );
}
