import { DateTime, Interval } from 'luxon';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import { usePmsContext } from '../../Context';
import { Booking } from '../../api/bookings/bookings';
import { UnitType } from '../../api/units/unit-types';
import { Unit } from '../../api/units/units';
import { Rate } from '../../api/rates/rates';
import FormInput from '../../../common/components/form/input';
import Modal from '../../../common/components/Modal';
import { BookingForm } from '../bookings/booking/NewBooking';
import { PaxSelector } from '../../components/booking/form/AddBookingUnit';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import {
  IconCash,
  IconCashOff,
  IconChevronRight,
  IconClock,
  IconSettings,
  IconTransferIn,
  IconTransferOut,
  IconUsers,
} from '@tabler/icons-react';
import PlanningSettingsModal from '../../components/planning/PlanningSettingsModal';
import Button from '../../../common/components/form/Button';
import {
  PlanningSettingsContextProvider,
  usePlanningSettingsContext,
} from '../../contexts/PlanningSettingsContext';
import {
  getArrayOfStringsFromLinedString,
  getCanvasFont,
  getTextWidth,
} from '../../../../utils/string';
import { Accommodation } from '../../api/accommodations';
import { Tooltip } from 'react-tooltip';
import { LocalDate } from '@js-joda/core';
import { getOverbookings, getUnitDataById } from '../../utils/booking';
import { localDateToLocaleDateString } from '../../../../utils/date';

const COLUMN_WIDTH_PX = 40;

export default function Planning() {
  return (
    <PlanningSettingsContextProvider>
      <PlanningContent />
    </PlanningSettingsContextProvider>
  );
}

function PlanningContent() {
  const { t } = useTranslation(['pms']);
  const { currentAccommodations, unitTypes: allUnitTypes, rateOptions } = usePmsContext();
  const { rateOptionUuid, setRateOptionUuid, startDaysOffset, showDays } =
    usePlanningSettingsContext();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const todayDate = DateTime.now().startOf('day');
  const startDate = todayDate.plus({ days: startDaysOffset });
  let endDate = startDate.plus({ days: showDays });
  if (endDate.day <= 2) {
    // Last day must be at least 3rd day of month to show month name fully
    endDate = endDate.plus({ days: 2 });
  }

  const totalDays = Interval.fromDateTimes(startDate, endDate).length('days');

  const queryBookings = trpc.pms.booking.queryBookings.useQuery({
    accommodationsUuids: currentAccommodations.map((a) => a.uuid),
    departureDateAfter: startDate.toISODate(),
    arrivalDateBefore: endDate.toISODate(),
    page: 1,
    limit: 1000,
  });

  const bookings = queryBookings.data?.bookings || [];

  const overbookings = useMemo(() => getOverbookings(bookings), [bookings]);

  if (queryBookings.error) {
    return <ErrorAlert errors={queryBookings.error} />;
  }

  if (
    queryBookings.data !== undefined &&
    queryBookings.data.total !== queryBookings.data.bookings.length
  ) {
    return <ErrorAlert errors={t('pms:calendar.tooManyBookingsReturnedError')} />;
  }

  // First column width
  const unitTypes = allUnitTypes.filter((ut) =>
    currentAccommodations.some((a) => a.uuid === ut.accommodationUuid),
  );
  const units = unitTypes.flatMap((ut) => ut.units);
  const unitTypeNamesTextWidth = unitTypes.reduce((acc, unitType) => {
    const unitTypeName = unitType.name;
    const unitTypeNameWidth = getTextWidth(unitTypeName, getCanvasFont());
    return Math.max(acc, unitTypeNameWidth);
  }, 0);
  const unitNamesTextWidth = units.reduce((acc, unit) => {
    const unitName = unit.name;
    const unitNameWidth = getTextWidth(unitName, getCanvasFont());
    return Math.max(acc, unitNameWidth);
  }, 0);

  let unitNamesColumnWidth: number;
  if (unitNamesTextWidth + COLUMN_WIDTH_PX > unitTypeNamesTextWidth) {
    // Unit name is wider than unit type name
    unitNamesColumnWidth = unitNamesTextWidth + 32 + 10;
  } else {
    // Unit type name is wider than unit name
    unitNamesColumnWidth = unitTypeNamesTextWidth - COLUMN_WIDTH_PX + 32 + 10;
  }

  return (
    <>
      <div className='d-flex justify-content-end gap-2'>
        {rateOptions.length > 1 && (
          <FormInput
            type='select'
            name='rateOptionUuid'
            defaultValue={rateOptionUuid}
            onChange={(value) => setRateOptionUuid(value)}
          >
            {rateOptions.map((option) => (
              <option key={option.uuid} value={option.uuid}>
                {option.name}
              </option>
            ))}
          </FormInput>
        )}
        <Button className='btn btn-icon mb-2' onClick={() => setIsSettingsModalOpen(true)}>
          <IconSettings size='1em' />
        </Button>
        <PlanningSettingsModal
          isOpen={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
        />
      </div>
      <Overbookings overbookings={overbookings} />
      <div className='card w-100 table-responsive pb-4'>
        <table
          className='table table-vcenter card-table table-bordered'
          style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}
        >
          <thead className='thead-light'>
            <HeaderDaysMonthsRows
              todayDate={todayDate}
              startDate={startDate}
              endDate={endDate}
              unitNamesColumnWidth={unitNamesColumnWidth}
            />
          </thead>
          <tbody>
            {currentAccommodations.map((accommodation) => (
              <>
                <tr className='bg-red-lt'>
                  <td
                    key={`planning-accommodation-${accommodation.uuid}`}
                    className='bg-red-lt'
                    colSpan={totalDays + 2}
                  >
                    <b style={{ position: 'sticky', left: '20px' }}>{accommodation.name}</b>
                  </td>
                </tr>
                <AccommodationPlanning
                  key={`planning-accommodation-${accommodation.uuid}`}
                  bookings={bookings}
                  bookingsIsFetching={queryBookings.isFetching}
                  accommodation={accommodation}
                  todayDate={todayDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            ))}
          </tbody>
        </table>
      </div>
      <Tooltips bookings={bookings} />
    </>
  );
}

function AccommodationPlanning({
  bookings,
  bookingsIsFetching,
  accommodation,
  todayDate,
  startDate,
  endDate,
}: {
  bookings: Booking[];
  bookingsIsFetching: boolean;
  accommodation: Accommodation;
  todayDate: DateTime<true>;
  startDate: DateTime<true>;
  endDate: DateTime<true>;
}) {
  const { unitTypes: allUnitTypes, rates } = usePmsContext();

  const navigate = useNavigate();

  const { rateOptionUuid, showPaymentNeeded, showCheckInOutStatus, bookingColors } =
    usePlanningSettingsContext();

  // Quick booking
  const [createQuickBookingUnit, setCreateQuickBookingUnit] = useState<string | null>(null);
  const [createQuickBookingFrom, setCreateQuickBookingFrom] = useState<string | null>(null);
  const [createQuickBookingTo, setCreateQuickBookingTo] = useState<string | null>(null);
  const [createQuickBookingToHover, setCreateQuickBookingToHover] = useState<string | null>(null);
  const [showQuickBookingModal, setShowQuickBookingModal] = useState(false);
  useEffect(() => {
    if (
      createQuickBookingUnit !== null &&
      createQuickBookingFrom !== null &&
      createQuickBookingTo !== null
    ) {
      setShowQuickBookingModal(true);
    }
  }, [createQuickBookingUnit, createQuickBookingFrom, createQuickBookingTo]);
  useEffect(() => {
    if (!showQuickBookingModal) {
      setCreateQuickBookingUnit(null);
      setCreateQuickBookingFrom(null);
      setCreateQuickBookingTo(null);
      setCreateQuickBookingToHover(null);
    }
  }, [showQuickBookingModal]);

  const unitTypes = allUnitTypes.filter((ut) => ut.accommodationUuid === accommodation.uuid);
  const units = unitTypes.flatMap((unitType) =>
    unitType.units.map((unit) => ({ ...unit, unitTypeId: unitType.uuid })),
  );

  const getBookingUnitBackgroundColor = useMemo(
    () =>
      ({
        checkInDate,
        checkOutDate,
      }: {
        checkInDate: string | null;
        checkOutDate: string | null;
      }) => {
        if (bookingColors.type === 'singleColor') {
          return bookingColors.color;
        }

        if (bookingColors.type === 'checkinCheckout') {
          if (checkOutDate !== null) {
            return bookingColors.colors.checkout;
          }
          if (checkInDate !== null) {
            return bookingColors.colors.checkin;
          }
          return bookingColors.colors.default;
        }

        return '#000';
      },
    [bookingColors],
  );

  const { unitTypesColumns, unitsColumns } = useMemo(
    () =>
      getPlanningData({
        isFetching: bookingsIsFetching,
        unitTypes,
        units,
        rates,
        bookings,
        rateOptionUuid,
        startDateIso: startDate.toISODate(),
        endDateIso: endDate.toISODate(),
        navigate,
        quickBookingUnit: createQuickBookingUnit,
        quickBookingFrom: createQuickBookingFrom,
        quickBookingToHover: createQuickBookingToHover,
        setQuickBookingToHover: (date) => {
          if (createQuickBookingTo === null) {
            setCreateQuickBookingToHover(date);
          }
        },
        onAvailableUnitDateClick: (unitUuid, date) => {
          if (
            createQuickBookingUnit === null ||
            createQuickBookingFrom === null ||
            unitUuid !== createQuickBookingUnit
          ) {
            setCreateQuickBookingUnit(unitUuid);
            setCreateQuickBookingFrom(date);
            return;
          }
          if (createQuickBookingFrom === date || createQuickBookingFrom > date) {
            setCreateQuickBookingUnit(null);
            setCreateQuickBookingFrom(null);
            setCreateQuickBookingTo(null);
            return;
          }
          if (createQuickBookingTo === null) {
            setCreateQuickBookingTo(date);
            return;
          }
        },
        showPaymentNeeded,
        showCheckInOutStatus,
        getBookingUnitBackgroundColor,
      }),
    [
      bookings,
      bookingsIsFetching,
      unitTypes,
      units,
      rates,
      bookings,
      startDate,
      endDate,
      todayDate,
      navigate,
    ],
  );

  return (
    <>
      {unitTypes.map((unitType) => (
        <React.Fragment key={`calendar-table-unitType-${unitType.uuid}`}>
          <tr className='bg-blue-lt'>{unitTypesColumns[unitType.uuid]}</tr>
          {units
            .filter((unit) => unit.unitTypeId === unitType.uuid)
            .map((unit: any) => (
              <tr key={`calendar-table-unit-${unit.uuid}`}>{unitsColumns[unit.uuid]}</tr>
            ))}
        </React.Fragment>
      ))}
      <QuickBookingModal
        unitUuid={createQuickBookingUnit}
        fromIso={createQuickBookingFrom}
        toIso={createQuickBookingTo}
        isOpen={showQuickBookingModal}
        onClose={() => setShowQuickBookingModal(false)}
      />
    </>
  );
}

function QuickBookingModal({
  unitUuid,
  fromIso,
  toIso,
  isOpen,
  onClose,
}: {
  unitUuid: string | null;
  fromIso: string | null;
  toIso: string | null;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const { accommodations, unitTypes, rateOptions } = usePmsContext();
  const [adultsNumber, setAdultsNumber] = useState(2);
  const [childrenNumber, setChildrenNumber] = useState(0);
  const [rateOptionUuid, setRateOptionUuid] = useState<string | null>(null);
  const [rateAmount, setRateAmount] = useState<number | null>(null);

  const [accommodation, unitType, unit] = useMemo(() => {
    for (const unitType of unitTypes && unitUuid !== null ? unitTypes : []) {
      for (const unit of unitType.units) {
        if (unit.uuid === unitUuid) {
          const accommodation = accommodations.find((a) => a.uuid === unitType.accommodationUuid);
          if (accommodation === undefined) {
            return [null, null, null];
          }
          return [accommodation, unitType, unit];
        }
      }
    }

    return [null, null, null];
  }, [accommodations, unitTypes, unitUuid]);

  const isDataInvalid =
    unitUuid === null ||
    fromIso === null ||
    toIso === null ||
    accommodation === null ||
    unitType === null ||
    unit === null;

  const quotesQuery = trpc.pms.rate.getQuotes.useQuery(
    isDataInvalid
      ? []
      : [
          {
            unitTypeUuid: unitType.uuid,
            adults: adultsNumber,
            children: childrenNumber,
            from: fromIso,
            to: toIso,
          },
        ],
  );

  useEffect(() => {
    if (unitType !== null) {
      setAdultsNumber(unitType.paxMax);
      setChildrenNumber(0);
    }
  }, [unitType]);

  if (isDataInvalid) {
    return null;
  }

  return (
    <Modal title={t('pms:booking.quickBooking')} isOpen={isOpen} onClose={onClose}>
      <div className='row mb-2'>
        <div className='col-12 col-md-6'>
          {accommodation.name} <IconChevronRight size='1em' /> {unitType.name}{' '}
          <IconChevronRight size='1em' /> {unit.name}
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-12 col-md-6'>
          <div>
            <FormInput
              name='from'
              type='text'
              title={t('pms:booking.form.from')}
              defaultValue={fromIso}
              disabled
            />
          </div>
          <div>
            <FormInput
              name='to'
              type='text'
              title={t('pms:booking.form.to')}
              defaultValue={toIso}
              disabled
            />
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <PaxSelector
            adultsPax={adultsNumber}
            childrenPax={childrenNumber}
            onChange={(a, c) => {
              setAdultsNumber(a);
              setChildrenNumber(c);
            }}
          />
        </div>
        {quotesQuery.isLoading ? (
          <LoadingSpinner center cardBody />
        ) : quotesQuery.error ? (
          <ErrorAlert errors={quotesQuery.error} />
        ) : (
          quotesQuery.data.length === 1 && (
            <div className='col-12'>
              <label className='form-label'>{t('pms:booking.form.rateOption')}</label>
              <div className='form-selectgroup form-selectgroup-boxes d-flex flex-column'>
                <label className='form-selectgroup-item flex-fill'>
                  <input
                    type='radio'
                    className='form-selectgroup-input'
                    checked={rateOptionUuid === null}
                    onChange={() => {
                      setRateOptionUuid(null);
                      setRateAmount(null);
                    }}
                  />
                  <div className='form-selectgroup-label d-flex align-items-center p-3'>
                    <div className='me-3'>
                      <span className='form-selectgroup-check'></span>
                    </div>
                    <div>{t('pms:booking.form.noRateOption')}</div>
                  </div>
                </label>
                {quotesQuery.data[0].options.map((option) => (
                  <label
                    className='form-selectgroup-item flex-fill'
                    key={`rate-option-${option.optionUuid}`}
                  >
                    <input
                      type='radio'
                      className='form-selectgroup-input'
                      checked={rateOptionUuid === option.optionUuid}
                      onChange={() => {
                        setRateOptionUuid(option.optionUuid);
                        setRateAmount(option.totalPrice);
                      }}
                    />
                    <div className='form-selectgroup-label d-flex align-items-center p-3'>
                      <div className='me-3'>
                        <span className='form-selectgroup-check'></span>
                      </div>
                      <div>
                        {`${rateOptions.find((ro) => ro.uuid === option.optionUuid)?.name} - ${
                          option.totalPrice
                        }€`}
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <BookingForm
        accommodationUuid={accommodation.uuid}
        selectedUnits={[
          {
            unitUuid,
            from: LocalDate.parse(fromIso),
            arrivalTime: null,
            to: LocalDate.parse(toIso),
            departureTime: null,
            adults: adultsNumber,
            children: childrenNumber,
            rateOptionUuid,
            rateAmount,
          },
        ]}
        callback={onClose}
      />
    </Modal>
  );
}

function getUnitBooking(unitUuid: string, bookings: Booking[], date: DateTime) {
  for (const booking of bookings) {
    for (const bookingUnit of booking.bookingUnits) {
      if (bookingUnit.unitUuid === unitUuid) {
        const arrivalDate = DateTime.fromISO(bookingUnit.arrivalDate);
        const departureDate = DateTime.fromISO(bookingUnit.departureDate);
        if (arrivalDate <= date && departureDate > date) return { booking, bookingUnit };
      }
    }
  }
  return null;
}

function HeaderDaysMonthsRows({
  startDate,
  endDate,
  todayDate,
  unitNamesColumnWidth,
}: {
  startDate: DateTime;
  endDate: DateTime;
  todayDate: DateTime;
  unitNamesColumnWidth: number;
}) {
  const headerDayColumns = [
    <th
      key={'Planning-headerDayColumn-start'}
      scope='col'
      style={{ width: unitNamesColumnWidth }}
    />,
  ];
  const headerMonthColumns = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    const currentIsoDate = currentDate.toISODate();

    const isCurrentDateToday = Interval.fromDateTimes(todayDate, currentDate).length('days') === 0;
    const isCurrentDateSunday = currentDate.weekday === 7;
    let thClassName = 'px-0 text-center';
    if (isCurrentDateToday) thClassName += ' bg-dark text-white';
    if (isCurrentDateSunday) thClassName += ' bg-danger text-white';
    headerDayColumns.push(
      <th
        key={`planning-headerDayColumn-${currentIsoDate}`}
        scope='col'
        style={{ width: COLUMN_WIDTH_PX }}
        className={thClassName}
      >
        {currentDate.weekdayShort![0]}
        <br />
        {currentDate.day}
      </th>,
    );

    if (currentDate === startDate) {
      const daysRemaining = currentDate.daysInMonth! - currentDate.day + 2;
      headerMonthColumns.push(
        <th
          key={`planning-headerMonthColumn-${currentIsoDate}`}
          scope='col'
          colSpan={daysRemaining}
        >
          <span style={{ position: 'sticky', left: '1.5rem' }}>
            {currentDate.monthLong} {currentDate.year}
          </span>
        </th>,
      );
    } else if (currentDate.day === 1) {
      const isLastMonth = currentDate.month === endDate.month && currentDate.year === endDate.year;
      const daysRemaining = isLastMonth ? endDate.day : currentDate.daysInMonth;
      headerMonthColumns.push(
        <th
          key={`planning-headerMonthColumn-${currentIsoDate}`}
          scope='col'
          colSpan={daysRemaining}
        >
          <span style={{ position: 'sticky', left: '1.5rem' }}>
            {currentDate.monthLong} {currentDate.year}
          </span>
        </th>,
      );
    }

    currentDate = currentDate.plus({ days: 1 });
  }

  return (
    <>
      <tr>{headerDayColumns}</tr>
      <tr>{headerMonthColumns}</tr>
    </>
  );
}

function getPlanningData({
  isFetching,
  unitTypes,
  units,
  rates,
  bookings,
  rateOptionUuid,
  startDateIso,
  endDateIso,
  navigate,
  quickBookingUnit,
  quickBookingFrom,
  quickBookingToHover,
  setQuickBookingToHover,
  onAvailableUnitDateClick,
  showPaymentNeeded,
  showCheckInOutStatus,
  getBookingUnitBackgroundColor,
}: {
  isFetching: boolean;
  unitTypes: UnitType[];
  units: Unit[];
  rates: Rate[];
  bookings: Booking[];
  rateOptionUuid: string | null;
  startDateIso: string;
  endDateIso: string;
  navigate: (to: string) => void;
  quickBookingUnit: string | null;
  quickBookingFrom: string | null;
  quickBookingToHover: string | null;
  setQuickBookingToHover: (date: string | null) => void;
  onAvailableUnitDateClick: (unitUuid: string, date: string) => void;
  showPaymentNeeded: boolean;
  showCheckInOutStatus: boolean;
  getBookingUnitBackgroundColor: ({
    checkInDate,
    checkOutDate,
  }: {
    checkInDate: string | null;
    checkOutDate: string | null;
  }) => string;
}) {
  const startDate = DateTime.fromISO(startDateIso);
  const endDate = DateTime.fromISO(endDateIso);

  const ratesByUnitType = new Map<string, Rate[]>();
  rates.forEach((rate) => {
    const rates = ratesByUnitType.get(rate.unitType.uuid) || [];
    rates.push(rate);
    ratesByUnitType.set(rate.unitType.uuid, rates);
  });

  const getUnitRate = ({ unitTypeUuid, date: d }: { unitTypeUuid: string; date: DateTime }) => {
    const date = d.toISODate()!;
    const allUnitTypeRates = ratesByUnitType.get(unitTypeUuid) || [];
    const rate = allUnitTypeRates.find(
      (r) =>
        rateOptionUuid !== null &&
        r.optionUuid === rateOptionUuid &&
        r.season.dateRanges.some((dr) => dr.from <= date && dr.to >= date),
    );
    return rate?.price;
  };

  let currentDate = startDate;
  const bookingBookingUnitsInCalendar: string[] = [];

  const unitTypesColumns: { [key: string]: ReactNode[] } = {};
  const unitsColumns: { [key: string]: ReactNode[] } = {};

  unitTypes.forEach((unitType) => {
    unitTypesColumns[unitType.uuid] = [
      <td
        key={`planning-unit-type-${unitType.uuid}`}
        className='bg-blue-lt text-truncate'
        colSpan={2}
        style={{ position: 'sticky', left: '0' }}
      >
        {unitType.name}
      </td>,
    ];
  });

  units.forEach((unit) => {
    unitsColumns[unit.uuid] = [
      <td
        key={`planning-unit-${unit.uuid}`}
        className='bg-blue-lt text-truncate'
        style={{ position: 'sticky', left: '0px', zIndex: 1 }}
      >
        {unit.name}
      </td>,
    ];
  });

  while (currentDate <= endDate) {
    const currentIsoDate = currentDate.toISODate();

    if (currentDate !== startDate) {
      for (const unitType of unitTypes) {
        unitTypesColumns[unitType.uuid].push(
          <td
            key={`planning-unit-type-${unitType.uuid}-${currentIsoDate}`}
            className='bg-blue-lt text-center'
          >
            <span className='text-truncate'>
              {getUnitRate({
                unitTypeUuid: unitType.uuid,
                date: currentDate,
              })}
            </span>
          </td>,
        );
      }
    }

    for (const unit of units) {
      const currentBooking = getUnitBooking(unit.uuid, bookings, currentDate);
      if (currentBooking === null) {
        unitsColumns[unit.uuid].push(
          <td
            key={`planning-unit-${unit.uuid}-${currentIsoDate}`}
            onClick={() => onAvailableUnitDateClick(unit.uuid, currentIsoDate!)}
            onMouseEnter={() => {
              if (quickBookingUnit === unit.uuid) {
                setQuickBookingToHover(currentIsoDate);
              }
            }}
            style={{ cursor: 'pointer' }}
            className={`${
              quickBookingUnit === unit.uuid &&
              (quickBookingFrom === currentIsoDate! ||
                (quickBookingFrom !== null &&
                  quickBookingFrom <= currentIsoDate! &&
                  quickBookingToHover !== null &&
                  quickBookingToHover > currentIsoDate!))
                ? 'bg-info'
                : ''
            }`}
          >
            {isFetching && <span className='animated-dots' />}
          </td>,
        );
      } else if (!bookingBookingUnitsInCalendar.includes(currentBooking.bookingUnit.uuid)) {
        bookingBookingUnitsInCalendar.push(currentBooking.bookingUnit.uuid);
        const remainingDays =
          Math.floor(
            Interval.fromDateTimes(
              currentDate,
              DateTime.fromISO(currentBooking.bookingUnit.departureDate),
            ).length('days'),
          ) - 1;
        const colSpan =
          Math.min(
            remainingDays,
            Math.floor(Interval.fromDateTimes(currentDate, endDate).length('days')),
          ) + 1;
        const bookingLink = `/pms/bookings/edit/${currentBooking.booking.uuid}`;
        const tooltipId = `booking-tooltip-${currentBooking.bookingUnit.uuid}`;
        unitsColumns[unit.uuid].push(
          <td
            key={`planning-unit-${unit.uuid}-${currentIsoDate}`}
            data-tooltip-id={tooltipId}
            className='py-1 px-0'
            colSpan={colSpan}
            onClick={() => navigate(bookingLink)}
            style={{
              backgroundColor: getBookingUnitBackgroundColor(currentBooking.bookingUnit),
              color: 'white',
              cursor: 'pointer',
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <span
              className='px-1'
              style={{
                verticalAlign: 'middle',
                whiteSpace: 'nowrap',
              }}
            >
              <Link to={bookingLink} className='text-white' onClick={(e) => e.stopPropagation()}>
                <b>{currentBooking.booking.lastName}</b> {currentBooking.booking.firstName}
              </Link>
              {(showPaymentNeeded || showCheckInOutStatus) && <br />}
              {showPaymentNeeded && (
                <>
                  {isFullyPaid(currentBooking.booking) ? (
                    <span className='ms-1' title='Fully paid'>
                      <IconCash size='1em' />
                    </span>
                  ) : (
                    <span className='ms-1' title='Payment needed'>
                      <IconCashOff size='1em' />
                    </span>
                  )}
                </>
              )}
              {showCheckInOutStatus && (
                <>
                  {currentBooking.bookingUnit.checkInDate && (
                    <span className='ms-1' title='Checked-in'>
                      <IconTransferIn size='1em' />
                    </span>
                  )}
                  {currentBooking.bookingUnit.checkOutDate && (
                    <span className='ms-1' title='Checked-out'>
                      <IconTransferOut size='1em' />
                    </span>
                  )}
                </>
              )}
            </span>
          </td>,
        );
      }
    }
    currentDate = currentDate.plus({ days: 1 });
  }

  return {
    unitTypesColumns,
    unitsColumns,
  };
}

function Tooltips({ bookings }: { bookings: Booking[] }) {
  const { t } = useTranslation(['pms']);

  const bookingUnits = bookings.flatMap((booking) =>
    booking.bookingUnits.map((unit) => ({ booking, bookingUnit: unit })),
  );
  return (
    <>
      {bookingUnits.map((currentBooking) => {
        const tooltipId = `booking-tooltip-${currentBooking.bookingUnit.uuid}`;
        const notes = getArrayOfStringsFromLinedString(currentBooking.booking.notes);
        return (
          <Tooltip id={tooltipId} place='right' style={{ backgroundColor: '#000', color: '#FFF' }}>
            <p>
              <b>{currentBooking.booking.lastName}</b> {currentBooking.booking.firstName}
              <br />
              <div className='d-flex justify-content-between gap-2'>
                <span>
                  <IconTransferIn size='1em' />{' '}
                  {DateTime.fromISO(currentBooking.bookingUnit.arrivalDate).toLocaleString(
                    DateTime.DATE_MED,
                  )}
                </span>
                {currentBooking.bookingUnit.arrivalTime && (
                  <span>
                    <IconClock size='1em' /> {currentBooking.bookingUnit.arrivalTime}
                  </span>
                )}
              </div>
              <div className='d-flex justify-content-between gap-2'>
                <span>
                  <IconTransferOut size='1em' />{' '}
                  {DateTime.fromISO(currentBooking.bookingUnit.departureDate).toLocaleString(
                    DateTime.DATE_MED,
                  )}
                </span>
                {currentBooking.bookingUnit.departureTime && (
                  <span>
                    <IconClock size='1em' /> {currentBooking.bookingUnit.departureTime}
                  </span>
                )}
              </div>
            </p>
            <p>
              <IconUsers size='1em' /> {currentBooking.bookingUnit.adultsNumber} +{' '}
              {currentBooking.bookingUnit.childrenNumber}
            </p>
            <p>
              {isFullyPaid(currentBooking.booking) ? (
                <span>
                  <IconCash size='1em' /> {t('pms:calendar.tooltips.fullyPaid')}
                </span>
              ) : (
                <span>
                  <IconCashOff size='1em' className='text-muted' />{' '}
                  {t('pms:calendar.tooltips.paymentNeeded')}
                </span>
              )}
              <br />
              {currentBooking.bookingUnit.checkInDate ? (
                <span>
                  <IconTransferIn size='1em' /> {t('pms:calendar.tooltips.checkedIn')}
                </span>
              ) : (
                <span>
                  <IconTransferIn size='1em' className='text-muted' />{' '}
                  {t('pms:calendar.tooltips.notCheckedIn')}
                </span>
              )}
              <br />
              {currentBooking.bookingUnit.checkOutDate ? (
                <span>
                  <IconTransferOut size='1em' /> {t('pms:calendar.tooltips.checkedOut')}
                </span>
              ) : (
                <span>
                  <IconTransferOut size='1em' className='text-muted' />{' '}
                  {t('pms:calendar.tooltips.notCheckedOut')}
                </span>
              )}
            </p>
            {notes && (
              <p>
                <b>{t('pms:booking.notes')}</b>
                <br />
                {notes.map((line, i) => (
                  <span key={`bookingUnit-${currentBooking.bookingUnit.uuid}-note-${i}`}>
                    {line}
                    {i < notes.length - 1 && <br />}
                  </span>
                ))}
              </p>
            )}
          </Tooltip>
        );
      })}
    </>
  );
}

function Overbookings({
  overbookings,
}: {
  overbookings: {
    unitUuid: string;
    date: LocalDate;
    bookings: Booking[];
  }[];
}) {
  const { t } = useTranslation(['pms']);
  const { unitTypes } = usePmsContext();

  if (overbookings.length === 0) {
    return null;
  }

  const overbookingsByUnit = overbookings.reduce((acc, overbooking) => {
    const overbookings = acc.get(overbooking.unitUuid) || [];
    overbookings.push(overbooking);
    acc.set(overbooking.unitUuid, overbookings);
    return acc;
  }, new Map<string, { date: LocalDate; bookings: Booking[] }[]>());

  return (
    <div className='alert alert-danger bg-white' role='alert'>
      <h4 className='alert-title'>{t('pms:calendar.overbooking.title')}</h4>
      <div className='text-secondary'>
        <ul>
          {Array.from(overbookingsByUnit).map(([unitUuid, overbookings]) => {
            const { unitName, unitTypeName } = getUnitDataById(unitTypes, unitUuid);

            return (
              <li key={`overbooking-${unitUuid}`}>
                <b>
                  {unitTypeName} - {unitName}
                </b>
                <ul>
                  {overbookings.map((overbooking) => (
                    <li key={`overbooking-${unitUuid}-${overbooking.date}`}>
                      <b>{localDateToLocaleDateString(overbooking.date)}</b> -{' '}
                      {t('pms:calendar.overbooking.booking', {
                        count: overbooking.bookings.length,
                      })}
                      <ul>
                        {overbooking.bookings.map((booking) => (
                          <li key={`overbooking-${unitUuid}-${overbooking.date}-${booking.uuid}`}>
                            <Link to={`/pms/bookings/edit/${booking.uuid}`}>
                              Booking: {booking.lastName} {booking.firstName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function isFullyPaid(booking: Booking) {
  const amountPaid = booking.bookingPayments.reduce((acc, payment) => acc + payment.totalAmount, 0);

  const totalRates = booking.bookingUnits.reduce(
    (acc, unit) => acc + (unit.rateAmount === null ? 0 : unit.rateAmount),
    0,
  );
  const totalCharges = booking.bookingCharges.reduce((acc, charge) => acc + charge.totalAmount, 0);
  const amountToPay = totalRates + totalCharges;

  return amountPaid === amountToPay;
}
