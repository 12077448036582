import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

export default function ConfigurationIndexItem({
  to,
  title,
  hint,
}: {
  to: string;
  title: string;
  hint: string;
}) {
  return (
    <div className='col-md-6 mb-3'>
      <Link to={to} className='card card-link'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <b>{title}</b>
            <IconArrowRight />
          </div>
          <i className='text-muted'>{hint}</i>
        </div>
      </Link>
    </div>
  );
}
