import { IconArrowRight } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { BookingUnit } from '../../../api/bookings/bookings';
import { UnitType } from '../../../api/units/unit-types';
import CheckInOutButton from '../../../components/CheckInOutButton';
import { getArrayOfStringsFromLinedString } from '../../../../../utils/string';

interface BookingListItemProps {
  bookingUnit: BookingUnit;
  unitTypes: UnitType[];
  isFirst: boolean;
}

function getUnitById(unitUuid: string, unitTypes: UnitType[]) {
  return unitTypes.flatMap((unitType) => unitType.units).find((unit) => unit.uuid === unitUuid);
}

function BookingUnitListItem({ bookingUnit, unitTypes, isFirst }: BookingListItemProps) {
  const arrivalDate = DateTime.fromISO(bookingUnit.arrivalDate).toLocaleString(DateTime.DATE_MED);
  const departureDate = DateTime.fromISO(bookingUnit.departureDate).toLocaleString(
    DateTime.DATE_MED,
  );
  const nightsNumber = DateTime.fromISO(bookingUnit.departureDate)
    .diff(DateTime.fromISO(bookingUnit.arrivalDate), 'days')
    .as('days');

  const adultsString = bookingUnit.adultsNumber ?? 'N/D';
  const childrenString = bookingUnit.childrenNumber ?? 'N/D';
  const borderTop = isFirst ? '' : 'border-top';

  const now = DateTime.now();
  const isCheckInToday = DateTime.fromISO(bookingUnit.arrivalDate).hasSame(now, 'day');
  const isCheckOutToday = DateTime.fromISO(bookingUnit.departureDate).hasSame(now, 'day');

  const notes = getArrayOfStringsFromLinedString(bookingUnit.booking.notes);

  return (
    <div className={`d-flex justify-content-between gap-2 py-2 ${borderTop}`}>
      <div>
        <div>
          <strong>{getUnitById(bookingUnit.unitUuid, unitTypes)?.name}</strong>
        </div>
        <div>
          <b>{bookingUnit.booking.lastName}</b> {bookingUnit.booking.firstName}{' '}
          {`(${adultsString} + ${childrenString})`}
        </div>
        <div>{`${arrivalDate} - ${departureDate} (${nightsNumber})`}</div>
        <CheckInOutButton
          bookingUnitUuid={bookingUnit.uuid}
          type='checkIn'
          show={isCheckInToday}
          datetime={bookingUnit.checkInDate}
        />
        <CheckInOutButton
          bookingUnitUuid={bookingUnit.uuid}
          type='checkOut'
          show={isCheckOutToday}
          datetime={bookingUnit.checkOutDate}
        />
        {notes && (
          <div>
            <small>
              <b>Notes:</b>{' '}
              {notes.map((note, index) => (
                <span key={`note-${index}`}>
                  {note}
                  {index < notes.length - 1 ? <br /> : ''}
                </span>
              ))}
            </small>
          </div>
        )}
      </div>
      <div className='text-end d-flex flex-row-reverse align-items-center'>
        <Link to={`/pms/bookings/edit/${bookingUnit.booking.uuid}`} className='btn btn-icon'>
          <IconArrowRight size='1em' />
        </Link>
      </div>
    </div>
  );
}

export default BookingUnitListItem;
