import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
  IconChevronRight,
  IconCircleCheck,
  IconCircleOff,
  IconDownload,
  IconExternalLink,
  IconPencil,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { z } from 'zod';
import QRCode from 'react-qr-code';
import { createMutation } from '../../../../../utils/api';
import { trpc } from '../../../../../utils/trpc';
import Breadcrumbs from '../../../../common/components/Breadcrumbs';
import Card from '../../../../common/components/Card';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import Modal from '../../../../common/components/Modal';
import Page from '../../../../common/components/dashboard/Page';
import Button from '../../../../common/components/form/Button';
import { useMenuContext } from '../../../Context';
import { MenuItem, MenuItemPrice, MenuPage, MenuSection } from '../../../api/menu-page';
import MenuItemForm from '../../../components/MenuItemForm';
import MenuPageForm from '../../../components/MenuPageForm';
import MenuSectionForm from '../../../components/MenuSectionForm';
import LanguageSelector from '../../../../common/components/LanguageSelector';
import { useLanguageContext } from '../../../../common/contexts/LanguageContext';
import MenuItemPriceForm from '../../../components/MenuItemPriceForm';
import { formatPriceInSmallestUnitToString } from '../../../../../utils/currency';
import UploadImageModal from '../../../../common/components/files/UploadImageModal';
import UnlinkOrDeleteElementButton from '../../../components/UnlinkOrDeleteElementButton';
import MenuSectionReorderElementsForm from '../../../components/MenuSectionReorderElementsForm';
import TranslationNotAvailableError from '../../../components/TranslationNotAvailableError';
import { useTranslation } from 'react-i18next';
import MenuDownloadPdf from '../../../components/MenuDownloadPdf';
import { getMenuPageUrl } from '../../../utils';
import PublicMenuImagePreview from '../../../components/PublicMenuImagePreview';

export default function MenuPageEditPage() {
  const { id: menuPageUuid } = useParams();
  const { menuPages, menuSections } = useMenuContext();
  const { selectedLanguage } = useLanguageContext();

  const menuPage = menuPages.find((menuPage: MenuPage) => menuPage.uuid === menuPageUuid);

  if (!menuPage) {
    return <ErrorAlert errors='Menu not found' />;
  }

  const pathToLabel = (path: string) => {
    switch (path) {
      case 'menu':
        return 'Menu';
      case 'menus':
        return 'Menus';
      case 'edit':
        return undefined;
    }

    const { success: isUuid } = z.string().uuid().safeParse(path);
    if (isUuid) {
      const mp = menuPages.find((_mp) => _mp.uuid === path);
      if (mp) {
        return mp.name;
      }

      const ms = menuSections.find((_ms) => _ms.uuid === path);
      if (ms) {
        return ms.name.translations[selectedLanguage.code] || '[n/a]';
      }
    }

    return undefined;
  };

  return (
    <Page title={menuPage.name}>
      <Breadcrumbs startFromPath='menu' pathToLabel={pathToLabel} />
      <div className='mb-3' />
      <MenuPageEditForm menuPage={menuPage} />
    </Page>
  );
}

function MenuPageEditForm({ menuPage }: { menuPage: MenuPage }) {
  const { t } = useTranslation(['menu']);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showDownloadPdfModal, setShowDownloadPdfModal] = useState(false);

  const url = getMenuPageUrl(menuPage);
  const urlWithoutProtocol = url.replace(/(^\w+:|^)\/\//, '');

  const downloadQrCode = () => {
    const svg = document.getElementById(`qrcode-menu-${menuPage.uuid}`);
    if (!svg) {
      return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `QR code menu ${menuPage.slug}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <div className='d-flex flex-wrap gap-2 mb-2'>
        <Button type='button' className='btn' onClick={() => setShowEditModal(true)}>
          {t('menu:editMenuPage')}
        </Button>
        <Button type='button' className='btn' onClick={() => setShowLinkModal(true)}>
          {t('menu:linkAndQrCode')}
        </Button>
        <Button type='button' className='btn' onClick={() => setShowDownloadPdfModal(true)}>
          {t('menu:pdf.downloadPdf')}
        </Button>
      </div>
      <LanguageSelector />
      <Modal
        title={t('menu:editMenuPage')}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        size='sm'
      >
        <MenuPageForm
          action='update'
          menuPage={menuPage}
          onCancel={() => setShowEditModal(false)}
          onSuccess={() => setShowEditModal(false)}
        />
      </Modal>
      <Modal
        title={t('menu:pdf.downloadPdf')}
        isOpen={showDownloadPdfModal}
        onClose={() => setShowDownloadPdfModal(false)}
        size='sm'
      >
        <MenuDownloadPdf menuPage={menuPage} />
      </Modal>
      <Modal
        title={t('menu:linkAndQrCode')}
        isOpen={showLinkModal}
        onClose={() => setShowLinkModal(false)}
        size='sm'
      >
        <Link to={url} target='_blank' rel='noreferrer' className='btn w-100'>
          {urlWithoutProtocol}
          <IconExternalLink size='1em' className='ms-2' />
        </Link>
        <Button type='button' className='mt-2 btn w-100' onClick={downloadQrCode}>
          {t('menu:downloadQrCode')} <IconDownload size='1em' className='ms-2' />
        </Button>
        <div className='mt-2 d-flex justify-content-center'>
          <QRCode value={url} size={256} id={`qrcode-menu-${menuPage.uuid}`} />
        </div>
      </Modal>
      <Routes>
        <Route index element={<Navigate to={`section/${menuPage.rootSectionUuid}`} />} />
        <Route path='/section/*' element={<SectionPage sectionUuid={menuPage.rootSectionUuid} />} />
      </Routes>
    </>
  );
}

function SectionPageFromParams() {
  const { sectionUuid } = useParams();

  if (!sectionUuid) {
    return null;
  }

  return <SectionPage sectionUuid={sectionUuid} />;
}

function SectionPage({ sectionUuid }: { sectionUuid: string }) {
  const menuData = useMenuContext();

  const section = menuData.menuSections.find((menuSection) => menuSection.uuid === sectionUuid);

  if (!section) {
    return null;
  }

  return (
    <Routes>
      <Route path='/:sectionUuid/*' element={<SectionPageFromParams />} />
      <Route
        path='/*'
        element={
          <Section
            menuSections={menuData.menuSections}
            menuItems={menuData.menuItems}
            menuItemPrices={menuData.menuItemPrices}
          />
        }
      />
    </Routes>
  );
}

function Section({
  menuSections,
  menuItems,
  menuItemPrices,
}: {
  menuSections: MenuSection[];
  menuItems: MenuItem[];
  menuItemPrices: MenuItemPrice[];
}) {
  const { pathname } = useLocation();
  const { sectionUuid: menuSectionUuid } = useParams();

  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const { getTranslatedString } = useLanguageContext();

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
  };
  const updateSectionElements = createMutation(
    trpc.menu.admin.updateMenuSectionElements,
    mutationOptions,
  );

  const menuSection = menuSections.find(
    (menuSection: MenuSection) => menuSection.uuid === menuSectionUuid,
  );

  if (!menuSection) {
    return <ErrorAlert errors={`Menu section ${menuSectionUuid} not found`} />;
  }

  const title = getTranslatedString(menuSection.name.translations);

  return (
    <>
      <h1>
        <EditSectionButton menuSection={menuSection} /> {title || <TranslationNotAvailableError />}
      </h1>
      <ReorderElementsButton menuSection={menuSection} />
      <div ref={parentRef}>
        {menuSection.elementsUuids.map((elementUuid) => {
          if (elementUuid.type === 'item') {
            return (
              <Item
                key={`item-${elementUuid.uuid}}`}
                menuItemUuid={elementUuid.uuid}
                menuItems={menuItems}
                menuItemPrices={menuItemPrices}
                parentMenuSection={menuSection}
              />
            );
          }

          const sectionName = getTranslatedString(
            menuSections.find((menuSection) => menuSection.uuid === elementUuid.uuid)?.name
              .translations || {},
          );

          return (
            <Card key={`section-${elementUuid.uuid}}`} style={{ marginTop: '1em' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <b>{sectionName || <TranslationNotAvailableError />}</b>
                <div className='ms-auto d-flex flex-column gap-2'>
                  <Link to={`${pathname}/${elementUuid.uuid}`} className='btn btn-icon'>
                    <IconChevronRight size='1em' />
                  </Link>
                  <UnlinkOrDeleteElementButton
                    type='section'
                    parentMenuSection={menuSection}
                    elementUuidToRemove={elementUuid.uuid}
                  />
                </div>
              </div>
            </Card>
          );
        })}
        <div className='mt-2 d-flex justify-content-center gap-2'>
          <AddSubElementsButton
            menuSection={menuSection}
            action='create-new-sub-section'
            menuSections={menuSections}
            menuItems={menuItems}
            updateSectionElements={updateSectionElements}
          />
          <AddSubElementsButton
            menuSection={menuSection}
            action='create-new-sub-item'
            menuSections={menuSections}
            menuItems={menuItems}
            updateSectionElements={updateSectionElements}
          />
        </div>
        <div className='mt-2 d-flex justify-content-center gap-2'>
          <AddSubElementsButton
            menuSection={menuSection}
            action='add-existing-sub-section'
            menuSections={menuSections}
            menuItems={menuItems}
            updateSectionElements={updateSectionElements}
          />
          <AddSubElementsButton
            menuSection={menuSection}
            action='add-existing-sub-item'
            menuSections={menuSections}
            menuItems={menuItems}
            updateSectionElements={updateSectionElements}
          />
        </div>
      </div>
    </>
  );
}

function AddSubElementsButton({
  menuSection,
  action,
  menuSections,
  menuItems,
  updateSectionElements,
}: {
  menuSection: MenuSection;
  action:
    | 'create-new-sub-section'
    | 'create-new-sub-item'
    | 'add-existing-sub-section'
    | 'add-existing-sub-item';
  menuSections: MenuSection[];
  menuItems: MenuItem[];
  updateSectionElements: ReturnType<
    typeof createMutation<typeof trpc.menu.admin.updateMenuSectionElements>
  >;
}) {
  const { t } = useTranslation(['menu']);
  const [showCreateNewModal, setShowCreateNewModal] = useState(false);
  const [showLinkExistingModal, setShowLinkExistingModal] = useState(false);
  const { getTranslatedString } = useLanguageContext();
  const { isRootSection } = useMenuContext();

  const isSection = action === 'create-new-sub-section' || action === 'add-existing-sub-section';
  const elements = isSection ? menuSections.filter((e) => !isRootSection(e.uuid)) : menuItems;

  const isCreateNew = action === 'create-new-sub-section' || action === 'create-new-sub-item';
  const setShowModal = isCreateNew ? setShowCreateNewModal : setShowLinkExistingModal;

  let label: string;
  switch (action) {
    case 'create-new-sub-section':
      label = t('menu:createNewSubSection');
      break;
    case 'create-new-sub-item':
      label = t('menu:createNewSubItem');
      break;
    case 'add-existing-sub-section':
      label = t('menu:addExistingSubSection');
      break;
    case 'add-existing-sub-item':
      label = t('menu:addExistingSubItem');
      break;
  }

  return (
    <>
      <Button
        type='button'
        className={`btn ${!isCreateNew ? 'btn-sm' : ''}`}
        style={{ whiteSpace: 'normal' }}
        onClick={() => {
          setShowModal(true);
        }}
        isLoading={updateSectionElements.isLoading}
      >
        {label}
      </Button>
      <Modal title={label} isOpen={showCreateNewModal} onClose={() => setShowModal(false)}>
        {isSection ? (
          <MenuSectionForm
            action='create'
            onCancel={() => setShowModal(false)}
            onSuccess={async (newMenuSectionUuid) => {
              updateSectionElements.mutate({
                uuid: menuSection.uuid,
                elementsUuids: [
                  ...menuSection.elementsUuids.map((e) => e.uuid),
                  newMenuSectionUuid,
                ],
              });
              setShowModal(false);
            }}
          />
        ) : (
          <MenuItemForm
            action='create'
            onCancel={() => setShowModal(false)}
            onSuccess={async (newMenuSectionUuid) => {
              updateSectionElements.mutate({
                uuid: menuSection.uuid,
                elementsUuids: [
                  ...menuSection.elementsUuids.map((e) => e.uuid),
                  newMenuSectionUuid,
                ],
              });
              setShowModal(false);
            }}
          />
        )}
      </Modal>
      <Modal title={label} isOpen={showLinkExistingModal} onClose={() => setShowModal(false)}>
        <div className='list-group'>
          {elements.map((element) => {
            const elementName = getTranslatedString(element.name.translations);
            return (
              <div
                key={`selector-${element.uuid}`}
                className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
              >
                <span>{elementName || <TranslationNotAvailableError />}</span>
                <Button
                  type='button'
                  className='btn ms-2'
                  onClick={() => {
                    updateSectionElements.mutate({
                      uuid: menuSection.uuid,
                      elementsUuids: [
                        ...menuSection.elementsUuids.map((e) => e.uuid),
                        element.uuid,
                      ],
                    });
                    setShowModal(false);
                  }}
                >
                  <IconPlus size='1em' />
                </Button>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

function AddPriceToItemButton({ menuItem }: { menuItem: MenuItem }) {
  const { t } = useTranslation(['menu']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type='button' className='btn' onClick={() => setShowModal(true)}>
        {t('menu:priceForm.addPrice')}
      </Button>
      <Modal
        title={t('menu:priceForm.addPrice')}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <MenuItemPriceForm
          action='create'
          menuPageItemUuid={menuItem.uuid}
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
}

function EditSectionButton({ menuSection }: { menuSection: MenuSection }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type='button'
        className='btn btn-icon'
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IconPencil size='1em' />
      </Button>
      <Modal title='Edit section' isOpen={showModal} onClose={() => setShowModal(false)} size='sm'>
        <MenuSectionForm
          action='update'
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
          menuSection={menuSection}
        />
      </Modal>
    </>
  );
}

function ReorderElementsButton({ menuSection }: { menuSection: MenuSection }) {
  const { t } = useTranslation(['menu']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type='button'
        className='btn'
        onClick={() => {
          setShowModal(true);
        }}
      >
        {t('menu:reorderElements')}
      </Button>
      <Modal
        title={t('menu:reorderElements')}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size='sm'
      >
        <MenuSectionReorderElementsForm
          onClose={() => setShowModal(false)}
          menuSection={menuSection}
        />
      </Modal>
    </>
  );
}

function Item({
  menuItemUuid,
  menuItems,
  menuItemPrices,
  parentMenuSection,
}: {
  menuItemUuid: string;
  menuItems: MenuItem[];
  menuItemPrices: MenuItemPrice[];
  parentMenuSection: MenuSection;
}) {
  const { t } = useTranslation(['menu']);
  const { getTranslatedString } = useLanguageContext();
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false);

  const trpcContext = trpc.useUtils();

  const createUploadUrl = createMutation(trpc.menu.admin.getUploadImageMenuItemUrl);
  const deleteImage = createMutation(trpc.menu.admin.deleteImageMenuItem, {
    invalidate: [trpcContext.menu],
  });

  const uploadImageCallback = async () => {
    await trpcContext.menu.invalidate();
  };

  const menuItem = menuItems.find((menuItem: MenuItem) => menuItem.uuid === menuItemUuid);

  if (!menuItem) {
    return <ErrorAlert errors='Menu item not found' />;
  }

  const name = getTranslatedString(menuItem.name.translations);
  const description = getTranslatedString(menuItem.description.translations);

  const itemPrices = useMemo(() => {
    return menuItemPrices.filter(
      (menuItemPrice) => menuItemPrice.menuPageItemUuid === menuItemUuid,
    );
  }, [menuItemPrices, menuItemUuid]);

  return (
    <Card style={{ marginTop: '1em' }}>
      <div className='mb-2 d-flex justify-content-start gap-2'>
        <div
          style={{
            width: '100px',
            textAlign: 'center',
          }}
        >
          {menuItem.imageUrl ? (
            <>
              <div className='d-flex justify-content-center gap-2 mb-2'>
                <Button
                  type='button'
                  className='btn btn-sm'
                  onClick={() => setIsUploadImageModalOpen(true)}
                >
                  {t('menu:uploadImageButton.change')}
                </Button>
                <Button
                  className='btn btn-sm btn-icon'
                  onClick={() => {
                    if (confirm('Are you sure you want to delete this image?')) {
                      deleteImage.mutate({ itemUuid: menuItem.uuid });
                    }
                  }}
                  isLoading={deleteImage.isLoading}
                  hideContentWhenLoading
                >
                  <IconTrash size='1em' />
                </Button>
              </div>
              <PublicMenuImagePreview src={menuItem.imageUrl} />
            </>
          ) : (
            <Button
              type='button'
              className='btn'
              onClick={() => setIsUploadImageModalOpen(true)}
              style={{ width: '100px', height: '100px', whiteSpace: 'normal' }}
            >
              {t('menu:uploadImageButton.add')}
            </Button>
          )}
          <UploadImageModal
            title={
              menuItem.imageUrl
                ? t('menu:uploadImageButton.change')
                : t('menu:uploadImageButton.add')
            }
            defaultImageUrl={menuItem.imageUrl}
            isOpen={isUploadImageModalOpen}
            onClose={() => setIsUploadImageModalOpen(false)}
            getPresignedUrl={(fileData) =>
              createUploadUrl.mutateAsync({ ...fileData, itemUuid: menuItem.uuid })
            }
            callback={uploadImageCallback}
          />
        </div>
        <div className='overflow-hidden'>
          <b>{name || <TranslationNotAvailableError />}</b>
          <br />
          <span>{description}</span>
        </div>
        <div className='ms-auto d-flex flex-column gap-2'>
          <EditItemButton menuItem={menuItem} />
          <UnlinkOrDeleteElementButton
            type='item'
            parentMenuSection={parentMenuSection}
            elementUuidToRemove={menuItem.uuid}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {itemPrices.map((itemPrice) => (
            <ItemPrice key={itemPrice.uuid} itemPrice={itemPrice} />
          ))}
          <div className='mt-2 text-center'>
            <AddPriceToItemButton menuItem={menuItem} />
          </div>
        </div>
      </div>
    </Card>
  );
}

function EditItemButton({ menuItem }: { menuItem: MenuItem }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type='button'
        className='btn btn-icon'
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IconPencil size='1em' />
      </Button>
      <Modal title='Edit item' isOpen={showModal} onClose={() => setShowModal(false)} size='sm'>
        <MenuItemForm
          action='update'
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
          menuItem={menuItem}
        />
      </Modal>
    </>
  );
}

function ItemPrice({ itemPrice }: { itemPrice: MenuItemPrice }) {
  const { t } = useTranslation(['menu']);
  const { getTranslatedString } = useLanguageContext();

  const name = getTranslatedString(itemPrice.name.translations);

  return (
    <Card style={{ marginTop: '1em' }}>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='overflow-hidden'>
          {!itemPrice.isAvailable && (
            <>
              <span className='badge bg-red text-white'>{t('menu:price.unavailable')}</span>
              <br />
            </>
          )}
          {!itemPrice.isDisplayed && (
            <>
              <span className='badge bg-red text-white'>{t('menu:price.notDisplayed')}</span>
              <br />
            </>
          )}
          {name && (
            <>
              {name}
              <br />
            </>
          )}
          {formatPriceInSmallestUnitToString(itemPrice.price, 'EUR')}
        </div>
        <div className='ms-auto d-flex gap-2'>
          <ItemPriceAvailabilityButton itemPrice={itemPrice} />
          <ItemPriceEditButton itemPrice={itemPrice} />
        </div>
      </div>
    </Card>
  );
}

function ItemPriceAvailabilityButton({ itemPrice }: { itemPrice: MenuItemPrice }) {
  const trpcContext = trpc.useUtils();
  const updatePriceAvailability = createMutation(trpc.menu.admin.updateMenuItemPriceAvailability, {
    invalidate: [trpcContext.menu],
  });

  const buttonContent = itemPrice.isAvailable ? (
    <IconCircleCheck size='1em' />
  ) : (
    <IconCircleOff size='1em' />
  );

  return (
    <Button
      type='button'
      className='btn btn-icon'
      onClick={() =>
        updatePriceAvailability.mutate({
          uuid: itemPrice.uuid,
          isAvailable: !itemPrice.isAvailable,
        })
      }
      isLoading={updatePriceAvailability.isLoading}
      hideContentWhenLoading
    >
      {buttonContent}
    </Button>
  );
}

function ItemPriceEditButton({ itemPrice }: { itemPrice: MenuItemPrice }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type='button' className='btn btn-icon' onClick={() => setShowModal(true)}>
        <IconPencil size='1em' />
      </Button>
      <Modal title='Edit price' isOpen={showModal} onClose={() => setShowModal(false)} size='sm'>
        <MenuItemPriceForm
          action='update'
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
          menuItemPrice={itemPrice}
          menuPageItemUuid={itemPrice.menuPageItemUuid}
        />
      </Modal>
    </>
  );
}
