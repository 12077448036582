import { IconPencil } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Booking } from '../../../api/bookings/bookings';
import { UnitType } from '../../../api/units/unit-types';
import { ContactsModalButtons } from '../../../components/booking/ContactsModalButtons';

interface BookingListItemProps {
  booking: Omit<
    Booking,
    'bookingCharges' | 'bookingPayments' | 'referral' | 'notes' | 'accommodationUuid'
  >;
  unitTypes: UnitType[];
  showEdit: boolean;
}

function getUnitById(unitUuid: string, unitTypes: UnitType[]) {
  return unitTypes.flatMap((unitType) => unitType.units).find((unit) => unit.uuid === unitUuid);
}

function BookingListItem({ booking, unitTypes, showEdit }: BookingListItemProps) {
  const bookingPhone = booking.phone;
  const bookingEmail = booking.email;

  return (
    <>
      <tr className='bg-blue-lt text-white'>
        <td colSpan={4}>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              <b>{booking.lastName}</b> {booking.firstName}
            </div>
            <div>
              <ContactsModalButtons
                size='small'
                bookingPhone={bookingPhone}
                bookingEmail={bookingEmail}
              />
            </div>
            {showEdit && (
              <div>
                <Link to={`/pms/bookings/edit/${booking.uuid}`} className='btn btn-light btn-sm'>
                  <IconPencil />
                  Edit
                </Link>
              </div>
            )}
          </div>
        </td>
      </tr>
      {booking.bookingUnits.map((bookingUnit) => (
        <tr key={`booking-list-item-bookingUnit-${bookingUnit.uuid}`}>
          <td>
            <strong className='me-auto ms-2'>
              {getUnitById(bookingUnit.unitUuid, unitTypes)?.name}
            </strong>
          </td>
          <td>{DateTime.fromISO(bookingUnit.arrivalDate).toLocaleString(DateTime.DATE_MED)}</td>
          <td>{DateTime.fromISO(bookingUnit.departureDate).toLocaleString(DateTime.DATE_MED)}</td>
          <td>
            {bookingUnit.adultsNumber ?? 'N/D'} + {bookingUnit.childrenNumber ?? 'N/D'}
          </td>
        </tr>
      ))}
    </>
  );
}

export default BookingListItem;
