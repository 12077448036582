import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import Button from '../../common/components/form/Button';
import { MenuSection } from '../api/menu-page';
import { useMenuContext } from '../Context';
import { useLanguageContext } from '../../common/contexts/LanguageContext';
import TranslationNotAvailableError from './TranslationNotAvailableError';
import { useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import { useTranslation } from 'react-i18next';

type MenuSectionReorderElementsFormProps = {
  onClose: () => void;
  menuSection: MenuSection;
};

export default function MenuSectionReorderElementsForm({
  onClose,
  menuSection,
}: MenuSectionReorderElementsFormProps) {
  const { t } = useTranslation(['menu']);
  const [parentRef] = useAutoAnimate();
  const menuContext = useMenuContext();
  const { getTranslatedString } = useLanguageContext();

  const [sortedElements, setSortedElements] = useState(menuSection.elementsUuids);

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
    onSuccess: onClose,
  };
  const updateElements = createMutation(trpc.menu.admin.updateMenuSectionElements, mutationOptions);

  const handleMoveElementUp = (elementUuid: string) => {
    const elementIndex = sortedElements.findIndex((i) => i.uuid === elementUuid);
    if (elementIndex === 0) {
      return;
    }
    const newSortedElements = [...sortedElements];
    newSortedElements.splice(elementIndex - 1, 0, newSortedElements.splice(elementIndex, 1)[0]);
    setSortedElements(newSortedElements);
  };

  const handleMoveElementDown = (elementUuid: string) => {
    const elementIndex = sortedElements.findIndex((i) => i.uuid === elementUuid);
    if (elementIndex === sortedElements.length - 1) {
      return;
    }
    const newSortedElements = [...sortedElements];
    newSortedElements.splice(elementIndex + 1, 0, newSortedElements.splice(elementIndex, 1)[0]);
    setSortedElements(newSortedElements);
  };

  return (
    <>
      <div className='list-group' ref={parentRef}>
        {sortedElements.map((elementUuid) => {
          let elementNameTranslation: { translations: { [x: string]: string } } | null = null;
          if (elementUuid.type === 'item') {
            elementNameTranslation =
              menuContext.menuItems.find((i) => i.uuid === elementUuid.uuid)?.name || null;
          } else if (elementUuid.type === 'section') {
            elementNameTranslation =
              menuContext.menuSections.find((i) => i.uuid === elementUuid.uuid)?.name || null;
          }
          if (!elementNameTranslation) {
            return null;
          }

          const elementName = getTranslatedString(elementNameTranslation.translations);

          const isFirstChild = sortedElements[0].uuid === elementUuid.uuid;
          const isLastChild = sortedElements[sortedElements.length - 1].uuid === elementUuid.uuid;

          return (
            <div
              key={`reorder-element-${elementUuid.uuid}`}
              className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
            >
              <span>{elementName || <TranslationNotAvailableError />}</span>
              <div>
                <Button
                  type='button'
                  className='btn ms-2'
                  onClick={() => handleMoveElementUp(elementUuid.uuid)}
                  disabled={isFirstChild}
                >
                  <IconArrowUp size='1em' />
                </Button>
                <Button
                  type='button'
                  className='btn ms-2'
                  onClick={() => handleMoveElementDown(elementUuid.uuid)}
                  disabled={isLastChild}
                >
                  <IconArrowDown size='1em' />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <div className='d-flex justify-content-end gap-2 mt-2'>
        <Button type='button' className='btn' onClick={onClose}>
          {t('menu:form.cancel')}
        </Button>
        <Button
          type='button'
          onClick={() => {
            updateElements.mutate({
              uuid: menuSection.uuid,
              elementsUuids: sortedElements.map((i) => i.uuid),
            });
          }}
          isLoading={updateElements.isLoading}
          hideContentWhenLoading
        >
          {t('menu:form.save')}
        </Button>
      </div>
    </>
  );
}
