import { createContext, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { trpc } from '../../utils/trpc';
import ErrorAlert from '../common/components/ErrorAlert';
import LoadingSpinner from '../common/components/LoadingSpinner';
import { Accommodation } from './api/accommodations';
import { RateSeason } from './api/rates/rate-seasons';
import { Rate } from './api/rates/rates';
import { UnitType } from './api/units/unit-types';
import { RateOption } from './api/rates/rate-options';
import { useTranslation } from 'react-i18next';
import { Referral } from './api/referrals';
import { LocaleStorageItemKey, useLocalStorage } from '../common/utils/local-storage';

type PmsContextValue = {
  accommodations: Accommodation[];
  currentAccommodations: Accommodation[];
  setCurrentAccommodationsUuids: (uuids: string[]) => void;
  unitTypes: UnitType[];
  rates: Rate[];
  rateOptions: RateOption[];
  rateSeasons: RateSeason[];
  referrals: Referral[];
};

const initialState: PmsContextValue = {
  accommodations: [],
  currentAccommodations: [],
  setCurrentAccommodationsUuids: () => {},
  unitTypes: [],
  rates: [],
  rateOptions: [],
  rateSeasons: [],
  referrals: [],
};

const PmsContext = createContext<PmsContextValue>(initialState);

export function PmsContextProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation(['pms']);
  const [currentAccommodationsUuids, setCurrentAccommodationsUuids] = useLocalStorage(
    LocaleStorageItemKey.PMS_CURRENT_ACCOMMODATIONS_UUIDS,
    [],
    z.array(z.string().uuid()),
  );
  const [rateOptionUuid, setRateOptionUuid] = useLocalStorage(
    LocaleStorageItemKey.PMS_DEFAULT_RATE_OPTION_UUID,
    null,
    z.string().uuid().nullable(),
  );
  const [currentAccommodations, setCurrentAccommodations] = useState<Accommodation[]>([]);

  const accommodationsQuery = trpc.pms.accommodation.getAccommodations.useQuery();
  const unitTypesQuery = trpc.pms.unitType.getUnitTypes.useQuery();
  const rateOptionsQuery = trpc.pms.rate.listRateOptions.useQuery();
  const rateSeasonsQuery = trpc.pms.rate.listRateSeasons.useQuery();
  const ratesQuery = trpc.pms.rate.listRates.useQuery();
  const referralsQuery = trpc.pms.referral.listReferrals.useQuery();

  useEffect(() => {
    if (accommodationsQuery.data === undefined) {
      return;
    }

    const currentAccommodations = accommodationsQuery.data.filter((accommodation) =>
      currentAccommodationsUuids.includes(accommodation.uuid),
    );

    if (currentAccommodations.length !== currentAccommodationsUuids.length) {
      setCurrentAccommodationsUuids(currentAccommodations.map((a) => a.uuid));
      return;
    }

    setCurrentAccommodations(currentAccommodations);
  }, [currentAccommodationsUuids, accommodationsQuery.data]);

  useEffect(() => {
    const rateOptions = rateOptionsQuery.data;
    if (rateOptions === undefined) {
      return;
    }

    if (rateOptions.length === 0) {
      setRateOptionUuid(null);
      return;
    }

    if (rateOptionUuid === null && rateOptions.length > 0) {
      setRateOptionUuid(rateOptions[0].uuid);
      return;
    }

    // Check if the current rate option is still available
    if (!rateOptions.some((option) => option.uuid === rateOptionUuid)) {
      setRateOptionUuid(rateOptions[0].uuid);
      return;
    }
  }, [rateOptionsQuery.data]);

  if (
    accommodationsQuery.isLoading ||
    unitTypesQuery.isLoading ||
    rateOptionsQuery.isLoading ||
    rateSeasonsQuery.isLoading ||
    ratesQuery.isLoading ||
    referralsQuery.isLoading ||
    (currentAccommodationsUuids.length > 0 &&
      currentAccommodations.length === 0 &&
      accommodationsQuery.data !== undefined)
  ) {
    return <LoadingSpinner text={t('pms:loadingData')} center cardBody />;
  }

  if (
    accommodationsQuery.error ||
    unitTypesQuery.error ||
    rateOptionsQuery.error ||
    rateSeasonsQuery.error ||
    ratesQuery.error ||
    referralsQuery.error
  ) {
    const errors = [
      ...(accommodationsQuery.error ? [accommodationsQuery.error] : []),
      ...(unitTypesQuery.error ? [unitTypesQuery.error] : []),
      ...(rateOptionsQuery.error ? [rateOptionsQuery.error] : []),
      ...(rateSeasonsQuery.error ? [rateSeasonsQuery.error] : []),
      ...(ratesQuery.error ? [ratesQuery.error] : []),
      ...(referralsQuery.error ? [referralsQuery.error] : []),
    ];
    return <ErrorAlert errors={errors} />;
  }

  const accommodations = accommodationsQuery.data;
  const unitTypes = unitTypesQuery.data;
  const rateOptions = rateOptionsQuery.data;
  const rateSeasons = rateSeasonsQuery.data;
  const rates = ratesQuery.data;
  const referrals = referralsQuery.data;

  return (
    <PmsContext.Provider
      value={{
        accommodations,
        currentAccommodations,
        setCurrentAccommodationsUuids,
        unitTypes,
        rateOptions,
        rateSeasons,
        rates,
        referrals,
      }}
    >
      {children}
    </PmsContext.Provider>
  );
}

export const usePmsContext = () => useContext(PmsContext);
