import { IconTrash } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { createMutation } from '../../../../../utils/api';
import { isNotEmpty } from '../../../../../utils/array';
import { trpc } from '../../../../../utils/trpc';
import Button from '../../../../common/components/form/Button';
import FormInput from '../../../../common/components/form/input';
import { FormErrors } from '../../../../common/utils/api';
import { usePmsContext } from '../../../Context';
import { BookingEnginePage } from '../../../api/booking-engine-pages';
import { PaymentMethod } from '../../../api/payment-methods';
import { UnitType } from '../../../api/units/unit-types';
import { isOnlinePaymentMethod } from '../../../utils/payment-method';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import { useTranslation } from 'react-i18next';
import { useCompanyContext } from '../../../../common/contexts/CompanyContext';
import { getNotificationMethodTypeName } from '../../../../common/utils/notification-method';
import TimezoneInput from '../../../../common/components/form/TimezoneInput';

type CreateBookingEnginePageFormProps = {
  action: 'create';
  bookingEnginePageUuid?: undefined;
  bookingEnginePage?: undefined;
  unitTypes: UnitType[];
  paymentMethods: PaymentMethod[];
  onSuccess: () => void;
  onCancel: () => void;
};
type UpdateBookingEnginePageFormProps = {
  action: 'update';
  bookingEnginePageUuid: string;
  bookingEnginePage: BookingEnginePage;
  unitTypes: UnitType[];
  paymentMethods: PaymentMethod[];
  onSuccess: () => void;
  onCancel: () => void;
};
type BookingEnginePageFormProps =
  | CreateBookingEnginePageFormProps
  | UpdateBookingEnginePageFormProps;

export default function BookingEnginePageForm({
  action,
  bookingEnginePageUuid,
  bookingEnginePage,
  unitTypes,
  paymentMethods,
  onSuccess,
  onCancel,
}: BookingEnginePageFormProps) {
  const { t } = useTranslation(['pms']);
  const trpcContext = trpc.useUtils();
  const { notificationMethods } = useCompanyContext();
  const { accommodations } = usePmsContext();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [requestConfirmation] = useState(bookingEnginePage?.requestConfirmation ?? false);
  const [requireOnlinePayment, setRequireOnlinePayment] = useState(
    bookingEnginePage?.requestPayment ?? false,
  );
  const [paymentAmountSettings, setPaymentAmountSettings] = useState(
    bookingEnginePage?.paymentAmountSettings ?? null,
  );
  const [selectedUnitTypesIds, setSelectedUnitTypesIds] = useState(
    bookingEnginePage?.unitTypes.map((unitType) => unitType.uuid) ?? [],
  );
  const [selectedPaymentMethodsIds, setSelectedPaymentMethodsIds] = useState(
    bookingEnginePage?.paymentMethods.map((paymentMethod) => paymentMethod.uuid) ?? [],
  );
  const [selectedNotificationMethodsIds, setSelectedNotificationMethodsIds] = useState(
    bookingEnginePage?.notificationMethodsUuids ?? [],
  );
  const [allowBookingUntilHourMinute, setAllowBookingUntilHourMinute] = useState(
    bookingEnginePage?.allowBookingUntilHourMinute ?? '23:59',
  );
  const [allowBookingUntilDaysBefore, setAllowBookingUntilDaysBefore] = useState(
    bookingEnginePage?.allowBookingUntilDaysBefore ?? 1,
  );
  const [timezone, setTimezone] = useState(
    bookingEnginePage?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [minStayNights, setMinStayNights] = useState(bookingEnginePage?.minStayNights ?? null);
  const [maxStayNights, setMaxStayNights] = useState(bookingEnginePage?.maxStayNights ?? null);

  const options = { invalidate: [trpcContext.pms.bookingEngine], onSuccess, setFormErrors };
  const createBookingEnginePage = createMutation(trpc.pms.bookingEngine.createPage, options);
  const updateBookingEnginePage = createMutation(trpc.pms.bookingEngine.updatePage, options);
  const deleteBookingEnginePage = createMutation(trpc.pms.bookingEngine.deletePage, options);

  useEffect(() => {
    if (requireOnlinePayment) {
      setSelectedPaymentMethodsIds(
        selectedPaymentMethodsIds
          .map((id) => paymentMethods.find((paymentMethod) => paymentMethod.uuid === id))
          .filter(isNotEmpty)
          .filter(isOnlinePaymentMethod)
          .map((paymentMethod) => paymentMethod?.uuid),
      );
    }
  }, [requireOnlinePayment]);

  const unitTypesByAccommodation = useMemo(() => {
    const unitTypesByAccommodation: { [accommodationUuid: string]: UnitType[] } = {};

    unitTypes.forEach((unitType) => {
      if (!unitTypesByAccommodation[unitType.accommodationUuid]) {
        unitTypesByAccommodation[unitType.accommodationUuid] = [];
      }
      unitTypesByAccommodation[unitType.accommodationUuid].push(unitType);
    });

    return unitTypesByAccommodation;
  }, [unitTypes]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const data = {
      name: form.bookingEnginePageName.value,
      allowBookingUntilHourMinute,
      allowBookingUntilDaysBefore,
      timezone,
      minStayNights,
      maxStayNights,
      requestConfirmation,
      requestPayment: requireOnlinePayment,
      paymentAmountSettings: paymentAmountSettings,
      paymentMethodsUuids: selectedPaymentMethodsIds,
      unitTypesUuids: selectedUnitTypesIds,
      notificationMethodsUuids: selectedNotificationMethodsIds,
    };

    if (action === 'create') {
      createBookingEnginePage.mutate(data);
    } else {
      updateBookingEnginePage.mutate({ ...data, uuid: bookingEnginePageUuid });
    }
  };

  const handleUnitTypesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const uuid = String(value);

    if (checked) {
      setSelectedUnitTypesIds([...selectedUnitTypesIds, uuid]);
    } else {
      setSelectedUnitTypesIds(selectedUnitTypesIds.filter((unitTypeId) => unitTypeId !== uuid));
    }
  };

  const handlePaymentMethodsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const uuid = String(value);

    if (checked) {
      setSelectedPaymentMethodsIds([...selectedPaymentMethodsIds, uuid]);
    } else {
      setSelectedPaymentMethodsIds(
        selectedPaymentMethodsIds.filter((paymentMethodId) => paymentMethodId !== uuid),
      );
    }
  };

  const handleNotificationMethodsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const uuid = String(value);

    if (checked) {
      setSelectedNotificationMethodsIds([...selectedNotificationMethodsIds, uuid]);
    } else {
      setSelectedNotificationMethodsIds(
        selectedNotificationMethodsIds.filter(
          (notificationMethodId) => notificationMethodId !== uuid,
        ),
      );
    }
  };

  const handleRequireOnlinePaymentChange = (checked: boolean) => {
    setRequireOnlinePayment(checked);
    if (checked && paymentAmountSettings === null) {
      setPaymentAmountSettings({
        type: 'full',
      });
    } else if (!checked) {
      setPaymentAmountSettings(null);
    }
  };

  const onlinePaymentMethods = paymentMethods.filter(isOnlinePaymentMethod);

  const isLoading =
    createBookingEnginePage.isLoading ||
    updateBookingEnginePage.isLoading ||
    deleteBookingEnginePage.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <FormInput
          type='text'
          name='bookingEnginePageName'
          title={t('pms:bookingEngine.form.pageTitle')}
          hint={t('pms:bookingEngine.form.pageTitleHint')}
          errorPath={['name']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={bookingEnginePage?.name}
        />
      </div>
      {/* <div className='row'>
        <div className='col-12'>
          <label className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={requestConfirmation}
              // disabled={requestPayment}
              disabled={true || isLoading} // TODO: enable when the feature is ready
              onChange={(e) => setRequestConfirmation(e.target.checked)}
            />
            <span className='form-check-label'>
              Each booking has to be manually approved before being confirmed.
              <br />
              If you disable this option, the booking will be automatically confirmed.
              {requireOnlinePayment ? (
                <>
                  <br />
                  <b>
                    This option is disabled and cannot be enabled because you force the user to pay
                    in advance. The booking is automatically confirmed as soon as the user completes
                    the payment.
                  </b>
                </>
              ) : (
                <>
                  <br />
                  <b>This feature is not available yet. It will be available soon.</b>
                </>
              )}
            </span>
          </label>
        </div>
      </div> */}
      <div className='row'>
        <div className='col-12'>
          <b>{t('pms:bookingEngine.form.allowBookingUntil')}</b>
        </div>
        <div className='col-6'>
          <FormInput
            type='time'
            name='allowBookingUntilHourMinute'
            hint={t('pms:bookingEngine.form.allowBookingUntilHourMinuteHint')}
            errorPath={['allowBookingUntilHourMinute']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={allowBookingUntilHourMinute}
            onChange={(value) => setAllowBookingUntilHourMinute(value)}
          />
        </div>
        <div className='col-6'>
          <FormInput
            type='number'
            name='allowBookingUntilDaysBefore'
            hint={t('pms:bookingEngine.form.allowBookingUntilDaysBeforeHint')}
            errorPath={['allowBookingUntilDaysBefore']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={String(allowBookingUntilDaysBefore)}
            onChange={(value) => setAllowBookingUntilDaysBefore(parseInt(value, 10))}
          />
        </div>
        <div className='col-12'>
          <TimezoneInput value={timezone} onChange={setTimezone} />
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='col-12'>
            <b>{t('pms:bookingEngine.form.minStayNights')}</b>
          </div>
          <FormInput
            type='switch'
            title={t('pms:bookingEngine.form.hasMinStayNights')}
            name='hasMinStayNights'
            disabled={isLoading}
            defaultValue={minStayNights !== null}
            checkboxValue='on'
            onChange={(checked) => setMinStayNights(checked ? 1 : null)}
          />
          {minStayNights !== null && (
            <FormInput
              type='number'
              name='minStayNights'
              hint={t('pms:bookingEngine.form.minStayNightsHint')}
              errorPath={['minStayNights']}
              formErrors={formErrors}
              disabled={isLoading}
              defaultValue={String(minStayNights)}
              onChange={(value) => setMinStayNights(parseInt(value, 10))}
            />
          )}
        </div>
        <div className='col-6'>
          <div className='col-12'>
            <b>{t('pms:bookingEngine.form.maxStayNights')}</b>
          </div>
          <FormInput
            type='switch'
            title={t('pms:bookingEngine.form.hasMaxStayNights')}
            name='hasMaxStayNights'
            disabled={isLoading}
            defaultValue={maxStayNights !== null}
            checkboxValue='on'
            onChange={(checked) => setMaxStayNights(checked ? 1 : null)}
          />
          {maxStayNights !== null && (
            <FormInput
              type='number'
              name='maxStayNights'
              hint={t('pms:bookingEngine.form.maxStayNightsHint')}
              errorPath={['maxStayNights']}
              formErrors={formErrors}
              disabled={isLoading}
              defaultValue={String(maxStayNights)}
              onChange={(value) => setMaxStayNights(parseInt(value, 10))}
            />
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <b>{t('pms:bookingEngine.form.requireOnlinePayment')}</b>
        </div>
        <div className='col-12'>
          <FormInput
            type='switch'
            title={t('pms:bookingEngine.form.requireOnlinePayment')}
            hint={t('pms:bookingEngine.form.requireOnlinePaymentHint')}
            name='requireOnlinePayment'
            errorPath={['requireOnlinePayment']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={requireOnlinePayment}
            checkboxValue='on'
            onChange={handleRequireOnlinePaymentChange}
          />
        </div>
      </div>
      <div className='row'>
        {requireOnlinePayment && paymentAmountSettings !== null && (
          <>
            <div className='col-12'>
              <b>{t('pms:bookingEngine.form.paymentAmountSettings.title')}</b>
            </div>
            <div className='col-12'>
              <FormInput
                type='select'
                name='paymentAmountSettings'
                defaultValue={paymentAmountSettings.type}
                onChange={(type) =>
                  setPaymentAmountSettings(
                    type === 'full' ? { type: 'full' } : { type: 'percentage', percentage: 100 },
                  )
                }
              >
                <option value='full'>
                  {t('pms:bookingEngine.form.paymentAmountSettings.fullPayment')}
                </option>
                <option value='percentage'>
                  {t('pms:bookingEngine.form.paymentAmountSettings.percentagePayment')}
                </option>
              </FormInput>
            </div>
            {paymentAmountSettings.type === 'percentage' && (
              <div className='col-12'>
                <FormInput
                  type='number'
                  name='paymentPercentage'
                  title={t('pms:bookingEngine.form.paymentAmountSettings.paymentPercentage')}
                  hint={t('pms:bookingEngine.form.paymentAmountSettings.paymentPercentageHint')}
                  errorPath={['paymentPercentage']}
                  formErrors={formErrors}
                  disabled={isLoading}
                  defaultValue={String(paymentAmountSettings.percentage)}
                  onChange={(value) =>
                    setPaymentAmountSettings({
                      type: 'percentage',
                      percentage: parseInt(value, 10),
                    })
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
      {requireOnlinePayment && onlinePaymentMethods.length === 0 && (
        <ErrorAlert errors={t('pms:bookingEngine.form.noOnlinePaymentMethodsError')} />
      )}
      {requireOnlinePayment && onlinePaymentMethods.length > 0 && (
        <div className='row'>
          <div className='col-12'>
            <label className='form-label'>{t('pms:configuration.onlinePaymentMethods')}</label>
            <div className='form-check'>
              {onlinePaymentMethods.map((paymentMethod) => (
                <label
                  key={`BookingEnginePageForm-paymentMethod-${paymentMethod.uuid}`}
                  className='form-check form-check-inline'
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='paymentMethodsIds[]'
                    value={paymentMethod.uuid}
                    checked={selectedPaymentMethodsIds.includes(paymentMethod.uuid)}
                    disabled={
                      isLoading || (requireOnlinePayment && paymentMethod.provider === null)
                    }
                    onChange={handlePaymentMethodsChange}
                  />
                  <span className='form-check-label'>{paymentMethod.name}</span>
                </label>
              ))}
              {requireOnlinePayment && selectedPaymentMethodsIds.length === 0 && (
                <div className='invalid-feedback d-block'>
                  Please select at least one payment method.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>{t('pms:unitTypes')}</label>
          <div className='form-check'>
            {Object.keys(unitTypesByAccommodation).map((accommodationUuid, i) => (
              <>
                {i > 0 && <br />}
                <b>
                  Accommodation {accommodations.find((a) => a.uuid === accommodationUuid)?.name}
                </b>
                <br />
                {unitTypesByAccommodation[accommodationUuid].map((unitType) => (
                  <label
                    key={`BookingEnginePageForm-unitType-${unitType.uuid}`}
                    className='form-check form-check-inline'
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='unitTypesIds[]'
                      value={unitType.uuid}
                      disabled={isLoading}
                      checked={selectedUnitTypesIds.includes(unitType.uuid)}
                      onChange={handleUnitTypesChange}
                    />
                    <span className='form-check-label'>{unitType.name}</span>
                  </label>
                ))}
              </>
            ))}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>{t('pms:configuration.notificationMethods')}</label>
          {notificationMethods.length === 0 ? (
            <div className='alert alert-warning' role='alert'>
              <div className='text-secondary'>
                {t('pms:bookingEngine.form.noNotificationMethodsWarning')}
              </div>
            </div>
          ) : (
            <div className='form-check'>
              {notificationMethods.map((notificationMethod) => (
                <label
                  key={`BookingEnginePageForm-notificationMethod-${notificationMethod.uuid}`}
                  className='form-check form-check-inline'
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='notificationMethodsIds[]'
                    value={notificationMethod.uuid}
                    checked={selectedNotificationMethodsIds.includes(notificationMethod.uuid)}
                    disabled={isLoading}
                    onChange={handleNotificationMethodsChange}
                  />
                  <span className='form-check-label'>
                    {getNotificationMethodTypeName(notificationMethod)}
                  </span>
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger w-100'
              isLoading={deleteBookingEnginePage.isLoading}
              disabled={isLoading}
              hideContentWhenLoading
              onClick={async () => {
                if (window.confirm('Are you sure?')) {
                  deleteBookingEnginePage.mutate({ uuid: bookingEnginePage.uuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            isLoading={createBookingEnginePage.isLoading || updateBookingEnginePage.isLoading}
            disabled={isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
