import React, { createContext, useContext } from 'react';
import { z } from 'zod';
import { LocaleStorageItemKey, useLocalStorage } from '../../common/utils/local-storage';
import { usePmsContext } from '../Context';

const bookingColorsSchema = z.union([
  z.object({
    type: z.literal('singleColor'),
    color: z.string(),
  }),
  z.object({
    type: z.literal('checkinCheckout'),
    colors: z.object({
      default: z.string(),
      checkin: z.string(),
      checkout: z.string(),
    }),
  }),
]);
type BookingColors = z.infer<typeof bookingColorsSchema>;
const DEFAULT_BOOKING_COLORS: BookingColors = {
  type: 'singleColor',
  color: '#d63939',
};

type PlanningSettingsContextValue = {
  startDaysOffset: number;
  setStartDaysOffset: (value: number) => void;
  showDays: number;
  setShowDays: (value: number) => void;
  rateOptionUuid: string | null;
  setRateOptionUuid: (value: string | null) => void;
  showPaymentNeeded: boolean;
  setShowPaymentNeeded: (value: boolean) => void;
  bookingColors: BookingColors;
  setBookingColors: (value: BookingColors) => void;
  showCheckInOutStatus: boolean;
  setShowCheckInOutStatus: (value: boolean) => void;
};

const initialState: PlanningSettingsContextValue = {
  startDaysOffset: -2,
  setStartDaysOffset: () => {},
  showDays: 30,
  setShowDays: () => {},
  rateOptionUuid: null,
  setRateOptionUuid: () => {},
  showPaymentNeeded: true,
  setShowPaymentNeeded: () => {},
  bookingColors: DEFAULT_BOOKING_COLORS,
  setBookingColors: () => {},
  showCheckInOutStatus: true,
  setShowCheckInOutStatus: () => {},
};

const PlanningSettingsContext = createContext<PlanningSettingsContextValue>(initialState);

export function PlanningSettingsContextProvider({ children }: { children: React.ReactNode }) {
  const { rateOptions } = usePmsContext();

  const [startDaysOffset, setStartDaysOffset] = useLocalStorage(
    LocaleStorageItemKey.PMS_PLANNING_START_DAYS_BEFORE,
    -2,
    z.number().int(),
  );
  const [showDays, setShowDays] = useLocalStorage(
    LocaleStorageItemKey.PMS_PLANNING_SHOW_DAYS,
    30,
    z.number().int().min(1).max(120),
  );
  const [rateOptionUuid, setRateOptionUuid] = useLocalStorage(
    LocaleStorageItemKey.PMS_DEFAULT_RATE_OPTION_UUID,
    rateOptions.length > 0 ? rateOptions[0].uuid : null,
    z.string().uuid().nullable(),
  );
  const [showPaymentNeeded, setShowPaymentNeeded] = useLocalStorage(
    LocaleStorageItemKey.PMS_PLANNING_SHOW_PAYMENT_NEEDED,
    true,
    z.boolean(),
  );
  const [bookingColors, setBookingColors] = useLocalStorage(
    LocaleStorageItemKey.PMS_PLANNING_BOOKING_COLORS,
    DEFAULT_BOOKING_COLORS,
    bookingColorsSchema,
  );
  const [showCheckInOutStatus, setShowCheckInOutStatus] = useLocalStorage(
    LocaleStorageItemKey.PMS_PLANNING_SHOW_CHECK_IN_OUT_STATUS,
    true,
    z.boolean(),
  );

  const value = {
    startDaysOffset,
    setStartDaysOffset,
    showDays,
    setShowDays,
    rateOptionUuid,
    setRateOptionUuid,
    showPaymentNeeded,
    setShowPaymentNeeded,
    bookingColors,
    setBookingColors,
    showCheckInOutStatus,
    setShowCheckInOutStatus,
  };

  return (
    <PlanningSettingsContext.Provider value={value}>{children}</PlanningSettingsContext.Provider>
  );
}

export const usePlanningSettingsContext = () => useContext(PlanningSettingsContext);
