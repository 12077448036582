import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { createMutation } from '../../../../../../utils/api';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import FormInput from '../../../../../common/components/form/input';
import { FormErrors } from '../../../../../common/utils/api';
import { usePmsContext } from '../../../../Context';
import { useTranslation } from 'react-i18next';

export default function EditAccommodation({ uuid }: { uuid: string }) {
  const { t } = useTranslation(['pms']);
  const trpcContext = trpc.useUtils();
  const { accommodations } = usePmsContext();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const updateAccommodation = createMutation(trpc.pms.accommodation.updateAccommodation, {
    invalidate: [trpcContext.pms.accommodation],
    setFormErrors,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    const form = e.currentTarget;

    let website = form.website.value as string;
    if (website !== '' && !website.startsWith('http://') && !website.startsWith('https://')) {
      website = `https://${website}`;
    }

    const data = {
      name: form.accommodationName.value,
      address: form.address.value,
      email: form.email.value,
      phone: form.phone.value,
      website,
    };

    updateAccommodation.mutate({ ...data, uuid });
  };

  const accommodation = accommodations && accommodations.find((a) => a.uuid === uuid);
  if (accommodation === undefined) {
    return <Navigate to='./../' />;
  }

  const disabled = updateAccommodation.isLoading;

  return (
    <div className='col-md-4'>
      <form onSubmit={handleSubmit}>
        <FormInput
          type='text'
          name='accommodationName'
          title={t('pms:configuration.accommodationSettings.form.name')}
          errorPath={['name']}
          formErrors={formErrors}
          disabled={disabled}
          defaultValue={accommodation.name}
        />
        <FormInput
          type='text'
          name='address'
          title={t('pms:configuration.accommodationSettings.form.address')}
          errorPath={['address']}
          formErrors={formErrors}
          disabled={disabled}
          defaultValue={accommodation.address}
        />
        <FormInput
          type='text'
          name='email'
          title={t('pms:configuration.accommodationSettings.form.email')}
          errorPath={['email']}
          formErrors={formErrors}
          disabled={disabled}
          defaultValue={accommodation.email}
        />
        <FormInput
          type='text'
          name='phone'
          title={t('pms:configuration.accommodationSettings.form.phone')}
          errorPath={['phone']}
          formErrors={formErrors}
          disabled={disabled}
          defaultValue={accommodation.phone}
        />
        <FormInput
          type='text'
          name='website'
          title={t('pms:configuration.accommodationSettings.form.website')}
          errorPath={['website']}
          formErrors={formErrors}
          disabled={disabled}
          defaultValue={accommodation.website.replace('https://', '').replace('http://', '')}
          startText='https://'
        />
        <div className='form-footer'>
          <Button
            disabled={disabled}
            isLoading={updateAccommodation.isLoading}
            hideContentWhenLoading
          >
            {t('pms:form.save')}
          </Button>
        </div>
      </form>
    </div>
  );
}
