import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trpcErrorParser } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import ErrorAlert from '../../common/components/ErrorAlert';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { Trans, useTranslation } from 'react-i18next';

function CompanyEmailField({
  disabled,
  formErrors,
}: {
  disabled: boolean;
  formErrors: FormErrors | null;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['email']}
      type='text'
      name='companyEmail'
      title={t('auth:form.companyEmail')}
      required
      disabled={disabled}
    />
  );
}

export default function Email({ showSessionExpiredError }: { showSessionExpiredError: boolean }) {
  const { t, i18n } = useTranslation(['auth']);
  const navigate = useNavigate();
  const [registrationRequestSent, setRegistrationRequestSent] = useState(false);
  const [genericError, setGenericError] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const isCompanyRegistered = trpc.auth.isCompanyRegistered.useMutation();
  const registerCompanyRequest = trpc.auth.registerRequest.useMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);
    setGenericError([]);

    const form = e.currentTarget;
    const formEmail = form.companyEmail.value as string;

    try {
      const exists = await isCompanyRegistered.mutateAsync({
        email: formEmail,
      });

      if (exists) {
        navigate(`/auth/login?email=${encodeURIComponent(formEmail)}`);
      } else {
        await registerCompanyRequest.mutateAsync({
          language: i18n.language,
          company: {
            email: formEmail,
          },
        });
        setRegistrationRequestSent(true);
      }
    } catch (err) {
      trpcErrorParser({
        error: err,
        setFormErrors,
        setGenericError,
      });
    }
  };

  const isLoading = isCompanyRegistered.isLoading || registerCompanyRequest.isLoading;

  return (
    <form className='card card-md' onSubmit={handleSubmit}>
      <div className='card-body'>
        {registrationRequestSent ? (
          <>
            <h2 className='card-title text-center mb-4'>{t('auth:registrationRequestSent')}</h2>
            <div className='alert alert-success'>
              {t('auth:registrationRequestSentMessage')}
              <p>
                <b>{t('auth:registrationRequestSentMessageAction')}</b>
              </p>
            </div>
          </>
        ) : (
          <>
            {showSessionExpiredError && (
              <div className='alert alert-warning'>{t('auth:sessionExpiredError')}</div>
            )}
            <h2 className='card-title text-center mb-4'>{t('auth:loginOrRegister')}</h2>
            <ErrorAlert errors={genericError} />
            <CompanyEmailField formErrors={formErrors} disabled={isLoading} />
            <div className='form-footer'>
              <Button
                className='btn btn-primary w-100'
                isLoading={isLoading}
                hideContentWhenLoading
              >
                {t('auth:form.next')}
              </Button>
            </div>
            <div className='mt-4 mb-4 text-center'>
              <Trans
                i18nKey='auth:form.termsAndConditions'
                values={{ buttonText: t('auth:form.next') }}
                components={{
                  termsLink: (
                    <a
                      href='https://www.simpulse.it/termini-e-condizioni'
                      target='_blank'
                      rel='noreferrer'
                    />
                  ),
                  privacyLink: (
                    <a
                      href='https://www.simpulse.it/privacy-policy'
                      target='_blank'
                      rel='noreferrer'
                    />
                  ),
                }}
              />
            </div>
          </>
        )}
      </div>
    </form>
  );
}
