import { PaymentMethod } from '../../pms/api/payment-methods';
import Satispay from './providers/Satispay';
import Stripe from './providers/Stripe';

export default function ViewPaymentProviders({
  paymentMethods,
}: {
  paymentMethods: PaymentMethod[];
}) {
  return (
    <div className='d-flex flex-wrap gap-2 mt-2'>
      <Stripe paymentMethods={paymentMethods} />
      <Satispay paymentMethods={paymentMethods} />
    </div>
  );
}
