import { useTranslation } from 'react-i18next';
import ConfigurationIndexItem from '../components/ConfigurationIndexItem';
import Page from '../components/dashboard/Page';

export default function Home() {
  const { t } = useTranslation(['generic']);

  return (
    <Page title='SimPulse'>
      <ConfigurationIndexItem to='/pms' title={t('generic:pms')} hint={t('generic:pmsHint')} />
      <ConfigurationIndexItem
        to='/menu/menus'
        title={t('generic:menu')}
        hint={t('generic:menuHint')}
      />
    </Page>
  );
}
