import { IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { createMutation } from '../../../../../utils/api';
import { euroFormatter } from '../../../../../utils/string';
import { trpc } from '../../../../../utils/trpc';
import Page from '../../../../common/components/dashboard/Page';
import Button from '../../../../common/components/form/Button';
import FormInput from '../../../../common/components/form/input';
import { FormErrors } from '../../../../common/utils/api';
import { usePmsContext } from '../../../Context';
import { UnitType } from '../../../api/units/unit-types';
import AddBookingUnit from '../../../components/booking/form/AddBookingUnit';
import { SelectedUnits } from '../../../types';
import { useTranslation } from 'react-i18next';
import { Accommodation } from '../../../api/accommodations';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import { localDateToLocaleDateString } from '../../../../../utils/date';

function SelectedUnitsList({
  unitTypes,
  selectedUnits,
  onRemove,
}: {
  unitTypes: UnitType[];
  selectedUnits: SelectedUnits;
  onRemove: (unit: SelectedUnits[0]) => void;
}) {
  const { t } = useTranslation(['pms']);
  const getUnitById = (unitUuid: string) =>
    unitTypes.flatMap((unitType) => unitType.units).find((unit) => unit.uuid === unitUuid);

  const unitsElements = selectedUnits.map((unit) => {
    const rateAmountString = unit.rateAmount ? euroFormatter.format(unit.rateAmount) : 'N/A';
    return (
      <tr key={`selected-units-list-${JSON.stringify(unit)}`}>
        <td>{getUnitById(unit.unitUuid)?.name}</td>
        <td>{localDateToLocaleDateString(unit.from)}</td>
        <td>{localDateToLocaleDateString(unit.to)}</td>
        <td>{`${unit.adults} + ${unit.children}`}</td>
        <td>{rateAmountString}</td>
        <td className='text-end'>
          <Button
            type='button'
            className='btn btn-outline-danger btn-icon w-100'
            onClick={() => onRemove(unit)}
          >
            <IconTrash size='1em' />
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div className='card w-100 table-responsive'>
      <table className='table table-vcenter'>
        <thead>
          <tr>
            <th>{t('pms:booking.form.unit')}</th>
            <th>{t('pms:booking.form.from')}</th>
            <th>{t('pms:booking.form.to')}</th>
            <th>{t('pms:booking.form.pax')}</th>
            <th>{t('pms:booking.form.rate')}</th>
            <th className='text-center' style={{ width: '50px' }} />
          </tr>
        </thead>
        <tbody>{unitsElements}</tbody>
      </table>
    </div>
  );
}

export function BookingForm({
  accommodationUuid,
  selectedUnits,
  callback,
}: {
  accommodationUuid: string;
  selectedUnits: SelectedUnits;
  callback: ({ bookingUuid }: { bookingUuid: string }) => void;
}) {
  const { t } = useTranslation(['pms']);
  const { referrals } = usePmsContext();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const trpcContext = trpc.useUtils();
  const createBooking = createMutation(trpc.pms.booking.createBooking, {
    invalidate: [trpcContext.pms.booking],
    setFormErrors,
    onSuccess: (booking: { uuid: string }) => {
      toast.success('Booking created successfully.');
      callback({ bookingUuid: booking.uuid });
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    const form = e.currentTarget;

    const bookingUnits: {
      unitUuid: string;
      adultsNumber: number | null;
      childrenNumber: number | null;
      arrivalDate: string;
      arrivalTime: string | null;
      departureDate: string;
      departureTime: string | null;
      rateOptionUuid: string | null;
      rateAmount: number | null;
      notes: string;
    }[] = [];

    selectedUnits.forEach((unit) => {
      const arrivalDate = unit.from.toString();
      const departureDate = unit.to.toString();

      if (!arrivalDate || !departureDate) {
        toast.error('Invalid arrival or departure date.');
        return;
      }

      bookingUnits.push({
        rateOptionUuid: unit.rateOptionUuid,
        unitUuid: unit.unitUuid,
        adultsNumber: unit.adults,
        childrenNumber: unit.children,
        arrivalDate,
        arrivalTime: unit.arrivalTime ? unit.arrivalTime.toString() : null,
        departureDate,
        departureTime: unit.departureTime ? unit.departureTime.toString() : null,
        rateAmount: unit.rateAmount,
        notes: '',
      });
    });

    createBooking.mutate({
      accommodationUuid,
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value === '' ? null : form.email.value,
      phone: form.phone.value === '' ? null : form.phone.value,
      notes: form.notes.value === '' ? null : form.notes.value,
      referralUuid: form.referral.value === '' ? null : form.referral.value,
      bookingUnits,
    });
  };

  const { isLoading } = createBooking;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='firstName'
            title={t('pms:booking.form.firstName')}
            errorPath={['firstName']}
            formErrors={formErrors}
            disabled={isLoading}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='lastName'
            title={t('pms:booking.form.lastName')}
            errorPath={['lastName']}
            formErrors={formErrors}
            disabled={isLoading}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='email'
            title={t('pms:booking.form.email')}
            errorPath={['email']}
            formErrors={formErrors}
            disabled={isLoading}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='phone'
            title={t('pms:booking.form.phone')}
            errorPath={['phone']}
            formErrors={formErrors}
            disabled={isLoading}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='select'
            name='referral'
            title={t('pms:booking.form.referral')}
            errorPath={['referralUuid']}
            formErrors={formErrors}
            disabled={isLoading}
          >
            <option value={''}>-- no referral --</option>
            {referrals.map((referral) => (
              <option key={`rateSeason-${referral.uuid}`} value={referral.uuid}>
                {referral.name}
              </option>
            ))}
          </FormInput>
        </div>
        <div className='col-12'>
          <FormInput
            type='textarea'
            name='notes'
            title={t('pms:booking.form.notes')}
            errorPath={['notes']}
            formErrors={formErrors}
            disabled={isLoading}
          />
        </div>
        <div className='mt-3'>
          <Button isLoading={isLoading} hideContentWhenLoading>
            {t('pms:form.create')}
          </Button>
        </div>
      </div>
    </form>
  );
}

function NewBookingContent({ accommodation }: { accommodation: Accommodation }) {
  const { t } = useTranslation(['pms']);
  const navigate = useNavigate();
  const { unitTypes: allUnitTypes } = usePmsContext();

  const [selectedUnits, setSelectedUnits] = useState<SelectedUnits>([]);

  const unitTypes = allUnitTypes.filter(
    (unitType) => unitType.accommodationUuid === accommodation.uuid,
  );

  return (
    <Page title={t('pms:navbar.newBooking')}>
      <AddBookingUnit
        accommodationUuid={accommodation.uuid}
        notAvailableUnits={selectedUnits}
        onSelect={(newBookingUnit) => {
          setSelectedUnits((prevSelectedUnits) => {
            const newSelectedUnits = [
              ...prevSelectedUnits,
              {
                ...newBookingUnit,
                rateAmount: newBookingUnit.totalRateAmount,
              },
            ];
            return newSelectedUnits;
          });
        }}
      />
      <hr />
      <h2>{t('pms:booking.form.selectedUnits')}</h2>
      {selectedUnits.length === 0 ? (
        <ErrorAlert errors={t('pms:booking.form.noSelectedUnits')} />
      ) : (
        <SelectedUnitsList
          unitTypes={unitTypes}
          selectedUnits={selectedUnits}
          onRemove={(unit) => {
            setSelectedUnits((prevSelectedUnits) => {
              const newSelectedUnits = prevSelectedUnits.filter(
                (selectedUnit) => JSON.stringify(selectedUnit) !== JSON.stringify(unit),
              );
              return newSelectedUnits;
            });
          }}
        />
      )}
      <hr />
      <BookingForm
        accommodationUuid={accommodation.uuid}
        selectedUnits={selectedUnits}
        callback={({ bookingUuid }) => {
          navigate(`/pms/bookings/edit/${bookingUuid}`);
        }}
      />
    </Page>
  );
}

export default function NewBooking() {
  const { t } = useTranslation(['pms']);
  const { accommodations } = usePmsContext();
  const [accommodation, setAccommodation] = useState<Accommodation | null>(null);

  if (accommodations.length === 1) {
    return <NewBookingContent accommodation={accommodations[0]} />;
  }

  if (!accommodation) {
    return (
      <Page title={t('pms:navbar.newBooking')}>
        <div className='d-flex flex-wrap gap-2'>
          {accommodations.map((accommodation) => (
            <Button
              className='btn w-100'
              key={`accommodation-${accommodation.uuid}`}
              onClick={() => setAccommodation(accommodation)}
            >
              {accommodation.name}
            </Button>
          ))}
        </div>
      </Page>
    );
  }

  return <NewBookingContent accommodation={accommodation} />;
}
