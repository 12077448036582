function WithCardBody({ children, cardBody }: { children: React.ReactNode; cardBody: boolean }) {
  if (cardBody) {
    return <div className='card card-body'>{children}</div>;
  }

  return <>{children}</>;
}

function WithCenter({ children, center }: { children: React.ReactNode; center: boolean }) {
  if (center) {
    return <div className='text-center'>{children}</div>;
  }

  return <>{children}</>;
}

function LoadingSpinner({
  text,
  className,
  cardBody,
  center,
}: {
  text?: string;
  className?: string;
  cardBody?: boolean;
  center?: boolean;
}) {
  return (
    <WithCardBody cardBody={cardBody === true}>
      <WithCenter center={center === true}>
        <span
          className={className ? `${className} spinner-border` : 'spinner-border'}
          role='status'
        />
        {text && <p>{text}</p>}
      </WithCenter>
    </WithCardBody>
  );
}

LoadingSpinner.defaultProps = {
  className: null,
  cardBody: false,
  center: false,
};

export default LoadingSpinner;
