import { useTranslation } from 'react-i18next';
import ViewPaymentMethods from '../../../../account/payment-methods/ViewPaymentMethods';
import ViewPaymentProviders from '../../../../account/payment-methods/ViewPaymentProviders';
import { useCompanyContext } from '../../../../common/contexts/CompanyContext';

export default function PaymentMethods() {
  const { t } = useTranslation(['pms']);
  const { paymentMethods } = useCompanyContext();

  return (
    <>
      <h1>{t('pms:configuration.offlinePaymentMethods')}</h1>
      <div className='alert alert-info'>{t('pms:configuration.offlinePaymentMethodsHint')}</div>
      <ViewPaymentMethods paymentMethods={paymentMethods} />
      <hr />
      <h1>{t('pms:configuration.onlinePaymentMethods')}</h1>
      <div className='alert alert-info'>{t('pms:configuration.onlinePaymentMethodsHint')}</div>
      <ViewPaymentProviders paymentMethods={paymentMethods} />
    </>
  );
}
