import { useTranslation } from 'react-i18next';
import { trpc } from '../../../../../utils/trpc';
import Card from '../../../../common/components/Card';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { usePmsContext } from '../../../Context';
import { ChannelManager } from '../../../api';
import { Accommodation } from '../../../api/accommodations';
import { UnitType } from '../../../api/units/unit-types';
import { useState } from 'react';
import Modal from '../../../../common/components/Modal';
import Steps from '../../../../common/components/Steps';
import Button from '../../../../common/components/form/Button';
import FormInput from '../../../../common/components/form/input';
import { createMutation } from '../../../../../utils/api';
import { IconAlertTriangle, IconPencil, IconTrash } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { TimeAgo } from '../../../../menu/pages/orders';

export default function ChannelManagerPage() {
  const { accommodations } = usePmsContext();
  const channelManagersQuery = trpc.pms.channelManager.getChannelManagers.useQuery();

  if (channelManagersQuery.isLoading) {
    return <LoadingSpinner center />;
  }

  if (channelManagersQuery.error) {
    return <ErrorAlert errors={channelManagersQuery.error} />;
  }

  return (
    <div className='d-flex flex-column gap-2'>
      {accommodations.map((accommodation) => (
        <AccommodationChannelManagers
          key={accommodation.uuid}
          accommodation={accommodation}
          channelManagers={channelManagersQuery.data}
        />
      ))}
    </div>
  );
}

function AccommodationChannelManagers({
  accommodation,
  channelManagers,
}: {
  accommodation: Accommodation;
  channelManagers: ChannelManager[];
}) {
  const { unitTypes: allUnitTypes } = usePmsContext();
  const unitTypes = allUnitTypes.filter(
    (unitType) => unitType.accommodationUuid === accommodation.uuid,
  );

  return (
    <Card title={accommodation.name}>
      {unitTypes.map((unitType) => (
        <UnitTypeChannelManagers
          key={unitType.uuid}
          unitType={unitType}
          channelManagers={channelManagers}
        />
      ))}
    </Card>
  );
}

function UnitTypeChannelManagers({
  unitType,
  channelManagers,
}: {
  unitType: UnitType;
  channelManagers: ChannelManager[];
}) {
  return (
    <>
      <h4>{unitType.name}</h4>
      {unitType.units.map((unit) => (
        <UnitChannelManagers key={unit.uuid} unit={unit} channelManagers={channelManagers} />
      ))}
    </>
  );
}

function UnitChannelManagers({
  unit,
  channelManagers,
}: {
  unit: UnitType['units'][number];
  channelManagers: ChannelManager[];
}) {
  const { t } = useTranslation(['pms']);
  const [showAddChannelManager, setShowAddChannelManager] = useState(false);
  const [showEditChannelManager, setShowEditChannelManager] = useState<ChannelManager | null>(null);

  const unitChannelManagers = channelManagers.filter(
    (channelManager) => channelManager.unitUuid === unit.uuid,
  );

  return (
    <>
      <h5>{unit.name}</h5>
      <ul>
        {unitChannelManagers.map((channelManager) => {
          const syncFromOtaOutOfSync =
            channelManager.syncFromOtaEnabled &&
            (channelManager.lastSyncFromOta === null ||
              Math.abs(DateTime.fromISO(channelManager.lastSyncFromOta).diffNow().as('minutes')) >
                30);
          const syncToOtaOutOfSync =
            channelManager.syncToOtaEnabled &&
            (channelManager.lastSyncToOta === null ||
              Math.abs(DateTime.fromISO(channelManager.lastSyncToOta).diffNow().as('minutes')) >
                30);
          return (
            <li key={channelManager.uuid}>
              <Button
                className='btn btn-icon btn-sm me-2'
                onClick={() => setShowEditChannelManager(channelManager)}
              >
                <IconPencil size='1em' />
              </Button>
              {(syncFromOtaOutOfSync || syncToOtaOutOfSync) && (
                <Button
                  className='btn btn-icon btn-sm me-2 btn-warning'
                  onClick={() => setShowEditChannelManager(channelManager)}
                >
                  <IconAlertTriangle size='1em' />
                </Button>
              )}
              {channelManager.data.type === 'ical'
                ? getDomain(channelManager.data.syncFromOtaUrl)
                : channelManager.data.type}
            </li>
          );
        })}
        <li>
          <button
            type='button'
            className='btn btn-sm'
            onClick={() => setShowAddChannelManager(true)}
          >
            {t('pms:channelManager.form.addChannelManager')}
          </button>
        </li>
      </ul>
      <AddChannelManagerModal
        unit={unit}
        isOpen={showAddChannelManager}
        onClose={() => setShowAddChannelManager(false)}
      />
      {showEditChannelManager && (
        <EditChannelManagerModal
          channelManager={showEditChannelManager}
          isOpen={true}
          onClose={() => setShowEditChannelManager(null)}
        />
      )}
    </>
  );
}

function getDomain(url: string) {
  const urlObject = new URL(url);
  return urlObject.hostname;
}

function AddChannelManagerModal({
  unit,
  isOpen,
  onClose: _onClose,
}: {
  unit: UnitType['units'][number];
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const { referrals } = usePmsContext();

  const [currentStep, setCurrentStep] = useState(1);
  const [, setSelectedOta] = useState<'ical' | null>(null);
  const [referralUuid, setReferralUuid] = useState<string | null>(null);
  const [syncFromOtaICalUrl, setSyncFromOtaICalUrl] = useState<string>('');
  const [syncToOtaICalUrl, setSyncToOtaICalUrl] = useState<string>('');

  const trpcContext = trpc.useUtils();
  const addUnitICalChannelManager = createMutation(
    trpc.pms.channelManager.addUnitICalChannelManager,
    {
      invalidate: [trpcContext.pms.channelManager],
      onSuccess: ({ syncToOtaUrl }: { syncToOtaUrl: string }) => {
        setSyncToOtaICalUrl(syncToOtaUrl);
        setCurrentStep(4);
      },
    },
  );

  function onAddUnitICalChannelManager() {
    addUnitICalChannelManager.mutate({
      unitUuid: unit.uuid,
      referralUuid,
      iCalUrl: syncFromOtaICalUrl,
    });
  }

  function onClose() {
    setCurrentStep(1);
    setSelectedOta(null);
    _onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('pms:channelManager.form.addChannelManager')}>
      <Steps steps={4} currentStep={currentStep} />
      <hr />

      {currentStep === 1 && (
        <>
          <span>{t('pms:channelManager.form.selectOta')}</span>
          <div className='d-flex flex-column gap-2 mt-2'>
            <Button
              className='btn w-100'
              onClick={() => {
                setSelectedOta('ical');
                setCurrentStep(2);
              }}
            >
              {t('pms:channelManager.form.ota.ical')}
            </Button>
          </div>
        </>
      )}

      {currentStep === 2 && (
        <>
          <FormInput
            title={t('pms:channelManager.form.referral')}
            name='referral'
            type='select'
            hint={t('pms:channelManager.form.referralHint')}
            onChange={(referralUuid) => setReferralUuid(referralUuid === '' ? null : referralUuid)}
          >
            <option value=''>{t('pms:channelManager.form.noReferral')}</option>
            {referrals.map((referral) => (
              <option key={referral.uuid} value={referral.uuid}>
                {referral.name}
              </option>
            ))}
          </FormInput>

          <div className='d-flex flex-row gap-2'>
            <Button className='btn btn w-100' onClick={() => setCurrentStep(1)}>
              {t('pms:form.back')}
            </Button>
            <Button className='btn btn-primary w-100' onClick={() => setCurrentStep(3)}>
              {t('pms:form.next')}
            </Button>
          </div>
        </>
      )}

      {currentStep === 3 && (
        <>
          <FormInput
            title={t('pms:channelManager.form.syncFromOtaICalUrl')}
            name='syncFromOtaICalUrl'
            type='text'
            hint={t('pms:channelManager.form.syncFromOtaICalUrlHint.ical')}
            onChange={setSyncFromOtaICalUrl}
          />

          <div className='d-flex flex-row gap-2'>
            <Button
              className='btn btn w-100'
              onClick={() => setCurrentStep(2)}
              disabled={addUnitICalChannelManager.isLoading}
            >
              {t('pms:form.back')}
            </Button>
            <Button
              className='btn btn-primary w-100'
              onClick={() => onAddUnitICalChannelManager()}
              isLoading={addUnitICalChannelManager.isLoading}
              hideContentWhenLoading
            >
              {t('pms:form.next')}
            </Button>
          </div>
        </>
      )}

      {currentStep === 4 && (
        <>
          <span>{t('pms:channelManager.form.syncToOtaICalUrlHint.ical')}</span>
          <hr />
          {syncToOtaICalUrl}
          <hr />

          <div className='d-flex flex-row gap-2'>
            <Button className='btn btn-primary w-100' onClick={onClose}>
              {t('pms:form.close')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

function EditChannelManagerModal({
  channelManager,
  isOpen,
  onClose,
}: {
  channelManager: ChannelManager;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const { referrals } = usePmsContext();

  const [referralUuid, setReferralUuid] = useState<string | null>(
    channelManager.referralUuid ?? null,
  );

  const trpcContext = trpc.useUtils();
  const options = {
    invalidate: [trpcContext.pms.channelManager],
    onSuccess: () => {
      onClose();
    },
  };
  const updateUnitChannelManager = createMutation(
    trpc.pms.channelManager.updateUnitChannelManager,
    options,
  );
  const deleteUnitChannelManager = createMutation(
    trpc.pms.channelManager.deleteUnitChannelManager,
    options,
  );
  const isLoading = updateUnitChannelManager.isLoading || deleteUnitChannelManager.isLoading;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('pms:channelManager.form.editChannelManager')}
    >
      {channelManager.syncFromOtaEnabled ? (
        <span>
          Last sync from OTA:{' '}
          {channelManager.lastSyncFromOta ? (
            <TimeAgo datetime={channelManager.lastSyncFromOta} />
          ) : (
            'Never'
          )}
        </span>
      ) : (
        <span>Sync from OTA disabled</span>
      )}
      <br />
      {channelManager.syncToOtaEnabled ? (
        <span>
          Last sync to OTA:{' '}
          {channelManager.lastSyncToOta ? (
            <TimeAgo datetime={channelManager.lastSyncToOta} />
          ) : (
            'Never'
          )}
        </span>
      ) : (
        <span>Sync to OTA disabled</span>
      )}
      <hr />
      {channelManager.data.type === 'ical' && (
        <>
          <span>{t('pms:channelManager.form.syncToOtaICalUrlHint.ical')}</span>
          <hr />
          {channelManager.data.syncToOtaUrl}
          <hr />
          <FormInput
            title={t('pms:channelManager.form.syncFromOtaICalUrl')}
            name='syncFromOtaICalUrl'
            type='text'
            defaultValue={channelManager.data.syncFromOtaUrl}
            disabled
          />
        </>
      )}

      <FormInput
        title={t('pms:channelManager.form.referral')}
        name='referral'
        type='select'
        hint={t('pms:channelManager.form.referralHint')}
        defaultValue={referralUuid ?? ''}
        onChange={(newReferralUuid) => {
          setReferralUuid(newReferralUuid === '' ? null : newReferralUuid);
        }}
      >
        <option value=''>{t('pms:channelManager.form.noReferral')}</option>
        {referrals.map((referral) => (
          <option key={referral.uuid} value={referral.uuid}>
            {referral.name}
          </option>
        ))}
      </FormInput>

      <div className='d-flex flex-row gap-2'>
        <Button
          className='btn btn-icon btn-outline-danger'
          disabled={isLoading}
          isLoading={deleteUnitChannelManager.isLoading}
          hideContentWhenLoading
          onClick={() => {
            if (
              window.confirm(
                'Are you sure you want to delete this channel manager? This action cannot be undone. All the bookings already synced from this channel manager will NOT be deleted.',
              )
            ) {
              deleteUnitChannelManager.mutate({
                channelManagerUuid: channelManager.uuid,
              });
            }
          }}
        >
          <IconTrash size='1em' />
        </Button>
        <Button
          className='btn btn-primary w-100'
          disabled={isLoading}
          isLoading={updateUnitChannelManager.isLoading}
          hideContentWhenLoading
          onClick={() => {
            updateUnitChannelManager.mutate({
              channelManagerUuid: channelManager.uuid,
              referralUuid,
            });
          }}
        >
          {t('pms:form.save')}
        </Button>
      </div>
    </Modal>
  );
}
