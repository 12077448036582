export function insertAtIndex<T>(array: T[], index: number, item: T) {
  if (index < 0 || index > array.length) {
    throw new Error('Index out of range');
  }
  const arr = JSON.parse(JSON.stringify(array));
  arr.splice(index, 0, item);
  return arr;
}

// https://stackoverflow.com/a/46700791
// TypeScript-friendly for .filer()
export function isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
