import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { createMutation } from '../../../../../../utils/api';
import { euroFormatter } from '../../../../../../utils/string';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import FormInput from '../../../../../common/components/form/input';
import { FormErrors } from '../../../../../common/utils/api';
import { BookingCharge } from '../../../../api/booking-charge';
import { Booking } from '../../../../api/bookings/bookings';
import { useTranslation } from 'react-i18next';

interface CreateBookingChargeFormProps {
  action: 'create';
  bookingChargeUuid?: undefined;
  bookingCharge?: undefined;
  bookingUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateBookingChargeFormProps {
  action: 'update';
  bookingChargeUuid: string;
  bookingCharge: BookingCharge;
  bookingUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
type BookingChargeFormProps = CreateBookingChargeFormProps | UpdateBookingChargeFormProps;

export function getBookingChargesAmounts(booking: Booking) {
  const totalExtras = booking.bookingCharges.reduce(
    (acc, bookingCharge) => acc + bookingCharge.totalAmount,
    0,
  );
  const totalRates = booking.bookingUnits.reduce(
    (acc, bookingUnit) => acc + (bookingUnit.rateAmount ?? 0),
    0,
  );
  const totalAmount = totalExtras + totalRates;

  return { totalExtras, totalRates, totalAmount };
}

function BookingChargeForm({
  action,
  bookingChargeUuid,
  bookingCharge,
  bookingUuid,
  onSuccess,
  onCancel,
}: BookingChargeFormProps) {
  const { t } = useTranslation(['pms']);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.pms.booking, trpcContext.pms.bookingCharge],
    onSuccess: () => {
      toast.success(`Booking charge updated`);
      onSuccess();
    },
    setFormErrors,
  };
  const createBookingCharge = createMutation(
    trpc.pms.bookingCharge.createBookingChargeExtra,
    mutationOptions,
  );
  const updateBookingCharge = createMutation(
    trpc.pms.bookingCharge.updateBookingChargeExtra,
    mutationOptions,
  );
  const deleteBookingCharge = createMutation(
    trpc.pms.bookingCharge.deleteBookingChargeExtra,
    mutationOptions,
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    const data = {
      name: form.bookingChargeName.value as string,
      date: DateTime.now().toISODate()!,
      notes: '',
      bookingUuid,
      bookingUnitUuid: null,
      productUuid: null,
      quantity: parseInt(form.quantity.value, 10),
      totalAmount: parseFloat(form.totalAmount.value),
    };

    if (action === 'create') {
      createBookingCharge.mutate(data);
    } else {
      updateBookingCharge.mutate({ ...data, uuid: bookingChargeUuid });
    }
  };

  const disabled =
    createBookingCharge.isLoading || updateBookingCharge.isLoading || deleteBookingCharge.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-2'>
          <FormInput
            type='number'
            title={t('pms:booking.form.quantity')}
            name='quantity'
            step='1'
            errorPath={['quantity']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={(bookingCharge?.quantity ?? 1).toString()}
          />
        </div>
        <div className='col-md-6'>
          <FormInput
            type='text'
            title={t('pms:booking.form.description')}
            name='bookingChargeName'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={bookingCharge?.name}
          />
        </div>
        <div className='col-md-4'>
          <FormInput
            type='number'
            title={t('pms:booking.form.total')}
            className='form-control'
            name='totalAmount'
            step='0.01'
            errorPath={['totalAmount']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={bookingCharge?.totalAmount.toString()}
            autoFocus
          />
        </div>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger w-100'
              isLoading={deleteBookingCharge.isLoading}
              disabled={disabled}
              hideContentWhenLoading
              onClick={() => {
                if (confirm('Are you sure you want to delete this booking charge?')) {
                  deleteBookingCharge.mutate({ uuid: bookingChargeUuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            disabled={disabled}
            isLoading={createBookingCharge.isLoading || updateBookingCharge.isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}

function BookingChargeExtraElement({
  bookingUuid,
  bookingCharge: bookingChargeExtra,
  action,
}: {
  bookingUuid: string;
  bookingCharge: BookingCharge;
  action: 'view' | 'edit';
}) {
  const [parentRef] = useAutoAnimate<HTMLTableRowElement>();
  const [showEditForm, setShowEditForm] = useState(false);

  return (
    <tr ref={parentRef}>
      {showEditForm ? (
        <td colSpan={4}>
          <BookingChargeForm
            action='update'
            bookingUuid={bookingUuid}
            bookingCharge={bookingChargeExtra}
            bookingChargeUuid={bookingChargeExtra.uuid}
            onSuccess={() => setShowEditForm(false)}
            onCancel={() => setShowEditForm(false)}
          />
        </td>
      ) : (
        <>
          <td>{bookingChargeExtra.quantity}</td>
          <td>{bookingChargeExtra.name}</td>
          <td className='text-end'>{euroFormatter.format(bookingChargeExtra.totalAmount)}</td>
          {action === 'edit' && (
            <td className='text-center'>
              <Button type='button' className='btn btn-icon' onClick={() => setShowEditForm(true)}>
                <IconPencil size='1em' />
              </Button>
            </td>
          )}
        </>
      )}
    </tr>
  );
}

export default function EditBookingCharges({
  booking,
  action,
}: {
  booking: Booking;
  action: 'view' | 'edit';
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate<HTMLTableSectionElement>();
  const [showNewChargeForm, setShowNewChargeForm] = useState(false);

  const { totalRates, totalAmount } = getBookingChargesAmounts(booking);

  return (
    <div className='card w-100 table-responsive'>
      <table className='table table-vcenter'>
        <thead>
          <tr>
            <th style={{ width: '50px' }}>{t('pms:booking.form.qty')}</th>
            <th>{t('pms:booking.form.description')}</th>
            <th className='text-end' style={{ width: '100px' }}>
              {t('pms:booking.form.amount')}
            </th>
            {action === 'edit' && <th style={{ width: '0%' }} />}
          </tr>
        </thead>
        <tbody ref={parentRef}>
          <tr>
            <td />
            <td>
              <b>{t('pms:booking.form.unitsRatesTotal')}</b>
            </td>
            <td className='text-end'>{euroFormatter.format(totalRates)}</td>
            {action === 'edit' && <td />}
          </tr>
          {booking.bookingCharges.map((charge) => (
            <BookingChargeExtraElement
              key={`booking-charge-${charge.uuid}`}
              bookingUuid={booking.uuid}
              bookingCharge={charge}
              action={action}
            />
          ))}
          <tr className='bg-dark text-white fw-bold'>
            <td colSpan={3}>
              <div className='d-flex justify-content-between'>
                <span>{t('pms:booking.form.total')}</span>
                <span>{euroFormatter.format(totalAmount)}</span>
              </div>
            </td>
            {action === 'edit' && <td />}
          </tr>
        </tbody>
      </table>
      {action === 'edit' && (
        <div className='p-3'>
          {showNewChargeForm ? (
            <BookingChargeForm
              action='create'
              bookingUuid={booking.uuid}
              onSuccess={() => setShowNewChargeForm(false)}
              onCancel={() => setShowNewChargeForm(false)}
            />
          ) : (
            <div className='text-center'>
              <Button className='btn' onClick={() => setShowNewChargeForm(true)}>
                <IconPlus size='1em' className='me-2' />
                {t('pms:booking.addNewCharge')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
