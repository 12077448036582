import { useState } from 'react';
import toast from 'react-hot-toast';
import { createMutation } from '../../../../../../utils/api';
import { trpc } from '../../../../../../utils/trpc';
import Button from '../../../../../common/components/form/Button';
import FormInput from '../../../../../common/components/form/input';
import { FormErrors } from '../../../../../common/utils/api';
import { Booking } from '../../../../api/bookings/bookings';
import { usePmsContext } from '../../../../Context';
import { useNavigate } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export default function EditBookingForm({
  booking,
  onSuccess,
}: {
  booking: Booking;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation(['pms']);
  const { referrals } = usePmsContext();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const trpcContext = trpc.useUtils();
  const updateBooking = createMutation(trpc.pms.booking.updateBooking, {
    invalidate: [trpcContext.pms.booking],
    setFormErrors,
    onSuccess: () => {
      toast.success('Booking updated.');
      if (onSuccess) {
        onSuccess();
      }
    },
  });
  const deleteBooking = createMutation(trpc.pms.booking.deleteBooking, {
    invalidate: [trpcContext.pms.booking],
    // Need to invalidate before onSuccessBeforeInvalidate is called because tRPC would try to
    // fetch the booking by UUID again and it would fail because the booking is already deleted.
    onSuccessBeforeInvalidate: async () => {
      toast.success('Booking deleted.');
      navigate('/pms/bookings');
      // Wait the navigation to be done before invalidating to avoid the error described above.
      await new Promise((resolve) => setTimeout(resolve, 100));
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    const form = e.currentTarget;
    updateBooking.mutate({
      uuid: booking.uuid,
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value === '' ? null : form.email.value,
      phone: form.phone.value === '' ? null : form.phone.value,
      referralUuid: form.referral.value === '' ? null : form.referral.value,
      notes: form.notes.value === '' ? null : form.notes.value,
    });
  };

  const disabled = updateBooking.isLoading || deleteBooking.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='firstName'
            title={t('pms:booking.form.firstName')}
            errorPath={['firstName']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.firstName || ''}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='lastName'
            title={t('pms:booking.form.lastName')}
            errorPath={['lastName']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.lastName || ''}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='email'
            title={t('pms:booking.form.email')}
            errorPath={['email']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.email || ''}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='text'
            name='phone'
            title={t('pms:booking.form.phone')}
            errorPath={['phone']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.phone || ''}
          />
        </div>
        <div className='col-lg-6'>
          <FormInput
            type='select'
            name='referral'
            title={t('pms:booking.form.referral')}
            errorPath={['referralUuid']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.referral?.uuid || ''}
          >
            <option value={''}>-- no referral --</option>
            {referrals.map((referral) => (
              <option key={`rateSeason-${referral.uuid}`} value={referral.uuid}>
                {referral.name}
              </option>
            ))}
          </FormInput>
        </div>
        <div className='col-12'>
          <FormInput
            type='textarea'
            name='notes'
            title={t('pms:booking.form.notes')}
            errorPath={['notes']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={booking.notes || ''}
          />
        </div>
        <div className='d-flex justify-content-center gap-2 mt-3'>
          <Button
            type='button'
            className='btn btn-icon btn-outline-danger'
            onClick={() => {
              if (
                confirm(
                  'Are you sure you want to delete this booking? All the units will be freed. All the payments and the charges will be deleted. This action cannot be undone.',
                )
              ) {
                deleteBooking.mutate({ uuid: booking.uuid });
              }
            }}
            disabled={disabled}
            isLoading={deleteBooking.isLoading}
            hideContentWhenLoading
          >
            <IconTrash size='1em' />
          </Button>
          <Button disabled={disabled} isLoading={updateBooking.isLoading} hideContentWhenLoading>
            Edit
          </Button>
        </div>
      </div>
    </form>
  );
}
