import { useTranslation } from 'react-i18next';
import Page from '../../../common/components/dashboard/Page';
import DateRangeSelector from '../../components/DateRangeSelector';
import { useMemo, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import { trpc } from '../../../../utils/trpc';
import { usePmsContext } from '../../Context';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ErrorAlert from '../../../common/components/ErrorAlert';
import { formatPriceInSmallestUnitToString } from '../../../../utils/currency';
import Card from '../../../common/components/Card';
import { AxisOptions, Chart } from 'react-charts';
import Modal from '../../../common/components/Modal';
import Button from '../../../common/components/form/Button';
import { useCompanyContext } from '../../../common/contexts/CompanyContext';
import { LocalDate, ChronoUnit } from '@js-joda/core';
import { localDateToUTCJSDate, localDateToLuxon } from '../../../../utils/date';

const BOOKINGS_LIMIT_QUERY = 500;

export default function StatsPage() {
  const { t } = useTranslation(['pms']);
  return (
    <Page title={t('pms:navbar.stats')}>
      <StatsWrapper />
    </Page>
  );
}

function StatsWrapper() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const today = LocalDate.now();
  const [startDate, setStartDate] = useState<LocalDate | null>(today.minusDays(30));
  const [endDate, setEndDate] = useState<LocalDate | null>(today);

  return (
    <>
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex justify-content-center'>
          <Button className='btn' onClick={() => setIsModalOpen(true)}>
            {startDate !== null && endDate !== null ? (
              <>
                {`${localDateToLuxon(startDate).toLocaleString({
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })} - ${localDateToLuxon(endDate).toLocaleString({
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}`}
              </>
            ) : (
              'Select date range'
            )}
          </Button>
        </div>
        <div>
          {startDate !== null && endDate !== null && (
            <Stats startDate={startDate} endDate={endDate} />
          )}
        </div>
      </div>
      <DateRangeSelectorModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  );
}

function DateRangeSelectorModal({
  isOpen,
  onClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  isOpen: boolean;
  onClose: () => void;
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  setStartDate: (date: LocalDate | null) => void;
  setEndDate: (date: LocalDate | null) => void;
}) {
  const { t } = useTranslation(['pms']);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='text-center' style={{ fontSize: '1.3rem' }}>
        <DateRangeSelector
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className='text-center'>
        <Button className='btn' onClick={onClose}>
          {t('pms:form.close')}
        </Button>
      </div>
    </Modal>
  );
}

function Stats({ startDate, endDate }: { startDate: LocalDate; endDate: LocalDate }) {
  const { t } = useTranslation(['pms']);

  const { paymentMethods } = useCompanyContext();
  const { currentAccommodations, unitTypes: allUnitTypes, referrals } = usePmsContext();

  const queryBookings = trpc.pms.booking.queryBookings.useQuery({
    accommodationsUuids: currentAccommodations.map((a) => a.uuid),
    page: 1,
    limit: BOOKINGS_LIMIT_QUERY,
    departureDateAfter: startDate.toString(),
    arrivalDateBefore: endDate.toString(),
  });

  if (queryBookings.isLoading) {
    return <LoadingSpinner center />;
  }

  if (queryBookings.error) {
    return <ErrorAlert errors={queryBookings.error} />;
  }

  const totalBookings = queryBookings.data.total;

  if (totalBookings > BOOKINGS_LIMIT_QUERY) {
    return <ErrorAlert errors={t('pms:stats.tooManyBookingsError')} />;
  }

  const nightsInDateRange = startDate.until(endDate, ChronoUnit.DAYS);
  const unitTypes = allUnitTypes.filter((u) =>
    currentAccommodations.map((a) => a.uuid).includes(u.accommodationUuid),
  );

  const totalRooms = unitTypes.reduce((acc, ut) => acc + ut.units.length, 0);
  const totalRoomsTimesNights = totalRooms * nightsInDateRange;

  const bookingsByDate = new Map<string, number>();
  const paymentsByPaymentMethodUuid = new Map<string, number>();
  const bookingsByReferralUuid = new Map<string, number>();
  let chargesTotal = 0;
  let ratesTotal = 0;
  let paymentsTotal = 0;
  let consumedRoomsTimesNights = 0;
  let adultsTotal = 0;
  let childrenTotal = 0;
  // let consumedRooms = 0;

  queryBookings.data.bookings.forEach((booking) => {
    booking.bookingUnits.forEach((bu) => {
      // Consumed rooms
      const arrivalDate = DateTime.fromISO(bu.arrivalDate);
      const departureDate = DateTime.fromISO(bu.departureDate);
      const nights = departureDate.diff(arrivalDate, 'days').days;
      consumedRoomsTimesNights += nights;

      // Pax
      adultsTotal += bu.adultsNumber ?? 0;
      childrenTotal += bu.childrenNumber ?? 0;

      // Bookings by date
      const dateInterval = Interval.fromDateTimes(arrivalDate, departureDate);
      for (const currentInterval of dateInterval.splitBy({ day: 1 })) {
        const arrivalDate = currentInterval.start?.toISODate() ?? '';
        const currentTotal = bookingsByDate.get(arrivalDate) ?? 0;
        bookingsByDate.set(arrivalDate, currentTotal + 1);
      }

      // Charges
      ratesTotal += bu.rateAmount ? bu.rateAmount * 100 : 0;
    });
    // consumedRooms += booking.bookingUnits.length;

    // Charges
    booking.bookingCharges.forEach((c) => {
      chargesTotal += c.totalAmount * 100;
    });

    // Payments
    booking.bookingPayments.forEach((p) => {
      const currentAmount = p.totalAmount * 100;
      paymentsTotal += currentAmount;
      const currentTotal = paymentsByPaymentMethodUuid.get(p.paymentMethodUuid ?? '') ?? 0;
      paymentsByPaymentMethodUuid.set(p.paymentMethodUuid ?? '', currentTotal + currentAmount);
    });

    // Referrals
    const referralUuid = booking.referral?.uuid ?? '';
    const currentTotal = bookingsByReferralUuid.get(referralUuid) ?? 0;
    bookingsByReferralUuid.set(referralUuid, currentTotal + 1);
  });

  const averageDailyRate = consumedRoomsTimesNights > 0 ? ratesTotal / consumedRoomsTimesNights : 0;
  const revenuePerAvailableRoom =
    consumedRoomsTimesNights > 0 ? ratesTotal / totalRoomsTimesNights : 0;
  const totalRevenuePerAvailableRoom =
    consumedRoomsTimesNights > 0 ? (ratesTotal + chargesTotal) / totalRoomsTimesNights : 0;

  return (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex flex-wrap gap-2'>
        <Card title={t('pms:stats.revenue')} style={{ flexGrow: 1 }}>
          <>
            <div className='h1 mb-3'>{formatPriceInSmallestUnitToString(paymentsTotal, 'EUR')}</div>
            <div>
              <table className='table table-sm table-nowrap card-table'>
                <tbody>
                  {[...paymentMethods, { name: 'N/A', uuid: '' }].map((pm) => (
                    <tr key={pm.uuid}>
                      <td className='text-truncate'>{pm.name}</td>
                      <td className='text-end'>
                        {formatPriceInSmallestUnitToString(
                          paymentsByPaymentMethodUuid.get(pm.uuid) ?? 0,
                          'EUR',
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr className='text-muted fst-italic'>
                    <td>{t('pms:stats.expected')}</td>
                    <td className='text-end'>
                      {formatPriceInSmallestUnitToString(chargesTotal + ratesTotal, 'EUR')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </Card>
        <Card title={t('pms:stats.bookings')} style={{ flexGrow: 1 }}>
          <>
            <div className='h1 mb-3'>{totalBookings}</div>
            {totalBookings > 0 && (
              <div>
                <table className='table table-sm table-nowrap card-table'>
                  <tbody>
                    {[...referrals, { name: 'N/A', uuid: '' }].map((r) => (
                      <tr key={r.uuid}>
                        <td>{r.name}</td>
                        <td className='text-end'>{bookingsByReferralUuid.get(r.uuid) ?? 0}</td>
                        <td className='text-end'>
                          {Math.round(
                            ((bookingsByReferralUuid.get(r.uuid) ?? 0) / totalBookings) * 100,
                          )}
                          %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        </Card>
        <Card title={t('pms:stats.indicators.indicators')} style={{ flexGrow: 1 }}>
          <>
            <div className='h1 mb-3'>
              {t('pms:stats.indicators.occupancy')}:{' '}
              {Math.round((consumedRoomsTimesNights / totalRoomsTimesNights) * 100)}%
            </div>
            <div>
              <table className='table table-sm table-nowrap card-table'>
                <tbody>
                  <tr>
                    <td>{t('pms:stats.indicators.totalRoomsNights')}</td>
                    <td className='text-end'>{totalRoomsTimesNights}</td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.occupiedRoomsNights')}</td>
                    <td className='text-end'>{consumedRoomsTimesNights}</td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.averageDailyRate')}</td>
                    <td className='text-end'>
                      {formatPriceInSmallestUnitToString(averageDailyRate, 'EUR')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.revenuePerAvailableRoom')}</td>
                    <td className='text-end'>
                      {formatPriceInSmallestUnitToString(revenuePerAvailableRoom, 'EUR')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.totalRevenuePerAvailableRoom')}</td>
                    <td className='text-end'>
                      {formatPriceInSmallestUnitToString(totalRevenuePerAvailableRoom, 'EUR')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.totalBookings')}</td>
                    <td className='text-end'>{totalBookings}</td>
                  </tr>
                  <tr>
                    <td>{t('pms:stats.indicators.totalPax')}</td>
                    <td className='text-end'>
                      {adultsTotal + childrenTotal} ({adultsTotal}+{childrenTotal})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </Card>
      </div>
      <div className='w-100'>
        <Card title={t('pms:stats.bookings')}>
          <BookingsByDateChart
            startDate={startDate}
            endDate={endDate}
            bookingsByDate={bookingsByDate}
            availableRooms={totalRooms}
          />
        </Card>
      </div>
    </div>
  );
}

function BookingsByDateChart({
  startDate,
  endDate,
  bookingsByDate,
  availableRooms,
}: {
  startDate: LocalDate;
  endDate: LocalDate;
  bookingsByDate: Map<string, number>;
  availableRooms: number;
}) {
  const { t } = useTranslation(['pms']);

  const data = useMemo(() => {
    const bookingsData: { primary: Date; secondary: number }[] = [];
    const availableRoomsData: { primary: Date; secondary: number }[] = [];

    for (let date = startDate; !date.isAfter(endDate); date = date.plusDays(1)) {
      const bookings = bookingsByDate.get(date.toString()) ?? 0;
      bookingsData.push({
        primary: localDateToUTCJSDate(date),
        secondary: bookings,
      });

      availableRoomsData.push({
        primary: localDateToUTCJSDate(date),
        secondary: availableRooms,
      });
    }

    return [
      { label: t('pms:stats.occupiedRooms'), data: bookingsData },
      { label: t('pms:stats.availableRooms'), data: availableRoomsData },
    ];
  }, [startDate, endDate, bookingsByDate]);

  const primaryAxis = useMemo<AxisOptions<(typeof data)[number]['data'][number]>>(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<(typeof data)[number]['data'][number]>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
      },
    ],
    [],
  );

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
        }}
      />
    </div>
  );
}
