import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { MenuItemPrice } from '../api/menu-page';
import { useTranslation } from 'react-i18next';

const EMPTY_I18N = {};

type MenuItemPriceFormProps = {
  onCancel?: () => void;
  onSuccess?: () => void;
} & (
  | {
      action: 'create';
      menuPageItemUuid: string;
    }
  | {
      action: 'update';
      menuPageItemUuid: string;
      menuItemPrice: MenuItemPrice;
    }
);

export default function MenuItemPriceForm(props: MenuItemPriceFormProps) {
  const { t } = useTranslation(['menu']);
  const { action, onCancel } = props;
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formMenuPriceName, setFormMenuPriceName] = useState<Record<string, string>>({});
  const [formMenuPricePrice, setFormMenuPricePrice] = useState(0);
  const [formMenuPriceIsAvailable, setFormMenuPriceIsAvailable] = useState(true);
  const [formMenuPriceIsDisplayed, setFormMenuPriceIsDisplayed] = useState(true);

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
    setFormErrors,
    onSuccess: () => {
      setFormErrors(null);
      props.onSuccess?.();
    },
  };
  const createPrice = createMutation(trpc.menu.admin.createMenuItemPrice, mutationOptions);
  const updatePrice = createMutation(trpc.menu.admin.updateMenuItemPrice, mutationOptions);
  const deletePrice = createMutation(trpc.menu.admin.deleteMenuItemPrice, mutationOptions);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name: formMenuPriceName,
      price: formMenuPricePrice,
      isAvailable: formMenuPriceIsAvailable,
      isDisplayed: formMenuPriceIsDisplayed,
    };

    if (action === 'create') {
      createPrice.mutate({ ...data, itemUuid: props.menuPageItemUuid });
    } else {
      updatePrice.mutate({ ...data, uuid: props.menuItemPrice.uuid });
    }
  };

  const isUpserting = createPrice.isLoading || updatePrice.isLoading;
  const isDeleting = deletePrice.isLoading;
  const isLoading = isUpserting || isDeleting;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col'>
          <FormInput
            type='i18n'
            title={t('menu:priceForm.priceName')}
            name='priceName'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={action === 'update' ? props.menuItemPrice.name.translations : EMPTY_I18N}
            onChange={setFormMenuPriceName}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <FormInput
            type='number'
            title={t('menu:priceForm.pricePrice')}
            name='pricePrice'
            errorPath={['price']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={String(action === 'update' ? props.menuItemPrice.price / 100 : 0)}
            onChange={(v) =>
              setFormMenuPricePrice(parseInt(parseFloat(v).toFixed(2).replace('.', '')))
            }
            step='0.01'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <FormInput
            type='checkbox'
            title={t('menu:priceForm.available')}
            name='priceIsAvailable'
            errorPath={['isAvailable']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={action === 'update' ? props.menuItemPrice.isAvailable : true}
            onChange={setFormMenuPriceIsAvailable}
            checkboxValue='available'
          />
        </div>
        <div className='col-6'>
          <FormInput
            type='checkbox'
            title={t('menu:priceForm.showInMenu')}
            name='priceIsDisplayed'
            errorPath={['isDisplayed']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={action === 'update' ? props.menuItemPrice.isDisplayed : true}
            onChange={setFormMenuPriceIsDisplayed}
            checkboxValue='displayed'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='row'>
            {action === 'update' && (
              <div className='col-2'>
                <Button
                  type='button'
                  className='btn btn-icon btn-outline-danger w-100'
                  isLoading={isDeleting}
                  disabled={isLoading}
                  hideContentWhenLoading
                  onClick={() => {
                    if (confirm('Are you sure you want to delete this price?')) {
                      deletePrice.mutate({ uuid: props.menuItemPrice.uuid });
                    }
                  }}
                >
                  <IconTrash size='1em' />
                </Button>
              </div>
            )}
            {onCancel && (
              <div className={action === 'update' ? 'col-4' : 'col-6'}>
                <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
                  {t('menu:form.cancel')}
                </Button>
              </div>
            )}
            <div className='col-6'>
              <Button disabled={isLoading} isLoading={isUpserting} hideContentWhenLoading>
                {action === 'create' ? t('menu:form.create') : t('menu:form.update')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
