import { IconCheck, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { createMutation } from '../../../../../../../utils/api';
import { trpc } from '../../../../../../../utils/trpc';
import { usePmsContext } from '../../../../../Context';
import { RateSeason } from '../../../../../api/rates/rate-seasons';
import { Rate } from '../../../../../api/rates/rates';
import { UnitType } from '../../../../../api/units/unit-types';
import FormInput from '../../../../../../common/components/form/input';
import Button from '../../../../../../common/components/form/Button';
import { RateOption } from '../../../../../api/rates/rate-options';

function RateTd({
  rateOption,
  rateSeason,
  unitType,
  rate,
}: {
  rateOption: RateOption;
  rateSeason: RateSeason;
  unitType: UnitType;
  rate?: Rate;
}) {
  const [price, setPrice] = useState('');

  const trpcContext = trpc.useUtils();
  const mutationOptions = { invalidate: [trpcContext.pms.rate] };
  const createRate = createMutation(trpc.pms.rate.createRate, mutationOptions);
  const updateRate = createMutation(trpc.pms.rate.updateRate, mutationOptions);
  const deleteRate = createMutation(trpc.pms.rate.deleteRate, mutationOptions);

  useEffect(() => {
    setPrice(rate?.price.toString() ?? '');
  }, [rate?.price]);

  if (rate) {
    const hasChanged = price !== rate.price.toString();
    const width = hasChanged || price !== '' ? 'calc(120px - 36px)' : '120px';

    return (
      <td>
        <div className='input-group justify-content-center'>
          <FormInput
            type='number'
            name='price'
            className='form-control'
            defaultValue={price}
            onChange={setPrice}
            style={{ width: width, maxWidth: width }}
            showHtmlInputOnly
            disabled={updateRate.isLoading || deleteRate.isLoading}
          />
          {hasChanged ? (
            <Button
              className='btn btn-icon btn-success'
              type='button'
              onClick={() => {
                updateRate.mutate({
                  uuid: rate.uuid,
                  price: parseFloat(price),
                });
              }}
              isLoading={updateRate.isLoading}
              hideContentWhenLoading
            >
              <IconCheck size='1em' />
            </Button>
          ) : price !== '' ? (
            <Button
              className='btn btn-icon btn-outline-danger'
              type='button'
              onClick={() => {
                deleteRate.mutate({
                  uuid: rate.uuid,
                });
              }}
              isLoading={deleteRate.isLoading}
              hideContentWhenLoading
            >
              <IconTrash size='1em' />
            </Button>
          ) : null}
        </div>
      </td>
    );
  }

  const hasChanged = price !== '';
  const width = hasChanged || price !== '' ? 'calc(120px - 36px)' : '120px';

  return (
    <td>
      <div className='input-group justify-content-center'>
        <FormInput
          type='number'
          name='price'
          className='form-control'
          placeholder='Set price'
          defaultValue={price}
          onChange={setPrice}
          style={{ width: width, maxWidth: width }}
          showHtmlInputOnly
          disabled={createRate.isLoading}
        />
        {hasChanged && (
          <Button
            className='btn btn-icon btn-success'
            type='button'
            onClick={() => {
              createRate.mutate({
                optionUuid: rateOption.uuid,
                seasonUuid: rateSeason.uuid,
                unitTypeUuid: unitType.uuid,
                price: parseFloat(price),
              });
            }}
            isLoading={createRate.isLoading}
            hideContentWhenLoading
          >
            <IconCheck size='1em' />
          </Button>
        )}
      </div>
    </td>
  );
}

interface Props {
  accommodationUuid: string;
}
export default function UnitTypesRatesList({ accommodationUuid }: Props) {
  const {
    unitTypes: allUnitTypes,
    rates,
    rateOptions,
    rateSeasons: allRateSeasons,
  } = usePmsContext();

  const unitTypes = allUnitTypes.filter(
    (unitType) => unitType.accommodationUuid === accommodationUuid,
  );
  const rateSeasons = allRateSeasons.filter((rateSeason) =>
    rateSeason.accommodations.some((a) => a.uuid === accommodationUuid),
  );

  return (
    <div className='table-responsive'>
      <table className='table table-vcenter card-table table-bordered'>
        <thead>
          <tr>
            <th />
            {rateSeasons.map((rateSeason) => (
              <th key={`TableUnitSeason-rateSeason-${rateSeason.uuid}`} className='text-center'>
                {`${rateSeason.name}`}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {unitTypes.map((unitType) => (
            <>
              <tr key={unitType.uuid}>
                <td className='bg-dark text-white' colSpan={rateSeasons.length + 1}>
                  {unitType.name}
                </td>
              </tr>
              {rateOptions.map((rateOption) => (
                <>
                  <tr key={rateOption.uuid}>
                    <td>{rateOption.name}</td>
                    {rateSeasons.map((rateSeason) => (
                      <RateTd
                        key={`TableUnitSeason-rate-${rateSeason.uuid}`}
                        rateOption={rateOption}
                        rateSeason={rateSeason}
                        unitType={unitType}
                        rate={rates.find(
                          (r) =>
                            r.unitType.uuid === unitType.uuid &&
                            r.optionUuid === rateOption.uuid &&
                            r.season.uuid === rateSeason.uuid,
                        )}
                      />
                    ))}
                  </tr>
                </>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}
