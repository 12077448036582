import { dateRange } from '../../../utils/date';
import type { Booking } from '../api/bookings/bookings';
import { UnitType } from '../api/units/unit-types';
import { Unit } from '../api/units/units';
import { SelectedUnits } from '../types';
import { LocalDate } from '@js-joda/core';

export const DEFAULT_ADULTS = 2;
export const DEFAULT_CHILDREN = 0;

export function isUnitSelectedInDateRange(
  unit: Unit,
  from: LocalDate,
  to: LocalDate,
  selectedUnits: SelectedUnits,
) {
  const newArrivalDate = from;
  const newDepartureDate = to;

  return selectedUnits.some((selectedUnit) => {
    const existingArrivalDate = selectedUnit.from;
    const existingDepartureDate = selectedUnit.to;

    // existingArrivalDate is between newArrivalDate and newDepartureDate
    const check1 =
      existingArrivalDate.isBefore(newDepartureDate) && existingArrivalDate.isAfter(newArrivalDate);

    // existingDepartureDate is between newArrivalDate and newDepartureDate
    const check2 =
      existingDepartureDate.isAfter(newArrivalDate) &&
      existingDepartureDate.isBefore(newDepartureDate);

    // [newArrivalDate, newDepartureDate] is contained in
    // [existingArrivalDate, existingDepartureDate]
    const check3 =
      existingArrivalDate.compareTo(newArrivalDate) <= 0 &&
      existingDepartureDate.compareTo(newDepartureDate) >= 0;

    return selectedUnit.unitUuid === unit.uuid && (check1 || check2 || check3);
  });
}

export const getUnitDataById = (unitTypes: UnitType[], unitUuid: string) => {
  for (const unitType of unitTypes) {
    for (const unit of unitType.units) {
      if (unit.uuid === unitUuid) {
        return {
          unitName: unit.name,
          unitTypeName: unitType.name,
        };
      }
    }
  }
  return {
    unitName: 'N/A',
    unitTypeName: 'N/A',
  };
};

export function getOverbookings(bookings: Booking[]) {
  // Map of unitUuid-date --> booking UUIDs
  const unitDatesToBookings = new Map<string, Booking[]>();
  const unitDatesWithOverbooking = new Set<string>();

  for (const booking of bookings) {
    for (const bookingUnit of booking.bookingUnits) {
      const checkInDate = LocalDate.parse(bookingUnit.arrivalDate);
      const checkOutDate = LocalDate.parse(bookingUnit.departureDate);

      for (const date of dateRange(checkInDate, checkOutDate)) {
        const key = `${bookingUnit.unitUuid}|${date.toString()}`;
        const bookingUuids = unitDatesToBookings.get(key) || [];
        bookingUuids.push(booking);
        unitDatesToBookings.set(key, bookingUuids);
        if (bookingUuids.length > 1) {
          unitDatesWithOverbooking.add(key);
        }
      }
    }
  }

  const overbookings: Array<{ unitUuid: string; date: LocalDate; bookings: Booking[] }> = [];
  unitDatesWithOverbooking.forEach((key) => {
    const [unitUuid, date] = key.split('|');
    overbookings.push({
      unitUuid,
      date: LocalDate.parse(date),
      bookings: unitDatesToBookings.get(key) || [],
    });
  });

  return overbookings;
}
