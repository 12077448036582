import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePmsContext } from '../../../Context';

function AccommodationsList() {
  const { t } = useTranslation(['pms']);
  const { accommodations, unitTypes } = usePmsContext();

  return (
    <>
      <div className='alert alert-info mt-3'>{t('pms:accommodation.hint')}</div>
      {accommodations.map((accommodation) => {
        const accommodationUnitTypes = unitTypes.filter(
          (unitType) => unitType.accommodationUuid === accommodation.uuid,
        );
        return (
          <div key={`AccommodationsList-accommodation-${accommodation.uuid}`} className='mt-3 card'>
            <div className='card-body'>
              <div className='d-flex justify-content-between'>
                <span>{accommodation.name}</span>
                <Link to={`./${accommodation.uuid}`} className='btn btn-primary ms-1'>
                  {t('pms:form.edit')}
                </Link>
              </div>
              <ul className='text-danger'>
                {accommodationUnitTypes.length === 0 && (
                  <li>{t('pms:accommodation.noUnitTypes')}</li>
                )}
                {accommodationUnitTypes
                  .filter((unitType) => unitType.units.length === 0)
                  .map((unitType) => (
                    <li key={`AccommodationsList-unitType-noUnits-${unitType.uuid}`}>
                      {t('pms:accommodation.noUnits', { unitTypeName: unitType.name })}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default function ViewAccommodations() {
  const { t } = useTranslation(['pms']);

  return (
    <>
      <Link to='./new' className='btn btn-primary w-100'>
        <IconPlus />
        {t('pms:accommodation.form.createNewAccommodation')}
      </Link>
      <AccommodationsList />
    </>
  );
}
