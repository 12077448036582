/**
 * Shows the image as it will be displayed in the public menu.
 */
export default function PublicMenuImagePreview({
  src,
  width,
  height,
}: {
  src: string;
  width?: number;
  height?: number;
}) {
  return (
    <img
      src={src}
      style={{
        objectFit: 'cover',
        minWidth: width || '100px',
        width: width || '100px',
        minHeight: height || '100px',
        height: height || '100px',
        borderRadius: '16px',
      }}
      className='border'
    />
  );
}
