import { useLanguageContext } from '../contexts/LanguageContext';
import FormInput from './form/input';

function LanguageSelector() {
  const { availableLanguages, selectedLanguage, setSelectedLanguage } = useLanguageContext();

  return (
    <FormInput
      type='select'
      name='language'
      defaultValue={selectedLanguage.code}
      onChange={(code) => setSelectedLanguage(code)}
    >
      {availableLanguages.map((language) => (
        <option key={`select-language-${language.code}`} value={language.code}>
          {language.name}
        </option>
      ))}
    </FormInput>
  );
}

export default LanguageSelector;
