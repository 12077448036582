import { useState } from 'react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { PaymentMethod } from '../../pms/api/payment-methods';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface CreatePaymentMethodFormProps {
  action: 'create';
  paymentMethodUuid?: string;
  paymentMethod?: undefined;
  onSuccess: () => void;
  onCancel: () => void;
  allowDelete?: undefined;
}
interface UpdatePaymentMethodFormProps {
  action: 'update';
  paymentMethodUuid: string;
  paymentMethod: PaymentMethod;
  onSuccess: () => void;
  onCancel: () => void;
  allowDelete: boolean;
}
type PaymentMethodFormProps = CreatePaymentMethodFormProps | UpdatePaymentMethodFormProps;

export default function PaymentMethodForm({
  action,
  paymentMethodUuid,
  paymentMethod,
  onSuccess,
  onCancel,
  allowDelete,
}: PaymentMethodFormProps) {
  const { t } = useTranslation(['pms']);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const trpcContext = trpc.useUtils();
  const options = {
    invalidate: [trpcContext.pay.paymentMethod],
    onSuccess,
    setFormErrors,
  };
  const createPaymentMethod = createMutation(trpc.pay.paymentMethod.createPaymentMethod, options);
  const updatePaymentMethod = createMutation(trpc.pay.paymentMethod.updatePaymentMethod, options);
  const deletePaymentMethod = createMutation(trpc.pay.paymentMethod.deletePaymentMethod, options);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const data = {
      name: form.paymentMethodName.value,
    };

    if (action === 'create') {
      createPaymentMethod.mutate(data);
    } else {
      updatePaymentMethod.mutate({ uuid: paymentMethodUuid, ...data });
    }
  };

  const disabled =
    createPaymentMethod.isLoading || updatePaymentMethod.isLoading || deletePaymentMethod.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <FormInput
            type='text'
            name='paymentMethodName'
            title={null}
            placeholder={t('pms:configuration.paymentMethodSettings.form.name')}
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={paymentMethod?.name}
          />
        </div>
        <div className='row col-md-6'>
          {allowDelete && (
            <div className='col-2'>
              <Button
                type='button'
                className='btn btn-icon btn-outline-danger w-100'
                isLoading={deletePaymentMethod.isLoading}
                disabled={disabled}
                hideContentWhenLoading
                onClick={() => {
                  if (confirm('Are you sure?')) {
                    deletePaymentMethod.mutate({ uuid: paymentMethod.uuid });
                  }
                }}
              >
                <IconTrash size='1em' />
              </Button>
            </div>
          )}
          <div className={allowDelete ? 'col-4' : 'col-6'}>
            <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
              {t('pms:form.cancel')}
            </Button>
          </div>
          <div className='col-6'>
            <Button
              disabled={disabled}
              isLoading={createPaymentMethod.isLoading || updatePaymentMethod.isLoading}
              hideContentWhenLoading
            >
              {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
