/**
 * Formats a price in cents to a string in the smallest unit of the currency.
 * E.g. 1000 cents EUR -> 10.00 €
 * E.g. 1000 cents USD -> $10.00
 * E.g. 1000 cents JPY -> ¥1000
 */
export function formatPriceInSmallestUnitToString(amountInCents: number, currencyCode: string) {
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const priceInCurrency = amountInCents / 100;
  return currencyFormatter.format(priceInCurrency);
}
