import { Navigate, Route, Routes } from 'react-router-dom';
import Page from '../../../common/components/dashboard/Page';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ViewPaymentProviders from '../../../account/payment-methods/ViewPaymentProviders';
import Breadcrumbs from '../../../common/components/Breadcrumbs';
import ConfigurationIndexItem from '../../../common/components/ConfigurationIndexItem';
import { useTranslation } from 'react-i18next';
import { useMenuContext } from '../../Context';

export default function ConfigurationPage() {
  const { t } = useTranslation(['menu']);
  const [parentRef] = useAutoAnimate();

  const pathToLabel = (path: string) => {
    switch (path) {
      case 'configuration':
        return t('menu:navbar.configuration');
      case 'payment-methods':
        return t('menu:configuration.paymentMethods');
    }
    return undefined;
  };

  return (
    <Page title={t('menu:navbar.configuration')}>
      <div ref={parentRef}>
        <Breadcrumbs startFromPath='configuration' pathToLabel={pathToLabel} />
        <div className='mb-3' />
        <Routes>
          <Route index element={<ConfigurationIndex />} />
          <Route path='/payment-methods' element={<PaymentMethods />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </div>
    </Page>
  );
}

function ConfigurationIndex() {
  const { t } = useTranslation(['menu']);
  return (
    <ConfigurationIndexItem
      to='./payment-methods'
      title={t('menu:configuration.paymentMethods')}
      hint={t('menu:configuration.paymentMethodsHint')}
    />
  );
}

function PaymentMethods() {
  const { t } = useTranslation(['pms']);
  const { paymentMethods } = useMenuContext();

  return (
    <>
      <h1>{t('pms:configuration.onlinePaymentMethods')}</h1>
      <div className='alert alert-info'>{t('pms:configuration.onlinePaymentMethodsHint')}</div>
      <ViewPaymentProviders paymentMethods={paymentMethods} />
    </>
  );
}
