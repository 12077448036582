import LoadingSpinner from './LoadingSpinner';

export default function LoadingSpinnerPageCenter() {
  return (
    <div
      style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <LoadingSpinner />
    </div>
  );
}
