import toast from 'react-hot-toast';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingSpinner from '../common/components/LoadingSpinner';
import { PmsContextProvider, usePmsContext } from './Context';
import Bookings from './pages/bookings';
import ViewBookingsDashboard from './pages/bookings/ViewBookingsDashboard';
import Configuration from './pages/configuration/Configuration';
import Planning from './pages/planning/Planning';
import Stats from './pages/stats/Stats';
import ErrorAlert from '../common/components/ErrorAlert';
import { useTranslation } from 'react-i18next';

function CurrentAccommodationsCheckboxes() {
  const { accommodations, currentAccommodations, setCurrentAccommodationsUuids } = usePmsContext();

  const currentAccommodationsUuids = currentAccommodations.map(
    (accommodation) => accommodation.uuid,
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let updatedCurrentAccommodationsUuids: string[];

    if (checked) {
      updatedCurrentAccommodationsUuids = [...currentAccommodationsUuids, value];
    } else {
      updatedCurrentAccommodationsUuids = currentAccommodationsUuids.filter(
        (uuid) => uuid !== value,
      );
    }

    setCurrentAccommodationsUuids(updatedCurrentAccommodationsUuids);
  };

  return (
    <div>
      {accommodations.map((accommodation) => (
        <label key={accommodation.uuid} className='form-check form-check-inline'>
          <input
            type='checkbox'
            className='form-check-input'
            value={accommodation.uuid}
            checked={currentAccommodationsUuids.includes(accommodation.uuid)}
            onChange={handleCheckboxChange}
          />
          <span className='form-check-label'>{accommodation.name}</span>
        </label>
      ))}
    </div>
  );
}

function WithCurrentAccommodations({ children }: { children: JSX.Element }) {
  const { accommodations, currentAccommodations, setCurrentAccommodationsUuids } = usePmsContext();

  // If there are no accommodations, redirect to the accommodations config page
  if (accommodations.length === 0) {
    toast.error('Please create an accommodation');
    return <Navigate to='/pms/configuration/accommodations' />;
  }

  // If there is no current accommodation, set the first one as current
  if (currentAccommodations.length === 0) {
    setCurrentAccommodationsUuids([accommodations[0].uuid]);
    return <LoadingSpinner center cardBody />;
  }

  return (
    <>
      {accommodations.length > 1 && (
        <div className='mb-3'>
          <CurrentAccommodationsCheckboxes />
        </div>
      )}
      {children}
    </>
  );
}

function MisconfiguredPmsCheck() {
  const { t } = useTranslation(['pms']);
  const { accommodations, unitTypes } = usePmsContext();

  const errors: string[] = [];

  if (accommodations.length === 0) {
    errors.push(t('pms:misconfiguredPmsErrors.noAccommodations'));
  }

  accommodations.forEach((accommodation) => {
    const accommodationUnitTypes = unitTypes.filter(
      (unitType) => unitType.accommodationUuid === accommodation.uuid,
    );

    if (accommodationUnitTypes.length === 0) {
      errors.push(
        t('pms:misconfiguredPmsErrors.noUnitTypes', { accommodationName: accommodation.name }),
      );
    }

    accommodationUnitTypes.forEach((unitType) => {
      if (unitType.units.length === 0) {
        errors.push(
          t('pms:misconfiguredPmsErrors.noUnits', {
            accommodationName: accommodation.name,
            unitTypeName: unitType.name,
          }),
        );
      }
    });
  });

  if (errors.length === 0) {
    return null;
  }

  return <ErrorAlert errors={errors} />;
}

export default function Router() {
  return (
    <PmsContextProvider>
      <MisconfiguredPmsCheck />
      <Routes>
        <Route path='/configuration/*' element={<Configuration />} />
        <Route
          path='*'
          element={
            <WithCurrentAccommodations>
              <Routes>
                <Route index element={<ViewBookingsDashboard />} />
                <Route path='/bookings/*' element={<Bookings />} />
                <Route path='/planning' element={<Planning />} />
                <Route path='/stats' element={<Stats />} />
              </Routes>
            </WithCurrentAccommodations>
          }
        />
      </Routes>
    </PmsContextProvider>
  );
}
