import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { z } from 'zod';
import Breadcrumbs from '../../../common/components/Breadcrumbs';
import Page from '../../../common/components/dashboard/Page';
import { usePmsContext } from '../../Context';
import Accommodations from './accommodations';
import BookingEnginePages from './booking-engine-pages';
import PaymentMethods from './payment-methods';
import Referrals from './referrals';
import { useTranslation } from 'react-i18next';
import ConfigurationIndexItem from '../../../common/components/ConfigurationIndexItem';
import ChannelManager from './channel-manager';
import NotificationMethods from './notification-methods';
import BookingGuestNotifications from './booking-guest-notifications';

function ConfigurationIndex() {
  const { t } = useTranslation(['pms']);

  return (
    <>
      <ConfigurationIndexItem
        to='./accommodations'
        title={t('pms:configuration.accommodations')}
        hint={t('pms:configuration.accommodationsHint')}
      />
      <ConfigurationIndexItem
        to='./referrals'
        title={t('pms:configuration.referrals')}
        hint={t('pms:configuration.referralsHint')}
      />
      <ConfigurationIndexItem
        to='./payment-methods'
        title={t('pms:configuration.paymentMethods')}
        hint={t('pms:configuration.paymentMethodsHint')}
      />
      <ConfigurationIndexItem
        to='./booking-engines'
        title={t('pms:configuration.bookingEngines')}
        hint={t('pms:configuration.bookingEnginesHint')}
      />
      <ConfigurationIndexItem
        to='./channel-manager'
        title={t('pms:configuration.channelManager')}
        hint={t('pms:configuration.channelManagerHint')}
      />
      <ConfigurationIndexItem
        to='./notification-methods'
        title={t('pms:configuration.notificationMethods')}
        hint={t('pms:configuration.notificationMethodsHint')}
      />
      <ConfigurationIndexItem
        to='./booking-guest-notifications'
        title={t('pms:configuration.bookingGuestNotifications')}
        hint={t('pms:configuration.bookingGuestNotificationsHint')}
      />
    </>
  );
}

export default function Configuration() {
  const { t } = useTranslation(['pms']);
  const { accommodations } = usePmsContext();
  const [parentRef] = useAutoAnimate();

  const pathToLabel = (path: string) => {
    switch (path) {
      case 'configuration':
        return t('pms:navbar.configuration');
      case 'accommodations':
        return t('pms:configuration.accommodations');
      case 'referrals':
        return t('pms:configuration.referrals');
      case 'payment-methods':
        return t('pms:configuration.paymentMethods');
      case 'booking-engines':
        return t('pms:configuration.bookingEngines');
      case 'channel-manager':
        return t('pms:configuration.channelManager');
      case 'notification-methods':
        return t('pms:configuration.notificationMethods');
      case 'booking-guest-notifications':
        return t('pms:configuration.bookingGuestNotifications');
    }

    const { success: isUuid } = z.string().uuid().safeParse(path);
    if (isUuid) {
      const accommodation = accommodations.find((a) => a.uuid === path);
      if (accommodation) {
        return accommodation.name;
      }
    }

    return undefined;
  };
  return (
    <Page title={t('pms:navbar.configuration')}>
      <div ref={parentRef}>
        <Breadcrumbs startFromPath='configuration' pathToLabel={pathToLabel} />
        <div className='mb-3' />
        <Routes>
          <Route index element={<ConfigurationIndex />} />
          <Route path='/accommodations/*' element={<Accommodations />} />
          <Route path='/referrals/*' element={<Referrals />} />
          <Route path='/payment-methods/*' element={<PaymentMethods />} />
          <Route path='/booking-engines/*' element={<BookingEnginePages />} />
          <Route path='/channel-manager/*' element={<ChannelManager />} />
          <Route path='/notification-methods/*' element={<NotificationMethods />} />
          <Route path='/booking-guest-notifications/*' element={<BookingGuestNotifications />} />
          <Route path='*' element={<Navigate to='/pms/configuration' />} />
        </Routes>
      </div>
    </Page>
  );
}
