import { IconBrandTelegram, IconMail } from '@tabler/icons-react';
import Card from '../../common/components/Card';
import { useCompanyContext } from '../../common/contexts/CompanyContext';
import NotificationMethodItem from './NotificationMethodItem';
import CreateEmailNotificationMethodButton from './providers/email/CreateEmailNotificationMethodButton';
import CreateTelegramNotificationMethodButton from './providers/email/CreateTelegramNotificationMethodButton';

export default function ViewNotificationMethods() {
  const { notificationMethods: notificationMethod } = useCompanyContext();

  const emailNotificationMethods = notificationMethod.filter(
    (method) => method.data.type === 'email',
  );
  const telegramNotificationMethods = notificationMethod.filter(
    (method) => method.data.type === 'telegram',
  );

  const hasEmailNotificationMethod = emailNotificationMethods.length > 0;
  const hasUnverifiedTelegramNotificationMethod =
    telegramNotificationMethods.filter((m) => !m.isVerified).length > 0;

  return (
    <>
      <Card
        title={
          <span>
            <IconMail size='1em' /> Email
          </span>
        }
      >
        <div className='d-flex flex-column gap-2'>
          {!hasEmailNotificationMethod && <CreateEmailNotificationMethodButton />}
          {emailNotificationMethods.map((method) => (
            <Card key={method.uuid}>
              <NotificationMethodItem notificationMethod={method} />
            </Card>
          ))}
        </div>
      </Card>
      <div className='mt-4' />
      <Card
        title={
          <span>
            <IconBrandTelegram size='1em' /> Telegram
          </span>
        }
      >
        <div className='d-flex flex-column gap-2'>
          {telegramNotificationMethods.map((method) => (
            <Card key={method.uuid}>
              <NotificationMethodItem notificationMethod={method} />
            </Card>
          ))}
          {!hasUnverifiedTelegramNotificationMethod && <CreateTelegramNotificationMethodButton />}
        </div>
      </Card>
    </>
  );
}
