import { IconPlus } from '@tabler/icons-react';
import { createMutation, trpcErrorParser } from '../../../../utils/api';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Button from '../../../common/components/form/Button';
import { PaymentMethod } from '../../../pms/api/payment-methods';
import { PaymentMethodElement } from '../PaymentMethodsList';
import Card from '../../../common/components/Card';
import { useTranslation } from 'react-i18next';

enum Status {
  NotStarted,
  Started,
  Completed,
}

function StripeShowPaymentMethod({
  paymentMethods,
  paymentMethodStripeId,
}: {
  paymentMethodStripeId: string | null;
  paymentMethods: PaymentMethod[];
}) {
  return (
    <>
      {paymentMethods
        .filter((paymentMethod) => paymentMethod.provider?.id === paymentMethodStripeId)
        .map((paymentMethod) => {
          return (
            <div
              key={`Stripe-paymentmethod-${paymentMethod.uuid}`}
              className='list-group list-group-flush list-group-hoverable'
            >
              <div className='list-group-item'>
                <PaymentMethodElement paymentMethod={paymentMethod} />
              </div>
            </div>
          );
        })}
    </>
  );
}

function StripeAction({
  status,
  paymentMethodStripeId,
  onClick,
  isLoading,
  paymentMethods,
}: {
  status: Status | null;
  paymentMethodStripeId: string | null;
  onClick: () => void;
  isLoading: boolean;
  paymentMethods: PaymentMethod[];
}) {
  const { t } = useTranslation(['pms']);

  if (status === null && !isLoading) {
    return null;
  }

  if (status === Status.Completed) {
    return (
      <StripeShowPaymentMethod
        paymentMethods={paymentMethods}
        paymentMethodStripeId={paymentMethodStripeId}
      />
    );
  }

  return (
    <Button type='button' isLoading={isLoading} hideContentWhenLoading onClick={onClick}>
      <IconPlus />
      {status === Status.Started
        ? t('pms:configuration.paymentMethodSettings.stripeForm.continue')
        : t('pms:configuration.paymentMethodSettings.stripeForm.start')}
    </Button>
  );
}

export default function Stripe({ paymentMethods }: { paymentMethods: PaymentMethod[] }) {
  const { t } = useTranslation(['pms']);
  const createStripeOnboardingUrl = createMutation(
    trpc.pay.providers.stripe.createStripeConnectLink,
    {},
  );

  const startSetupOnClick = async () => {
    try {
      const { redirectUrl } = await createStripeOnboardingUrl.mutateAsync();
      window.location.href = redirectUrl;
    } catch (error) {
      trpcErrorParser({ error });
    }
  };

  const getAccountStatus = trpc.pay.providers.stripe.getAccountStatus.useQuery();

  const isLoading = getAccountStatus.isLoading;
  if (isLoading) {
    return <LoadingSpinner center cardBody />;
  }

  if (!getAccountStatus.data) {
    return <ErrorAlert errors={getAccountStatus.error} />;
  }

  let status;
  if (getAccountStatus.data.processCompleted) {
    status = Status.Completed;
  } else if (getAccountStatus.data.processStarted) {
    status = Status.Started;
  } else {
    status = Status.NotStarted;
  }

  const paymentMethodStripeId = getAccountStatus.data.processStarted
    ? getAccountStatus.data.uuid
    : null;

  return (
    <Card title='Stripe' style={{ flexGrow: 1 }}>
      <span>{t('pms:configuration.paymentMethodSettings.stripeForm.description')}</span>
      {'stripeAccountId' in getAccountStatus.data && (
        <div className='mt-3'>
          <span>
            {t('pms:configuration.paymentMethodSettings.stripeForm.stripeAccountId')}:{' '}
            <code>{getAccountStatus.data.stripeAccountId}</code>
          </span>
        </div>
      )}
      <div className='mt-3'>
        {isLoading && <LoadingSpinner center />}
        <StripeAction
          status={status}
          onClick={() => {
            startSetupOnClick();
          }}
          isLoading={isLoading || createStripeOnboardingUrl.isLoading}
          paymentMethods={paymentMethods}
          paymentMethodStripeId={paymentMethodStripeId}
        />
      </div>
    </Card>
  );
}
