import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../common/components/form/Button';
import PaymentMethodForm from './PaymentMethodForm';
import PaymentMethodsList from './PaymentMethodsList';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../pms/api/payment-methods';

export default function ViewPaymentMethods({
  paymentMethods,
}: {
  paymentMethods: PaymentMethod[];
}) {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [showNewPaymentMethodForm, setShowNewPaymentMethodForm] = useState(false);

  return (
    <div ref={parentRef}>
      {showNewPaymentMethodForm ? (
        <div className='card'>
          <div className='card-body'>
            <PaymentMethodForm
              action='create'
              onCancel={() => setShowNewPaymentMethodForm(false)}
              onSuccess={() => {
                setShowNewPaymentMethodForm(false);
              }}
            />
          </div>
        </div>
      ) : (
        <Button
          type='button'
          onClick={() => {
            setShowNewPaymentMethodForm(true);
          }}
        >
          <IconPlus />
          {t('pms:configuration.paymentMethodSettings.addNewPaymentMethod')}
        </Button>
      )}
      <PaymentMethodsList paymentMethods={paymentMethods} />
    </div>
  );
}
