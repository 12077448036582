import { forwardRef } from 'react';
import LoadingSpinner from '../LoadingSpinner';

type Props = {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  hideContentWhenLoading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  title?: string | null;
  ref?: React.Ref<HTMLButtonElement>;
};

function ButtonComponent({
  children,
  type,
  isLoading,
  hideContentWhenLoading,
  disabled,
  className,
  style,
  onClick,
  title,
  ref,
}: Props) {
  return (
    <button
      type={type}
      className={className}
      style={style}
      disabled={disabled || isLoading}
      onClick={onClick}
      title={title ?? undefined}
      ref={ref}
    >
      {isLoading && <LoadingSpinner className='spinner-border spinner-border-sm m-0 icon' />}
      {!hideContentWhenLoading || !isLoading ? children : null}
    </button>
  );
}

// TODO: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.
ButtonComponent.defaultProps = {
  isLoading: false,
  hideContentWhenLoading: false,
  disabled: false,
  type: 'submit',
  className: 'btn btn-primary w-100',
  onClick: () => {},
  title: null,
};

const Button = forwardRef(function Button(props: Props, ref: React.Ref<HTMLButtonElement>) {
  return <ButtonComponent {...props} ref={ref} />;
});

export default Button;
