import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../../../common/components/form/Button';
import { usePmsContext } from '../../../../../Context';
import { RateOption } from '../../../../../api/rates/rate-options';
import RateOptionForm from './RateOptionForm';

function RateOptionElement({ rateOption }: { rateOption: RateOption }) {
  const [showEditRateOptionForm, setShowEditRateOptionForm] = useState(false);

  if (showEditRateOptionForm) {
    return (
      <div className='mb-2 card'>
        <div className='card-body'>
          <RateOptionForm
            action='update'
            rateOptionUuid={rateOption.uuid}
            rateOption={rateOption}
            onSuccess={() => setShowEditRateOptionForm(false)}
            onCancel={() => setShowEditRateOptionForm(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='card card-body mb-2'>
      <div className='d-flex justify-content-between'>
        <div className='text-truncate mb-2'>{rateOption.name}</div>
        <Button
          type='button'
          className='btn btn-icon ms-1'
          onClick={() => {
            setShowEditRateOptionForm(true);
          }}
        >
          <IconPencil size='1em' />
        </Button>
      </div>
    </div>
  );
}

export default function RateOptionsList() {
  const { rateOptions } = usePmsContext();

  return (
    <>
      {rateOptions.map((option) => (
        <RateOptionElement
          key={`RateOptionElement-rateOption-${option.uuid}`}
          rateOption={option}
        />
      ))}
    </>
  );
}
