import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { MenuItem } from '../api/menu-page';

const EMPTY_I18N = {};

type MenuItemFormProps = {
  onCancel?: () => void;
  onSuccess?: (uuid: string) => void;
} & (
  | {
      action: 'create';
    }
  | {
      action: 'update';
      menuItem: MenuItem;
    }
);

export default function MenuItemForm(props: MenuItemFormProps) {
  const { action, onCancel } = props;
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formMenuItemName, setFormMenuItemName] = useState<Record<string, string>>({});
  const [formMenuItemDescription, setFormMenuItemDescription] = useState<Record<string, string>>(
    {},
  );

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
    setFormErrors,
    onSuccess: (data: { uuid: string }) => {
      setFormErrors(null);
      props.onSuccess?.(data.uuid);
    },
  };
  const createItem = createMutation(trpc.menu.admin.createMenuItem, mutationOptions);
  const updateItem = createMutation(trpc.menu.admin.updateMenuItemMetadata, mutationOptions);
  const deleteItem = createMutation(trpc.menu.admin.deleteMenuItem, mutationOptions);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name: formMenuItemName,
      description: formMenuItemDescription,
    };

    if (action === 'create') {
      createItem.mutate(data);
    } else {
      updateItem.mutate({ ...data, uuid: props.menuItem.uuid });
    }
  };

  const isUpserting = createItem.isLoading || updateItem.isLoading;
  const isDeleting = deleteItem.isLoading;
  const isLoading = isUpserting || isDeleting;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col'>
          <FormInput
            type='i18n'
            title='Item name'
            name='menuItemName'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={action === 'update' ? props.menuItem.name.translations : EMPTY_I18N}
            onChange={setFormMenuItemName}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <FormInput
            type='i18n'
            title='Item description'
            name='menuItemDescription'
            errorPath={['description']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={
              action === 'update' ? props.menuItem.description.translations : EMPTY_I18N
            }
            onChange={setFormMenuItemDescription}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='row'>
            {action === 'update' && (
              <div className='col-2'>
                <Button
                  type='button'
                  className='btn btn-icon btn-outline-danger w-100'
                  isLoading={isDeleting}
                  disabled={isLoading}
                  hideContentWhenLoading
                  onClick={() => {
                    if (confirm('Are you sure you want to delete this item?')) {
                      deleteItem.mutate({ uuid: props.menuItem.uuid });
                    }
                  }}
                >
                  <IconTrash size='1em' />
                </Button>
              </div>
            )}
            {onCancel && (
              <div className={action === 'update' ? 'col-4' : 'col-6'}>
                <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            )}
            <div className='col-6'>
              <Button disabled={isLoading} isLoading={isUpserting} hideContentWhenLoading>
                {action === 'create' ? 'Create' : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
