import { useTranslation } from 'react-i18next';
import { usePmsContext } from '../../../../../Context';
import ViewRateOptions from '../rate-options/ViewRateOptions';
import UnitTypesRatesList from './UnitTypesRatesList';

interface ViewUnitsProps {
  accommodationUuid: string;
}
export default function ViewRates({ accommodationUuid }: ViewUnitsProps) {
  const { t } = useTranslation(['pms']);
  const { unitTypes, rateOptions, rateSeasons: allRateSeasons } = usePmsContext();

  if (allRateSeasons.length === 0) {
    return (
      <div className='alert alert-info'>
        {t(
          'pms:configuration.accommodationSettings.ratesConfiguration.createAtLeastOneSeasonError',
        )}
      </div>
    );
  }

  const rateSeasons = allRateSeasons.filter((rateSeason) =>
    rateSeason.accommodations.some((a) => a.uuid === accommodationUuid),
  );

  if (rateSeasons.length === 0) {
    return (
      <div className='alert alert-info'>
        {t(
          'pms:configuration.accommodationSettings.ratesConfiguration.assignAtLeastOneSeasonError',
        )}
      </div>
    );
  }

  if (unitTypes.length === 0) {
    return (
      <div className='alert alert-info'>
        {t(
          'pms:configuration.accommodationSettings.ratesConfiguration.createAtLeastOneUnitTypeError',
        )}
      </div>
    );
  }

  return (
    <>
      <ViewRateOptions />
      {rateOptions.length === 0 ? (
        <div className='alert alert-info'>
          {t(
            'pms:configuration.accommodationSettings.ratesConfiguration.createAtLeastOneOptionError',
          )}
        </div>
      ) : (
        <>
          <div className='alert alert-info'>
            {t('pms:configuration.accommodationSettings.ratesConfiguration.hint')}
          </div>
          <UnitTypesRatesList accommodationUuid={accommodationUuid} />
        </>
      )}
    </>
  );
}
