import { useState } from 'react';
import { createMutation } from '../../../../../../../utils/api';
import { trpc } from '../../../../../../../utils/trpc';
import Button from '../../../../../../common/components/form/Button';
import FormInput from '../../../../../../common/components/form/input';
import { FormErrors } from '../../../../../../common/utils/api';
import { RateOption } from '../../../../../api/rates/rate-options';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface CreateRateOptionFormProps {
  action: 'create';
  rateOptionUuid?: undefined;
  rateOption?: undefined;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateRateOptionFormProps {
  action: 'update';
  rateOptionUuid: string;
  rateOption: RateOption;
  onSuccess: () => void;
  onCancel: () => void;
}
type RateOptionFormProps = CreateRateOptionFormProps | UpdateRateOptionFormProps;

export default function RateOptionForm({
  action,
  rateOptionUuid,
  rateOption,
  onSuccess,
  onCancel,
}: RateOptionFormProps) {
  const { t } = useTranslation(['pms']);
  const trpcContext = trpc.useUtils();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const mutationOptions = {
    invalidate: [trpcContext.pms.rate],
    setFormErrors,
    onSuccess,
  };
  const createRateOption = createMutation(trpc.pms.rate.createRateOption, mutationOptions);
  const updateRateOption = createMutation(trpc.pms.rate.updateRateOption, mutationOptions);
  const deleteRateOption = createMutation(trpc.pms.rate.deleteRateOption, mutationOptions);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const rateOptionName = formData.get('rateOptionName') as string;

    if (action === 'create') {
      createRateOption.mutate({
        name: rateOptionName,
      });
    } else {
      updateRateOption.mutate({
        uuid: rateOptionUuid,
        name: rateOptionName,
      });
    }
  };

  const disabled =
    createRateOption.isLoading || updateRateOption.isLoading || deleteRateOption.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-12'>
          <FormInput
            type='text'
            name='rateOptionName'
            title={t('pms:configuration.accommodationSettings.optionsForm.name')}
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={rateOption?.name}
            autoFocus
          />
        </div>
      </div>
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger ms-1 w-100'
              disabled={disabled}
              isLoading={deleteRateOption.isLoading}
              hideContentWhenLoading
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  deleteRateOption.mutate({ uuid: rateOptionUuid });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            disabled={disabled}
            isLoading={createRateOption.isLoading || updateRateOption.isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
