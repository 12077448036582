import { createContext, useContext } from 'react';
import { PaymentMethod } from '../../pms/api/payment-methods';
import { trpc } from '../../../utils/trpc';
import ErrorAlert from '../components/ErrorAlert';
import { NotificationMethod } from '../../pms/api/notification-methods';
import LoadingSpinnerPageCenter from '../components/LoadingSpinnerPageCenter';

type CompanyContextValue = {
  companyData: {
    deletedAt: string | null;
  };
  paymentMethods: PaymentMethod[];
  notificationMethods: NotificationMethod[];
};

const initialState: CompanyContextValue = {
  companyData: {
    deletedAt: null,
  },
  paymentMethods: [],
  notificationMethods: [],
};

const CompanyContext = createContext<CompanyContextValue>(initialState);

export function CompanyContextProvider({ children }: { children: React.ReactNode }) {
  const companyDataQuery = trpc.auth.getCompanyData.useQuery();
  const paymentMethodsQuery = trpc.pay.paymentMethod.listPaymentMethods.useQuery();
  const notificationMethodsQuery = trpc.notification.getNotificationMethods.useQuery();

  if (
    companyDataQuery.isLoading ||
    paymentMethodsQuery.isLoading ||
    notificationMethodsQuery.isLoading
  ) {
    return <LoadingSpinnerPageCenter />;
  }

  if (companyDataQuery.error || paymentMethodsQuery.error || notificationMethodsQuery.error) {
    const errors = [
      ...(companyDataQuery.error ? [companyDataQuery.error] : []),
      ...(paymentMethodsQuery.error ? [paymentMethodsQuery.error] : []),
      ...(notificationMethodsQuery.error ? [notificationMethodsQuery.error] : []),
    ];
    return <ErrorAlert errors={errors} />;
  }

  const companyData = companyDataQuery.data;
  const paymentMethods = paymentMethodsQuery.data;
  const notificationMethod = notificationMethodsQuery.data;

  return (
    <CompanyContext.Provider
      value={{
        companyData,
        paymentMethods,
        notificationMethods: notificationMethod,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export const useCompanyContext = () => useContext(CompanyContext);
