import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { getNewZodErrorMessagesByPath, getZodErrorMessagesByPath } from '../../../utils/api';
import { AVAILABLE_LANGUAGES } from '../../../contexts/LanguageContext';

type BaseFormInputProps = {
  errors?: any;
  formErrors?: any;
  errorPath?: string[] | string[][];
  name: string;
  title?: string | JSX.Element | null;
  placeholder?: string | null;
  hint?: string | null;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  step?: string;
  showHtmlInputOnly?: boolean;
  startIcon?: JSX.Element | null;
  endIcon?: JSX.Element | null;
  style?: React.CSSProperties;
};

type FormInputProps = BaseFormInputProps &
  (
    | {
        type: 'text' | 'number' | 'time' | 'email' | 'password' | 'date' | 'color';
        defaultValue?: string | null;
        onChange?: (value: string) => void;
        startText?: string;
        endText?: string;
      }
    | {
        type: 'textarea';
        defaultValue?: string | null;
        onChange?: (value: string) => void;
      }
    | {
        type: 'i18n';
        defaultValue?: Record<string, string> | null;
        onChange?: (value: Record<string, string>) => void;
      }
    | {
        type: 'checkbox' | 'switch';
        defaultValue?: boolean;
        onChange?: (value: boolean) => void;
        checkboxValue: string;
      }
    | {
        type: 'select';
        defaultValue?: string | null;
        onChange?: (value: string) => void;
        children?: React.ReactNode;
      }
  );
function FormInput(props: FormInputProps) {
  const {
    errors,
    formErrors,
    errorPath,
    type,
    name,
    title,
    placeholder,
    hint,
    required,
    disabled,
    autoFocus,
    defaultValue: dv,
    className,
    onChange,
    step,
    showHtmlInputOnly,
    startIcon,
    endIcon,
    style,
  } = props;

  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const isCheckOrSwitch = type === 'checkbox' || type === 'switch';
  const defaultValue = isCheckOrSwitch ? Boolean(dv) : dv || '';
  const [value, setValue] = useState<string | boolean | Record<string, string>>(defaultValue);
  const placeholderValue = placeholder || (typeof title === 'string' ? title : '');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    // @ts-expect-error type is always correct
    onChange?.(value);
  }, [value]);

  let zodErrors: string[] = [];
  if (errors && errorPath) {
    zodErrors = getNewZodErrorMessagesByPath(errors, errorPath);
  } else if (formErrors && errorPath) {
    zodErrors = getZodErrorMessagesByPath(formErrors, errorPath);
  }

  let input: JSX.Element;
  if (type === 'select') {
    input = (
      <select
        name={name}
        className={`form-control 
    ${zodErrors.length === 0 ? '' : 'is-invalid'} ${className || ''}`}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus || false}
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        style={style}
      >
        <>{props.children}</>
      </select>
    );
  } else if (isCheckOrSwitch) {
    input = (
      <label className={`form-check ${type === 'switch' ? 'form-switch' : ''}`}>
        <input
          type='checkbox'
          name={name}
          value={props.checkboxValue}
          className={`form-check-input ${zodErrors.length === 0 ? '' : 'is-invalid'} ${
            className || ''
          }`}
          required={required}
          disabled={disabled}
          defaultChecked={props.defaultValue}
          onChange={(e) => setValue(e.target.checked)}
          style={style}
        />
        <span className='form-check-label'>{title}</span>
      </label>
    );
  } else if (type === 'i18n') {
    const i18nValue = value as Record<string, string>;
    const languagesInValue = new Set(Object.keys(i18nValue));
    const languagesNotInValue = AVAILABLE_LANGUAGES.filter(
      (lang) => !languagesInValue.has(lang.code),
    );
    input = (
      <div ref={parentRef}>
        {AVAILABLE_LANGUAGES.filter((lang) => languagesInValue.has(lang.code)).map((lang) => (
          <div className='mb-2' key={lang.code}>
            <div className='d-flex justify-content-between'>
              <img
                src={lang.flagUrl}
                alt={lang.code}
                title={lang.code}
                style={{
                  width: '1.5rem',
                  maxHeight: '2em',
                }}
                className='me-2'
              />
              <input
                type='text'
                name={`${name}.${lang}`}
                className={`form-control
            ${zodErrors.length === 0 ? '' : 'is-invalid'} ${className || ''}`}
                placeholder={placeholderValue}
                required={required}
                disabled={disabled}
                autoFocus={autoFocus || false}
                value={i18nValue[lang.code] || ''}
                onChange={(e) => {
                  const newValue = { ...i18nValue, [lang.code]: e.target.value };
                  setValue(newValue);
                }}
              />
              <button
                type='button'
                className='btn btn-outline-danger ms-2'
                disabled={disabled}
                onClick={() => {
                  const newValue = { ...i18nValue };
                  delete newValue[lang.code];
                  setValue(newValue);
                }}
              >
                <IconX size='1em' />
              </button>
            </div>
          </div>
        ))}
        {languagesNotInValue.map((lang) => (
          <React.Fragment key={lang.code}>
            <button
              type='button'
              className='btn btn-outline-primary me-2'
              disabled={disabled}
              onClick={() => {
                const newValue = { ...i18nValue, [lang.code]: '' };
                setValue(newValue);
              }}
            >
              <IconPlus size='1em' />
              <img
                src={lang.flagUrl}
                alt={lang.code}
                title={lang.code}
                style={{
                  width: '1.5rem',
                  maxHeight: '1rem',
                  marginLeft: '0.5rem',
                }}
              />
            </button>
          </React.Fragment>
        ))}
      </div>
    );
  } else if (type === 'textarea') {
    input = (
      <textarea
        name={name}
        defaultValue={defaultValue as string}
        className={`form-control ${zodErrors.length === 0 ? '' : 'is-invalid'} ${className || ''}`}
        placeholder={placeholderValue}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus || false}
        onChange={(e) => setValue(e.target.value)}
        style={style}
      />
    );
  } else {
    input = (
      <input
        type={type}
        name={name}
        className={`form-control ${type === 'color' ? 'form-control-color' : ''} ${
          zodErrors.length === 0 ? '' : 'is-invalid'
        } ${className || ''}`}
        placeholder={placeholderValue}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus || false}
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        step={step}
        style={style}
      />
    );
  }

  if (showHtmlInputOnly) {
    return input;
  }

  const startTextPresent = 'startText' in props && props.startText;
  const endTextPresent = 'endText' in props && props.endText;

  return (
    <div className='mb-2'>
      {!isCheckOrSwitch && title !== null && <label className='form-label'>{title}</label>}
      <InputStartEndText
        startText={startTextPresent ? props.startText : undefined}
        endText={endTextPresent ? props.endText : undefined}
      >
        <InputStartEndIcon startIcon={startIcon} endIcon={endIcon}>
          {input}
          <div className='invalid-feedback'>{zodErrors.length > 0 && zodErrors[0]}</div>
        </InputStartEndIcon>
      </InputStartEndText>
      <small className='form-hint'>{hint}</small>
    </div>
  );
}
FormInput.defaultProps = {
  errors: null,
  formErrors: null,
  title: null,
  placeholder: null,
  hint: null,
  required: false,
  disabled: false,
  autoFocus: false,
  defaultValue: '',
  className: '',
  onChange: () => {},
  step: undefined,
  showHtmlInputOnly: false,
  children: null,
  startIcon: null,
  endIcon: null,
};

function InputStartEndText({
  startText,
  endText,
  children,
}: {
  startText?: string;
  endText?: string;
  children: React.ReactNode;
}) {
  if (!startText && !endText) {
    return <>{children}</>;
  }

  return (
    <div className={`input-group`}>
      {startText && <span className='input-group-text'>{startText}</span>}
      {children}
      {endText && <span className='input-group-text'>{endText}</span>}
    </div>
  );
}

function InputStartEndIcon({
  startIcon,
  endIcon,
  children,
}: {
  startIcon?: JSX.Element | null;
  endIcon?: JSX.Element | null;
  children: React.ReactNode;
}) {
  if (!startIcon && !endIcon) {
    return <>{children}</>;
  }

  return (
    <div className={`input-icon`}>
      {startIcon && <span className='input-icon-addon'>{startIcon}</span>}
      {children}
      {endIcon && <span className='input-icon-addon'>{endIcon}</span>}
    </div>
  );
}

export default FormInput;
