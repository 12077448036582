import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Bugfender } from '@bugfender/sdk';
import App from './App';
import './i18n';
import Modal from 'react-modal';
import NonProductionEnvironmentModalWarning from './packages/common/components/NonProductionEnvironmentModalWarning';
import LoadingSpinnerPageCenter from './packages/common/components/LoadingSpinnerPageCenter';

if (process.env.REACT_APP_VERCEL_ENV !== 'development') {
  Bugfender.init({
    appKey: 'B6i0bqkHXvQHHd96FK5BUOszIxnD2Jun',
    build: process.env.REACT_APP_VERCEL_GIT_PULL_REQUEST_ID,
    printToConsole: false,
  });
}

const el = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(el);
Modal.setAppElement(el);

root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinnerPageCenter />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <NonProductionEnvironmentModalWarning />
    </Suspense>
  </React.StrictMode>,
);
