import {
  IconArrowRight,
  IconBrandWhatsapp,
  IconChevronsLeft,
  IconChevronsRight,
} from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import Button from '../../../common/components/form/Button';
import BookingUnitsList from './booking/BookingUnitsList';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../../utils/trpc';
import Card from '../../../common/components/Card';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ErrorAlert from '../../../common/components/ErrorAlert';
import { Link } from 'react-router-dom';
import Modal from '../../../common/components/Modal';
import { getLanguageFromCode } from '../../utils/i18n';

function DatePickerButton({
  selected,
  onChange,
  monthsShown,
}: {
  selected: Date;
  onChange: (date: Date | null) => void;
  monthsShown: number;
}) {
  const { t } = useTranslation(['pms']);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);
  const selectedDate = DateTime.fromJSDate(selected);
  const isTodaySelected = selectedDate.toISODate() === DateTime.now().toISODate();

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div>
        <Button
          type='button'
          className={'btn btn-sm h-5 px-3'}
          disabled={isTodaySelected}
          onClick={() => onChange(DateTime.now().startOf('day').toJSDate())}
        >
          {isTodaySelected ? t('pms:dashboard.today') : t('pms:dashboard.goToToday')}
        </Button>
        <div className='input-group'>
          <Button
            type='button'
            className='btn h-5'
            onClick={() => onChange(selectedDate.minus({ days: 1 }).toJSDate())}
            title='-1 day'
          >
            <IconChevronsLeft />
          </Button>
          <DatePicker
            selected={selected}
            onChange={onChange}
            monthsShown={monthsShown}
            customInput={
              <Button type='button' className='btn h-5' onClick={handleClick}>
                {selected.toLocaleDateString()}
              </Button>
            }
            withPortal
          />
          <Button
            type='button'
            className='btn h-5'
            onClick={() => onChange(selectedDate.plus({ days: 1 }).toJSDate())}
            title='+1 day'
          >
            <IconChevronsRight />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function ViewBookingsDashboard() {
  const { t } = useTranslation(['pms']);
  const [currentDate, setCurrentDate] = useState(DateTime.now().startOf('day'));

  return (
    <>
      <div className='mb-3 text-center'>
        <DatePickerButton
          selected={currentDate.toJSDate()}
          onChange={(d) => {
            if (d) {
              const datetime = DateTime.fromJSDate(d);
              if (datetime.isValid) {
                setCurrentDate(datetime);
              }
            }
          }}
          monthsShown={1}
        />
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='card mb-3'>
            <div className='card-header bg-dark text-white'>{t('pms:arrivals')}</div>
            <div className='card-body'>
              <BookingUnitsList
                params={{
                  arrivalDate: currentDate.toISODate(),
                }}
              />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='card mb-3'>
            <div className='card-header bg-dark text-white'>{t('pms:departures')}</div>
            <div className='card-body'>
              <BookingUnitsList
                params={{
                  departureDate: currentDate.toISODate(),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header bg-dark text-white'>{t('pms:staying')}</div>
        <div className='card-body'>
          <BookingUnitsList
            params={{
              arrivalDateBefore: currentDate.toISODate(),
              departureDateAfter: currentDate.toISODate(),
            }}
          />
        </div>
      </div>
      <div className='mt-3' />
      <div className='row'>
        <div className='col-12'>
          <SelfWhatsAppBookingGuestNotifications />
        </div>
      </div>
    </>
  );
}

function SelfWhatsAppBookingGuestNotifications() {
  const { t } = useTranslation(['pms']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<{
    notification: { name: string };
    phoneNumber: string | null;
    template: Record<string, string>;
  } | null>(null);

  const now = DateTime.now();
  const notificationsQuery =
    trpc.pms.bookingGuestNotification.getSelfWhatsAppBookingsToSendGuestNotificationByDate.useQuery(
      {
        date: now.toISODate().slice(0, 10),
      },
    );

  if (notificationsQuery.isLoading) {
    return <LoadingSpinner center cardBody />;
  }

  if (notificationsQuery.error) {
    return <ErrorAlert errors={notificationsQuery.error} />;
  }

  const notifications = notificationsQuery.data;

  return (
    <>
      <Card
        title={`${t('pms:bookingGuestNotification.whatsAppNotificationsToSend')} (${now.toLocaleString()})`}
      >
        {notifications.length === 0 && (
          <div className='text-center'>
            <p>No notifications to send.</p>
          </div>
        )}
        {notifications.map((notification, i) => (
          <>
            <div className='d-flex justify-content-between gap-2'>
              <div className='flex-grow-1'>
                <b>{`${notification.booking.lastName} ${notification.booking.firstName}`}</b>
                <br />
                {notification.notification.name}
                {notification.phoneNumber === null && (
                  <>
                    <br />
                    <span className='badge badge-outline text-red'>
                      {t('pms:bookingGuestNotification.noPhoneNumber')}
                    </span>
                  </>
                )}
              </div>
              <div className='flex-shrink-0 d-flex gap-2 flex-column'>
                <Button
                  type='button'
                  className={`btn btn-icon ${notification.phoneNumber === null ? 'btn-outline-danger' : 'btn-green'}`}
                  onClick={() => {
                    setCurrentNotification(notification);
                    setIsModalOpen(true);
                  }}
                >
                  <IconBrandWhatsapp size='1em' />
                </Button>
                <Link
                  to={`/pms/bookings/edit/${notification.booking.uuid}`}
                  className='btn btn-icon'
                >
                  <IconArrowRight size='1em' />
                </Link>
              </div>
            </div>
            {i < notifications.length - 1 && <hr />}
          </>
        ))}
      </Card>
      {currentNotification && (
        <SendWhatsAppMessageModal
          notificationName={currentNotification.notification.name}
          phoneNumber={currentNotification.phoneNumber}
          template={currentNotification.template}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
}

function SendWhatsAppMessageModal({
  notificationName,
  phoneNumber,
  template,
  isOpen,
  onClose,
}: {
  notificationName: string;
  phoneNumber: string | null;
  template: Record<string, string>;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(['pms']);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('pms:bookingGuestNotification.sendWhatsAppMessage')}
    >
      <SendWhatsAppMessage
        notificationName={notificationName}
        phoneNumber={phoneNumber}
        template={template}
      />
      <div className='d-flex justify-content-end mt-4'>
        <Button className='btn' type='button' onClick={onClose}>
          {t('pms:form.close')}
        </Button>
      </div>
    </Modal>
  );
}

function SendWhatsAppMessage({
  notificationName,
  phoneNumber,
  template,
}: {
  notificationName: string;
  phoneNumber: string | null;
  template: Record<string, string>;
}) {
  const { t } = useTranslation(['pms']);

  if (phoneNumber === null) {
    return <ErrorAlert errors={[t('pms:bookingGuestNotification.noPhoneNumberError')]} />;
  }

  if (Object.keys(template).length === 0) {
    return (
      <ErrorAlert
        errors={[
          t('pms:bookingGuestNotification.noTemplateError', {
            name: notificationName,
          }),
        ]}
      />
    );
  }

  return (
    <div className='d-flex flex-column gap-2'>
      <div>
        <strong>{t('pms:bookingGuestNotification.phoneNumber')}:</strong> {phoneNumber}
      </div>
      {Object.entries(template).map(([languageCode, message]) => {
        const language = getLanguageFromCode(languageCode);

        const formattedPhoneNumber = phoneNumber.replace('+', '');
        const encodedMessage = encodeURIComponent(message);

        return (
          <a
            className='btn'
            key={`SendWhatsAppMessageModal-${languageCode}`}
            href={`https://wa.me/${formattedPhoneNumber}?text=${encodedMessage}`}
            target='_blank'
            rel='noreferrer'
          >
            <IconBrandWhatsapp size='1em' className='me-2' />
            {t('pms:bookingGuestNotification.sendInLanguage')}{' '}
            {language === undefined ? (
              languageCode
            ) : (
              <>
                {language.name}
                <img
                  src={language.flagUrl}
                  alt={language.name}
                  className='ms-2'
                  style={{ width: '1em', height: '1em' }}
                />
              </>
            )}
          </a>
        );
      })}
    </div>
  );
}
