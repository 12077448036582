import { IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { SelfWhatsAppBookingGuestNotification } from '../../types';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../../../../utils/trpc';
import { usePmsContext } from '../../../../Context';
import { FormErrors } from '../../../../../common/utils/api';
import { createMutation } from '../../../../../../utils/api';
import FormInput from '../../../../../common/components/form/input';
import Button from '../../../../../common/components/form/Button';
import { checkUnreachableAndReturn } from '../../../../utils/type';
import I18NTextarea from '../../../../../common/components/form/I18NTextarea';

type CreateSelfWhatsAppNotificationFormProps = {
  action: 'create';
  selfWhatsAppNotification?: undefined;
  onSuccess: () => void;
  onCancel: () => void;
};
type UpdateSelfWhatsAppNotificationFormProps = {
  action: 'update';
  selfWhatsAppNotification: SelfWhatsAppBookingGuestNotification;
  onSuccess: () => void;
  onCancel: () => void;
};
type SelfWhatsAppNotificationFormProps =
  | CreateSelfWhatsAppNotificationFormProps
  | UpdateSelfWhatsAppNotificationFormProps;
type WhenToSend =
  | 'checkInDay'
  | 'checkOutDay'
  | 'daysBeforeCheckIn'
  | 'daysAfterCheckIn'
  | 'daysBeforeCheckOut'
  | 'daysAfterCheckOut';

export default function SelfWhatsAppNotificationForm({
  action,
  selfWhatsAppNotification,
  onSuccess,
  onCancel,
}: SelfWhatsAppNotificationFormProps) {
  const { t } = useTranslation(['pms']);
  const trpcContext = trpc.useUtils();
  const { accommodations } = usePmsContext();

  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [selectedAccommodationsUuids, setSelectedAccommodationsUuids] = useState<string[]>(
    selfWhatsAppNotification?.accommodationsUuids ?? [],
  );
  const [isEnabled, setIsEnabled] = useState(
    selfWhatsAppNotification ? selfWhatsAppNotification.isEnabled : true,
  );
  const [checkInOffsetDays, setCheckInOffsetDays] = useState<number | null>(
    selfWhatsAppNotification !== undefined ? selfWhatsAppNotification.checkInOffsetDays : 0,
  );
  const [checkOutOffsetDays, setCheckOutOffsetDays] = useState<number | null>(
    selfWhatsAppNotification !== undefined ? selfWhatsAppNotification.checkOutOffsetDays : null,
  );
  const [template, setTemplate] = useState(selfWhatsAppNotification?.template ?? {});

  let defaultWhenToSend: WhenToSend = 'checkInDay';
  let defaultWhenToSendDays = 0;
  if (selfWhatsAppNotification !== undefined) {
    const { checkInOffsetDays, checkOutOffsetDays } = selfWhatsAppNotification;
    if (checkInOffsetDays !== null) {
      if (checkInOffsetDays === 0) {
        defaultWhenToSend = 'checkInDay';
      } else if (checkInOffsetDays < 0) {
        defaultWhenToSend = 'daysBeforeCheckIn';
        defaultWhenToSendDays = Math.abs(checkInOffsetDays);
      } else {
        defaultWhenToSend = 'daysAfterCheckIn';
        defaultWhenToSendDays = Math.abs(checkInOffsetDays);
      }
    } else if (checkOutOffsetDays !== null) {
      if (checkOutOffsetDays === 0) {
        defaultWhenToSend = 'checkOutDay';
      } else if (checkOutOffsetDays < 0) {
        defaultWhenToSend = 'daysBeforeCheckOut';
        defaultWhenToSendDays = Math.abs(checkOutOffsetDays);
      } else {
        defaultWhenToSend = 'daysAfterCheckOut';
        defaultWhenToSendDays = Math.abs(checkOutOffsetDays);
      }
    }
  }
  const [whenToSend, setWhenToSend] = useState<WhenToSend>(defaultWhenToSend);
  const [whenToSendDays, setWhenToSendDays] = useState(defaultWhenToSendDays);
  const showWhenToSendDaysInput = whenToSend !== 'checkInDay' && whenToSend !== 'checkOutDay';

  const options = {
    invalidate: [trpcContext.pms.bookingGuestNotification],
    onSuccess,
    setFormErrors,
  };
  const createNotification = createMutation(
    trpc.pms.bookingGuestNotification.createSelfWhatsAppBookingGuestNotification,
    options,
  );
  const updateNotification = createMutation(
    trpc.pms.bookingGuestNotification.updateSelfWhatsAppBookingGuestNotification,
    options,
  );
  const deleteNotification = createMutation(
    trpc.pms.bookingGuestNotification.deleteBookingGuestNotification,
    options,
  );

  // Sync whenToSend/whenToSendDays with checkInOffsetDays/checkOutOffsetDays
  useEffect(() => {
    if (whenToSend === 'checkInDay') {
      setCheckInOffsetDays(0);
      setCheckOutOffsetDays(null);
    } else if (whenToSend === 'checkOutDay') {
      setCheckInOffsetDays(null);
      setCheckOutOffsetDays(0);
    } else if (whenToSend === 'daysBeforeCheckIn') {
      setCheckInOffsetDays(-whenToSendDays);
      setCheckOutOffsetDays(null);
    } else if (whenToSend === 'daysAfterCheckIn') {
      setCheckInOffsetDays(whenToSendDays);
      setCheckOutOffsetDays(null);
    } else if (whenToSend === 'daysBeforeCheckOut') {
      setCheckInOffsetDays(null);
      setCheckOutOffsetDays(-whenToSendDays);
    } else if (whenToSend === 'daysAfterCheckOut') {
      setCheckInOffsetDays(null);
      setCheckOutOffsetDays(whenToSendDays);
    } else {
      checkUnreachableAndReturn(whenToSend, null);
    }
  }, [whenToSend, whenToSendDays]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const data = {
      name: form.get('notificationName') as string,
      isEnabled,
      checkInOffsetDays,
      checkOutOffsetDays,
      accommodationsUuids: selectedAccommodationsUuids,
      fallbackPhoneInternationalPrefix: `+${form.get('fallbackPhoneInternationalPrefix') as string}`,
      template,
    };

    if (action === 'create') {
      createNotification.mutate(data);
    } else {
      updateNotification.mutate({
        ...data,
        selfWhatsAppBookingGuestNotificationUuid:
          selfWhatsAppNotification.selfWhatsAppBookingGuestNotification.uuid,
      });
    }
  };

  const handleAccommodationsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const uuid = String(value);

    if (checked) {
      setSelectedAccommodationsUuids([...selectedAccommodationsUuids, uuid]);
    } else {
      setSelectedAccommodationsUuids(selectedAccommodationsUuids.filter((id) => id !== uuid));
    }
  };

  const isLoading =
    createNotification.isLoading || updateNotification.isLoading || deleteNotification.isLoading;

  // Remove the "+" from the default phone prefix, e.g. "+1" -> "1
  const defaultPhonePrefix =
    selfWhatsAppNotification === undefined
      ? '1'
      : selfWhatsAppNotification.selfWhatsAppBookingGuestNotification
            .fallbackPhoneInternationalPrefix === null
        ? ''
        : selfWhatsAppNotification.selfWhatsAppBookingGuestNotification.fallbackPhoneInternationalPrefix.substring(
            1,
          );

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <FormInput
          type='text'
          name='notificationName'
          title={t('pms:configuration.bookingGuestNotificationsSettings.form.name')}
          hint={t('pms:configuration.bookingGuestNotificationsSettings.form.nameHint')}
          errorPath={['name']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={selfWhatsAppNotification?.name}
        />
      </div>
      <div className='row'>
        <FormInput
          type='switch'
          name='isEnabled'
          checkboxValue='true'
          title={t('pms:configuration.bookingGuestNotificationsSettings.form.isEnabled')}
          errorPath={['isEnabled']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={isEnabled}
          onChange={setIsEnabled}
        />
      </div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>
            {t('pms:configuration.bookingGuestNotificationsSettings.form.accommodations')}
          </label>
          <div className='form-check'>
            {accommodations.map((accommodation) => (
              <label
                key={`SelfWhatsAppNotificationForm-accommodation-${accommodation.uuid}`}
                className='form-check form-check-inline'
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='accommodationsIds[]'
                  value={accommodation.uuid}
                  disabled={isLoading}
                  checked={selectedAccommodationsUuids.includes(accommodation.uuid)}
                  onChange={handleAccommodationsChange}
                />
                <span className='form-check-label'>{accommodation.name}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className='mb-2'>
        <b>{t('pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.whenToSend')}</b>
      </div>
      <div className='row'>
        <div className={showWhenToSendDaysInput ? 'col-12 col-sm-6' : 'col-12'}>
          <FormInput
            type='select'
            name='whenToSend'
            errorPath={['whenToSend']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={whenToSend}
            onChange={(v) => setWhenToSend(v as WhenToSend)}
          >
            <option value='checkInDay'>
              {t('pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.checkInDay')}
            </option>
            <option value='checkOutDay'>
              {t('pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.checkOutDay')}
            </option>
            <option value='daysBeforeCheckIn'>
              {t(
                'pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.daysBeforeCheckIn',
              )}
            </option>
            <option value='daysAfterCheckIn'>
              {t(
                'pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.daysAfterCheckIn',
              )}
            </option>
            <option value='daysBeforeCheckOut'>
              {t(
                'pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.daysBeforeCheckOut',
              )}
            </option>
            <option value='daysAfterCheckOut'>
              {t(
                'pms:configuration.bookingGuestNotificationsSettings.form.whenToSend.daysAfterCheckOut',
              )}
            </option>
          </FormInput>
        </div>
        {showWhenToSendDaysInput && (
          <div className='col-12 col-sm-6'>
            <FormInput
              type='number'
              name='whenToSendDays'
              endText={
                whenToSend === 'daysBeforeCheckIn' || whenToSend === 'daysBeforeCheckOut'
                  ? t(
                      'pms:configuration.bookingGuestNotificationsSettings.form.whenToSendDaysBefore',
                    )
                  : whenToSend === 'daysAfterCheckIn' || whenToSend === 'daysAfterCheckOut'
                    ? t(
                        'pms:configuration.bookingGuestNotificationsSettings.form.whenToSendDaysAfter',
                      )
                    : ''
              }
              errorPath={['whenToSendDays']}
              formErrors={formErrors}
              disabled={isLoading}
              defaultValue={String(whenToSendDays)}
              onChange={(v) => setWhenToSendDays(parseInt(v, 10))}
            />
          </div>
        )}
      </div>
      <div>
        <FormInput
          type='number'
          name='fallbackPhoneInternationalPrefix'
          title={t(
            'pms:configuration.bookingGuestNotificationsSettings.form.fallbackPhoneInternationalPrefix',
          )}
          hint={t(
            'pms:configuration.bookingGuestNotificationsSettings.form.fallbackPhoneInternationalPrefixHint',
          )}
          startText='+'
          errorPath={['fallbackPhoneInternationalPrefix']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={defaultPhonePrefix}
        />
      </div>
      <div>
        <I18NTextarea
          name='template'
          title={t('pms:configuration.bookingGuestNotificationsSettings.form.template')}
          hint={t('pms:configuration.bookingGuestNotificationsSettings.form.templateHint')}
          errorPath={['template']}
          formErrors={formErrors}
          disabled={isLoading}
          defaultValue={selfWhatsAppNotification?.template ?? {}}
          onChange={setTemplate}
        />
      </div>
      <div className='row'>
        {action === 'update' && (
          <div className='col-2'>
            <Button
              type='button'
              className='btn btn-icon btn-outline-danger w-100'
              isLoading={deleteNotification.isLoading}
              disabled={isLoading}
              hideContentWhenLoading
              onClick={async () => {
                if (window.confirm('Are you sure?')) {
                  deleteNotification.mutate({
                    bookingGuestNotificationUuid: selfWhatsAppNotification.uuid,
                  });
                }
              }}
            >
              <IconTrash size='1em' />
            </Button>
          </div>
        )}
        <div className={action === 'update' ? 'col-4' : 'col-6'}>
          <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
            {t('pms:form.cancel')}
          </Button>
        </div>
        <div className='col-6'>
          <Button
            isLoading={createNotification.isLoading || updateNotification.isLoading}
            disabled={isLoading}
            hideContentWhenLoading
          >
            {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
          </Button>
        </div>
      </div>
    </form>
  );
}
