import { useTranslation } from 'react-i18next';
import { getAvailableLanguages, setLanguage } from '../../../../i18n';

export default function LanguageSelector() {
  const { i18n } = useTranslation();

  return (
    <select
      className='form-select mb-2'
      value={i18n.language}
      onChange={(e) => {
        setLanguage(e.target.value);
      }}
    >
      {getAvailableLanguages().map((language) => (
        <option key={language.code} value={language.code} className='nav-link-title'>
          {language.name}
        </option>
      ))}
    </select>
  );
}
