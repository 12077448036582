import { IconArrowLeft } from '@tabler/icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { createMutation } from '../../../../utils/api';
import { trpc } from '../../../../utils/trpc';
import Button from '../../../common/components/form/Button';
import { CompanyEmailField } from '../common/CompanyEmailField';

export default function RequestPasswordReset({ email }: { email: string | null }) {
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const requestPasswordReset = createMutation(trpc.auth.passwordResetRequest, {
    onSuccess: () => {
      setHasSucceeded(true);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get('companyEmail') as string;

    requestPasswordReset.mutate({ email });
  };

  return (
    <form className='card card-md' onSubmit={handleSubmit}>
      <div className='card-body'>
        <h2 className='card-title text-center mb-4'>Reset your password</h2>
        {hasSucceeded ? (
          <>
            <div className='alert alert-success'>
              <h4 className='alert-title'>Email sent</h4>
              <div className='text-secondary'>
                An email with instructions to reset your password has been sent.
                <br />
                If you do not receive an email within a few minutes, please check your spam folder.
              </div>
            </div>
            <div className='form-footer'>
              <Link to={`../login?email=${email}`} className='btn btn-icon w-100'>
                Go back to login
              </Link>
            </div>
          </>
        ) : (
          <>
            <CompanyEmailField disabled={false} value={email || ''} />
            <div className='form-footer'>
              <div className='row'>
                <div className='col-4'>
                  <Link to={`../login?email=${email}`} className='btn btn-icon w-100'>
                    <IconArrowLeft size='1em' />
                  </Link>
                </div>
                <div className='col-8'>
                  <Button isLoading={requestPasswordReset.isLoading} hideContentWhenLoading>
                    Reset password
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </form>
  );
}
