import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil, IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../common/components/Card';
import Modal from '../../../common/components/Modal';
import Page from '../../../common/components/dashboard/Page';
import Button from '../../../common/components/form/Button';
import { useMenuContext } from '../../Context';
import { MenuPage } from '../../api/menu-page';
import MenuPageForm from '../../components/MenuPageForm';
import { useTranslation } from 'react-i18next';

export default function MenuPagesPage() {
  const { t } = useTranslation(['menu']);

  return (
    <Page title={t('menu:menuPages')}>
      <NewMenuPageButton />
      <MenuPages />
    </Page>
  );
}

function MenuPages() {
  const { menuPages } = useMenuContext();

  if (menuPages.length === 0) {
    return <NoMenuPagesAlert />;
  }

  return <MenuPageElements menuPages={menuPages} />;
}

function NoMenuPagesAlert() {
  const { t } = useTranslation(['menu']);
  return (
    <div className='alert alert-info' role='alert' style={{ marginTop: '1rem' }}>
      <h4 className='alert-title'>{t('menu:noMenuPagesAlert')}</h4>
    </div>
  );
}

function MenuPageElements({ menuPages }: { menuPages: MenuPage[] }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  return (
    <div ref={parentRef}>
      {menuPages.map((menuPage) => (
        <MenuPageElement key={`menu-page-element-${menuPage.uuid}`} menuPage={menuPage} />
      ))}
    </div>
  );
}

function MenuPageElement({ menuPage }: { menuPage: any }) {
  return (
    <Card style={{ marginTop: '1rem' }}>
      <div className='d-flex justify-content-between'>
        <div className='text-truncate'>{menuPage.name}</div>
        <div className='text-truncate'>
          <EditMenuPageButton menuPageUuid={menuPage.uuid} />
        </div>
      </div>
    </Card>
  );
}

function NewMenuPageButton() {
  const { t } = useTranslation(['menu']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type='button' onClick={() => setShowModal(true)}>
        <IconPlus /> {t('menu:newMenuPage')}
      </Button>
      <Modal
        title={t('menu:newMenuPage')}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size='sm'
      >
        <MenuPageForm
          action='create'
          onCancel={() => setShowModal(false)}
          onSuccess={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
}

function EditMenuPageButton({ menuPageUuid }: { menuPageUuid: string }) {
  return (
    <Link to={`/menu/menus/edit/${menuPageUuid}`} className='btn btn-icon'>
      <IconPencil size='1em' />
    </Link>
  );
}
