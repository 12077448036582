import { useEffect, useState } from 'react';
import { z } from 'zod';

export enum LocaleStorageItemKey {
  DASHBOARD_LAST_VISITED_PAGE = 'dashboard_last_visited_page',
  PMS_CURRENT_ACCOMMODATIONS_UUIDS = 'pms_current_accommodations_uuids',
  PMS_PLANNING_START_DAYS_BEFORE = 'pms_planning_start_days_before',
  PMS_PLANNING_SHOW_DAYS = 'pms_planning_show_days',
  PMS_PLANNING_SHOW_PAYMENT_NEEDED = 'pms_planning_show_payment_needed',
  PMS_PLANNING_BOOKING_COLORS = 'pms_planning_booking_colors',
  PMS_PLANNING_SHOW_CHECK_IN_OUT_STATUS = 'pms_planning_show_check_in_out_status',
  PMS_DEFAULT_RATE_OPTION_UUID = 'pms_default_rate_option_uuid',
  PMS_CHARGES_PAYMENTS_DOWNLOAD_PDF_INCLUDE_CHARGES = 'pms_charges_payments_download_pdf_include_charges',
  PMS_CHARGES_PAYMENTS_DOWNLOAD_PDF_INCLUDE_PAYMENTS = 'pms_charges_payments_download_pdf_include_payments',
}

export function useLocalStorage<TypeValidator extends z.Schema>(
  key: LocaleStorageItemKey,
  defaultValue: z.infer<TypeValidator>,
  inputSchema: TypeValidator,
) {
  const [value, setValue] = useState<z.infer<TypeValidator>>(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      try {
        const parsedValue = JSON.parse(storedValue);
        const validatedValue = inputSchema.parse(parsedValue);
        return validatedValue;
      } catch (error) {
        console.error(error);
        return defaultValue;
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
}
