export default function ProgressBar({ progress }: { progress: number }) {
  return (
    <div className='progress'>
      <div
        className='progress-bar'
        style={{ width: `${progress}%` }}
        role='progressbar'
        aria-valuenow={38}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-label={`${progress}% complete`}
      >
        <span className='visually-hidden'>{`${progress}% complete`}</span>
      </div>
    </div>
  );
}
