import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { createMutation, trpcErrorParser } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import ErrorAlert from '../../common/components/ErrorAlert';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { useAuth } from '../hooks/AuthContext';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies();

function CompanyEmailField({
  formErrors,
  defaultValue,
}: {
  formErrors: FormErrors | null;
  defaultValue: string;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={[['email'], ['company', 'email']]}
      type='email'
      name='companyEmail'
      title={
        <>
          {t('auth:form.companyEmail')}
          {' ('}
          <Link to='/'>{t('auth:form.changeEmail')}</Link>
          {')'}
        </>
      }
      required
      defaultValue={defaultValue}
      disabled
    />
  );
}

function UserPasswordField({
  email,
  formErrors,
  isLoading,
}: {
  email: string;
  formErrors: FormErrors | null;
  isLoading: boolean;
}) {
  const { t } = useTranslation(['auth']);
  return (
    <FormInput
      formErrors={formErrors}
      errorPath={['password']}
      type='password'
      name='userPassword'
      title={
        <>
          {t('auth:form.password')}
          <span className='form-label-description'>
            <Link to={`../request-password-reset?email=${email}`}>
              {t('auth:form.forgotPassword')}
            </Link>
          </span>
        </>
      }
      required
      disabled={isLoading}
    />
  );
}

function RememberLoginCheckbox({ isLoading }: { isLoading: boolean }) {
  const { t } = useTranslation(['auth']);
  return (
    <div className='mb-2'>
      <label className='form-check'>
        <input
          type='checkbox'
          name='rememberLogin'
          className='form-check-input'
          disabled={isLoading}
        />
        <span className='form-check-label'>{t('auth:form.rememberMe')}</span>
      </label>
    </div>
  );
}

export default function LoginPassword({ email }: { email: string }) {
  const { t } = useTranslation(['auth']);
  const { login: useLogin } = useAuth();
  const navigate = useNavigate();
  const [genericError, setGenericError] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const login = createMutation(trpc.auth.login, {
    setFormErrors,
    setGenericError,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const formPassword = form.userPassword.value;
    const rememberLogin = form.rememberLogin.checked;

    try {
      const data = await login.mutateAsync({
        company: { email },
        user: { username: 'admin', password: formPassword },
      });

      const maxAge = rememberLogin ? 30 * 24 * 60 * 60 : undefined;

      cookies.set('token', data.token, {
        maxAge,
        path: '/',
      });
      useLogin();
      navigate('/');
    } catch (err) {
      trpcErrorParser({
        error: err,
        setFormErrors,
        setGenericError,
      });
    }
  };

  return (
    <form className='card card-md' onSubmit={handleSubmit}>
      <div className='card-body'>
        <h2 className='card-title text-center mb-4'>{t('auth:loginToYourAccount')}</h2>
        <ErrorAlert errors={genericError} />
        <CompanyEmailField formErrors={formErrors} defaultValue={email} />
        <UserPasswordField email={email} isLoading={login.isLoading} formErrors={formErrors} />
        <RememberLoginCheckbox isLoading={login.isLoading} />
        <div className='form-footer'>
          <Button isLoading={login.isLoading} hideContentWhenLoading>
            {t('auth:form.login')}
          </Button>
        </div>
      </div>
    </form>
  );
}
