import { useId, useState } from 'react';
import { trpc } from '../../../../../utils/trpc';
import ErrorAlert from '../../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { usePmsContext } from '../../../Context';
import { GetBookingsArgs } from '../../../api/bookings/bookings';
import { Pagination } from '../../../../common/components/Pagination';
import BookingListItem from './BookingListItem';
import { useTranslation } from 'react-i18next';

export default function BookingList({
  params,
}: {
  params: Omit<GetBookingsArgs, 'accommodationsUuids'>;
}) {
  const { t } = useTranslation(['pms']);
  const { currentAccommodations, unitTypes: allUnitTypes } = usePmsContext();
  const currentAccommodationsUuids = currentAccommodations.map((a) => a.uuid);

  const id = useId();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const queryBookingsParams = {
    accommodationsUuids: currentAccommodationsUuids,
    page,
    limit: pageSize,
    ...params,
  };
  const queryBookings = trpc.pms.booking.queryBookings.useQuery(queryBookingsParams);

  // TODO: new todo: instead of prefetching the next page, get the entire month's bookings
  // OLD TODO: Prefetch next page
  // const nextPageQueryParams = {
  //   ...queryBookingsParams,
  //   page: page + 1,
  // };
  // queryClient.prefetchQuery(['bookings-of-accommodation', nextPageQueryParams], () =>
  //   getBookings(nextPageQueryParams),
  // );

  if (queryBookings.isLoading) {
    return <LoadingSpinner cardBody center />;
  }

  const bookings = queryBookings.data?.bookings;
  const totalBookings = queryBookings.data?.total;
  if (!bookings || totalBookings === null || totalBookings === undefined) {
    return <ErrorAlert errors='An error occurred' />;
  }

  if (bookings.length === 0) {
    return (
      <div className='card card-body'>
        <div className='text-center'>
          <h3>{t('pms:booking.noBookingsFound')}</h3>
        </div>
      </div>
    );
  }

  const unitTypes = allUnitTypes.filter((u) =>
    currentAccommodationsUuids.includes(u.accommodationUuid),
  );

  return (
    <>
      <div className='mb-3'>
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(totalBookings / pageSize)}
          onPageChange={(p) => setPage(p)}
          currentPageLimit={pageSize}
          onPageLimitChange={(p) => setPageSize(p)}
        />
      </div>
      <div className='card table-responsive'>
        <table className='table'>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>Room</th>
              <th scope='col'>Arrival</th>
              <th scope='col'>Departure</th>
              <th scope='col' className='text-end'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <BookingListItem
                key={`${id}-booking-list-item-${booking.uuid}`}
                unitTypes={unitTypes}
                booking={booking}
                showEdit={true}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
