import DatePicker from 'react-datepicker';
import { LocalDate } from '@js-joda/core';

export default function DateRangeSelector({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  setStartDate: (date: LocalDate | null) => void;
  setEndDate: (date: LocalDate | null) => void;
}) {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(getLocalDateFromDate(start));
    setEndDate(getLocalDateFromDate(end));
  };

  const startJsDate = startDate === null ? undefined : new Date(startDate.toString() + 'T00:00:00');
  const endJsDate = endDate === null ? undefined : new Date(endDate.toString() + 'T00:00:00');

  return (
    <>
      <div className='text-center d-none d-md-inline' style={{ fontSize: '1.3rem' }}>
        <DatePicker
          selected={startJsDate}
          onChange={onChange}
          startDate={startJsDate}
          endDate={endJsDate}
          selectsRange
          inline
          monthsShown={2}
        />
      </div>
      <div className='text-center d-md-none' style={{ fontSize: '1.3rem' }}>
        <DatePicker
          selected={startJsDate}
          onChange={onChange}
          startDate={startJsDate}
          endDate={endJsDate}
          selectsRange
          inline
          monthsShown={1}
        />
      </div>
    </>
  );
}

function getLocalDateFromDate(date: Date | null) {
  if (date === null) {
    return null;
  }

  // We need to remove the offset otherwise it doesn't work e.g. in Europe/Rome
  // https://stackoverflow.com/a/39209842
  const offset = Math.abs(date.getTimezoneOffset());
  const dateWithoutOffset = new Date(date.getTime() + offset * 60 * 1000);

  return LocalDate.parse(dateWithoutOffset.toISOString().slice(0, 10));
}
