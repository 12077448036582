export default function Card({
  title,
  children,
  style,
  className,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div className={`card ${className || ''}`} style={style}>
      {title && (
        <div className='card-header'>
          <h3 className='card-title'>{title}</h3>
        </div>
      )}
      <div className='card-body'>{children}</div>
    </div>
  );
}
