import { Page, Text, Document, StyleSheet, Font, View, usePDF } from '@react-pdf/renderer';
import { MenuItem, MenuItemPrice, MenuPage, MenuSection } from '../api/menu-page';
import { useMenuContext } from '../Context';
import { useLanguageContext } from '../../common/contexts/LanguageContext';
import { formatPriceInSmallestUnitToString } from '../../../utils/currency';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import ErrorAlert from '../../common/components/ErrorAlert';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconExternalLink } from '@tabler/icons-react';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 14,
    fontFamily: 'Helvetica',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  sectionName: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginTop: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDD',
    padding: '5px 0',
  },
  itemName: {
    fontFamily: 'Helvetica',
  },
  itemDescription: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: 12,
  },
  prices: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
  },
  priceTextView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minWidth: 50,
  },
});

function getSectionsRecursive(
  sectionUuid: string,
  visitedSections: string[],
  menuSections: MenuSection[],
): MenuSection[] {
  if (visitedSections.includes(sectionUuid)) {
    return [];
  }
  visitedSections.push(sectionUuid);
  const section = menuSections.find((s) => s.uuid === sectionUuid);
  console.log('menuSections', menuSections);
  if (!section) {
    return [];
  }
  const childSections = section.elementsUuids
    .map((element) => {
      if (element.type === 'section') {
        return getSectionsRecursive(element.uuid, visitedSections, menuSections);
      }
      return [];
    })
    .flat();
  return [section, ...childSections];
}

const PdfDocument = ({
  menuPage,
  menuSections,
  menuItems,
  menuItemPrices,
  getTranslatedString,
  selectedLanguageCode,
}: {
  menuPage: MenuPage;
  menuSections: MenuSection[];
  menuItems: MenuItem[];
  menuItemPrices: MenuItemPrice[];
  getTranslatedString: (
    translations: { [key: string]: string },
    selectedLanguageCode?: string,
  ) => string | null;
  selectedLanguageCode: string;
}) => {
  const sections = getSectionsRecursive(menuPage.rootSectionUuid, [], menuSections);

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <Text style={styles.title} fixed>
          {menuPage.name}
        </Text>
        {sections.map((section) => (
          <>
            <Text key={section.uuid} style={styles.sectionName}>
              {getTranslatedString(section.name.translations, selectedLanguageCode)}
            </Text>
            {section.elementsUuids.map((element) => {
              if (element.type === 'section') {
                return null;
              }
              const item = menuItems.find((item) => item.uuid === element.uuid);
              if (!item) {
                return null;
              }
              const itemPrices = menuItemPrices.filter(
                (price) => price.menuPageItemUuid === item.uuid,
              );

              const itemName = getTranslatedString(item.name.translations, selectedLanguageCode);
              const itemDescription = getTranslatedString(
                item.description.translations,
                selectedLanguageCode,
              );

              return (
                <View key={element.uuid} style={styles.item}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.itemName}>{itemName}</Text>
                    {itemDescription && (
                      <Text style={styles.itemDescription}>{itemDescription}</Text>
                    )}
                  </View>
                  <View style={styles.prices}>
                    {itemPrices.map((price) => (
                      <View key={price.uuid} style={styles.priceView}>
                        <View style={styles.priceTextView}>
                          <Text>
                            {getTranslatedString(price.name.translations, selectedLanguageCode)}
                          </Text>
                        </View>
                        <View style={styles.priceTextView}>
                          <Text>{formatPriceInSmallestUnitToString(price.price, 'EUR')}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
          </>
        ))}
      </Page>
    </Document>
  );
};

export default function MenuDownloadPdf({ menuPage }: { menuPage: MenuPage }) {
  const { t } = useTranslation(['menu']);
  const { menuSections, menuItems, menuItemPrices } = useMenuContext();
  const { getTranslatedString, availableLanguages, selectedLanguage } = useLanguageContext();
  const [pdfLanguage, setPdfLanguage] = useState(selectedLanguage.code);

  const [instance, updateInstance] = usePDF({});

  useEffect(() => {
    updateInstance(
      <PdfDocument
        menuPage={menuPage}
        menuSections={menuSections}
        menuItems={menuItems}
        menuItemPrices={menuItemPrices}
        getTranslatedString={getTranslatedString}
        selectedLanguageCode={pdfLanguage}
      />,
    );
  }, [pdfLanguage]);

  if (instance.loading) {
    return <LoadingSpinner center />;
  }
  if (instance.error) {
    return <ErrorAlert errors={instance.error} />;
  }
  if (instance.url === null) {
    return <ErrorAlert errors={['Something went wrong']} />;
  }

  return (
    <div className='d-grid gap-2'>
      <div>
        <select
          className='form-select'
          value={pdfLanguage}
          onChange={(e) => setPdfLanguage(e.target.value as any)}
        >
          {availableLanguages.map((language) => (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          ))}
        </select>
      </div>
      <div className='d-flex justify-content-center gap-2'>
        <a href={instance.url} target='_blank' rel='noreferrer' className='btn w-100'>
          {t('menu:pdf.openPdf')}
          <IconExternalLink size='1em' className='ms-2' />
        </a>
        <a
          href={instance.url}
          download={`Menu ${menuPage.name} (${pdfLanguage}).pdf`}
          className='btn btn-primary w-100'
        >
          {t('menu:pdf.downloadPdf')}
        </a>
      </div>
    </div>
  );
}
