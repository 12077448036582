import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconPencil } from '@tabler/icons-react';
import { useState } from 'react';
import Button from '../../../../common/components/form/Button';
import { Referral } from '../../../api/referrals';
import ReferralForm from './ReferralForm';

function ReferralElement({ referral }: { referral: Referral }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();
  const [showEditReferralForm, setShowEditReferralForm] = useState(false);

  return (
    <div ref={parentRef}>
      {showEditReferralForm ? (
        <ReferralForm
          action='update'
          referralUuid={referral.uuid}
          referral={referral}
          onSuccess={() => setShowEditReferralForm(false)}
          onCancel={() => setShowEditReferralForm(false)}
        />
      ) : (
        <div className='d-flex justify-content-between'>
          <h3 className='text-truncate'>{referral.name}</h3>
          <div>
            <Button
              type='button'
              className='btn btn-icon ms-1'
              onClick={() => {
                setShowEditReferralForm(true);
              }}
            >
              <IconPencil size='1em' />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function ReferralsList({ referrals }: { referrals: Referral[] }) {
  const [parentRef] = useAutoAnimate<HTMLDivElement>();

  return (
    <div className='row' ref={parentRef}>
      {referrals.map((referral) => (
        <div key={`ReferralsList-referral-${referral.uuid}`} className='col-md-6 pt-3'>
          <div className='card card-body'>
            <ReferralElement referral={referral} />
          </div>
        </div>
      ))}
    </div>
  );
}
