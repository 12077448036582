import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import ErrorAlert from '../../common/components/ErrorAlert';
import Button from '../../common/components/form/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/AuthContext';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Demo() {
  const { t, i18n } = useTranslation(['auth']);
  const navigate = useNavigate();
  const { login: useLogin } = useAuth();
  const [genericError, setGenericError] = useState<string[]>([]);

  const createDemoCompany = createMutation(trpc.demo.createDemoCompany, {
    onSuccess: ({ token }: { token: string }) => {
      cookies.set('token', token, {
        path: '/',
      });
      useLogin();
      navigate('/');
    },
    setGenericError,
  });

  return (
    <div className='card card-md card-body'>
      <h2 className='card-title text-center'>{t('auth:demo.title')}</h2>
      <ErrorAlert errors={genericError} />
      <p>{t('auth:demo.description')}</p>
      <p>
        <Button
          onClick={() =>
            createDemoCompany.mutate({
              language: i18n.language,
            })
          }
          isLoading={createDemoCompany.isLoading}
          hideContentWhenLoading
        >
          {t('auth:demo.button')}
        </Button>
      </p>
      <div className='alert alert-warning'>{t('auth:demo.dataDisclaimer')}</div>
      <div className='mt-4 mb-4 text-center'>
        <Trans
          i18nKey='auth:form.termsAndConditions'
          values={{ buttonText: 'Prova SimPulse' }}
          components={{
            termsLink: (
              <a
                href='https://www.simpulse.it/termini-e-condizioni'
                target='_blank'
                rel='noreferrer'
              />
            ),
            privacyLink: (
              <a href='https://www.simpulse.it/privacy-policy' target='_blank' rel='noreferrer' />
            ),
          }}
        />
      </div>
    </div>
  );
}
