import { useState } from 'react';
import toast from 'react-hot-toast';
import { createMutation } from '../../../../../../../utils/api';
import { trpc } from '../../../../../../../utils/trpc';
import Button from '../../../../../../common/components/form/Button';
import FormInput from '../../../../../../common/components/form/input';
import { FormErrors } from '../../../../../../common/utils/api';
import { IconTrash } from '@tabler/icons-react';
import { UnitType } from '../../../../../api/units/unit-types';
import { useTranslation } from 'react-i18next';

interface CreateUnitTypeFormProps {
  action: 'create';
  unitType?: undefined;
  accommodationUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateUnitTypeFormProps {
  action: 'update';
  unitType: UnitType;
  accommodationUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
type UnitTypeFormProps = CreateUnitTypeFormProps | UpdateUnitTypeFormProps;

export default function UnitTypeForm({
  action,
  unitType,
  accommodationUuid,
  onSuccess,
  onCancel,
}: UnitTypeFormProps) {
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formPublicName, setFormPublicName] = useState<Record<string, string>>({});
  const [formPublicDescription, setFormPublicDescription] = useState<Record<string, string>>({});

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.pms.unitType, trpcContext.pms.unit],
    onSuccess: () => {
      toast.success(`Unit type updated`);
      onSuccess();
    },
    setFormErrors,
  };
  const createUnitType = createMutation(trpc.pms.unitType.createUnitType, mutationOptions);
  const updateUnitType = createMutation(trpc.pms.unitType.updateUnitType, mutationOptions);
  const deleteUnitType = createMutation(trpc.pms.unitType.deleteUnitType, mutationOptions);

  const handleAction = (form: EventTarget & HTMLFormElement) => {
    const data = {
      name: form.unitTypeName.value,
      publicName: formPublicName,
      publicDescription: formPublicDescription,
      paxMin: parseInt(form.paxMin.value, 10),
      paxMax: parseInt(form.paxMax.value, 10),
    };

    if (action === 'create') {
      createUnitType.mutate({
        ...data,
        accommodationUuid,
      });
    } else {
      updateUnitType.mutate({ ...data, uuid: unitType.uuid });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);

    const form = e.currentTarget;
    handleAction(form);
  };

  const disabled = createUnitType.isLoading || updateUnitType.isLoading || deleteUnitType.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-lg-6 col-xl-4'>
          <FormInput
            type='text'
            name='unitTypeName'
            title={t('pms:configuration.accommodationSettings.unitTypeForm.name')}
            errorPath={['name']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={unitType?.name}
          />
        </div>
        <div className='col-lg-6 col-xl-4'>
          <FormInput
            type='number'
            name='paxMin'
            title={t('pms:configuration.accommodationSettings.unitTypeForm.minPax')}
            errorPath={['paxMin']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={unitType?.paxMin.toString()}
          />
        </div>
        <div className='col-lg-6 col-xl-4'>
          <FormInput
            type='number'
            name='paxMax'
            title={t('pms:configuration.accommodationSettings.unitTypeForm.maxPax')}
            errorPath={['paxMax']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={unitType?.paxMax.toString()}
          />
        </div>
        <div className='col-lg-6 col-xl-4'>
          <FormInput
            type='i18n'
            title={t('pms:configuration.accommodationSettings.unitTypeForm.publicName')}
            name='publicName'
            errorPath={['publicName']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={action === 'update' ? unitType.publicName.translations : {}}
            onChange={setFormPublicName}
          />
        </div>
        <div className='col-lg-6 col-xl-4'>
          <FormInput
            type='i18n'
            title={t('pms:configuration.accommodationSettings.unitTypeForm.publicDescription')}
            name='publicDescription'
            errorPath={['publicDescription']}
            formErrors={formErrors}
            disabled={disabled}
            defaultValue={action === 'update' ? unitType.publicDescription.translations : {}}
            onChange={setFormPublicDescription}
          />
        </div>
        <div className='form-footer row'>
          {action === 'update' && (
            <div className='col-2'>
              <Button
                type='button'
                className='btn btn-icon btn-outline-danger ms-1 w-100'
                isLoading={deleteUnitType.isLoading}
                hideContentWhenLoading
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    deleteUnitType.mutate({ uuid: unitType.uuid });
                  }
                }}
              >
                <IconTrash size='1em' />
              </Button>
            </div>
          )}
          <div className={action === 'update' ? 'col-4' : 'col-6'}>
            <Button type='button' className='btn w-100' disabled={disabled} onClick={onCancel}>
              {t('pms:form.cancel')}
            </Button>
          </div>
          <div className='col-6'>
            <Button
              disabled={disabled}
              isLoading={createUnitType.isLoading || updateUnitType.isLoading}
              hideContentWhenLoading
            >
              {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
