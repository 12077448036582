import { useState } from 'react';
import { createMutation } from '../../../../../../../utils/api';
import { trpc } from '../../../../../../../utils/trpc';
import ErrorAlert from '../../../../../../common/components/ErrorAlert';
import Button from '../../../../../../common/components/form/Button';
import FormInput from '../../../../../../common/components/form/input';
import { FormErrors } from '../../../../../../common/utils/api';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface Unit {
  uuid: string;
  name: string;
}
interface CreateUnitFormProps {
  action: 'create';
  unit?: undefined;
  unitTypeUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
interface UpdateUnitFormProps {
  action: 'update';
  unit: Unit;
  unitTypeUuid: string;
  onSuccess: () => void;
  onCancel: () => void;
}
type UnitFormProps = CreateUnitFormProps | UpdateUnitFormProps;

export default function UnitForm({
  action,
  unit,
  unitTypeUuid,
  onSuccess,
  onCancel,
}: UnitFormProps) {
  const { t } = useTranslation(['pms']);
  const [genericError, setGenericError] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.pms.unitType, trpcContext.pms.unit],
    onSuccess,
    setFormErrors,
  };
  const createUnit = createMutation(trpc.pms.unit.createUnit, mutationOptions);
  const updateUnit = createMutation(trpc.pms.unit.updateUnit, mutationOptions);
  const unitDelete = createMutation(trpc.pms.unit.deleteUnit, mutationOptions);

  const handleAction = async (form: EventTarget & HTMLFormElement) => {
    const data = {
      name: form.unitName.value as string,
      unitTypeUuid,
    };
    if (action === 'create') {
      createUnit.mutate(data);
    } else {
      updateUnit.mutate({ ...data, uuid: unit.uuid });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(null);
    setGenericError([]);

    const form = e.currentTarget;
    handleAction(form);
  };

  const isLoading = createUnit.isLoading || updateUnit.isLoading;

  return (
    <>
      <ErrorAlert errors={genericError} />
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-12'>
            <FormInput
              type='text'
              name='unitName'
              title={t('pms:configuration.accommodationSettings.unitForm.name')}
              errorPath={['name']}
              formErrors={formErrors}
              disabled={isLoading}
              autoFocus
              defaultValue={unit?.name}
            />
          </div>
          {action === 'update' && (
            <div className='col-2'>
              <Button
                type='button'
                className='btn btn-icon btn-outline-danger ms-1 w-100'
                isLoading={unitDelete.isLoading}
                hideContentWhenLoading
                onClick={() => {
                  if (window.confirm('Are you sure?')) {
                    unitDelete.mutate({ uuid: unit.uuid });
                  }
                }}
              >
                <IconTrash size='1em' />
              </Button>
            </div>
          )}
          <div className={action === 'update' ? 'col-4' : 'col-6'}>
            <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
              {t('pms:form.cancel')}
            </Button>
          </div>
          <div className='col-6'>
            <Button isLoading={isLoading} hideContentWhenLoading>
              {action === 'create' ? t('pms:form.create') : t('pms:form.update')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
