import { DateTime } from 'luxon';
import { formatPriceInSmallestUnitToString } from '../../../../utils/currency';
import { trpc } from '../../../../utils/trpc';
import ErrorAlert from '../../../common/components/ErrorAlert';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import Page from '../../../common/components/dashboard/Page';
import Button from '../../../common/components/form/Button';
import { IconRefresh, IconSearch } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { MenuOrder } from '../../api/menu-order';
import Modal from '../../../common/components/Modal';
import { Pagination } from '../../../common/components/Pagination';
import { useTranslation } from 'react-i18next';
import { useMenuContext } from '../../Context';

export default function OrdersPage() {
  return (
    <Page title='Orders'>
      <OrdersTableContainer />
    </Page>
  );
}

function OrdersTableContainer() {
  const { t } = useTranslation(['menu']);
  const [includeDraft, setIncludeDraft] = useState(false);
  const [includeAwaitPayment, setIncludeAwaitPayment] = useState(false);
  const [includeSubmitted, setIncludeSubmitted] = useState(true);
  const [includeExpired, setIncludeExpired] = useState(false);

  return (
    <>
      <div className='row'>
        <div className='col-sm-6 col-lg-3'>
          <Switch
            id='include-draft'
            checked={includeDraft}
            onChange={setIncludeDraft}
            label={t('menu:orders.draft')}
          />
        </div>
        <div className='col-sm-6 col-lg-3'>
          <Switch
            id='include-await-payment'
            checked={includeAwaitPayment}
            onChange={setIncludeAwaitPayment}
            label={t('menu:orders.awaitingPayment')}
          />
        </div>
        <div className='col-sm-6 col-lg-3'>
          <Switch
            id='include-submitted'
            checked={includeSubmitted}
            onChange={setIncludeSubmitted}
            label={t('menu:orders.submitted')}
          />
        </div>
        <div className='col-sm-6 col-lg-3'>
          <Switch
            id='include-expired'
            checked={includeExpired}
            onChange={setIncludeExpired}
            label={t('menu:orders.expired')}
          />
        </div>
      </div>
      <OrdersTable
        includeDraft={includeDraft}
        includeAwaitPayment={includeAwaitPayment}
        includeSubmitted={includeSubmitted}
        includeExpired={includeExpired}
      />
    </>
  );
}

function OrdersTable({
  includeDraft,
  includeAwaitPayment,
  includeSubmitted,
  includeExpired,
}: {
  includeDraft: boolean;
  includeAwaitPayment: boolean;
  includeSubmitted: boolean;
  includeExpired: boolean;
}) {
  const { t } = useTranslation(['menu']);
  const [parentRef] = useAutoAnimate();

  const [autoRefresh, setAutoRefresh] = useState(false);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState<NodeJS.Timeout | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(25);

  const ordersQuery = trpc.menu.admin.getOrders.useQuery({
    page: currentPage,
    perPage: ordersPerPage,
    include: {
      draft: includeDraft,
      awaitPayment: includeAwaitPayment,
      submitted: includeSubmitted,
      expired: includeExpired,
    },
  });

  useEffect(() => {
    if (autoRefreshInterval) {
      clearInterval(autoRefreshInterval);
    }

    if (autoRefresh) {
      const intervalId = setInterval(
        () => {
          ordersQuery.refetch();
        },
        5 * 60 * 1000,
      ); // 5 minutes

      setAutoRefreshInterval(intervalId);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [autoRefresh]);

  if (ordersQuery.isLoading) {
    return <LoadingSpinner center />;
  }

  if (ordersQuery.error) {
    return <ErrorAlert errors={ordersQuery.error} />;
  }

  const orders = ordersQuery.data.orders;
  const totalPages = Math.ceil(ordersQuery.data.ordersCount / ordersPerPage);

  const lastUpdate = DateTime.fromMillis(ordersQuery.dataUpdatedAt).toISO()!;

  return (
    <>
      <div className='d-flex justify-content-end align-items-center gap-2'>
        <span>
          {t('menu:orders.lastUpdate')} <TimeAgo datetime={lastUpdate} />
        </span>
        <Button
          className='btn btn-icon btn-sm ms-2'
          onClick={() => {
            ordersQuery.refetch();
          }}
          isLoading={ordersQuery.isFetching}
          hideContentWhenLoading
        >
          <IconRefresh size='1em' />
        </Button>
        <Switch
          id='auto-refresh'
          checked={autoRefresh}
          onChange={setAutoRefresh}
          label={t('menu:orders.autoRefresh5minutes')}
        />
      </div>
      <div className='table-responsive'>
        <table className='table table-vcenter table-mobile-sm card-table'>
          <thead>
            <tr>
              <th className='w-1'></th>
              <th className='text-nowrap text-center w-1'>{t('menu:orders.time')}</th>
              <th className='text-nowrap'>{t('menu:orders.name')}</th>
              <th className='text-nowrap'>{t('menu:orders.phone')}</th>
              <th className='text-nowrap text-center'>{t('menu:orders.status')}</th>
              <th className='text-nowrap text-end'>{t('menu:orders.totalPrice')}</th>
              <th className='w-1'></th>
            </tr>
          </thead>
          <tbody ref={parentRef}>
            {orders.map((order, index) => (
              <OrderRow
                key={order.uuid}
                rowNumber={ordersPerPage * (currentPage - 1) + index + 1}
                order={order}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        currentPageLimit={ordersPerPage}
        onPageLimitChange={setOrdersPerPage}
      />
    </>
  );
}

function OrderRow({ rowNumber, order }: { rowNumber: number; order: MenuOrder }) {
  const isJustSubmitted =
    order.submittedAt && order.submittedAt > DateTime.now().minus({ minutes: 30 }).toUTC().toISO()!;

  return (
    <tr>
      <th className='text-nowrap'>{rowNumber}</th>
      <td className='text-center'>
        {order.submittedAt && (
          <span className={isJustSubmitted ? 'badge bg-blue' : 'badge badge-outline text-blue'}>
            <TimeAgo datetime={order.submittedAt} />
          </span>
        )}
      </td>
      <td>
        <b>{order.lastName}</b> {order.firstName}
      </td>
      <td>{order.phone}</td>
      <td className='text-center'>
        <OrderStatusBadge order={order} />
      </td>
      <td className='text-end'>{formatPriceInSmallestUnitToString(order.totalPrice, 'EUR')}</td>
      <td className='text-end'>
        <OrderQuickViewButton order={order} />
      </td>
    </tr>
  );
}

function OrderStatusBadge({ order }: { order: MenuOrder }) {
  const { t } = useTranslation(['menu']);
  if (order.expiresAt && order.expiresAt < DateTime.now().toISO()!) {
    return <span className='badge bg-danger text-white'>{t('menu:orders.expired')}</span>;
  }

  if (order.status === 'draft') {
    return <span className='badge bg-secondary text-white'>{t('menu:orders.draft')}</span>;
  }

  if (order.status === 'await-payment') {
    return <span className='badge bg-warning text-white'>{t('menu:orders.awaitingPayment')}</span>;
  }

  if (order.status === 'submitted') {
    return <span className='badge bg-success text-white'>{t('menu:orders.submitted')}</span>;
  }

  return <span className='badge bg-secondary text-white'>{t('menu:orders.unknown')}</span>;
}

function OrderQuickViewButton({ order }: { order: MenuOrder }) {
  const { t } = useTranslation(['menu']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        className='btn btn-icon'
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IconSearch size='1em' />
      </Button>
      <Modal
        title={t('menu:orders.order')}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size='lg'
      >
        <OrderQuickView order={order} />
      </Modal>
    </>
  );
}

function OrderQuickView({ order }: { order: MenuOrder }) {
  const { t } = useTranslation(['menu']);
  return (
    <>
      <div className='datagrid'>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.name')}</div>
          <div className='datagrid-content'>
            <b>{order.lastName}</b> {order.firstName}
          </div>
        </div>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.email')}</div>
          <div className='datagrid-content'>{order.email}</div>
        </div>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.phone')}</div>
          <div className='datagrid-content'>{order.phone}</div>
        </div>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.created')}</div>
          <div className='datagrid-content'>
            <TimeAgo datetime={order.createdAt} />
          </div>
        </div>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.submitted')}</div>
          <div className='datagrid-content'>
            {order.submittedAt ? (
              <TimeAgo datetime={order.submittedAt} />
            ) : (
              <span className='badge bg-secondary'>{t('menu:orders.notSubmitted')}</span>
            )}
          </div>
        </div>
        <div className='datagrid-item'>
          <div className='datagrid-title'>{t('menu:orders.status')}</div>
          <div className='datagrid-content'>
            <OrderStatusBadge order={order} />
          </div>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='card-header'>
          <ul className='nav nav-tabs card-header-tabs' data-bs-toggle='tabs' role='tablist'>
            <li className='nav-item' role='presentation'>
              <a href='#items' className='nav-link active show' data-bs-toggle='tab' role='tab'>
                Order
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a href='#payments' className='nav-link' data-bs-toggle='tab' role='tab'>
                Payments
              </a>
            </li>
          </ul>
        </div>
        <div className='tab-content'>
          <div className='tab-pane active show' id='items' role='tabpanel'>
            <OrderItemsTable order={order} />
          </div>
          <div className='tab-pane' id='payments' role='tabpanel'>
            <OrderPaymentsTable order={order} />
          </div>
        </div>
      </div>
    </>
  );
}

function OrderItemsTable({ order }: { order: MenuOrder }) {
  const { t } = useTranslation(['menu']);

  return (
    <div className='table-responsive'>
      <table className='table table-vcenter table-mobile-sm card-table'>
        <thead>
          <tr>
            <th className='text-nowrap'>
              <b>{t('menu:orders.item')}</b>
            </th>
            <th className='text-nowrap text-end'>
              <b>{t('menu:orders.price')}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item) => (
            <tr key={item.uuid}>
              <td>
                {item.quantity}x {item.itemName}
              </td>
              <td className='text-end'>
                {formatPriceInSmallestUnitToString(item.totalPrice, 'EUR')}
              </td>
            </tr>
          ))}
          <tr className='table-active'>
            <td className='text-nowrap'>
              <b>{t('menu:orders.total')}</b>
            </td>
            <td className='text-end'>
              <b>{formatPriceInSmallestUnitToString(order.totalPrice, 'EUR')}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function OrderPaymentsTable({ order }: { order: MenuOrder }) {
  const { t } = useTranslation(['menu']);
  const { paymentMethods } = useMenuContext();

  const paymentsQuery = trpc.menu.admin.getOrderPayments.useQuery({ orderUuid: order.uuid });

  if (paymentsQuery.isLoading) {
    return (
      <div className='m-4'>
        <LoadingSpinner center />
      </div>
    );
  }

  if (paymentsQuery.error) {
    return <ErrorAlert errors={paymentsQuery.error} />;
  }

  const { payments } = paymentsQuery.data;

  return (
    <div className='table-responsive'>
      <table className='table table-vcenter table-mobile-sm card-table'>
        <thead>
          <tr>
            <th className='text-nowrap'>
              <b>{t('menu:orders.paymentMethod')}</b>
            </th>
            <th className='text-nowrap'>
              <b>{t('menu:orders.priceAmount')}</b>
            </th>
            <th className='text-nowrap'>
              <b>{t('menu:orders.status')}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.uuid}>
              <td>{paymentMethods.find((pm) => pm.uuid === payment.methodUuid)?.name ?? 'N/A'}</td>
              <td>{formatPriceInSmallestUnitToString(payment.amountCents, 'EUR')}</td>
              <td>{payment.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Switch({
  id,
  checked,
  onChange,
  label,
}: {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}) {
  return (
    <div className='form-check form-switch'>
      <input
        className='form-check-input'
        type='checkbox'
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label className='form-check-label' htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export const TimeAgo = ({ datetime }: { datetime: string }) => {
  const [time, setTime] = useState(DateTime.fromISO(datetime).toRelative());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(DateTime.fromISO(datetime).toRelative());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });

  return <>{time}</>;
};
