import timezones, { TimeZone } from 'timezones-list';

export const getTimezonesByRegions = () => {
  const regions = timezones.reduce(
    (acc, timezone) => {
      const region = timezone.tzCode.split('/')[0];
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(timezone);
      return acc;
    },
    {} as { [key: string]: TimeZone[] },
  );
  return regions;
};
