import { IconBrandWhatsapp } from '@tabler/icons-react';
import { trpc } from '../../../../utils/trpc';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import ErrorAlert from '../../../common/components/ErrorAlert';
import Card from '../../../common/components/Card';
import { BookingGuestNotification } from '../../api/booking-guest-notifications';
import SelfWhatsAppNotificationCard from './providers/self-whatsapp/SelfWhatsAppNotificationCard';
import { SelfWhatsAppBookingGuestNotification } from './types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '../../../common/components/form/Button';
import SelfWhatsAppNotificationForm from './providers/self-whatsapp/SelfWhatsAppNotificationForm';

export default function ViewBookingGuestNotifications() {
  const { t } = useTranslation(['pms']);
  const bookingGuestNotificationsQuery =
    trpc.pms.bookingGuestNotification.getBookingGuestNotifications.useQuery();
  const [showCreateForm, setShowCreateForm] = useState(false);

  if (bookingGuestNotificationsQuery.isLoading) {
    return <LoadingSpinner cardBody center />;
  }

  if (bookingGuestNotificationsQuery.error) {
    return <ErrorAlert errors={bookingGuestNotificationsQuery.error} />;
  }

  const bookingGuestNotifications = bookingGuestNotificationsQuery.data;

  const selfWhatsAppBookingGuestNotifications = bookingGuestNotifications.filter(
    isSelfWhatsAppNotification,
  );

  return (
    <Card
      title={
        <span>
          <IconBrandWhatsapp size='1em' /> WhatsApp
        </span>
      }
    >
      <div className='d-flex flex-column gap-2'>
        <span className='text-muted'>
          {t('pms:configuration.bookingGuestNotificationsSettings.selfWhatsApp.description')}
        </span>
        {showCreateForm ? (
          <Card>
            <SelfWhatsAppNotificationForm
              action='create'
              onSuccess={() => {
                setShowCreateForm(false);
                bookingGuestNotificationsQuery.refetch();
              }}
              onCancel={() => setShowCreateForm(false)}
            />
          </Card>
        ) : (
          <Button onClick={() => setShowCreateForm(true)}>
            {t('pms:configuration.bookingGuestNotificationsSettings.selfWhatsApp.create')}
          </Button>
        )}
        {selfWhatsAppBookingGuestNotifications.map((notification) => (
          <SelfWhatsAppNotificationCard key={notification.uuid} notification={notification} />
        ))}
      </div>
    </Card>
  );
}

function isSelfWhatsAppNotification(
  notification: BookingGuestNotification,
): notification is SelfWhatsAppBookingGuestNotification {
  return notification.selfWhatsAppBookingGuestNotification !== null;
}
