import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
  IconArrowRight,
  IconDoor,
  IconFileEuro,
  IconHome,
  IconInfoCircle,
  IconSun,
} from '@tabler/icons-react';
import { Link, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { getPathsFromPathname } from '../../../../../../utils/string';
import NavTabs from '../../../../components/NavTabs';
import EditAccommodation from './EditAccommodation';
import ViewRates from './rates/ViewRates';
import ViewUnits from './units/ViewUnits';
import ViewSeasons from './seasons/ViewSeasons';
import { useTranslation } from 'react-i18next';
import { usePmsContext } from '../../../../Context';

export default function ViewAccommodation() {
  const { t } = useTranslation(['pms']);
  const [parentRef] = useAutoAnimate();
  const location = useLocation();
  const { uuid } = useParams();

  const locationPaths = getPathsFromPathname(location.pathname);
  const locationPath = locationPaths[locationPaths.length - 1];

  if (uuid === undefined) {
    return <Navigate to='../' />;
  }

  const navItems = [
    { id: 'overview', label: <IconHome size='1.4em' />, link: './' },
    {
      id: 'edit',
      label: t('pms:configuration.accommodationSettings.accommodation'),
      link: './edit',
    },
    { id: 'units', label: t('pms:configuration.accommodationSettings.units'), link: './units' },
    { id: 'rates', label: t('pms:configuration.accommodationSettings.rates'), link: './rates' },
    {
      id: 'seasons',
      label: t('pms:configuration.accommodationSettings.seasons'),
      link: './seasons',
    },
  ];
  const activeId =
    navItems.find((item) => item.link.replace('./', '') === locationPath)?.id ?? 'overview';

  return (
    <>
      <NavTabs items={navItems} activeId={activeId} />
      <div className='pt-3' ref={parentRef}>
        <Routes>
          <Route index element={<Overview />} />
          <Route path='/edit' element={<EditAccommodation uuid={uuid} />} />
          <Route path='/units' element={<ViewUnits accommodationUuid={uuid} />} />
          <Route path='/rates' element={<ViewRates accommodationUuid={uuid} />} />
          <Route path='/seasons' element={<ViewSeasons accommodationUuid={uuid} />} />
        </Routes>
      </div>
    </>
  );
}

function Overview() {
  const { t } = useTranslation(['pms']);
  const { accommodations, unitTypes } = usePmsContext();
  const accommodation = accommodations.find((acc) => acc.uuid === useParams().uuid);

  let unitClassNameSuffix = '';
  if (accommodation) {
    const accommodationUnitTypes = unitTypes.filter(
      (ut) => ut.accommodationUuid === accommodation.uuid,
    );
    if (
      accommodationUnitTypes.length === 0 ||
      accommodationUnitTypes.some((ut) => ut.units.length === 0)
    ) {
      unitClassNameSuffix = 'bg-warning text-white';
    }
  }

  return (
    <>
      <div className='col-md-6 mb-3'>
        <Link to='./edit' className='card card-link'>
          <div className='card-body d-flex justify-content-between'>
            <span>
              <IconInfoCircle className='me-2' />
              {t('pms:configuration.accommodationSettings.editAccommodationInformation')}
            </span>
            <IconArrowRight />
          </div>
        </Link>
      </div>
      <div className='col-md-6 mb-3'>
        <Link to='./units' className={`card card-link ${unitClassNameSuffix}`}>
          <div className='card-body d-flex justify-content-between'>
            <span>
              <IconDoor className='me-2' />
              {t('pms:configuration.accommodationSettings.editUnitsAndUnitTypes')}
            </span>
            <IconArrowRight />
          </div>
        </Link>
      </div>
      <div className='col-md-6 mb-3'>
        <Link to='./rates' className='card card-link'>
          <div className='card-body d-flex justify-content-between'>
            <span>
              <IconFileEuro className='me-2' />
              {t('pms:configuration.accommodationSettings.editRates')}
            </span>
            <IconArrowRight />
          </div>
        </Link>
      </div>
      <div className='col-md-6'>
        <Link to='./seasons' className='card card-link'>
          <div className='card-body d-flex justify-content-between'>
            <span>
              <IconSun className='me-2' />
              {t('pms:configuration.accommodationSettings.editSeasons')}
            </span>
            <IconArrowRight />
          </div>
        </Link>
      </div>
    </>
  );
}
