import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { createMutation } from '../../../utils/api';
import { trpc } from '../../../utils/trpc';
import Button from '../../common/components/form/Button';
import FormInput from '../../common/components/form/input';
import { FormErrors } from '../../common/utils/api';
import { MenuSection } from '../api/menu-page';
import { useMenuContext } from '../Context';

const EMPTY_I18N = {};

type MenuSectionFormProps = {
  onCancel?: () => void;
  onSuccess?: (uuid: string) => void;
} & (
  | {
      action: 'create';
    }
  | {
      action: 'update';
      menuSection: MenuSection;
    }
);

export default function MenuSectionForm(props: MenuSectionFormProps) {
  const { action, onCancel } = props;
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formMenuSectionName, setFormMenuSectionName] = useState<Record<string, string>>({});
  const menuContext = useMenuContext();

  const trpcContext = trpc.useUtils();
  const mutationOptions = {
    invalidate: [trpcContext.menu],
    setFormErrors,
    onSuccess: (data: { uuid: string }) => {
      setFormErrors(null);
      props.onSuccess?.(data.uuid);
    },
  };
  const createSection = createMutation(trpc.menu.admin.createMenuSection, mutationOptions);
  const updateSection = createMutation(trpc.menu.admin.updateMenuSectionMetadata, mutationOptions);
  const deleteSection = createMutation(trpc.menu.admin.deleteMenuSection, mutationOptions);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name: formMenuSectionName,
    };

    if (action === 'create') {
      createSection.mutate(data);
    } else {
      updateSection.mutate({ ...data, uuid: props.menuSection.uuid });
    }
  };

  const isUpserting = createSection.isLoading || updateSection.isLoading;
  const isDeleting = deleteSection.isLoading;
  const isLoading = isUpserting || isDeleting;

  const isRootSection = action === 'update' && menuContext.isRootSection(props.menuSection.uuid);
  const showDeleteButton = action === 'update' && !isRootSection;

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col'>
          <FormInput
            type='i18n'
            title={isRootSection ? 'Page name' : 'Section name'}
            name='menuSectionName'
            errorPath={['name']}
            formErrors={formErrors}
            disabled={isLoading}
            defaultValue={action === 'update' ? props.menuSection.name.translations : EMPTY_I18N}
            onChange={setFormMenuSectionName}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='row'>
            {showDeleteButton && (
              <div className='col-2'>
                <Button
                  type='button'
                  className='btn btn-icon btn-outline-danger w-100'
                  isLoading={isDeleting}
                  disabled={isLoading}
                  hideContentWhenLoading
                  onClick={() => {
                    if (confirm('Are you sure you want to delete this section?')) {
                      deleteSection.mutate({ uuid: props.menuSection.uuid });
                    }
                  }}
                >
                  <IconTrash size='1em' />
                </Button>
              </div>
            )}
            {onCancel && (
              <div className={showDeleteButton ? 'col-4' : 'col-6'}>
                <Button type='button' className='btn w-100' disabled={isLoading} onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            )}
            <div className='col-6'>
              <Button disabled={isLoading} isLoading={isUpserting} hideContentWhenLoading>
                {action === 'create' ? 'Create' : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
